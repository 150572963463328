import { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { Typography, Grid, Divider,Tabs, Tab } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import BrandComponent from "./BusinessTypeComponents/BrandComponent";
import BannerComponent from "./BusinessTypeComponents/BannerComponent";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";

export default function BusinessType() {
  
  const dispatch = useDispatch();
  const [businessTypes, setBusinessTypes] = useState([]);
  let [widgets, setWidgets] = useState([{ widgetNo: 1, widgetData:{} }]);
  let [bannerWidget, setBannerWidget] = useState([{ widgetNo: 1, widgetData:{} }]);
  let [usedBusinessTypes, setUsedBusinessTypes] = useState([]);
  let [usedBannerBusinessTypes, setUsedBannerBusinessTypes] = useState([]);
  let [tabValue, setTabValue] = useState('brand');

  const getBusinessTypes = async () => {
    const response = await GlobalService(API.GET_ALL_BUSINESS);
    setBusinessTypes(response.businesstype.rows.filter((type) => type.slug !== "grocery"));
  };

  const getWidgetData = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.WIDGET.GET_ALL_BUSINESS_WIDGETS);
    const widgetsForBrand = response?.data.filter((widget) => widget.widget_style === "brand");
    const widgetForBanner = response?.data.filter((widget) => widget.widget_style === "banner");

    widgets = widgetsForBrand.map((widget, index) => {
      return { widgetNo: index + 1, widgetData: widget };
    });

    bannerWidget = widgetForBanner.map((widget, index) => {
      return { widgetNo: index + 1, widgetData: widget };
    });
    usedBusinessTypes = widgetsForBrand.map((widget) => widget.type_id);
    setUsedBusinessTypes(usedBusinessTypes);

    usedBannerBusinessTypes = widgetForBanner.map((widget) => widget.type_id);
    setUsedBannerBusinessTypes(usedBannerBusinessTypes);

    setWidgets(widgets);
    setBannerWidget(bannerWidget);
    dispatch(changeLoader(false));
  };
  
  const addSection = () => {
    const newSectionId = widgets.length + 1;
    widgets = [...widgets, { widgetNo: newSectionId, widgetData: {} }];
    setWidgets(widgets);
    setTimeout(() => {
      document.getElementById(`brandComponent-${newSectionId - 1}`)?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const addBannerSection = () => {
    const newSectionId = bannerWidget.length + 1;
    bannerWidget = [...bannerWidget, { widgetNo: newSectionId, widgetData: {} }]
    setBannerWidget(bannerWidget);
    setTimeout(() => {
    document.getElementById(`BannerComponent-${newSectionId - 1}`).scrollIntoView({ behavior: "smooth" });
    }, 500);
  }

  const removeEmptySection = (idToRemove) => {
    setWidgets(widgets.filter((widget) => widget.widgetNo !== idToRemove));
  }

  const removeEmptyBannerSection = (idToRemove) => {
    setBannerWidget(bannerWidget.filter((widget) => widget.widgetNo !== idToRemove));
  }

  const removeSection = async (idToRemove) => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.WIDGET.DELETE_BUSINESS_WIDGETS, {
      ids: idToRemove.toString(),
      type: "widget",
    }, "delete");
    if (response?.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: "Widget deleted successfully"
      }))
      getWidgetData()
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: response?.msg
      }))
    }
    dispatch(changeLoader(false));
  };

  useLayoutEffect(() => {
    getBusinessTypes();
  }, []);

  useEffect(() => {
    getWidgetData();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Business Type Management System
                </Typography>
                <Tabs
                  variant="scrollable" scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}
                >
                  <Tab className={""} label={`Brand`} value={'brand'} />
                  <Tab className={""} label={`Banner`} value={'banner'} />
                </Tabs>
                <Divider />
              </Grid>
              {tabValue === 'brand'? <>
              {widgets.map((widget, index) => (
                <span
                  key={`brandComponent-${index}`}
                  id={`brandComponent-${index}`}
                >
                  <BrandComponent
                    widgetIndex={index + 1}
                    addSection={addSection}
                    removeSection={removeSection}
                    removeEmptySection={removeEmptySection}
                    businessTypes={businessTypes}
                    widget={widget}
                    usedBusinessTypes={usedBusinessTypes}
                    getWidgetData={getWidgetData}
                  />
                </span>
              ))}
              </>: null}

              {tabValue === 'banner'? <>
              {bannerWidget.map((widget, index) => (
                <span
                  key={`BannerComponent-${index}`}
                  id={`BannerComponent-${index}`}
                >
                  <BannerComponent
                    widgetIndex={index + 1}
                    addSection={addBannerSection}
                    removeSection={removeSection}
                    removeEmptySection={removeEmptyBannerSection}
                    businessTypes={businessTypes}
                    widget={widget}
                    usedBusinessTypes={usedBannerBusinessTypes}
                    getWidgetData={getWidgetData}
                  />
                </span>
              ))}
              </>: null}

            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
