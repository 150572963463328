import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getdataByKey, getUomImage,getAddonFromOrder } from "../../../helper/custom";
import { Link } from "react-router-dom";

const OrderItem = ({ orderItem, index, storeId }) => {

	let [uonSelect, setUonSelect] = useState(null)
	let [price, setPrice] = useState(0)

	useEffect(() => {
		if (orderItem) {
			uonSelect = orderItem?.product?.uoms.find((uom) => uom?.id === orderItem?.uomId)
			setUonSelect(uonSelect)
			price = (orderItem?.salePrice !== 0 && orderItem?.salePrice !== orderItem?.price) ?
				orderItem?.salePrice : orderItem?.price
			setPrice(price)
		}
	}, [orderItem]);
	let orderItemaddons	=getAddonFromOrder(orderItem?.addons_json);
	

    return (
		<TableRow key={`item-${index}`}>
			<TableCell>
				<div className="flex" style={{alignItems: 'flex-start'}}>
					<img
						src={getUomImage(uonSelect?.uom_images)}
						alt=""
						className="img32"
					/>
					<div className="">
						<span className="product-title">
							{getdataByKey(orderItem?.product?.products_locales, "en", 'title')} {uonSelect?.uomName}
						</span>
						<p className="product-title w-500 mb-0">{orderItemaddons}</p>
					{orderItem?.offers.length > 0?<div className="">
						<span className="product-title">
							Offer/Discount: {orderItem?.offers[0]?.name}
						</span>
						
						</div> : null}
						{orderItem?.specialRequest && (
							<span className="product-title">
								Special Request: {orderItem.specialRequest}
							</span>
						 )}
					</div>
				</div>
			</TableCell>
			<TableCell>
				{orderItem?.quantity} x QAR{" "}{price?.toFixed(2)}{" "}
				{(orderItem?.salePrice !== 0 && orderItem?.salePrice !== orderItem?.price)? 
				<span className="gray-through">
					QAR {orderItem?.price?.toFixed(2)}
				</span>: null}
			</TableCell>
			<TableCell align="right">QAR{" "} {(price * +orderItem?.quantity).toFixed(2)}</TableCell>
			{storeId === 1?<TableCell align="right">
				<Link
					to={`/stock-ledger/${orderItem?.product?.id}`}
					target="_blank"
				>View Stock</Link>
			</TableCell>: null}
		</TableRow>
    )

}

export default OrderItem;