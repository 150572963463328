import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { Typography, TextField, Button } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aside from "../../layout/aside/Aside";
import { useEffect, useState } from "react";
import services from "../../../services";
import Header from "../../layout/header/Header";
// import { spliceDate } from "../../global/spliceDate";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { Grid } from "@mui/material";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { getFormatedDate } from "../../helper/custom";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";

export default function DriverOnboardingListing() {

  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [driverlist, setDriverlist] = useState(["a", "b"]);
  let [count, setCount] = useState(1);
  let [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [sort_by, set_sort_by] = useState("");
  const [order_by, set_order_by] = useState("desc");
  let [page, setPage] = useState(1);
  const [fleetForDelet, setFleetForDelet] = useState("");

  const routerDriverOnboardingDetails = (fleet_id) => {
    const path = fleet_id?
      `/driver-onboarding-Details/${fleet_id}`:
      `/driver-onboarding-Details`;
    navigate(path);
  };
  const handlePageChange = (event, value) => {
    page = value;
    setPage(page);
    getDrivers();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      set_sort_by("");
      page = 1;
      setPage(page)
      setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const changeOrderBy = (order, value) => {
    if (order === "asc") set_order_by("asc");
    else if (order === "desc") set_order_by("desc");
    set_sort_by(value);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleClose = () => {
    setOpenDelete(false);
  };
  const getDrivers = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageDriver?.GET_DRIVERS(
      limit, page, sort_by, order_by, search
    );
    if (response?.data.ack == 1) {
      setDriverlist(response?.data?.drivers);
      count = Math.ceil(response?.data?.total_count / limit);
      setCount(count)
    }
    dispatch(changeLoader(false));
  };
  useEffect(() => {
    page = 1;
    setPage(page);
    getDrivers();
  }, [search, sort_by,order_by]);

  const deleteDriver = async (id) => {
    try {
      dispatch(changeLoader(true));
      const response = await services?.manageDriver?.DELETE_DRIVER(id);
      if (response?.data?.ack == 1) {
        handleClose();
        getDrivers();
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.msg,
            state: "success",
          })
        );
        dispatch(changeLoader(false));
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.message,
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanAdd(permission?.driverOnboarding.create === 1)
      setCanEdit(permission?.driverOnboarding.edit === 1)
      setCanDelete(permission?.driverOnboarding.delete === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Driver Onboarding Listing
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    placeholder="Driver name.."
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: "8px" }} />
                      ),
                    }}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                {canAdd?<Button
                  onClick={() => {
                    routerDriverOnboardingDetails(null);
                  }}
                  variant="contained"
                  size="medius"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Add Driver Onboarding Details
                </Button>: null}
              </div>
            </div>
            {driverlist.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Driver Id
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "id");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "id");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* first_name */}
                      <div className="short-div">
                        Full Name
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "first_name");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "first_name");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* email */}
                      <div className="short-div">
                        Email ID
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "email");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "email");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Phone Number
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "phone");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "phone");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {/* createdAt */}
                      <div className="short-div">
                        Registered Date
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("asc", "createdAt");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("desc", "createdAt");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    { canEdit || canDelete ?
                      <TableCell align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {driverlist?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item?.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.first_name} {item?.last_name}
                        </TableCell>
                        <TableCell>{item?.email}</TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">
                          {getFormatedDate(item?.createdAt)}
                        </TableCell>

                        { canEdit || canDelete ?
                          <TableCell align="center">
                          {canEdit?<Tooltip
                            title="Edit"
                            placement="top"
                            sx={{ marginRight: 1 }}
                            onClick={() => {
                              routerDriverOnboardingDetails(item?.fleet_id);
                            }}
                          >
                            <BorderColorIcon color="action" fontSize="small" />
                          </Tooltip>: null}
                          {canDelete?<Tooltip
                            title="Delete"
                            placement="top"
                            onClick={() => {
                              setFleetForDelet(item?.fleet_id);
                              setOpenDelete(true);
                            }}
                          >
                            <DeleteForever color="error" fontSize="small" />
                          </Tooltip>: null}
                        </TableCell>: null}

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound
              text={"No Driver Found"}
            />}
            {count > 1?<Pagination
              count={count}
              page={page}
              color="primary"
              onChange={handlePageChange}
            />: null}
          </Box>
        </Box>
      </Box>
      <Dialog open={openDelete} onClose={handleClose} className="alert-delete">
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  {" "}
                  Are you sure you want to delete this driver?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => {
                    deleteDriver(fleetForDelet);
                  }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
