import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { stateWithValidation } from "../pages/helper/Validation";


const RejectOrder = ({ isOpen = false, handleClose, submit, cancelReasons }) => {
  const [message, setMessage] = useState({
    val: "",
    isValid: false,
    err: "",
  });
  let [cancelReasonOther, setCancelReasonOther] = useState('');

  const submits = () => {
    let returnMessage = '';
    if (message.val !== "" && message.val !== "other") {
      returnMessage = message.val;
    } else if (message.val === "other" && cancelReasonOther !== "") {
      returnMessage = cancelReasonOther;
    }
    if (returnMessage !== "") {
      submit(returnMessage);
    } else {
      setMessage({
        ...message,
        err: "Please enter reason",
      });
    }
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          handleClose();
          setMessage({
            val: "",
            isValid: false,
            err: "",
          });
        }}
        className="main-modal maxwidth-430"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => {
            handleClose();
            setMessage({
              val: "",
              isValid: false,
              err: "",
            });
          }}
          className="modal-close"
        />
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "8px", fontSize: "20px" }}>
            Reject Order
          </h2>
          <p className="p3" style={{ marginBottom: "30px", fontSize: "14px" }}>
            Are you sure you want to reject order?
          </p>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={message.val}
              label="Reason"
              onChange={(e) => {
                setMessage(stateWithValidation(e.target.value, "rejectReason"))
              }}
            >
              {cancelReasons?.map((item, index) => (
                item.status ==='active'?<MenuItem key={index} value={item.reason}>
                  {item.reason}
                </MenuItem>: null
              ))}
              <MenuItem key={'vendorClosed'} value={'vendor closed'}>Vendor closed</MenuItem>
            <MenuItem key={'other'} value={'other'}>Other</MenuItem>
            </Select>
          </FormControl>

          {message.val === 'other'?
            <TextField
            id="outlined-multiline-flexible"
            label="Reason"
            style={{ marginTop: "20px" }}
            multiline={true}
            rows={3}
            value={cancelReasonOther}
            onChange={(e) =>
              setCancelReasonOther(e.target.value)
            }
          />: null}
          <div className="error">{message.err}</div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ marginTop: "30px" }}
            disabled={!message.isValid}
            onClick={() => submits()}
          >
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default RejectOrder;
