import React, { useEffect, useState } from "react";
import moment from "moment";

const TrackingComponentIdle = ({in_pick,completed,placed } ) => {
  return (
    <>
    <div className="idle-tracking">
    <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                            Order Tracking
                          </h6>
    <div className=" order-tracking-box mb-4">
      <div
        className={`order-status position-relative w-150 ${placed[0]&& in_pick[0]?"active done" : placed[0]&& !in_pick[0] ?"active":""}`} // ${logger.class}
      >
        <p className="p2">Order Received</p>
        <p className="p4">{placed[0]?moment
          .tz(placed[0]?.updated_at, "Asia/Qatar")
          .format("DD MMMM YYYY hh:mm a"):""}</p>
      </div>
      <div
        className={`order-status position-relative w-150 ${ in_pick[0] && completed[0]?"active done" : in_pick[0]&& !completed[0]?"active":""}`} // ${logger.class}
      >
        <p className="p2">Pickup</p>
        <p className="p4">{in_pick[0]?moment
          .tz(in_pick[0]?.updated_at, "Asia/Qatar")
          .format("DD MMMM YYYY hh:mm a"):""}</p>
      </div>
      <div
        className={`order-status position-relative w-150 ${completed[0]?"active":""}`} // ${logger.class}
      >
        <p className="p2">Pickup Complete</p>
        <p className="p4">{completed[0]?moment
          .tz(completed[0]?.updated_at, "Asia/Qatar")
          .format("DD MMMM YYYY hh:mm a"):""}</p>
      </div>
    </div>
    </div>
    </>
  );
};

export default TrackingComponentIdle;
