import { Typography, Grid } from "@mui/material";

const CustomerAddress = ({ TabPanel, value, address }) => {

  let addressCount = 1;

  return (
    <TabPanel value={value} index={1}>
      <Grid item xs={12}>
        <Typography variant="h6" component="h6" mb={3} fontSize={"16px"} fontWeight={500}>
          Address
        </Typography>
      </Grid>
      <div className="card-white">
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {address?.map((item, index) =>
              <Typography key={index} variant="h6" component="h6" fontSize={"14px"} fontWeight={400} mt={1}>
                {`${addressCount++}) ${item?.business_address}`}
              </Typography>)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
};

export default CustomerAddress;
