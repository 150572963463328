import React, { useEffect, useState } from "react";
import {
  Box, Pagination, Divider, Collapse, IconButton, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { API } from "../../../ENUM";
import { currentPickerSocket } from "../../../../redux/reducers/socket";
import { useSelector } from "react-redux";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import GlobalService from "../../../../services/GlobalService";
import moment from "moment-timezone";

const NotPicked = ({ sortBy, search }) => {

  const limit = 10;
  const currentPickerSocketObj = useSelector(currentPickerSocket);
  const [Color, setColor] = useState("");
  let [count, setCount] = useState();
  let [data, setData] = useState("");
  let [page, setPage] = useState(1);
  let [openIds, setOpenIds] = useState([]);

  const GET_COLOR = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_Time_Start_Picking;
      const response = await GlobalService(url);
      setColor(response?.startTime?.color);
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };
  
  const getAllOrder = async () => {
    const object = {
      // pickerStatus: "pending_pickup",
      page: page,
      limit: limit,
      sortBy: sortBy,
      search: search,
    };
    const query = new URLSearchParams(object);
    try {
      data = [];
      setData(data); // to empty
      let url = API.PICKER_TRACKING.GET_NOTPICKER_ORDER(query);
      const response = await GlobalService(url);
      count = Math.ceil(response.total_count / limit);
      setCount(count);
      if (response?.ack === 1) {
        setData(response?.orders);
        createData();
      } else {
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const createData = () => {
    let array =
      data &&
      data.length > 0 &&
      data.map((item, index) => {
        const productArray = item?.orders_details
          ? item?.orders_details.map((detail, index) => ({
              SNo: (index + 1).toString(),
              ProductName: detail?.product?.products_locales[0]?.title,
              // ProductName: detail?.product?.products_locales[1]?.title,
              ItemCode: detail?.product?.itemCode,
              PickupStatus: detail?.status,
            }))
          : [];
        let statusLog = item?.order_status_log !== '' ? JSON.parse(item?.order_status_log) : [];
        let log = statusLog.find((log) => log?.status === "placed");
        const orderDate = new Date(log?.updated_at);
        const currentDate = new Date(
          moment.tz(new Date(), "Asia/Qatar").format("DD MMMM YYYY hh:mm a")
        );
        const diff = Math.abs(currentDate - orderDate);

        const duration = Math.floor(diff / 1000 / 60);


        return {
          OrderID: item?.order_id,
          NoOfItems: item?.total_quantity,
          Priority:
            duration > item?.start_Time_Threshold - 2 ? "High" : "Medium",
          OrderDateTime:
            duration < item?.start_Time_Threshold
              ? Math.abs(item?.start_Time_Threshold - duration) + " min"
              : "Already OverDue",
          PlacementDateTime: moment(item?.createdAt).format(
            "DD MMMM YYYY hh:mm a"
          ),
          over_due:
            duration < item?.start_Time_Threshold
              ? "--"
              : Math.abs(duration - item?.start_Time_Threshold) + " min",
          TrafficColor:
            item?.start_Time_Threshold - duration > 0 ? "white" : Color,
          product: productArray,
        };
      });
    return array;
  };

  function Row({ row }) {
    // const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          style={{ borderWidth: "2px", borderColor: row?.TrafficColor }}
        >
          <TableCell component="th" scope="row">
            {row?.OrderID}
          </TableCell>
          <TableCell>{row?.NoOfItems}</TableCell>
          <TableCell>
            <span className="chips chips-inactive">{row?.Priority}</span>
            {/* you can change status color for use this classes 'chips-active', 'chips-pending','chips-inactive' */}
          </TableCell>
          <TableCell>
            {moment
              .tz(row?.PlacementDateTime, "Asia/Qatar")
              .subtract(30, "minutes")
              .format("DD MMMM YYYY hh:mm a")}
            {/* {
                moment(row?.PlacementDateTime).format("DD MMMM YYYY hh:mm a")
              } */}
          </TableCell>
          <TableCell>
            <span className={row?.OrderDateTime}>{row?.OrderDateTime}</span>
          </TableCell>
          <TableCell>
            <span className={row?.over_due ? "chips chips-inactive" : ""}>
              {row?.over_due}
            </span>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (openIds.includes(row?.OrderID)) {
                  openIds = openIds.filter((id) => id !== row?.OrderID);
                } else {
                  openIds = [...openIds, row?.OrderID];
                }
                setOpenIds(openIds)
              }}
            ><img src={openIds.includes(row?.OrderID) ? minusIcon: plusIcon} alt="plus-icon" /></IconButton>
          </TableCell>
        </TableRow>
        <Divider className="my-1" />
        <TableRow>
          <TableCell className="p-0 px-2" colSpan={7}>
            <Collapse in={openIds.includes(row?.OrderID)} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, backgroundColor: "#f9f9f9" }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>S no.</TableCell>
                      <TableCell>Product name</TableCell>
                      <TableCell>Item code</TableCell>
                      <TableCell>Picked Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.product.map((productListing, index) => (
                      <TableRow key={`productListing-${index}`}>
                        <TableCell component="th" scope="row">
                          {productListing.SNo}
                        </TableCell>

                        <TableCell>{productListing.ProductName}</TableCell>
                        <TableCell>{productListing.ItemCode}</TableCell>
                        <TableCell>
                          <span className="chips chips-pending">
                            Not picked
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllOrder();
  };
    
  useEffect(() => {
    if (currentPickerSocketObj) {
      getAllOrder();
    }
  }, [currentPickerSocketObj]);;

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllOrder();
    }, 500);
    return () => clearTimeout(getData);
  }, [sortBy, search]);

  useEffect(() => {
    GET_COLOR();
  }, []);

  return (
    <>
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>No. of Items</TableCell>
            <TableCell>Priority on the basis threshold</TableCell>
            <TableCell>Placement Date and Time</TableCell>
            <TableCell>Remaining Time</TableCell>
            <TableCell>Over Due By</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {createData() && createData().map((row, index) =>
            <Row row={row} key={`notpicked-${inde}`} /> )}
        </TableBody>
      </Table>
      {count > 1 ? 
        <Pagination count={count} page={page} onChange={handlePagination} color="primary" /> : null}
    </TableContainer>
    </>
  );
};
export default NotPicked;
