import constant from "../../constant";

export function getAddonFromOrder(addons_json){
   let itemsall=[];
   if(addons_json){
        addons_json=JSON.parse(addons_json);
        addons_json.map((item)=>{
          itemsall.push(getdataByKey(item.addon_items_locales,'en','name'));
        })
   }
   if(itemsall.length>0){
     return itemsall.join(', ');
   }
   return '';
}

export const utcTimeConvert = (time = "") => {
  if (time) {
    const utcTimestamp = time;
    let date = new Date(utcTimestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    date = year + "-" + month + "-" + day;
    return {
      date: time.split("T")[0],
    };
  }
  return {
    date: "",
  };
};

export const checkIfImageExists = (url) => {
  return url;
};

export const uomImages = (uom, qty = 0) => {};

export const getUomImage = (images) => {
  if (images) {
    let image = images.find((item) => item?.type == "front");
    const returnUrl = image ? image?.image : images[0]?.image;
    return constant.IMG_BASE_URL + returnUrl;
  } else {
    return "";
  }
};

export const getdataByKey = (data, type = "en", key = "") => {
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item[key];
  });
  return newData;
};

export const priceSet = (data) => {
  return "QAR " + data;
};

export const getStoreNameFromLocale = (data, type = "en") => {
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item?.name;
  });
  return newData;
};

export const getStoreDescriptionFromLocale = (data, type = "en") => {
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item?.description;
  });
  return newData;
};

export const getStoreNameBusinessFromLocale = (data, type = "en") => {
  let newData = "";
  data?.forEach((item) => {
    if (item?.locale == type) newData = item?.business_name;
  });
  return newData;
};

export const getAllBarcode = (barcodes) => {
  let barcode = [];
  barcodes?.forEach((item) => {
    barcode.push(item?.barcode);
  });
  return barcode.join(",");
};

export const priceHandler = (price) => {
  return price ? parseFloat(price).toFixed(2) : 0.0;
};
export const validateFromFields = (field, val) => {
  if (field == "email") {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(val);
  }
  if (field == "phone") {
    const numericPhoneNumber = val.replace(/\D/g, "");
    const phoneNumberPattern = /^[0-9]{10}$/;
    // return phoneNumberPattern.test(numericPhoneNumber);
    // ....
    const cleanedNumber = val.replace(/\D/g, "");
    if (cleanedNumber.length === 8) return true;
    return false;
  }
};

export const formatedDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getFormatedDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getFormatedDateDb = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

/**
 * @description convert time to 12 hour format
 * @param {*} time - time in 24 hour format
 * @returns string - time in 12 hour format
 */
export const convertTimeTo12Hour = (time) => {
  const hours = parseInt(time.split(":")[0], 10);
  const minutes = parseInt(time.split(":")[1], 10);
  const am_pm = hours >= 12 ? "PM" : "AM";
  let hour = hours % 12;
  hour = hour === 0 ? 12 : hour;
  const hourStr = hour < 10 ? `0${hour}` : hour;
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hourStr}:${minuteStr} ${am_pm}`;
};

/**
 * @description function to get all hours in a day with 10 minutes interval
 * @returns 
 */
export const generateHours = (timeDifference=10) => {
  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += timeDifference) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const time = `${formattedHour}:${formattedMinute}`;
      hours.push(time);
    }
  }
  hours.push(`24:00`);
  return hours;
};

/**
 * @description function to arrange days in a week sunday to saturday
 * @returns 
 */

export const arrangeDaysInWeek = (temp) => {
  let temp1 = [];
  temp.forEach((val, ind) => {
    if (val?.day == "sunday") {
      temp1[0] = val;
    } else if (val?.day == "monday") {
      temp1[1] = val;
    } else if (val?.day == "tuesday") {
      temp1[2] = val;
    } else if (val?.day == "wednesday") {
      temp1[3] = val;
    } else if (val?.day == "thursday") {
      temp1[4] = val;
    } else if (val?.day == "friday") {
      temp1[5] = val;
    } else if (val?.day == "saturday") {
      temp1[6] = val;
    }
  });
  return temp1;
}

//compress base64 image
export const reduce_image_file_size = async (
  base64Str,
  MAX_WIDTH = 1080,
  MAX_HEIGHT = 400
) => {
  let resized_base64 = await new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
};

export const base64toFile = (base64, name) => {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const extension = mime.split('/')[1];
  const filename = `${name}.${extension}`;
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) { u8arr[n] = bstr.charCodeAt(n); }
  return new File([u8arr], filename, { type: mime });
}

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};