import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid, Typography, TextField, Button, Alert, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, MenuItem, Select, Dialog, DialogContent
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import { API } from "../../../pages/ENUM";
import {getdataByKey } from "../../helper/custom";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import updateStateWithValidation from "../../helper/Validation";
import modalClose from "../../../assets/images/structure/close-modal.png";
import services from "../../../services";
import GlobalService from "../../../services/GlobalService";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";

export default function StoreRequestList() {

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canApprove, setCanApprove] = useState(true);
  let [canAssign, setCanAssign] = useState(true);

  // const [selectedBusiness, setSelectedBusiness] = useState("All");
  const [rejection, setRejection] = useState({
    isOpen: false,
    err: "",
    val: "",
    isValid: false,
    id: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState([]);
  const [storeRequstData, setStoreRequestData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState();
  let [search, setSearch] = useState("");
  let [filter, setFilter] = useState(0);
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });

  useEffect(() => {
    page = 1;
    setPage(page);
    getAllRequest();
  }, [filter, search, order_type, order_by]);

  useEffect(() => {
    page = searchParams.get('page')
    setPage(page);
    if (searchParams.get('search')) {
      search = searchParams.get('search')
      setSearch(search)
    }
    if (searchParams.get('filter')) {
      filter = searchParams.get('filter')
      setFilter(filter)
    }
    GetAllBusinessType();
  }, []);

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllRequest = async () => {
    setIsLoaded(true);
    try {
      const query =
        filter || search
          ? new URLSearchParams({
              page: page ? page : 1,
              limit: limit,
              search: search ? search : "",
              reqList: true,
              businessTypeId: filter === 0 ? "" : filter,
            }).toString()
          : new URLSearchParams({
              page: page ? page : 1,
              limit: limit,
              reqList: true,
              order_type,
              order_by,
            }).toString();
      let url = '/store-request';
      let options = [];
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (page) { options.push(`page=${page}`) }
      if (options.length > 0) { url += `?${options.join('&')}` }
      navigate(url)
      dispatch(changeLoader(true));
      const response = await services.Stores.GET_ALL_STORE(query);

      if (response) {
        setStoreRequestData(response.data.stores.rows);
        count = Math.ceil(response.data.stores.count / limit);
        setCount(count);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBusinessType = async () => {
    try {
      dispatch(changeLoader(true));
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();

      if (response) {
        setBusinesstypeData(response.data.businesstype.rows);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (e, value) => {
    if (count >= value) {
      page = value;
      setPage(page);
      getAllRequest();
    }
  };

  const editStoreRequest = (id) => {
    navigate(`/store-listing-detail/${id}/request/${page}?${searchParams.toString()}`);
  };

  const onChangeRejectionHandler = (e, type) => {
    const updatedState = updateStateWithValidation(e, rejection, type);
    setRejection({ ...updatedState, id: rejection.id, isOpen: true });
  };

  const onRejectionSaveHandler = async () => {
    try {
      if (rejection.val.trim() === '') {
        setRejection({ ...rejection, isValid:false, err: 'Reject reason is required' });
        return false
      }
      dispatch(changeLoader(true));
      const response = await services.Stores.UPDATE_STORE(rejection.id, {
        store: JSON.stringify({
          is_approved: false,
          reason_of_reject: rejection.val,
        }),
      });
      if (response.data.ack) {
        setRejection({ ...rejection, isOpen: false });
        setToaster({
          status: "error",
          text: `Store Request Reject`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllRequest();
      }

      dispatch(changeLoader(false));
    } catch (error) {
      setRejection({ ...rejection, isOpen: false });
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ ...toaster, visibilty: false });
      }, 2500);
    }
  };

  const handleApprove = async (id) => {
    try {
      dispatch(changeLoader(true));
      // const response = await services.Stores.UPDATE_STORE(id, {
      //   store: JSON.stringify({ is_approved: true, online: "online" }),
      // });
      const response = await GlobalService(API.STORE_REQUEST.APPROVE(id)); 
      if (response.ack === 1) {
        setToaster({
          status: "success",
          text: `Store Request approved`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 6000);
        getAllRequest();
      } else {
        setToaster({
          status: "error",
          text: `${response.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 6000);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({
          status: "",
          text: ``,
          visibilty: false,
        });
      }, 2500);
    }
  };

  const getStoreName = (data) => {
    let newData = "";
    data?.forEach((item) => {
      if (item?.locale == "en") newData = item?.name;
    });
    return newData;
  };

  const getStoreNameBusiness = (data) => {
    let newData = "";
    data?.forEach((item) => {
      if (item?.locale == "en") newData = item?.business_name;
    });
    return newData;
  };

  const MyTableData = ({
    item,
    index,
    canApprove = true,
    canAssign = true,
  }) => {
    let [address, setAddress] = useState("");

    const getGeoCode = async () => {
      let code = "No address found ";
      if (item?.business_address) {
        code = item?.business_address;
      }
      setAddress(code);
    };

    useEffect(() => {
      getGeoCode();
    }, []);

    return (
      <>
        <TableRow key={index}>
          <TableCell
            onClick={() => editStoreRequest(item.id)}
            component="th"
            scope="row"
          >
            {getStoreName(item?.business_type?.business_type_locales)}
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            {getStoreNameBusiness(item?.brands.stores_locales)}
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            {getStoreNameBusiness(item?.stores_locales)}
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            <div className="address-table w-270">{address}</div>
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            {item?.brands.user?.email}
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            <div className="owner-detail">
              <p className="name-text">{item?.brands.user?.full_name}</p>
              <p className="number-text">{item?.brands.user?.contact_number}</p>
            </div>
          </TableCell>
          <TableCell onClick={() => editStoreRequest(item.id)}>
            {moment(item.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY")}
          </TableCell>
          {canAssign ? (
            <TableCell>
              <a href={`/associate-zone/${item.id}`}>
                <small>Add/Edit associates</small>
              </a>
            </TableCell>
          ) : null}
          {canApprove ? (
            <TableCell className="nowrap" align="center">
              {item.is_approved === null ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  disabled={item?.user?.commission === null ? true : false}
                  sx={{ marginRight: "10px" }}
                  onClick={() => handleApprove(item.id)}
                >
                  Approve
                </Button>
              ) : null}
              {item.is_approved === null || item.is_approved === false ? (
                <Button
                  disabled={
                    item.is_approved === false || item?.user?.commission === null
                      ? true
                      : false
                  }
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() =>
                    setRejection({
                      ...rejection,
                      isOpen: true,
                      id: item.id,
                      err: ''
                    })
                  }
                >
                  {item.is_approved === false ? "Rejected" : "Reject"}
                </Button>
              ) : null}
            </TableCell>
          ) : null}
        </TableRow>
      </>
    );
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.storeRequest.edit === 1);
      setCanApprove(permission?.storeRequest.actions.approveReject === 1);
      setCanAssign(permission?.storeRequest.actions.addEditAssociates === 1);
    } else {
      setCanEdit(true);
      setCanApprove(true);
      setCanAssign(true);
    }
  }, [permission]);

  return (
    <>
      {toaster.visibilty && toaster.status === "success" && (
        <Alert severity="success">{toaster.text}</Alert>
      )}
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Store Request
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    value={search}
                    placeholder="Search Store"
                    onChange={(e) => setSearch(e.target.value.trimStart())}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: "3px" }} />
                      ),
                      endAdornment: search ? (
                        <CloseIcon
                          onClick={() => {
                            setSearch("");
                          }}
                        />
                      ) : null,
                    }}
                  />
                </div>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={filter}
                  size="small"
                  onChange={(e) => {
                    setFilter(e.target.value);
                    page = 1;
                    setPage(page);
                  }}
                  className="status-drop"
                >
                  <MenuItem value={0}>All</MenuItem>
                  {businesstypeData &&
                    businesstypeData.length > 0 &&
                    businesstypeData.map((item, index) => {
                      return (
                        <MenuItem key={`menu-item-${index}`} value={item.id}>
                          {getdataByKey(item.business_type_locales, "en", "name")}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </div>
            {storeRequstData.length > 0 ? (
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="short-div">
                          Store Type
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Brand Name
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("business_name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("business_name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Store Name
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("business_name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("business_name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Business Address
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("business_address", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("business_address", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Owner Email
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("business_email", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("business_email", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Owner Detail
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("user_name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("user_name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Registered Date
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("createdAt", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("createdAt", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>

                      {canAssign ? (
                        <TableCell className="nowrap">Associates</TableCell>
                      ) : null}
                      {canApprove ? (
                        <TableCell className="nowrap" align="center">
                          Action
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeRequstData.map((item, index) => {
                      return (
                        <MyTableData
                          item={item}
                          index={`table-item-${item.id}`}
                          key={`table-item-${item.id}`}
                          canApprove={canApprove}
                          canAssign={canAssign}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoDataFound text="No Store Request Found" />
            )}
            {count > 1 && storeRequstData.length > 0 ? (
              <Pagination
                count={count}
                color="primary"
                page={page}
                onChange={(e, value) => handlePagination(e, value)}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={rejection.isOpen}
        onClose={() => setRejection({ ...rejection, isOpen: false })}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setRejection({ ...rejection, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Reason"
                  multiline
                  rows={2}
                  // value={rejection.val}
                  onChange={(e) => onChangeRejectionHandler(e, "paragraph_250")}
                />
                {rejection.err && (
                  <div style={{ color: "red" }}>{rejection.err}</div>
                )}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={!rejection.isValid}
                  onClick={() => onRejectionSaveHandler()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* delete Modal */}
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Store ?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
