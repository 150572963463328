import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import modalClose from "../../../../assets/images/structure/close-modal.png";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import Header from "../../../layout/header/Header";
import Aside from "../../../layout/aside/Aside";
import { Boxed } from "../../../../Styles-Elements/Box";
import deliverIcon from "../../../../assets/images/structure/deliver-icon.svg";
import {
  Box,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Alert,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import RejectOrder from "../../../../dialog/RejectOrder";
import moment from "moment";
import services from "../../../../services";
import { stateWithValidation } from "../../../helper/Validation";
import OrderItem from "./OrderItem";
import { useSelector } from "react-redux";
import { currentPermission } from "../../../../redux/reducers/permission";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import constant from "../../../../constant";

const Pending = ({ data, type = "pending" }) => {

  const statusRedirect = {
    placed: "orders",
    processing: "processing",
    readytopick: "ready-to-pick",
    shipped: "shipped",
    completed: "completed",
    rejected: "rejected",
    cancelled: "cancelled",
    pending: "pending"
  }
  const { orderId } = useParams();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canConfirm, setCanConfirm] = useState(true);
  let [canCancel, setCanCancel] = useState(true);
  // const [picker, setPicker] = useState("");
  // const [idealTIme,setIdealTIme] = useState("");

  const [productDetails, setProductDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState("");
  const [vendortiming, setVendortiming] = useState([]);
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [lastValue, setLastValue] = useState(null);
  const [rejection, setRejection] = useState({ isOpen: false });
  const [cancelOrder, setCancelOrder] = useState({
    isOpen: false,
    reason: { val: "", isValid: false, err: "" },
    refund: { val: "", isValid: false, err: "" },
    storePayout: { val: "", isValid: false, err: "" },
  });
  let [addressData, setAddressData] = useState(null);
  const [wallet, setWallet] = useState(0);
  let [store, setStore] = useState(null);
  let [cancelReasons, setCancelReasons] = useState([]);
  let [cancelReasonOther, setCancelReasonOther] = useState("");

  const getCancelReasons = async () => {
    const response = await GlobalService(API.GET_ALL_CANCELLATION_REASON);
    setCancelReasons(response.results);
  };
  // const getall = async () => {
  //   const res = await GlobalService(API.TRAFFIC_LIGHT_SYSTEM.GET_ALL);
  //  setIdealTIme(res?.results[8]?.time);
  // };
  // useEffect(() => {
  //   getall();
  // });

  const getOrderDetails = async (orderId) => {
    try {
      const response = await services.Orders.GET_ORDER_BY_ID(orderId);
      if (response?.data.orders.status !== 'pending') {
        navigate(`/order-manage-detail/${statusRedirect[response?.data.orders.status]}/${orderId}`);
      }
      setProductDetails(response.data.order_details);
      let total_MRP = 0;
      if (response?.data?.order_details.length > 0) {
        response?.data?.order_details?.map((item) => {
          total_MRP = total_MRP + item.price * +item.quantity;
          return total_MRP;
        });
      }
      addressData =
        response.data.orders?.addressJson &&
        response.data.orders?.addressJson !== ""
          ? JSON.parse(response.data.orders?.addressJson)
          : null;
      setAddressData(addressData);
      const placesData = response?.data?.orderLogs?.filter((item) => item.status === "placed");
      setOrderDetails({ ...response.data.orders, MRP: total_MRP, createdAt: placesData[0]?.updated_at });

      
      setVendortiming(JSON.parse(response.data?.ManageWorkingHour?.timejson || '[]'))
      
      store = response.data.orders?.store;
      setStore(store);
      if (response.data.transaction.length > 0) {
        const walletAmount = response.data.transaction.find(
          (item) => item.method === "wallet"
        );
        setWallet(walletAmount?.amount);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setCancelOrder({
      isOpen: false,
      reason: { val: "", isValid: false, err: "" },
      refund: { val: "", isValid: false, err: "" },
      storePayout: { val: "", isValid: false, err: "" },
    });
  };

  const handleOrderReject = async (orderId, message) => {
    if (message !== "") {
      try {
        let updatedData = { status: "rejected", reason_of_reject: message };
        const response = await services.Orders.REJECT_PENDING_ORDER(
          orderId,
          updatedData
        );
        if (response) {
          setRejection({ isOpen: false });
          setToaster({
            status: "success",
            text: `Order Rejected Successfuly`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 2500);
          getOrderDetails(orderId);
          // navigate("/order-listing");
        }
      } catch (error) {
        setRejection({ isOpen: false });
        setToaster({
          status: "error",
          text: `Something Went Wrong`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 2500);
      }
    }
  };

  const handleOrderAccept = async () => {
    try {
      const response = await services.Orders.CONFIRM_ORDER(orderId, {
        status: "processing",
      });
      if (response) {
        setToaster({
          status: "success",
          text: `Order Confirmed Successfuly`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 2500);
        getOrderDetails(orderId);
        // navigate("/order-listing");
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `Something Went Wrong`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({
          status: "",
          text: ``,
          visibilty: false,
        });
      }, 2500);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('tabtype');
  const goBack = () => myParam === "false"?
    navigate(`/order-listing`):
    navigate(`/order-listing?${orderDetails.storeId === 1? 'isbaladi=baladi&':''}status=pendingBaladi`);
  
  useEffect(() => {
    getOrderDetails(orderId);
    getCancelReasons();
  }, [orderId]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanConfirm(
        permission?.orderListing?.actions?.confirmPendingOrder === 1
      );
      setCanCancel(permission?.orderListing?.actions?.cancelOrder === 1);
    } else {
      setCanConfirm(true);
      setCanCancel(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <div className="order-listing-wrapper">
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={"20px"}
              >
                <div
                  className="store-detail-head"
                  style={{ marginBottom: "0px" }}
                >
                  <img
                    src={BackIcon}
                    alt="back-icon"
                    style={{ cursor: "pointer" }}
                    onClick={goBack}
                  />
                  <Typography
                    variant="h6"
                    component="h6"
                    mb={0}
                    fontWeight={500}
                  >
                    Orders Detail
                  </Typography>
                </div>
                <div className="search-box"></div>
              </Boxed>
              <div className="">
                <div className="tabs-wrap">
                  <div className="">
                    <div className="table-type-parent">
                      <Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          mb={2}
                          fontSize={"14px"}
                          fontWeight={500}
                        >
                          Pending Orders
                        </Typography>
                        {vendortiming && vendortiming.length > 0 && (
                              <Typography
                                variant="h6"
                                component="h6"
                                mb={2}
                                fontSize={"14px"}
                                fontWeight={500}
                              >
                                <span> Vendor Timing: </span><br />
                                {vendortiming.map((timing, index) => (
                                  <div key={index}>
                                    <span>Opening: {timing.starttime} , </span> 
                                    <span>Closing: {timing.endtime} </span><br />
                                  </div>
                                ))}
                              </Typography>
                            )}
                      </Box>

                      <div className="table-type-box">
                        <div className="table-type-td flex">
                          <img
                            src={deliverIcon}
                            alt="deliver"
                            className="icon24"
                          />
                          <div className="relative">
                            <span className="type-th">Order Type</span>
                            <h3 className="type-td">
                              Deliver {orderDetails?.deliver}
                            </h3>
                          </div>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Order ID</span>
                          <h3 className="type-td width120">
                            #{orderDetails?.order_id}
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Customer Name</span>
                          <h3 className="type-td width120">
                            {orderDetails?.user?.full_name}
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">No. of Items</span>
                          <h3 className="type-td">
                            {orderDetails?.total_quantity} Items
                          </h3>
                        </div>
                        <div className="table-type-td">
                          <span className="type-th">Total Amount</span>
                          <h3 className="type-td">
                            QAR {orderDetails?.total_amount?.toFixed(2)}
                          </h3>
                        </div>
                        <div className="table-type-td"></div>
                      </div>

                      <div className="table-type-body">
                        <div className="table-type-body-inner-flex">
                          <div className="left-detail-box">
                            <h6 className="h6">Product Details</h6>
                            <Table className="product-table">
                              <TableBody>
                                {productDetails &&
                                  productDetails.length > 0 &&
                                  productDetails?.map((item, index) => (
                                    <OrderItem key={index} orderItem={item} storeId={store?.id}/>
                                  ))}
                              </TableBody>
                            </Table>
                          </div>
                          <div className="right-detail-box">
                            <h6 className="h6 mb-4">Payment Details</h6>
                            <div className="payment-value">
                              <span className="p2">MRP</span>
                              <span
                                className={`p2-bold ${
                                  orderDetails?.MRP !== orderDetails?.sub_total
                                    ? "through-text"
                                    : ""
                                }`}
                              >
                                {orderDetails?.MRP !==
                                orderDetails?.sub_total ? (
                                  <strike>
                                    QAR {orderDetails?.MRP?.toFixed(2)}
                                  </strike>
                                ) : (
                                  <span>
                                    QAR {orderDetails?.MRP?.toFixed(2)}
                                  </span>
                                )}
                              </span>
                            </div>
                            {orderDetails?.MRP !== orderDetails?.sub_total ? (
                              <div className="payment-value">
                                <span className="p2">Discounted Price</span>
                                <span className="p2-bold">
                                  QAR {orderDetails?.sub_total?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.delivery_charges > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Delivery Charges</span>
                                <span className="p2-bold">
                                  QAR{" "}
                                  {orderDetails?.delivery_charges?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.tax > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Tax</span>
                                <span className="p2-bold">
                                  QAR {orderDetails?.tax?.toFixed(2)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {orderDetails?.discount > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Coupon Discount</span>
                                <span className="p2-bold">
                                  - QAR {orderDetails?.discount?.toFixed(2)}
                                </span>
                              </div>
                            ) : null}
                            {orderDetails?.offer_amount > 0 ? (
                            <div className="payment-value">
                              <span className="p2">Offer/Discount</span>
                              <span className="p2-bold">
                                - QAR {orderDetails?.offer_amount.toFixed(2)}
                              </span>
                            </div>
                          ) : null}
                            {wallet > 0 ? (
                              <div className="payment-value">
                                <span className="p2">Wallet</span>
                                <span className="p2-bold">
                                  - QAR {wallet?.toFixed(2)}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <Divider />
                            <div className="payment-value-total">
                              <span className="p2">
                                {wallet > 0 ? "Net Pay" : "Total Amount"}
                              </span>
                              <span className="p2">
                                QAR{" "}
                                {((wallet)?orderDetails?.total_amount - (wallet || 0): orderDetails?.total_amount)?.toFixed(
                                  2
                                )}
                              </span>
                            </div>


                            {canConfirm ? (
                              <div className="btn-row">
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="large"
                                  className="mx-5 secondary-gray"
                                  onClick={() => setRejection({ isOpen: true })}
                                >
                                  {" "}
                                  Reject{" "}
                                </Button>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="large"
                                  className="mx-5 secondary"
                                  onClick={() => handleOrderAccept()}
                                >
                                  {" "}
                                  Confirm{" "}
                                </Button>
                              </div>
                            ) : null}

                            <Divider className="my-5" />
                            {orderDetails?.specialRequest && (
                                <Boxed
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems={"flex-end"}
                                >
                                  <h6 className="h6 mb-4">Special Request: {orderDetails.specialRequest}</h6>
                                </Boxed>
                              )}
                            <Boxed
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"flex-end"}
                            >
                              <h6 className="h6 mb-4">Other Details</h6>{" "}
                            </Boxed>
                            <div className="collapsible">
                              <div className="relative mb-4">
                                <p className="p3">Order Date</p>
                                <p className="p2">
                                  {moment(orderDetails?.createdAt).format(
                                    "DD MMMM YYYY hh:mm a"
                                  )}
                                </p>
                              </div>
                              <div className="relative mb-4">
                                <p className="p3">Payment Method</p>
                                <p className="p2">
                                  {orderDetails?.payment_method}
                                </p>
                              </div>
                              <div className="relative">
                                <p className="p3">Customer Details</p>
                                <p className="p2">
                                  {addressData?.full_name} |{" "}
                                  {`+${orderDetails?.user?.country_code} ${addressData?.contact_number}`}{" "}
                                  | {orderDetails?.user?.email}
                                </p>
                                <Button
                                  variant="contained"
                                  size="small"
                                  className="secondary"
                                  onClick={() => {
                                    navigate(
                                      `/manage-customer-Details/${orderDetails?.user?.id}`
                                    );
                                  }}
                                >
                                  {" "}
                                  View Customer{" "}
                                </Button>
                              </div>
                              <Divider />
                              <div className="relative mb-4">
                                <p className="p3">Vendor Details</p>
                                <p className="p2">
                                  {store?.stores_locales[0].business_name}
                                  {store?.country_code !== null && store?.contact_number !==null?<>
                                    {" "} | {" "}
                                  {`${store?.country_code !== null?'+'+store?.country_code:''} ${store?.contact_number !==null?store?.contact_number:''}`}{" "}
                                  </>: null}
                                  {" "} | {store?.business_address}
                                </p>
                              </div>
                              <Divider />
                              <div className="relative">
                                <p className="p3">Delivery Partner</p>
                                {store?.market_place === 1?
                                  <p className="p2">MarketPlace</p>:
                                  <p className="p2 capitalize">{store?.deliveryPartner}</p>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <RejectOrder
                      isOpen={rejection.isOpen}
                      handleClose={() => {
                        setRejection({ isOpen: false });
                      }}
                      submit={(message) => {
                        handleOrderReject(orderId, message);
                        setRejection({ isOpen: false });
                      }}
                      cancelReasons={cancelReasons}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
        
      </Box>
    </>
  );
};
export default Pending;
