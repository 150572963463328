import { useState, useEffect } from "react";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getdataByKey } from "../../helper/custom";
import { useTheme } from "@mui/material/styles";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import {
  Button, Typography, TextField, Grid, Divider, Alert, Select, InputLabel, MenuItem, FormControl,
  OutlinedInput, Box
} from "@mui/material";
import services from "../../../services";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import constant from "../../../constant";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(pickers, pickersName, theme) {
  return {
    fontWeight:
      pickersName.indexOf(pickers) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SectionManagementDetails() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  let sid = id;
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  const [loadParentCat, setLoadParentCat] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [pagetype, setPageType] = useState("section");
  const [existing_category, setExisting_category] = useState([]);
  const [existing_user, setExisting_user] = useState([]);
  const [BusinessCategory, setBusinessCategory] = useState("");
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [pickersName, setPickersName] = useState([]);
  const [sectionManagerName, setSectionManagerName] = useState([]);
  const [qcManagerName, setQcManagerName] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [subSubCategoriesName, setSubSubCategoriesName] = useState([]);
  const [pickers, setPickers] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [sectionmanagers, setSectionmanagers] = useState([]);
  const [allQcManagers, setAllQcManagers] = useState([]);
  const [categories, setScategories] = useState([]);
  const [categoriesRemoved, setScategoriesRemoved] = useState(false);
  const [usersRemoved, setUsersRemoved] = useState(false);  
  const [categoriesstatus, setcategoriesstatus] = useState(0);
  const theme = useTheme();

  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const routerStoreListing = () => navigate(`/section-management-listing`);

  const handleBusinessCategory = (event) => setBusinessCategory(event.target.value);
  
  const OpenRejectReason = () => setOpenRejectReason(true);

  const handleClose = () => setOpenRejectReason(false);
  
  const OpenAlertDelete = () => setOpenAlertDelete(true);

  const handleClose2 = () => setOpenAlertDelete(false);

  const routerStoreListDetail = () => navigate(`/section-management-listing`);
  
  const handleChangeName = (event) => setSectionName(event.target.value);
  
  const handleChange = (event) => {
    const { target: { value },} = event;
    setPickersName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange3 = (event) => {
    const {target: { value },} = event;
    setSectionManagerName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange10 = (event) => {
    const {target: { value },} = event;
    setQcManagerName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange4 = (value, val) => {
    if (val == "cat") {
      setCategoriesName([value]);
      setSubSubCategoriesName([]);
      setSubCategoriesName([]);
    } else if (val == "subCat") {
      setSubCategoriesName([value]);
      setSubSubCategoriesName([]);
    } else if (val == "subSubCat") {
      let unmatched=[];
      if(value){
        let numbers=typeof value=="string"?[Number(value)]:value;  
        const strings = numbers.map((number) => Number(number));
        const uniqueArray = [...new Set(strings)];
        setSubSubCategoriesName(uniqueArray);
      }else{ setSubSubCategoriesName([]); }
    }
  };

  const getAllUsers = async () => {
    const response = await services?.manageUser?.GET_CUSTOMERS( 1, 500, "", "", "", "", "" );
    if (response?.data?.ack == 1) {
      let rowdata = response?.data?.picker?.rows;
      if (typeof rowdata !== "undefined") {
        let rowd = rowdata.filter((item) => { if (item.role == "picker") { return item; } });
        setPickers(rowd);
        let sectionmanager = rowdata.filter((item) => {
          if (item.role == "section_manager") { return item; }
        });
        setSectionmanagers(sectionmanager);
        let qcall = rowdata.filter((item) => {
          if (item.role == "qc") { return item; }
        });
        setAllQcManagers(qcall);
      }
    }
  };

  const getCategories = async () => {
    if (categoriesstatus == 0) {
      const query = new URLSearchParams({
        businessTypeId: constant.BALADI_STORE_ID,
        parent_id: 0,
        page_type: pagetype,
      }).toString();
      setcategoriesstatus(1);
      let catList = (await services.Category.GET_CATEGORIES(query)).data;
      if (catList && categoriesstatus == 0) {
        let existing_category = catList.existing_category.split(",");
        setScategories(catList?.categories.filter((item) => !existing_category.includes(item.id.toString())));
        if (catList?.existing_category && catList?.existing_user) {
          setExisting_category(catList?.existing_category.split(","));
          setExisting_user(catList?.existing_user.split(","));
        }
      }
    }
  };

  const removeExistingCategories = () => {
      if(categoriesRemoved==false && existing_category.length>0){
          let temp = [];
          if (categories.length > 0) {
            categories.forEach((item) => {
              let flag = false;
              existing_category.forEach((item2) => {
                if (item2 == item.id) flag = true;
              });
              if (!flag) temp.push(item);
            });
          }
          setScategories(temp);
          if (subCategory.length > 0) {
            let temp2 = [];
            subCategory.forEach((item) => {
              let flag = false;
              existing_category.forEach((item2) => {
                if (item2 == item.id) flag = true;
              });
              if (!flag) temp2.push(item);
            });
            setSubCategory(temp2);
          }
          if (subSubCategory.length > 0) {
            let temp3 = [];
            subSubCategory.forEach((item) => {
              let flag = false;
              existing_category.forEach((item2) => {
                if (item2 == item.id) flag = true;
              });
              if (!flag) temp3.push(item);
            });
            setSubSubCategory(temp3);
          }
          setScategoriesRemoved(true);
      }
  };

  const removeExistingUsers = () => {
    if(usersRemoved==false && existing_user.length>0){
    let temp = [];
    if (pickers.length > 0) {
      pickers.forEach((item) => {
        let flag = false;
        existing_user.forEach((item2) => {
          if (item2 == item.id) flag = true;
        });
        if (!flag) temp.push(item);
      });
      setPickers(temp);
    }
    if (sectionmanagers.length > 0) {
      let temp2 = [];
      sectionmanagers.forEach((item) => {
        let flag = false;
        existing_user.forEach((item2) => {
          if (item2 == item.id) flag = true;
        });
        if (!flag) temp2.push(item);
      });
      setSectionmanagers(temp2);
    }
    if (allQcManagers.length > 0) {
      let temp3 = [];
      allQcManagers.forEach((item) => {
        let flag = false;
        existing_user.forEach((item2) => {
          if (item2 == item.id) flag = true;
        });
        if (!flag) temp3.push(item);
      });
      setAllQcManagers(temp3);
    }
    setUsersRemoved(true);
  }
  };

  const getDetails = async () => {
    if (sid != 0) {
      //alert(services.sectionManager.GET_SECTION_BY_ID);
      const response = await services.sectionManager.GET_SECTION_BY_ID(sid);
      if (response?.data?.ack == 1) {
        //setSectionDetails(response?.data?.data);
        setSectionName(response?.data?.data?.name);
        let catids = [];
        if (
          typeof response?.data?.data?.allcategories_id !==
          "undefined"
        ) {
          let catitem = response?.data?.data?.allcategories_id;
          catitem = catitem ? catitem.split(",") : [];
          catitem.length > 0 ? setLoadParentCat(catitem) : "";
        }

        let managerids = [];
        let pickerids = [];
        let qcids = [];
        if (
          typeof response?.data?.data?.picker_section_user_relations !==
          "undefined"
        ) {
          response?.data?.data?.picker_section_user_relations.map((item) => {
            if (item?.role == "section_manager") {
              managerids.push(item?.pickerUserId);
            }
            if (item?.role == "picker") {
              pickerids.push(item?.pickerUserId);
            }
            if (item?.role == "qc") {
              qcids.push(item?.pickerUserId);
            }
          });
          managerids.length > 0 ? setSectionManagerName(managerids) : "";
          pickerids.length > 0 ? setPickersName(pickerids) : "";
          qcids.length > 0 ? setQcManagerName(qcids) : "";
        }
      } else {
        routerStoreListDetail();
      }
    }
  };

  const ADD_SECTION = async () => {
    if (
      sectionName &&
      sectionName.length &&
      categoriesName.length &&
      sectionManagerName.length &&
      qcManagerName.length
    ) {
      dispatch(changeLoader(true));
      setShowAlert(false);
      const data = {
        name: sectionName,
        category_ides: subSubCategoriesName.join(),
        picker_ides: pickersName.join(),
        manager_ides: sectionManagerName.join(),
        qc_ides: qcManagerName.join(),
      };
      const response =
        sid == 0
          ? await services.sectionManager.ADD_SECTION(data)
          : await services.sectionManager.UPDATE_SECTION(sid, data);
      if (response?.data.ack == 1) {
        // setSeverity("success");
        // setAlertMsg(response?.data?.msg);

        //disableAlert();
        let path = "/section-management-listing/";
        setTimeout(() => {
          navigate(path);
        }, 100);
      } else {
        setSeverity("error");
        let errormsg = response?.data?.errMsg;
        if (errormsg !== "undefined") {
          errormsg.map((item) => {
            let key = Object.keys(item)[0];
            setAlertMsg(item[key]);
          });
        } else {
          setAlertMsg(response?.data?.msg);
        }
        setShowAlert(true);
        disableAlert();
      }
      dispatch(changeLoader(false));
    } else {
      // setSeverity("error");
      // setAlertMsg('Please fill all the fields');
      setShowAlert(true);
      disableAlert();
    }
  };

  const handleCategoryChange = async (level, index) => {};
  
  useEffect(() => {
    if (sid == 0) removeExistingCategories();
  }, [subSubCategory, subCategory, categories]);

  useEffect(() => {
    if (sid == 0) removeExistingUsers();
  }, [allQcManagers, sectionmanagers, pickers]);

  useEffect(() => {
    if (categoriesName.length) {
      (async () => {
        const query = new URLSearchParams({
          businessTypeId: constant.BALADI_STORE_ID,
          parent_id: categoriesName[0],
        }).toString();
        //setcategoriesstatus(1);
        let catList = (await services.Category.GET_CATEGORIES(query)).data
          .categories;
        setSubCategory(catList.filter((item) => !existing_category.includes(item.id.toString())));
      })();
    }
  }, [categoriesName]);

  useEffect(() => {
    if (subCategoriesName.length) {
      (async () => {
        const query = new URLSearchParams({
          businessTypeId: constant.BALADI_STORE_ID,
          parent_id: subCategoriesName[0],
        }).toString();
        //setcategoriesstatus(1);
        let catList = (await services.Category.GET_CATEGORIES(query)).data
          .categories;
          if (sid === '0') {
            catList = catList.filter((item) => !existing_category.includes(item.id.toString())) 
          }
        setSubSubCategory(catList);
      })();
    }
  }, [subCategoriesName]);
  
  useEffect(() => {
    if (loadParentCat.length) {
      setSubSubCategoriesName(loadParentCat);
      (async () => {
        const query = new URLSearchParams({
          businessTypeId: constant.BALADI_STORE_ID,
          parent_id: 0,
          id: typeof loadParentCat[0] !== "undefined" ? loadParentCat[0] : 0,
        }).toString();
        let catList = (await services.Category.GET_CATEGORIES(query)).data.categories;
        if (catList) {
          if (catList[0]?.parent_id > 0) {
            setSubCategoriesName([catList[0]?.parent_id]);
            const querys = new URLSearchParams({
              businessTypeId: constant.BALADI_STORE_ID,
              parent_id: 0,
              id: typeof catList[0] !== "undefined" ? catList[0]?.parent_id : 0,
            }).toString();
            catList = (await services.Category.GET_CATEGORIES(querys)).data
              .categories;
            if (catList) {
              if (catList[0]?.parent_id > 0) {
                setCategoriesName([catList[0]?.parent_id]);
              }
            }
          }
        }
      })();
    }
  }, [loadParentCat]);

  useEffect(() => { getDetails(); }, []);
  
  useEffect(() => { getAllUsers(); }, []);
  
  useEffect(() => { getCategories(); }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      if (sid != 0) {
        setCanEdit(permission?.sectionManagement.edit === 1)
      } else {
        setCanEdit(permission?.sectionManagement.create === 1)
      }
    } else {
      setCanEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            {showAlert && alertMsg ? <Alert severity={severity}>{alertMsg}</Alert>: null}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  {sid == 0 ? "Add" : "Update"} Section Management Detail
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      required={true} id="outlined-required" label="Section Name"
                      value={sectionName} onChange={handleChangeName}
                      disabled={!canEdit}
                    />

                    {showAlert == true && sectionName == "" ?
                      <div className="error">Section name is required</div>: null}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Pickers *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name" multiple={true}
                        value={pickersName} onChange={handleChange} input={<OutlinedInput label="Select Pickers" />}
                        MenuProps={MenuProps}
                        disabled={!canEdit}
                      >
                        {pickers.map((pickers) => 
                        <MenuItem key={pickers?.first_name} value={pickers?.id} >{pickers.first_name}</MenuItem>)}
                      </Select>
                      {showAlert == true && pickersName.length == 0 ?
                        <div className="error">Picker is required</div>: null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Categories *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name" value={categoriesName}
                        onChange={(event) => handleChange4(event.target.value, "cat") }
                        input={<OutlinedInput label="Select Categories" />} MenuProps={MenuProps}
                        disabled={!canEdit}
                      >
                        {categories.map((categories, index) => (
                          <MenuItem
                            key={categories.id} value={categories.id} style={getStyles(categories, categoriesName, theme)}
                            onClick={() => handleCategoryChange(1, index) }
                          >{getdataByKey(categories?.category_locales, "en", "name")}</MenuItem>
                        ))}
                      </Select>
                      {showAlert == true && categoriesName.length == 0 ?
                        <div className="error">Category is required</div>: null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Sub Categories *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name"
                        onChange={(event) => handleChange4(event.target.value, "subCat") }
                        input={<OutlinedInput label="Select Sub Categories" />}
                        value={subCategoriesName} MenuProps={MenuProps} disabled={!canEdit}
                      >
                        {subCategory.map((categories, index) => 
                        <MenuItem
                          key={categories.id} value={categories.id}
                          style={getStyles( categories, categoriesName, theme )}
                          onClick={() =>  handleCategoryChange(2, index) }
                        >{getdataByKey( categories?.category_locales, "en", "name" )}</MenuItem>
                        )}
                        {subCategory.length == 0 && <span>No data found</span>}
                      </Select>
                      {showAlert == true && categoriesName.length == 0 ?
                        <div className="error">Category is required</div>: null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Sub Sub Categories *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name"
                        multiple={true} value={subSubCategoriesName} MenuProps={MenuProps}
                        onChange={(event) => handleChange4(event.target.value, "subSubCat") }
                        input={<OutlinedInput label="Select Sub Sub Categories" />}
                        disabled={!canEdit}
                      >
                        {subSubCategory.map((categories, index) =>  <MenuItem
                          key={categories?.id} value={categories?.id}
                          style={getStyles( categories, categoriesName, theme )}
                        >{getdataByKey( categories?.category_locales, "en", "name" )}</MenuItem>)}
                        {subSubCategory.length == 0 && (
                          <span>No data found</span>
                        )}
                      </Select>
                      {showAlert == true && categoriesName.length == 0 ?
                        <div className="error">Category is required</div>: null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Section Manager *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name"
                        multiple={true} value={sectionManagerName} onChange={handleChange3}
                        input={<OutlinedInput label="Select Section Manager" />}
                        MenuProps={MenuProps} disabled={!canEdit}
                      >
                        {sectionmanagers.map((pickers) => 
                          <MenuItem key={pickers?.first_name} value={pickers?.id} >{pickers.first_name}</MenuItem>
                        )}
                      </Select>
                      {showAlert == true && sectionManagerName.length == 0 ? 
                        <div className="error">Section manager is required</div>: null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-multiple-name-label">Select Qc *</InputLabel>
                      <Select
                        required={true} labelId="demo-multiple-name-label" id="demo-multiple-name"
                        multiple= {true} value={qcManagerName} onChange={handleChange10}
                        input={<OutlinedInput label="Select Qc" />} MenuProps={MenuProps}
                        disabled={!canEdit}
                      >
                        {allQcManagers.map((pickers) =>
                          <MenuItem key={pickers?.first_name} value={pickers?.id} > {pickers.first_name} </MenuItem>)}
                      </Select>
                      {showAlert == true && qcManagerName.length == 0 ?
                        <div className="error">Qc is required</div>: null}
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    variant="standard" size="large" sx={{ marginRight: "10px" }}
                    onClick={routerStoreListing} >Back </Button>
                  {canEdit?<Button
                    variant="contained" size="large" color="primary"
                    onClick={(e) => { ADD_SECTION(); }}
                  >{sid == 0 ? "Save" : "Update"} </Button>: null}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
