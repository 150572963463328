import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, Button, Grid, Alert } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import constant from "../../../constant";
// image
import uploadImg from "../../../assets/images/structure/upload-img.png";
import categoryImg1 from "../../../assets/images/product/category-img1.png";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import services from "../../../services";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";
import { ImageCropper, imagesToBase64, base64toFile } from "../../../dialog/Cropper";
import { blobToBase64 } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function AddBusinessType() {
  
  const permission = useSelector(currentPermission);
  let [canCreateEdit, setCanCreateEdit] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyRegId, setCompanyRegId] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [addImages, setAddImages] = useState([]);
  let [cropperData, setCropperData] = useState({
    croppedImage: "",
    isOpen: false,
  });
  let [cropperData2, setCropperData2] = useState({
    croppedImage: "",
    isOpen: false,
  });
  const [redirectTextAr, setRedirectTextAr] = useState({
    val: "",
    err: "Please enter the redirect text in arabic",
    isValid: false,
  });
  const [redirectTextEn, setRedirectTextEn] = useState({
    val: "",
    err: "Please enter the redirect text in english",
    isValid: false,
  });
  const [businessTypeLogo, setBusinessTypeLogo] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });
  const [businessTypeLogo2 , setBusinessTypeLogo2] = useState({
    file: "",
    isValid: false,
    err: "Please upload banner logo",
  });
  const [categoryName, setCategoryName] = useState({
    val: "",
    err: "Please enter the business Name",
    isValid: false,
  });

  const [categoryNameAr, setCategoryNameAr] = useState({
    val: "",
    err: "Please enter the business Name",
    isValid: false,
  });

  const [categoryDesc, setCategoryDesc] = useState({
    val: "",
    err: "Please enter a short description",
    isValid: false,
  });

  const [categoryDescAr, setCategoryDescAr] = useState({
    val: "",
    err: "Please enter a short description",
    isValid: false,
  });

  const [categoryOption, setCategoryOption] = useState({
    val: "1",
    err: "",
    isValid: true,
  });
  const [deliveryCharge, setDeliveryCharge] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [orderAgainColor, setOrderAgainColor] = useState({
    val: "",
    err: "",
    isValid: false,
  })
  const [MinimumOrder, setMinimumOrder] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [isValidate, setIsValidate] = useState(false);
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });

  // const handleCategoryLevel = (event) => {
  //   setCategoryLevel(event.target.value);
  // };

  function handleOptionChange(e) {
    if (e.target.value) {
      setCategoryOption({ val: e.target.value, err: "", isValid: true });
    } else {
      setCategoryOption({
        val: "",
        err: "Category level Required",
        isValid: false,
      });
    }
  }

  function handleNameChange(e, type = "en") {
    // setIsValidate(true);

    if (type == "ar") {
      if (e.target.value.length == 0) {
        setCategoryNameAr({
          val: e.target.value,
          err: "Please enter the business",
          isValid: false,
        });
      } else {
        if (e.target.value.length > 100) {
          setCategoryNameAr({
            val: e.target.value,
            err: "max 100 character allowed",
            isValid: false,
          });
        } else {
          setCategoryNameAr({ val: e.target.value, err: "", isValid: true });
        }
      }

      return true;
    }

    if (e.target.value) {
      if (
        e.target.value.toLocaleLowerCase() !== `grocery` &&
        businessTypeLogo.file.type === "image/gif" && businessTypeLogo2.file.type === "image/gif"
      ) {
        setBusinessTypeLogo({
          ...businessTypeLogo,
          err: "GIF not allowed",
          isValid: false,
        });
        setBusinessTypeLogo2({
          ...businessTypeLogo2,
          err: "GIF not allowed",
          isValid: false,
        });
        setToaster({
          status: "error",
          text: `GIF not allowed`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 2500);
      }
      if (e.target.value.length > 100) {
        setCategoryName({
          val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setCategoryName({ val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1), err: "", isValid: true });
      }
    } else {
      setCategoryName({
        val: "",
        err: "Please enter the business",
        isValid: false,
      });
    }
  }

  function handleDescriptionChange(e, type = "en") {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setCategoryDescAr({
          val: e.target.value,
          err: "Please enter a short description",
          isValid: false,
        });
      } else {
        if (e.target.value.length > 100) {
          setCategoryDescAr({
            val: e.target.value,
            err: "max 100 character allowed",
            isValid: false,
          });
        } else {
          setCategoryDescAr({ val: e.target.value, err: "", isValid: true });
        }
      }

      return true;
    }

    // setIsValidate(true)
    if (e.target.value) {
      if (e.target.value.length > 100) {
        setCategoryDesc({
          val: e.target.value,
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setCategoryDesc({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setCategoryDesc({
        val: "",
        err: "Please enter a short description",
        isValid: false,
      });
    }
  }

  function handleRedirectTextChange(e, type = "en") {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setRedirectTextAr({
          val: e.target.value,
          err: "Please enter a short description",
          isValid: false,
        });
      } else {
        if (e.target.value.length > 120) {
          setRedirectTextEn({
            val: e.target.value,
            err: "max 120 character allowed",
            isValid: false,
          });
        } else {
          setRedirectTextAr({ val: e.target.value, err: "", isValid: true });
        }
      }

      return true;
    }
    if (e.target.value) {
      if (e.target.value.length > 100) {
        setRedirectTextEn({
          val: e.target.value,
          err: "max 100 character allowed",
          isValid: false,
        });
      } else {
        setRedirectTextEn({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setRedirectTextEn({
        val: "",
        err: "Please enter a short description",
        isValid: false,
      });
    }
  }
  

  const onSaveHandler = async () => {
    let isValid = true;
    if (deliveryCharge.val === '' || deliveryCharge.val < 0) {
      setDeliveryCharge({
        val: deliveryCharge.val,
        err: "Please enter delivery charge",
        isValid: false,
      });
      isValid = false;
    }
    if (MinimumOrder.val === '' || MinimumOrder.val < 0) {
      setMinimumOrder({
        val: MinimumOrder.val,
        err: "Please enter minimum order amount",
        isValid: false,
      });
      isValid = false;
    }
    if (
      categoryDesc.isValid &&
      categoryName.isValid &&
      categoryNameAr.isValid &&
      categoryDescAr.isValid &&
      businessTypeLogo.isValid && isValid  || 
      businessTypeLogo2.isValid
    ) {
      //Code
      setIsValidate(true);
      try {
        const saveData = {
          name_en: categoryName.val,
          name_ar: categoryNameAr.val,
          description_en: categoryDesc.val,
          description_ar: categoryDescAr.val,
          category_level: categoryOption.val,
          redirect_text_en: redirectTextEn.val,
          redirect_text_ar: redirectTextAr.val,
          delivery_charges: deliveryCharge.val,
          minimum_order_value: MinimumOrder.val,
          color_code: orderAgainColor.val,
        };
        const formData = new FormData();
        for (const property in saveData) {
          if (saveData.hasOwnProperty(property)) {
            formData.append(property, saveData[property]);
          }
        }
        if (businessTypeLogo && businessTypeLogo.file) {
          formData.append("image", businessTypeLogo.file);
        }
        if (businessTypeLogo2 && businessTypeLogo2.file) {
          formData.append("banner", businessTypeLogo2.file);
        }
        dispatch(changeLoader(true));
        const response = await services.BusinessType.ADD_BUSINESS_TYPE(
          formData
        );
        dispatch(changeLoader(false));
        if (response) {
          setIsValidate(false);
          if (response) {
            setToaster({
              status: "success",
              text: `Business Category Added Successfuly`,
              visibilty: true,
            });
            setTimeout(() => {
              setToaster({ ...toaster, visibilty: false });
            }, 2500);
            navigate(`/business-type-listing`);
          }
        }
     
      } catch (error) {
        console.log("Error_In_Login", error);
      }
    } else if (!isValidate) {
      setIsValidate(true);
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreateEdit(permission?.businessCategory?.create === 1)
    } else {
      setCanCreateEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
              Add Store Type
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="upload-img-parent">
                  <div
                    className="upload-img"
                    style={{ margin: "0px 0px 30px 0px" }}
                  >
                    {!(businessTypeLogo && businessTypeLogo.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          // accept="image/png, image/jpeg"
                          id="catfile"
                          accept={`image/png, image/jpeg, ${
                            categoryName.val.toLocaleLowerCase() === `grocery`
                              ? `image/gif`
                              : ``
                          }`}
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              (categoryName.val.toLocaleLowerCase() ===
                              "grocery"
                                ? imageType === "image/gif"
                                : "") ||
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                              document.getElementById("catfile").value = "";
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        <EditIcon
                          fontSize="small"
                          className="delete-icon"
                          onClick={() => {
                            setBusinessTypeLogo({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                            setBusinessTypeLogo2({
                              file: "",
                              isValid: false,
                              err: "Please upload banner logo",
                            });
                          }}
                        />
                        <img
                          src={URL.createObjectURL(businessTypeLogo.file)}
                          alt=".."
                          className="upload-icon"
                        />
                        <input
                          type="file"
                          id="catfile3"
                          accept={`image/png, image/jpeg, ${
                            categoryName.val.toLocaleLowerCase() === `grocery`
                              ? `image/gif`
                              : ``
                          }`}
                          // accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              (categoryName.val.toLocaleLowerCase() ===
                              "grocery"
                                ? imageType === "image/gif"
                                : "") ||
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                              document.getElementById("catfile3").value = "";
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!businessTypeLogo.isValid && isValidate && (
                    <div className="error">{businessTypeLogo.err}</div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="upload-img-parent">
                  <div
                    className="upload-img"
                    style={{ margin: "0px 0px 30px 0px" }}
                  >
                    {!(businessTypeLogo2 && businessTypeLogo2.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          // accept="image/png, image/jpeg"
                          id="catfile"
                          accept={`image/png, image/jpeg, ${
                            categoryName.val.toLocaleLowerCase() === `grocery`
                              ? `image/gif`
                              : ``
                          }`}
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              (categoryName.val.toLocaleLowerCase() ===
                              "grocery"
                                ? imageType === "image/gif"
                                : "") ||
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);                        
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData2(cropperData);
                              document.getElementById("catfile").value = "";
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        <EditIcon
                          fontSize="small"
                          className="delete-icon"
                          onClick={() => {
                            setBusinessTypeLogo2({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                        <img
                          src={URL.createObjectURL(businessTypeLogo2.file)}
                          alt=".."
                          className="upload-icon"
                        />
                        <input
                          type="file"
                          id="catfile3"
                          accept={`image/png, image/jpeg, ${
                            categoryName.val.toLocaleLowerCase() === `grocery`
                              ? `image/gif`
                              : ``
                          }`}
                          // accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              (categoryName.val.toLocaleLowerCase() ===
                              "grocery"
                                ? imageType === "image/gif"
                                : "") ||
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData2(cropperData);
                              document.getElementById("catfile3").value = "";
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!businessTypeLogo.isValid && isValidate && (
                    <div className="error">{businessTypeLogo.err}</div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={4}>
                <TextField
                  required
                  id="outlined-required"
                  label="Business Type Name (English)"
                  inputProps={{ maxLength: 50 }}
                  value={categoryName.val}
                  onChange={(e) => handleNameChange(e)}
                  disabled={!canCreateEdit}
                />
                {!categoryName.isValid && isValidate && (
                  <div className="error">{categoryName.err}</div>
                )}
                <TextField
                  required
                  style={{ marginTop: "10px" }}
                  id="outlined-required-ar"
                  label="Business Type Name (Arabic)"
                  inputProps={{ maxLength: 50 }}
                  value={categoryNameAr.val}
                  onChange={(e) => handleNameChange(e, "ar")}
                  disabled={!canCreateEdit}
                />
                {!categoryNameAr.isValid && isValidate && (
                  <div className="error">{categoryNameAr.err}</div>
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Category Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryOption.val}
                    label="Payment Status"
                    onChange={handleOptionChange}
                    disabled={!canCreateEdit}
                  >
                    <MenuItem value={"1"}>1</MenuItem>
                    <MenuItem value={"2"}>2</MenuItem>
                    <MenuItem value={"3"}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Short Description (English)"
                  multiline
                  value={categoryDesc.val}
                  onChange={(e) => handleDescriptionChange(e, "en")}
                  inputProps={{ maxLength: 100 }}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />
                {!categoryDesc.isValid && isValidate && (
                  <div className="error">{categoryDesc.err}</div>
                )}

                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Short Description (Arabic)"
                  multiline
                  value={categoryDescAr.val}
                  onChange={(e) => handleDescriptionChange(e, "ar")}
                  inputProps={{ maxLength: 100 }}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />
                {!categoryDescAr.isValid && isValidate && (
                  <div className="error">{categoryDescAr.err}</div>
                )}
                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Redirect Text (English)"
                  multiline
                  value={redirectTextEn.val}
                  onChange={(e) => handleRedirectTextChange(e, "en")}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />

                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-multiline-flexible-ar"
                  label="Redirect Text (Arabic)"
                  multiline
                  value={redirectTextAr.val}
                  onChange={(e) => handleRedirectTextChange(e, "ar")}
                  maxRows={4}
                  disabled={!canCreateEdit}
                />


                <TextField
                  style={{ marginTop: "10px" }}
                  type="number"
                  id="outlined-multiline-flexible-ar"
                  label="Minimum Order Amount"
                  value={ MinimumOrder.val }
                  onChange={(e) => setMinimumOrder({
                    val: e.target.value,
                    err: "",
                    isValid: true,
                  })}
                  disabled={!canCreateEdit}
                />
                {!MinimumOrder.isValid && isValidate && (
                  <div className="error">{MinimumOrder.err}</div>
                )}

                <TextField
                  style={{ marginTop: "10px" }}
                  type="number"
                  id="outlined-multiline-flexible-ar"
                  label="Delivery Charge"
                  value={ deliveryCharge.val }
                  onChange={(e) => setDeliveryCharge({
                    val: e.target.value,
                    err: "",
                    isValid: true,
                  })}
                  disabled={!canCreateEdit}
                />
                {!deliveryCharge.isValid && isValidate && (
                  <div className="error">{deliveryCharge.err}</div>
                )}

                <TextField
                  style={{ marginTop: "10px", maxWidth: "150px" }}
                  type="color"
                  id="outlined-flexible-color"
                  label="Order Again Color"
                  value={ orderAgainColor.val }
                  onChange={(e) => {
                    setOrderAgainColor({
                      val: e.target.value,
                      err: "",
                      isValid: true,
                    });
                  }}
                  disabled={!canCreateEdit}
                />

              </Grid>

              <Grid item xs={8}>
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                  {canCreateEdit?<Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => onSaveHandler()}
                    disabled={
                      !categoryDesc.isValid ||
                      !categoryName.isValid ||
                      !businessTypeLogo.isValid ||
                      !categoryDescAr.isValid ||
                      !categoryNameAr.isValid
                    }
                  > Save </Button>: null}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => {
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setBusinessTypeLogo({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}

      {cropperData2.isOpen ? (
        <ImageCropper
          cropperImage={cropperData2.croppedImage}
          closepopup={() => {
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setBusinessTypeLogo2({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 16/9,}}  
        />
      ) : null}
    </>
  );
}
