import { useNavigate } from "react-router-dom";
import {
  Typography, Tooltip, Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Select, MenuItem,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import GlobalService from "../../../services/GlobalService";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { encode } from "base-64";
import { currentPermission } from "../../../redux/reducers/permission";
import {getdataByKey} from "../../helper/custom";
import services from "../../../services";

export default function CuisineCategoryListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
	const dragItem = useRef();
  const dragOverItem = useRef();

  const permission = useSelector(currentPermission);
  const [data, setData] = useState([]);
  const [businesstypeList, setBusinesstypeList] = useState([]);
  let [filter, setFilter] = useState(null);
  let [canEdit, setCanEdit] = useState(true);
  let [canView, setCanView] = useState(true);

  const GetAllBusinessType = async () => {
    try {
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeList(response.data.businesstype.rows);
				setFilter(response.data.businesstype.rows[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };

	const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...data];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setData(copyListItems);
    const data2 = copyListItems.map((item, index) => {
      return {
        id: item?.id,
        sort: index + 1
      }
    })
		GlobalService(API.CUISINE.SORT_CUISINE, {order_type : data2}, "put")
  };

  const callApi = () => {
		if (!filter) return 
    dispatch(changeLoader(true));
    GlobalService(
      API.CUISINE.GET_ALL_CUISINE_business(filter),
      {},
      "get"
    ).then((da) => {
      if (da.ack == 1) {
        setData(da.data.cuisines);
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: err.response.body.msg,
          state: "error",
        })
      );
    }).finally(() => {
      dispatch(changeLoader(false));
    });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      callApi();
    }, 500);
    return () => clearTimeout(getData);
  }, [filter]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.cuisine?.edit === 1)
      setCanView(permission?.cuisine?.read === 1)
    } else {
      setCanEdit(true)
      setCanView(true)
    }
  }, [permission]);

  useLayoutEffect(() => {
    GetAllBusinessType();
  }, []);  

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Cuisine Listing
              </Typography>
              {(businesstypeList && businesstypeList.length > 0) && <div className="right-filter">
                <div className="search-box">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={filter}
                  size="small"
                  onChange={(e) => setFilter(e.target.value)}
                  className="status-drop"
                >
                  {businesstypeList.map((item, index) => (<MenuItem key={index} value={item.id}>
                    {getdataByKey( item?.business_type_locales, "en", "name" )}
                  </MenuItem>))}
                </Select>
                </div>
              </div>}
            </div>
            {data.length ? <>
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell> Cuisine Name English </TableCell>
                      <TableCell>Cuisine Arabic Name</TableCell>
                      { canEdit? <TableCell className="nowrap" align="center">Action</TableCell>: null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((obj, ind) => (
                    <TableRow
											key={`cus-${ind}`}
											onDragStart={(e) => dragStart(e, ind)}
                      onDragEnter={(e) => dragEnter(e, ind)}
                      onDragEnd={drop}
                      draggable
										>
                      <TableCell component="th" scope="row">{getdataByKey(obj?.cuisines_locales, 'en', 'name')}</TableCell>
                      <TableCell>{getdataByKey(obj?.cuisines_locales, 'ar', 'name')}</TableCell>
                      { canEdit?
                      <TableCell align="center" className="nowrap">
                      	<Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
													<BorderColorIcon
														color="action"
														fontSize="small"
														onClick={() => {
															let datas = encodeURIComponent(encode(JSON.stringify({id: obj?.id,})));
															sessionStorage.setItem("cuisineData", JSON.stringify({
																name_en: getdataByKey(obj?.cuisines_locales, 'en', 'name'),
																name_ar: getdataByKey(obj?.cuisines_locales, 'ar', 'name'),
																description_en: getdataByKey(obj?.cuisines_locales, 'en', 'description'),
																description_ar: getdataByKey(obj?.cuisines_locales, 'ar', 'description'),
																id: obj?.id,
																cuisineCategoryId: obj?.cuisineCategoryId,
																image: obj?.image,
																businessTypeId: obj?.businessTypeId
															}));
															navigate(`/add-cuisine/${datas}`);
														}}
													/>
												</Tooltip>
                      </TableCell>: null}
                    </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>: <NoDataFound text={"No Cuisine Found"} />}
          </Box>
        </Box>
      </Box>
    </>
  );
}
