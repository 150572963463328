import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_ALL_ORDERS: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Orders.GET_ALL_ORDERS(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_ALL_ORDERS_COUNT: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Orders.GET_ALL_ORDERS_COUNT(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  GET_ORDER_INVOICE: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Orders.GET_ORDER_INVOICE(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },


  COMPLETE_ORDER_BY_ADMIN: (orderId,updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Orders.COMPLETE_ORDER_BY_ADMIN(orderId),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
       
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },




  GET_ORDER_BY_QC_ID : (id)=>  `/orders/qc-specific-order/${id}`,
  GET_ORDER_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Orders.GET_ORDER_BY_ID(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_ORDER: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Orders.UPDATE_ORDER(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  DELETE_ORDER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.Orders.DELETE_ORDER(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  CONFIRM_ORDER: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Orders.CONFIRM_ORDER(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  REJECT_PENDING_ORDER: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Orders.REJECT_PENDING_ORDER(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
