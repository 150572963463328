import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeUserId } from "../../../redux/reducers/manageUser";
import { currentPermission } from "../../../redux/reducers/permission";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Tooltip,
  MenuItem,
  Select,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import modalClose from "../../../assets/images/structure/close-modal.png";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";

// page, limit, sort_by, order_by, created_by
export default function ManageUserListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  const [Category, setCategory] = useState("all");
  const [search, setSearch] = useState("");
  let [page, setPage] = useState(1);
  let [count, setCount] = useState(5);
  const [limit, setLimit] = useState(10);
  const [sort_by, setSort_by] = useState("id");
  const [order_by, setOrder_by] = useState("desc");
  const [created_by, setCreated_by] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [role, setRole] = useState("all");
  const [userId, setuserId] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [openRejectReason, setOpenRejectReason] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCategory = (event) => {
    setRole(event.target.value);
    setCategory(event.target.value);
  };

  const OpenRejectReason = () => {
    setOpenRejectReason(true);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const routerManageUserDetails = () => {
    const path = `/manage-user-details`;
    navigate(path);
  };

  const handlePageChange = (event, value) => {
    page = value;
    setPage(page);
    getUsers();
  };

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_by("asc");
    else if (order === "desc") setOrder_by("desc");
    setSort_by(value);
  };

  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
      navigate("/manage-user-listing");
    }, 500);
  };

  const SetAlert = (type, msg) => {
    setSeverity(type);
    setAlertMsg(msg);
    setShowAlert(true);
    disableAlert();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSort_by("");
      setSearch(searchTerm);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const getUsers = async () => {
    setUsers([]);
    count = 0;
    setCount(count);
    let userRole = role == "all" ? "" : role;
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_CUSTOMERS(
      page,
      limit,
      sort_by,
      order_by,
      created_by,
      search,
      userRole
    );

    if (response?.data?.ack == 1) {
      setUsers(response?.data?.picker?.rows);
      count = Math.ceil(response?.data?.picker?.count / limit);
      setCount(count);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    page = 1;
    setPage(page);
    getUsers();
  }, [search, order_by, role]);

  const deleteUser = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.DELETE_USER(userId);
    if (response?.data?.ack == 1) {
      setOpenDelete(false);
      SetAlert("success", response?.data?.msg);
      setTimeout(() => {
        getUsers();
      }, 600);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.manageUsers.create === 1);
      setCanEdit(permission?.manageUsers.edit === 1);
      setCanDelete(permission?.manageUsers.delete === 1);
      setCanUpdateStatus(permission?.manageUsers.actions.status === 1);
    } else {
      setCanCreate(true);
      setCanEdit(true);
      setCanDelete(true);
      setCanUpdateStatus(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Manage User List
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search.."
                    value={searchTerm}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: "3px" }} />
                      ),
                      endAdornment: search ? (
                        <CloseIcon
                          onClick={() => {
                            setSearchTerm("");
                          }}
                        />
                      ) : null,
                    }}
                  />
                </div>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={"All"}
                  value={Category}
                  size="small"
                  onChange={handleCategory}
                  className="status-drop"
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"picker"}>Picker</MenuItem>
                  <MenuItem value={"qc"}>QC</MenuItem>
                  <MenuItem value={"section_manager"}>Section Manager</MenuItem>
                </Select>

                {canCreate ? (
                  <Button
                    variant="contained"
                    size="medius"
                    onClick={() => {
                      routerManageUserDetails();
                      dispatch(changeUserId(null));
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    {" "}
                    Add User{" "}
                  </Button>
                ) : null}
              </div>
            </div>
            {users.length > 0 ? (
              <TableContainer className="height-fix">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="short-div">
                          First Name
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("first_name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("first_name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Last Name
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("last_name", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("last_name", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Email
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("email", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("email", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Role
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("role", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("role", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Wallet Amount
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                changeOrderBy("role", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                changeOrderBy("role", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Last Wallet Amount
                          <div className="short-box">
                            <ArrowDropUpIcon
                              onClick={() => {
                                // changeOrderBy("role", "asc");
                              }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                // changeOrderBy("role", "desc");
                              }}
                            />
                          </div>
                        </div>
                      </TableCell>

                      {canEdit || canDelete ? (
                        <TableCell className="nowrap" align="center">
                          Action
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {user?.first_name}
                          </TableCell>
                          <TableCell>{user?.last_name}</TableCell>
                          <TableCell>{user?.email}</TableCell>
                          <TableCell>{user?.role}</TableCell>
                          <TableCell>{user?.wallet_amount}</TableCell>
                          <TableCell>{user?.last_wallet_amount}</TableCell>
                          {canEdit || canDelete ? (
                            <TableCell align="center" className="nowrap">
                              {canEdit ? (
                                <Tooltip
                                  title="Edit"
                                  placement="top"
                                  sx={{ marginRight: 1 }}
                                >
                                  <BorderColorIcon
                                    color="action"
                                    fontSize="small"
                                    onClick={() => {
                                      routerManageUserDetails();
                                      dispatch(changeUserId(user?.id));
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                              {canDelete ? (
                                <Tooltip title="Delete" placement="top">
                                  <DeleteForever
                                    color="error"
                                    fontSize="small"
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setuserId(user?.id);
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoDataFound text={"No User Found"} />
            )}
            {count > 1 && (
              <Pagination
                count={count}
                color="primary"
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Dialog open={openRejectReason} onClose={handleClose}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required label="Reason" multiline rows={2} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openDelete} onClose={handleClose} className="alert-delete">
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  {" "}
                  Are you sure you want to delete this
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => {
                    deleteUser();
                  }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
