import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography, TextField, Button, Grid, InputLabel, Box, Table, IconButton, InputAdornment,
  TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Select, FormControl
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import PermissionRow from "./components/PermissionRow";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import {PhoneMask} from "../../../global-modules/MaskedInputs";
import {Visibility, VisibilityOff} from "@mui/icons-material";

// image
export default function StoreListing() {

  const { id } = useParams();
  const COUNTRY_CODE = '974';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [roleList, setRoleList] = useState([]);
  let [roleId, setRoleId] = useState('');
  let [permission, setPermission] = useState([]);
  let [fullNames, setFullNames] = useState('');
  let [email, setEmail] = useState('');
  let [contact, setContact] = useState('');
  let [password, setPassword] = useState('');
  let [confirmPassword, setConfirmPassword] = useState('');
  let [showPassword, setShowPassword] = useState(false);
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let [fullNamesError, setFullNamesError] = useState('');
  let [emailError, setEmailError] = useState('');
  let [contactError, setContactError] = useState('');
  let [roleIdError, setRoleIdError] = useState('');
  let [passwordError, setPasswordError] = useState('');
  let [confirmPasswordError, setConfirmPasswordError] = useState('');

  const getRoleList = async () => {
    dispatch(changeLoader(true));
    let url = API.GET_ALL_USER_ROLES;
    const response = await GlobalService(url);
    roleList = response.data;
    setRoleList(roleList);
    dispatch(changeLoader(false));
    if (id) { getUserEditData(); }
  };

  const handleRoleChange = (id) => {
    if (id) {
      roleId = id;
      setRoleId(roleId)
      permission = roleList.find(item => item.id === roleId)?.permissions;
      if (permission) {
        setPermission(permission.filter(item => item.read === 1));
      }
    }
  }

  const handleSave = async () => {
    dispatch(changeLoader(true));
    const passwordValidation =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
    let isValid = true;
    if (fullNames === '') {
      setFullNamesError('Full name is required');
      isValid = false;
    }
    if (email === '') {
      setEmailError('Email is required');
      isValid = false;
    }
    if (contact === '') {
      setContactError('Contact is required');
      isValid = false;
    } else if (contact.length !== 8) {
      setContactError('Contact must be 8 digits');
      isValid = false;
    }
    if (roleId === null) {
      setRoleIdError('Role is required');
      isValid = false;
    }
    if (!id) {
      if (password === '') {
        setPasswordError('Password is required');
        isValid = false;
      } else if (!passwordValidation.test(password)) {
        setPasswordError('Password must contain at least 6 characters, including UPPER/lowercase and numbers');
        isValid = false;
      }
      if (confirmPassword === '') {
        setConfirmPasswordError('Confirm password is required');
        isValid = false;
      } else if (password !== confirmPassword) {
        setConfirmPasswordError('Password and confirm password must be same');
        isValid = false;
      }
    }

    if (isValid) {
      const url = id? API.UPDATE_SUB_USERS(id): API.ADD_SUB_USERS;
      const method = id? 'put': 'post';
      let data = {
        full_name: fullNames,
        email: email,
        country_code: COUNTRY_CODE,
        contact_number: contact,
        subadminRoleId: roleId,
      };
      if (!id) {
        data.password = password;
      }
      const response = await GlobalService(url, data, method);
      if (response.ack === 1) {
        dispatch(changeSnackbar({
          isOpen : true,
          message : response.msg,
          state : 'success'
        }));
        dispatch(changeLoader(false));
        navigate('/user-management');
      } else {
        if (response.msg !== '') {
          dispatch(changeSnackbar({
            isOpen : true,
            message : response.msg,
            state : 'error'
          }));
        } else {
          response?.errMsg.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              switch (key) {
                case 'full_name':
                  setFullNamesError(value);
                  break;
                case 'email':
                  setEmailError(value);
                  break;
                case 'contact_number':
                  setContactError(value);
                  break;
                case 'subadminRoleId':
                  setRoleIdError(value);
                  break;
                case 'password':
                  setPasswordError(value);
                  break;
                case 'msg':
                  dispatch(changeSnackbar({
                    isOpen : true,
                    message : value,
                    state : 'error'
                  }));
                  break;
                default:
                  break;
              }
            }
          });
        }
        dispatch(changeLoader(false));
      }

    } else {
      dispatch(changeLoader(false));
    }
  };

  const getUserEditData = async () => {
    dispatch(changeLoader(true));
    let url = API.GET_USER_BY_ID(id);
    const response = await GlobalService(url);
    setFullNames(response?.data[0]?.full_name);
    setEmail(response?.data[0]?.email);
    setContact(response?.data[0]?.contact_number.toString());
    roleId = response?.data[0]?.subadmin_role?.id || null;
    setRoleId(roleId);
    handleRoleChange(roleId);
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <Grid container spacing={3} alignItems={'flex-start'} justifyContent={'flex-start'}>
              <Grid container item xs={5} spacing={2} >
                <Grid item xs={12}>
                  {id?
                  <Typography variant="h6" component="h6" mb={0} fontWeight={500}>Edit User</Typography>:
                  <Typography variant="h6" component="h6" mb={0} fontWeight={500}>Add User</Typography>}
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-required" label="Full Name" value={fullNames}
                      onChange={(e) => {
                        setFullNames(e.target.value)
                        setFullNamesError('')
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    <span className="redspan">{fullNamesError}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-required" label="Email" value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                        setEmailError('')
                      }}
                      inputProps={{ maxLength: 100 }}
                    />
                    <span className="redspan">{emailError}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-required" label="Contact Number" value={contact}
                      onChange={(e) => {
                        setContact(e.target.value)
                        setContactError('')
                      }}
                      InputProps={{
                        startAdornment: <span className="country-code">+{COUNTRY_CODE}</span>,
                        inputComponent: PhoneMask,
                      }}
                    />
                    <span className="redspan">{contactError}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                    <Select
                      labelId="demo-simple-select-label" id="demo-simple-select" label="Roles"
                      value={roleId} onChange={(e) => {
                        handleRoleChange(e.target.value)
                        setRoleIdError('')
                      }}
                    >
                      {roleList.length > 0?
                        <MenuItem value={''} key={`role-00`} disabled>Select Role</MenuItem>:
                        <MenuItem value={''} key={`role-00`} disabled>No Role Found</MenuItem>}
                      {roleList?.map((item, index) => 
                        <MenuItem value={item?.id} key={`role-${index}`}>{item?.name}</MenuItem>)}
                    </Select>
                    <span className="redspan">{roleIdError}</span>
                  </FormControl>
                </Grid>

                {id?null:<>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-required" label="Password" value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                        setPasswordError('')
                      }}
                      type={showPassword?"text": "password"}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            > {showPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                          </InputAdornment>
                      }}
                    />
                    <span className="redspan bottom-extra">{passwordError}</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '10px' }}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-required" label="Confirm Password" value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        setConfirmPasswordError('')
                      }}
                      type={showConfirmPassword?"text": "password"}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            > {showConfirmPassword ? <Visibility /> : <VisibilityOff />} </IconButton>
                          </InputAdornment>
                      }}
                    />
                    <span className="redspan">{confirmPasswordError}</span>
                  </FormControl>
                </Grid>
                </>}

                <Grid item xs={12} style={{ marginTop: '30px' }}>
                <Button
                    variant="outlined"
                    size="medium"
                    color="error" 
                    style={{ marginRight: '10px' }}
                    mt={3} mb={4}
                    onClick={() => navigate(-1)}
                  >Back</Button>
                  <Button
                    variant="contained" size="medius" mt={3} mb={4}
                    onClick={() => handleSave()}
                  >Save</Button>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6" mb={0} fontWeight={500} >Permissions (View Only)</Typography>
                </Grid>
                <Grid item xs={12}>
                  {permission?.length > 0? <TableContainer className="height-fix">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell component="th"><b>#</b></TableCell>
                          <TableCell component="th"><b>Modules</b></TableCell>
                          <TableCell component="th"><b>Action</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permission.map((perm, index) => 
                        <PermissionRow key={`permission-${index}`} permission={perm} index={index + 1} />)}
                      </TableBody>
                    </Table>
                  </TableContainer>: <NoDataFound
                    text={"No permission found, select role to see permissions"}
                  />}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
