import React, { useState, useEffect } from "react";
import calenderIcon from "../../../../../assets/images/structure/calender-schedule.svg";
import DownloadIcon from "../../../../../assets/images/structure/download-icon.svg";
import EyeIcon from "../../../../../assets/images/structure/view.svg";
import services from "../../../../../services";
import constant from "../../../../../constant";
import moment from 'moment-timezone';

const OrderItem = ({
  orderDetail,
  orderDate,
  storeName,
  businessName,
  ContactNumber,
  OrderId,
  b2bOrder,
  CustomerName,
  total_quantity,
  TotalAmount,
  lastModified,
  statusColors,
  id,
  deliver,
  delivery_date_time,
  checked,
  orderStatus
}) => {
  const [statusColor, setStatusColor] = useState("");
  
  const checkTimeDifference = () => {
    if (statusColors.order_status === orderStatus) {
      const date1 = new Date(lastModified);
      const date2 = new Date();
      const diffTime = Math.abs(date2 - date1);
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      if (diffMinutes > statusColors.time) {
        setStatusColor((prev) => (prev = statusColors.color));
      }
    } 
  };                   
  // const originalTime = moment.tz(orderDate, 'Asia/Qatar');
  // const originalTime = moment.tz(orderDate, "DD MMMM YYYY hh:mm a", 'Asia/Qatar')
  // const modifiedTime = originalTime.subtract(30, 'minutes');
  const formattedTime = moment(orderDate).format(
    "DD MMMM YYYY hh:mm a"
  ) // modifiedTime.format('YYYY-MM-DD hh:mm:ss A');

  const downloadInvoice = async () => {
    try {
      const response = (await services.Orders.GET_ORDER_INVOICE(id)).data;
      if (response.ack == 1) {
        window.open(constant.IMG_BASE_URL + response.invoice, "_blank");
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (statusColors && statusColors?.status === "active") {
      checkTimeDifference();
      setInterval(() => {
        checkTimeDifference();
      }, 10000);
    } else {
      setStatusColor((prev) => (prev = ''));
    }
  }, [lastModified, statusColors]);

  return (
    <div>
      <div
        className="table-type-parent gray-border"
        style={{ borderColor: checked ? statusColors.color : statusColor, borderWidth: "2px", }}
      >
        <div className="table-type-box">
          <div className="table-type-td flex" style={{ cursor: 'default' }}>
            <img src={calenderIcon} alt="deliver" className="icon24" />
            {deliver === "now" ? 
            <div className="relative">
              <span className="type-th">Order Date</span>
              <h3 className="type-td">{formattedTime}</h3>
            </div> : 
            <div className="relative">
               {b2bOrder == true  ? <>
             <span className="type-th" style={{ color: "#FF0000", fontWeight: "bolder" }}>  (B2B)   </span> 
              </> : null}
              <span className="type-th" style={{ color: "#FF0000", fontWeight: "bolder" }}> Scheduled Date  </span> 
                
             
              <h3 className="type-td">{delivery_date_time}</h3>
            </div>}
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Store Name</span>
            <h3 className="type-td width120">{storeName}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Business Address</span>
            <h3 className="type-td width120">{businessName}</h3>
            <span className="type-th">Contact Number</span>
            <h3 className="type-td width120">{ContactNumber}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Order ID</span>
            <h3 className="type-td width120">{OrderId}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Customer Name</span>
            <h3 className="type-td width120">{CustomerName}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">No. of Items</span>
            <h3 className="type-td">{`${total_quantity} Items`}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Total Amount</span>
            <h3 className="type-td">{TotalAmount}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'default' }}>
            <span className="type-th">Last Modified</span>
            <h3 className="type-td">{lastModified}</h3>
          </div>
          <div className="table-type-td" style={{ cursor: 'pointer' }} onClick={() => downloadInvoice() }>
            <h3 className="type-td">
              <img src={DownloadIcon} className="icon20" alt="" /> Download Invoice
            </h3>
          </div>
          <div className="table-type-td" onClick={() => { orderDetail() }} style={{ cursor: 'pointer' }}>
            <h3 className="type-td" style={{ color: '#1EA896' }}>
              <img src={EyeIcon} className="icon20" style={{ maxWidth: '20px' }} alt="" /> View
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
