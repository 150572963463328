const updateStateWithValidation = (e, state, type = null) => {
  switch (type) {
    case "contact": {
      if (!isNaN(e.target.value) && e.target.value) {
        if (e.target.value.length > 8) {
          return {
            val: e.target.value,
            isValid: false,
            err: "Mobile number must be of 8 digit",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        if (isNaN(e.target.value)) {
          return {
            val: e.target.value,
            isValid: false,
            err: "Only Numbers allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: false,
            err: "required",
          };
        }
      }
    }
    case "email": {
      let validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (e.target.value) {
        if (!validRegex.test(e.target.value)) {
          return {
            val: e.target.value,
            isValid: false,
            err: "Enter a valid email address",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "commission": {
      if (isNaN(e.target.value)) {
        return {
          val: e.target.value,
          isValid: false,
          err: "Only Numbers allowed",
        };
      }

      const floatValue = parseFloat(e.target.value);

      if (floatValue <= 100) {
        return {
          val: e.target.value,
          isValid: true,
          err: "",
        };
      } else if (floatValue > 100) {
        return {
          val: e.target.value,
          isValid: false,
          err: "percentage can't be greater than 100",
        };
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "rejectionTime": {
      if (isNaN(e.target.value)) {
        return {
          val: e.target.value,
          isValid: false,
          err: "Only Numbers allowed",
        };
      }

      const floatValue = parseFloat(e.target.value);

      if (floatValue <= 60) {
        return {
          val: e.target.value,
          isValid: true,
          err: "",
        };
      } else if (floatValue > 60) {
        return {
          val: e.target.value,
          isValid: false,
          err: "rejection time must be less than or 60",
        };
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "paragraph": {
      if (e.target.value) {
        if (e.target.value.length > 1000) {
          return {
            val: e.target.value,
            isValid: false,
            err: "max 1000 char allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "paragraph_250": {
      if (e.target.value) {
        if (e.target.value.length > 250) {
          return {
            val: e.target.value,
            isValid: false,
            err: "max 250 char allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "address": {
      if (e.target.value) {
        if (e.target.value.length > 100) {
          return {
            val: e.target.value,
            isValid: false,
            err: "max 100 char allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
    default: {
      if (e.target.value) {
        if (e.target.value.length > 256) {
          return {
            val: e.target.value,
            isValid: false,
            err: "max 256 char allowed",
          };
        } else {
          return {
            val: e.target.value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: e.target.value,
          isValid: false,
          err: "required",
        };
      }
    }
  }
  // if (e.target.value) {
  //   if (e.target.value.length > 50) {
  //     return {
  //       val: e.target.value,
  //       isValid: false,
  //       err: "max 50 char allowed",
  //     };
  //   } else {
  //     return {
  //       val: e.target.value,
  //       isValid: true,
  //       err: "",
  //     };
  //   }
  // } else {
  //   return {
  //     val: e.target.value,
  //     isValid: false,
  //     err: "required",
  //   };
  // }
};

export const stateWithValidation = (value, type = null) => {
  switch (type) {
    case "price": {
      if (isNaN(value)) {
        return {
          val: value,
          isValid: false,
          err: "Only Numbers allowed",
        };
      }
      const floatValue = parseFloat(value);

      if (floatValue > -1 && floatValue <= 10000000) {
        return {
          val: value,
          isValid: true,
          err: "",
        };
      }else if(floatValue >=  10000000){
        return {
          val: value,
          isValid: false,
          err: "Out of range value",
        };
      } else if (floatValue < 0) {
        return {
          val: value,
          isValid: false,
          err: "negative value not allowed",
        };
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "quantity": {
      if (isNaN(value)) {
        return {
          val: value,
          isValid: false,
          err: "Only Numbers allowed",
        };
      }
      const floatValue = parseFloat(value);

      if (floatValue > 0 && floatValue <= 10000) {
        return {
          val: value,
          isValid: true,
          err: "",
        };
      }else if(floatValue >= 10000){
        return {
          val: value,
          isValid: false,
          err: "Out of range value",
        };
      } else if (floatValue < 0) {
        return {
          val: value,
          isValid: false,
          err: "negative value not allowed",
        };
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "contact": {
      if (!isNaN(value) && value) {
        if (value.length > 10) {
          return {
            val: value,
            isValid: false,
            err: "Enter valid Mobile Number",
          };
        } else {
          return {
            val: value,
            isValid: true,
            err: "",
          };
        }
      } else {
        if (isNaN(value)) {
          return {
            val: value,
            isValid: false,
            err: "Only Numbers allowed",
          };
        } else {
          return {
            val: value,
            isValid: false,
            err: "required",
          };
        }
      }
    }
    case "paragraph": {
      if (value) {
        if (value.length > 1000 || value.length < 1) {
          return {
            val: value,
            isValid: false,
            err: "min 1 and max 1000 character allowed",
          };
        } else {
          return {
            val: value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "rejectReason": {
      if (value) {
        if (value.length > 250) {
          return {
            val: value,
            isValid: false,
            err: "max 250 character allowed",
          };
        } else {
          return {
            val: value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
    case "tag": {
      //   if (value) {
      //     if (value.length > 250) {
      //       return {
      //         val: value,
      //         isValid: false,
      //         err: "max 250 char allowed",
      //       };
      //     } else {
      //       return {
      //         val: value,
      //         isValid: true,
      //         err: "",
      //       };
      //     }
      //   } else {
      //     return {
      //       val: value,
      //       isValid: false,
      //       err: "required",
      //     };
      //   }
    }
    case "title": {
      if (value) {
        if (value.length > 256) {
          return {
            val: value,
            isValid: false,
            err: "max 256 char allowed",
          };
        } else {
          return {
            val: value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
    default: {
      if (value) {
        if (value.length > 50) {
          return {
            val: value,
            isValid: false,
            err: "max 50 char allowed",
          };
        } else {
          return {
            val: value,
            isValid: true,
            err: "",
          };
        }
      } else {
        return {
          val: value,
          isValid: false,
          err: "required",
        };
      }
    }
  }
};

export default updateStateWithValidation;
