import React, { useState, useEffect } from "react";
import { Button, Typography, TextField, Grid, Dialog, DialogContent, InputAdornment } from "@mui/material";
import walletIcon from "../../../assets/images/structure/wallet-icon.svg";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { Boxed } from "../../../Styles-Elements/Box";
import { useLocation } from "react-router-dom";
import services from "../../../services";
import WalletRecords from "./WalletRecords";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import { Alert } from "@mui/material";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";

const WalletDetails = ({ TabPanel, value, canUpdateWallet, id }) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const currentLoading = useSelector(currentLoader);
  const [walletDetails, setWalletDetails] = useState([]);
  const [openAddFaq, setAddFaq] = React.useState(false);
  const [openAddFaqforAdd, setAddFaqforAdd] = React.useState(false);
  
  const [amount, setAmount] = React.useState(null);
  const [reason, setReason] = React.useState(null);
  const [amountError, setAmountError] = React.useState('');
  const [adminId, setAdminId] = React.useState('');
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [reRender, setReRender] = useState(false);
  const [maxAmount, setMaxAmount] = useState(null);

  const OpenAddFaq = () => {
    setAmountError('');
    setAddFaq(true);
    setReason('')
  };
  const OpenAddFaqForAdd_button = () => {
    setAmountError('');
    setAddFaqforAdd(true);
    setReason('')
  };
  
  const handleCloseAddFaq = () => {
    setAddFaq(false);
  };
  const handleCloseAddFaqForAdd = () => {
    setAddFaqforAdd(false);
  };
  
  
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  
  const getCoustomerDetails = async () => {
    try {
      if (id) {
        dispatch(changeLoader(true));
        const response =
          await services?.manageCustomerListing?.GET_CUSTOMER_DETAILS(
            id
          );

        if (response?.data.ack === 1) {
          const data = response?.data?.data;
          setWalletDetails(data?.wallet_details);
        }
        dispatch(changeLoader(false));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWalletSettings = async () => {
    const response = await GlobalService(API.GET_SETTING_VALUE('max_wallet_amount'));
    if (response?.ack === 1) {
      setMaxAmount(response?.results?.value || 0);
    }
  };
  const getAddminDetail = () => {
    const token = localStorage.getItem("access_token");
    const decoded = jwt_decode(token);
    console.log(decoded?.id);
    setAdminId(decoded?.id);
  }
  useEffect(() => {
    getWalletSettings();
    getCoustomerDetails();
    getAddminDetail();
  }, []);

 
  const validateAmount = (input) => {
    return isNaN(parseFloat(input));
  };




  const addMoney = async (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    try {
      if (validateAmount(amount)) {
        setAmountError("Please enter valid amount");
        dispatch(changeLoader(false));
        return false;
      }
      if (eval(parseFloat(walletDetails?.amount) + parseFloat(amount)) < 0) {
        setAmountError("Deducted amount will be smaller or equal to the current balance amount");
        dispatch(changeLoader(false));
        return false;
      }
      if (parseFloat(amount) < 0) {
        if (isNaN(eval(parseFloat(walletDetails?.amount) + parseFloat(amount))) || eval(parseFloat(walletDetails?.amount) + parseFloat(amount)) < 0) {
          setAmountError("Please enter valid amount");
          dispatch(changeLoader(false));
          return false;
        }
      } else {
        if (eval(parseFloat(walletDetails?.amount) + parseFloat(amount)) > maxAmount) {
          setAmountError("Maximum amount which can be added to wallet is " + maxAmount);
          dispatch(changeLoader(false));
          return false;
        }
      }
      const token = localStorage.getItem("access_token");
      const decoded = jwt_decode(token);
      dispatch(changeLoader(false));
      const data = {
        customer_id: id,
        amount,
        admin_id: decoded?.id,
      };
      const response = await services?.manageCustomerListing?.ADD_MONEY(data);

      if (response?.data?.ack === 1) {
        setAddFaqforAdd(false);
        setSeverity("success");
        setAlertMsg('Amount added successfully');
        setShowAlert(true);
        disableAlert();
        getCoustomerDetails();
        setReRender(!reRender);
        setAmount(null);
      } else {
        setSeverity("error");
        setAlertMsg(response?.data?.msg);
        setShowAlert(true);
        disableAlert();
        setAddFaq(false);
      }
      dispatch(changeLoader(false));
    } catch (e) {
      console.log(e);
      dispatch(changeLoader(false));
    }
    dispatch(changeLoader(false));
  };
  const deductMoney = async (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    try {
      if (validateAmount(amount)) {
        setAmountError("Please enter valid amount");
        dispatch(changeLoader(false));
        return false;
      }
      if (eval(parseFloat((walletDetails?.amount.toFixed(2) || 0))) < 0) {
        setAmountError("Deducted amount will be smaller or equal to the current balance amount");
        dispatch(changeLoader(false));
        return false;
      }
      if (parseFloat(amount) < 0) {
        if (isNaN(eval(parseFloat((walletDetails?.amount.toFixed(2) || 0)) + parseFloat(amount))) || eval(parseFloat((walletDetails?.amount.toFixed(2) || 0)) + parseFloat(amount)) < 0) {
          setAmountError("Please enter valid amount");
          dispatch(changeLoader(false));
          return false;
        }
      }
      // else {
      //   if (eval(parseFloat(walletDetails?.amount) + parseFloat(amount)) > maxAmount) {
      //     setAmountError("Maximum amount which can be added to wallet is " + maxAmount);
      //     dispatch(changeLoader(false));
      //     return false;
      //   }
      // }
      const token = localStorage.getItem("access_token");
      const decoded = jwt_decode(token);
      dispatch(changeLoader(false));
      const data = {
        customer_id: id,
        amount,
        reason:reason,
        admin_id: decoded?.id,
      };
      const response = await services?.manageCustomerListing?.DEDUCT_MONEY(data);

      if (response?.data?.ack === 1) {
        handleCloseAddFaq();
        setSeverity("success");
        setAlertMsg('Amount deduct successfully');
        setShowAlert(true);
        disableAlert();
        getCoustomerDetails();
        setReRender(!reRender);
        setAmount(null);
      } else {
        setSeverity("error");
        setAlertMsg(response?.data?.msg);
        setShowAlert(true);
        disableAlert();
        setAddFaq(false);
      }
      dispatch(changeLoader(false));
    } catch (e) {
      console.log(e);
      dispatch(changeLoader(false));
    }
    dispatch(changeLoader(false));
  };
  return (
    <>
      <TabPanel value={value} index={2}>
        {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" mb={3} fontSize={"16px"} fontWeight={500}>
            Wallet Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} mb={4}>
            <Grid item xs={12}>
              <div className="card-white" style={{ marginBottom: "16px" }}>
                <div className="wallet-box-new">
                  <Boxed display={"flex"} alignItems={"center"}>
                    <img src={walletIcon} className="wallet-icon" alt="" />
                    <div className="relative">
                      <h4 className="h4 latter-spacing1">
                        QAR {" "} {walletDetails?.amount ? (walletDetails?.amount.toFixed(2)) : <span> 0</span>}
                      </h4>
                      <p className="p2">Wallet Balance</p>
                    </div>
                  </Boxed>
                  <div>
                  {canUpdateWallet && (adminId  == '157528' || adminId == '157529') ?
                    <>
                     
                     
                      <Button variant="contained" size="medius" style={{ marginRight: '2px' }} onClick={() => {
                          OpenAddFaqForAdd_button()
                      }}> Add Money For With Out Order Id </Button>  
                     
                    </> :
                    null}
                
                  {canUpdateWallet &&   (adminId  == '157528' || adminId == '157529')  ?
                    <>
                     
                     
                      <Button variant="contained" size="medius" onClick={OpenAddFaq}> Deduct Money </Button>
                     
                    </> :
                    null}
                    </div>
                </div>
              </div>
              <WalletRecords reRender={reRender} id={id}/>
            </Grid>
          </Grid>
        </Grid>
        
      </TabPanel>
      
      <Dialog open={openAddFaqforAdd} onClose={handleCloseAddFaqForAdd}>
        <img src={modalClose} alt="..." onClick={handleCloseAddFaqForAdd} className="modal-close" />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}> Add Money </Typography>
          <form onSubmit={addMoney}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" fontSize={"14px"} color="#A9ACAA" fontWeight={400} mb={2}>
                  Enter the amount below to add money in your wallet.
                </Typography>
                <TextField
                  required={true} label="Amount" id="outlined-end-adornment" value={amount}
                  InputProps={{ endAdornment: <InputAdornment position="end">QAR</InputAdornment> }}
                  onChange={(e) => {
                    if ((maxAmount !== null && maxAmount !== 0 ) && parseFloat(e.target.value) > maxAmount) {
                      setAmountError("Maximum amount is " + maxAmount);
                    } else {
                      setAmount(e.target.value);
                      setAmountError("");
                    }
                  }}
                />
                <span style={{ color: "red", fontSize: "12px" }}>{ amountError }</span>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained" size="large" sx={{ width: 1, marginTop: "16px" }} type="submit"
                  disabled={currentLoading} 
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      
      <Dialog open={openAddFaq} onClose={handleCloseAddFaq}>
        <img src={modalClose} alt="..." onClick={handleCloseAddFaq} className="modal-close" />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}> Deduct Money </Typography>
          <form onSubmit={deductMoney}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" component="h6" fontSize={"14px"} color="#A9ACAA" fontWeight={400} mb={2}>
                  Enter the amount Deduction reason.
                </Typography>
                <TextField
                  required={true} label="Reason" id="outlined-end-adornment" value={reason}
                  
                  onChange={(e) => {
                     setReason(e.target.value);
                      // setAmountError("");
                   }}
                />
              
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" fontSize={"14px"} color="#A9ACAA" fontWeight={400} mb={2}>
                  Enter the amount below to deduct money in your wallet.
                </Typography>
                <TextField
                  required={true} label="Amount" id="outlined-end-adornment" value={amount}
                  InputProps={{ endAdornment: <InputAdornment position="end">QAR</InputAdornment> }}
                  onChange={(e) => {
                    if ( parseFloat(e.target.value) > (walletDetails?.amount.toFixed(2) || 0)) {
                      setAmountError("Existance amount is " + (walletDetails?.amount.toFixed(2) || 0) + " You can't deduct More then current Existance Amount");
                    } else {
                      setAmount(e.target.value);
                      setAmountError("");
                    }
                  }}
                />
                <span style={{ color: "red", fontSize: "12px" }}>{ amountError }</span>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained" size="large" sx={{ width: 1, marginTop: "16px" }} type="submit"
                  disabled={currentLoading} 
                >
                  Deduct
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WalletDetails;
