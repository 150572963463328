import { Divider } from "@mui/material";

import moneyAdded from "../../../assets/images/structure/money-added.svg";
import moneyPaid from "../../../assets/images/structure/money-paid.svg";
import services from "../../../services";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WalletDetailsMain from "./WalletDetailsMain";
const WalletRecords = ({ reRender, id }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [transactions, setTransections] = useState([]);
  const [transectionData, setTransectionData] = useState([]);

  const updateData = (transactions) => {
    let details = [];

    transactions?.map((item) => {
      let date = item.createdAt;
      const currentDate = new Date(date);
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear().toString();
      const formattedDate = `${day}-${month}-${year}`;
      let d = details[formattedDate];
      if (typeof d === "undefined") {
        details[formattedDate] = [item];
      } else {
        details[formattedDate].push(item);
      }
    });
    setTransectionData(details);
  };

  const getTransaction = async () => {
    if (id) {
      const response =
        await services?.manageCustomerListing?.GET_CUSTOMER_DETAILS(
          id
        );

      if (response?.data.ack === 1) {
        setTransections(response?.data?.data?.transactions);
        updateData(response?.data?.data?.transactions);
      }
    }
  };
  useEffect(() => {
    getTransaction();
  }, [reRender]);

  return (
    <div className="card-white">
      <div className="business-detail-wrap">
        {Object.keys(transectionData).map((key) => {
          const value = transectionData[key];

          return (
            <>
              <span className="date">{key}</span>
              <WalletDetailsMain data={value}  />
              <Divider />
            </>
          );
        })}
        <Divider />
      </div>
    </div>
  );
};

export default WalletRecords;
