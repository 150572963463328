import { Autocomplete, Box, Button, TextField, Typography, createFilterOptions } from "@mui/material";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import { API } from "../../ENUM";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useParams, useNavigate } from "react-router-dom";
import GlobalService from "../../../services/GlobalService";

const AssociateZone = () => {

  const navigate = useNavigate();
  let [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  let [zoneError, setZoneError] = useState('');
  const dispatch = useDispatch();
  const route = useParams();
  const id = route.storeId;

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name,
  });

  const handleOptionChange = (event, newValue) => {
    zoneError = '';
    setZoneError(zoneError)
    setSelectedOption(newValue);
  };
  
  const getData = async () => {
    const response = await GlobalService(API.GET_LOCATION)
    options = response.data;
    setOptions(options);
    getAssociates();
  };

  const addAssociate = async () => {
    if (selectedOption.length > 0) {
      const str = selectedOption.map((obj) => obj.id).join(",");
      const data = { storeId: id, associates: str }
      const response = await GlobalService(API.ADD_ASSOCIATES, data, "post")
      if (response?.ack == 1) {
        navigate(`/store-listing`);
        dispatch(
          changeSnackbar({
            message: response?.msg,
            isOpen: true,
            state: "success",
          })
        );
      } else {
        zoneError = response?.msg;
        setZoneError(zoneError)
      }
    } else {
      zoneError = "Please select zone first";
      setZoneError(zoneError)
    }
  }
  
  const getAssociates = async () => {
    const response = await GlobalService(`${API.GET_ASSOCIATES}?storeId=${id}`)
    const itemIds = response.data.map((item) => item.id);
    let selectedOption = options?.filter((item) => itemIds.includes(item.id));
    setSelectedOption(selectedOption);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
              Add Zone Associates
            </Typography>

            <div className="grid-item" style={{
              position: 'relative'
            }}>
              <Autocomplete
                multiple={true}
                value={selectedOption}
                onChange={handleOptionChange}
                options={options}
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} label="Select Option" variant="outlined" />}
              />
              <span className="redspan">{zoneError}</span>
            </div>
            <div className="grid-item" style={{ textAlign: "right", marginTop: "20px" }}>
              <Button variant="contained" onClick={addAssociate}>
                Submit
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AssociateZone;
