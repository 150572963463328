import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { GoogleMap, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import { API } from "../../ENUM";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import GlobalService from "../../../services/GlobalService";

const mapContainerStyle = {
  width: "100%",
  height: "150px",
  borderRadius: "8px",
};

const AreaZoneManager = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [zones, setZones] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const [zoneError, setZoneError] = useState("");
  const [businessTypeError, setBusinessTypeError] = useState("");
  const [zoneData, setZoneData] = useState({ name: "", area_ids: [] });
  const [activeZoneId, setActiveZoneId] = useState(null); // Track active zone ID for in-place editing
  const dispatch = useDispatch();

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY",
  });

  const handleOptionChange = (event, newValue) => {
    setZoneError("");
    setSelectedOption(newValue);
  };

  const handleBusinessTypeChange = (event, newValue) => {
    setBusinessTypeError("");
    setSelectedBusinessType(newValue);
  };

  const getLocationData = async () => {
    const response = await GlobalService(API.GET_LOCATION);
    if (response?.data) setOptions(response.data);
  };

  const getZoneData = async () => {
    const response = await GlobalService(API.GET_AREAZONE);
    if (response?.ack === 1) setZones(response.data);
  };

  const getBusinessData = async () => {
    const response = await GlobalService(API.GET_BALADI_BUINESSTYPE);
    console.log("response", response);
    if (response?.ack === 1)
      setBusinessData(
        response.businesstype.rows.map((row) => ({
          id: row.id,
          name: row.business_type_locales[0]?.name,
        }))
      );
  };

  const loadZone = async (zoneId) => {
    const response = await GlobalService(API.GET_AREAZONE_BY_ID(zoneId));

    if (response?.ack === 1) {
      const selectedAreas = options.filter((opt) => response.data.area_ids.includes(opt.id));
      setZoneData(response.data);
      setSelectedOption(selectedAreas);
      setSelectedBusinessType(
        businessData.find((business) => business.id === response.data.business_type_id)
      );
      setActiveZoneId(zoneId); // Set active zone ID for editing mode
    } else {
      dispatch(
        changeSnackbar({ message: "Error fetching zone data.", isOpen: true, state: "error" })
      );
    }
  };

  const saveZone = async () => {
    if (selectedOption.length === 0) {
      setZoneError("Please select areas for the zone.");
      return;
    }

    if (!selectedBusinessType) {
      setBusinessTypeError("Please select a business type.");
      return;
    }

    const areaIds = selectedOption.map((option) => option.id);
    const payload = {
      name: zoneData.name,
      area_ids: areaIds,
      business_type_id: selectedBusinessType.id,
      zoneId: activeZoneId,
    };

    const response = await GlobalService(
      activeZoneId ? API.UPDATE_AREAZONE(activeZoneId) : API.ADD_AREAZONE,
      payload,
      activeZoneId ? "put" : "post"
    );
    if (response?.ack === 1) {
      dispatch(changeSnackbar({ message: response.msg, isOpen: true, state: "success" }));
      setZoneData({ name: "", area_ids: [] });
      setSelectedOption([]);
      setSelectedBusinessType(null);
      setActiveZoneId(null); // Clear active zone ID after save
      getZoneData(); // Refresh zone list
    } else {
      setZoneError(response?.msg || "Error saving zone.");
    }
  };

  const deleteZone = async (zoneId) => {
    try {
      const response = await GlobalService(API.DELETE_AREAZONE(zoneId), {}, "delete");
      if (response?.ack === 1) {
        dispatch(changeSnackbar({ message: response.msg, isOpen: true, state: "success" }));
        getZoneData(); // Refresh zone list after deletion
      } else {
        setZoneError(response?.msg || "Error deleting zone.");
      }
    } catch (error) {
      setZoneError("An error occurred while trying to delete the zone.");
    }
  };

  useEffect(() => {
    getLocationData();
    getZoneData();
    getBusinessData();
  }, []);

  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20-30">
          <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
            {activeZoneId ? "Edit Zone" : "Create New Zone"}
          </Typography>

          <div className="grid-item" style={{ position: "relative" }}>
            <TextField
              label="Zone Name"
              value={zoneData.name}
              onChange={(e) => setZoneData({ ...zoneData, name: e.target.value })}
              fullWidth
              margin="normal"
            />
            <Autocomplete
              multiple
              value={selectedOption}
              onChange={handleOptionChange}
              options={options}
              getOptionLabel={(option) => option.name}
              filterOptions={filterOptions}
              renderInput={(params) => <TextField {...params} label="Select Areas" variant="outlined" />}
            />
            <span className="redspan">{zoneError}</span>

            <Autocomplete
              value={selectedBusinessType}
              onChange={handleBusinessTypeChange}
              options={businessData}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Select Business Type" variant="outlined" />}
              style={{ marginTop: "16px" }}
            />
            <span className="redspan">{businessTypeError}</span>
          </div>

          <div className="grid-item" style={{ textAlign: "right", marginTop: "20px" }}>
            <Button variant="contained" onClick={saveZone} style={{ marginRight: "10px" }}>
              {activeZoneId ? "Update Zone" : "Create Zone"}
            </Button>
            {activeZoneId && (
              <Button variant="outlined" color="secondary" onClick={() => setActiveZoneId(null)}>
                Cancel
              </Button>
            )}
          </div>

          <Typography variant="h6" component="h6" mt={4} mb={2}>
            List of Zones
          </Typography>

          <Grid container spacing={3}>
            {zones.length > 0 ? (
              zones.map((zone) => (
                <Grid item xs={12} md={6} lg={4} key={zone.id}>
                  <Card variant="outlined" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      zoom={12}
                      center={
                        zone.areas.length > 0
                          ? JSON.parse(zone.areas[0].latlng)[0]
                          : { lat: 25.276987, lng: 51.520008 }
                      }
                    >
                      {zone.areas.map((area) => {
                        const areaPath = JSON.parse(area.latlng);
                        const centroid = {
                          lat: areaPath.reduce((sum, point) => sum + point.lat, 0) / areaPath.length,
                          lng: areaPath.reduce((sum, point) => sum + point.lng, 0) / areaPath.length,
                        };

                        return (
                          <div key={area.id}>
                            <Polygon
                              paths={areaPath}
                              options={{
                                fillColor: "#2196f3",
                                fillOpacity: 0.15,
                                strokeColor: "#1976d2",
                                strokeWeight: 2,
                              }}
                            />
                            <Marker
                              position={centroid}
                              label={{
                                text: area.name,
                                color: "#555",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        );
                      })}
                    </GoogleMap>
                    <CardContent>
                      <Typography variant="h6" component="h6" fontWeight={500}>
                        {zone.name}
                      </Typography>
                      <Box mt={1} display="flex" flexWrap="wrap" gap="4px">
                        {zone.areas.map((area) => (
                          <Typography
                            key={area.id}
                            variant="caption"
                            style={{
                              backgroundColor: "#e0e0e0",
                              padding: "2px 6px",
                              borderRadius: "4px",
                              fontSize: "0.75rem",
                            }}
                          >
                            {area.name}
                          </Typography>
                        ))}
                      </Box>
                    </CardContent>
                    <CardActions style={{ justifyContent: "flex-end" }}>
                      <Button variant="outlined" size="small" onClick={() => loadZone(zone.id)}>
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => deleteZone(zone.id)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => navigate(`/store-zone-management/${zone.id}`)}
                      >
                        Manage Store
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>No zones available.</Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AreaZoneManager;
