import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import calenderIcon from "../../../assets/images/structure/calender-schedule.svg";
import { API } from "../../ENUM";
import GlobalService from "../../../services/GlobalService";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import moment from "moment";
import { useEffect } from "react";
const PickerOrderDetail = ({ ID, start_date, end_date }) => {
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [Data, setData] = useState("");
  useEffect(() => {
    handleChildClick();
  }, [ID]);
  const handleChildClick = async () => {
    const object ={
      start_date:start_date?start_date:"",
      end_date:end_date?end_date:"",
    }
    const query = new URLSearchParams(object)
    try {
      let url = API.PICKER_TRACKING.GET_PICKERS_ORDER(ID, query);
      const response = await GlobalService(url);
      setData(response?.orders);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setDataLoaded(true);
    }, 100);
  }, []);
  return (
    <>
      {dataLoaded && (
        <div className="table-type-parent gray-border">
          {Data && Data.length > 0 ? (
            Data.map((item, i) => (
              <div className="table-type-box" key={i}>
                <div className="table-type-td flex">
                  <img src={calenderIcon} alt="deliver" className="icon24" />
                  <div className="relative">
                    <span className="type-th">Order Date</span>
                    <h3 className="type-td">
                      {moment(item?.createdAt).format("DD MMMM YYYY hh:mm a")}
                    </h3>
                  </div>
                </div>
                <div className="table-type-td">
                  <span className="type-th">Store Name</span>
                  <h3 className="type-td width120">
                    {item?.store?.stores_locales[0]?.business_name}
                  </h3>
                </div>
                <div className="table-type-td">
                  <span className="type-th">Business Address</span>
                  <h3 className="type-td width250">
                    {JSON.parse(item.addressJson)?.business_address}
                  </h3>

                  <span className="type-th">Contact Number</span>
                  <h3 className="type-td width120">
                    {JSON.parse(item?.addressJson)?.contact_number}
                  </h3>
                </div>

                <div className="table-type-td width180">
                  <span className="type-th">Order ID</span>
                  <div>
                    <a
                      href={`/order-manage-detail/${item?.status}/${item?.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `/order-manage-detail/${item?.status}/${item?.id}`,
                          "_blank"
                        );
                      }}
                    >
                      {item?.order_id}
                    </a>
                  </div>
                </div>
                <div className="table-type-td">
                  <span className="type-th">Customer Name</span>
                  <h3 className="type-td width120">
                    {JSON.parse(item?.addressJson)?.full_name}
                  </h3>
                </div>
                <div className="table-type-td">
                  <span className="type-th">No. of Items</span>
                  <h3 className="type-td">{item?.total_quantity}</h3>
                </div>
                <div className="table-type-td">
                  <span className="type-th">Total Amount</span>
                  <h3 className="type-td">QAR {item?.total_amount}</h3>
                </div>
              </div>
            ))
          ) : (
            <NoDataFound text={"No Data Found"} />
          )}
        </div>
      )}
    </>
  );
};

export default PickerOrderDetail;
