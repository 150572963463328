import React, { useState, useEffect } from "react";
import { Boxed } from "../../../Styles-Elements/Box";
import { useParams, useNavigate } from "react-router-dom";
import "./OrderManage.scss";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import {
  Typography,
  Box,
  TextField,
  Button,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { getdataByKey, getAllBarcode } from "../../helper/custom";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";
import { useSelector } from "react-redux";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import moment from "moment";

const StockLedger = () => {

  const { productId } = useParams();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
	const [stockList, setStockList] = useState([])
  let [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  let [orderBy, setOrderBy] = useState("id");
  let [order, setOrder] = useState("desc");


	const SortTab = ({ column, sort }) => {
    return null;
		return (
			<div className="short-box">
        <ArrowDropUpIcon onClick={() => sort(column, "asc")} />
        <ArrowDropDownIcon onClick={() => sort(column, "desc")} />
      </div>
		)
	}

  const onSort = (column, orderType) => {
    orderBy = column;
    setOrderBy(orderBy);
    order = orderType;
    setOrder(order);
    setPage(1);
    getStockList();
  }

	const getStockList = async () => {
    dispatch(changeLoader(true));
    let url = API.STOCK_LOGS(productId);
    url += `?page=${page}&limit=${limit}`;
    url += `&order_by=${orderBy}&order_type=${order}`;
    const res = await GlobalService(url);
    if (res.ack === 1) {
      setStockList(res.data);
      setTotal(Math.ceil(res.count / limit));
    } else {
      setStockList([]);
    }
    dispatch(changeLoader(false));
	}

	useEffect(() => {
		getStockList();
	}, [])
	

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20">
          <div className="top-heading-search">
            <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
						Stock Ledger
            </Typography>
						{/* <div className=" ">
              <button className="btn clear-all" style={{ marginRight: '10px', }}>Download CSV</button>
            </div> */}
          </div>
          <div className="order-listing-wrapper">
            <div className="">
						{stockList.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
												Date <SortTab
                          column="createdAt"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Product name 
                        {/* <SortTab column="products_name" sort={onSort} /> */}
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Type <SortTab
                          column="documentType"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Order ID<SortTab
                          column="documentId"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className="short-div">
												Created By
                        <SortTab column="createdBy" sort={onSort} />
                      </div>
                    </TableCell> */}
										<TableCell>
                      <div className="short-div">
												Created By
                        {/* <SortTab column="full_name" sort={onSort} /> */}
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Uom ID <SortTab
                          column="uomId"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Uom Name
                        {/* <SortTab column="uomName" sort={onSort} /> */}
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Relation With Base
                        {/* <SortTab column="relationWithBase" sort={onSort} /> */}
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
												Barcode
                        {/* <SortTab column="barcode" sort={onSort} /> */}
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
                      Qty. Inward<SortTab
                          column="qty_outward"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
                      Qty. Outward <SortTab
                          column="ordered_qty"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
										<TableCell>
                      <div className="short-div">
                      Balance <SortTab
                          column="qty_inward"
                          sort={onSort}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

									{stockList.map((stock, index) =>
                  <TableRow key={`stock-${index}`}>
                    <TableCell>{
                      moment(stock.createdAt).format('DD/MM/YYYY HH:mm:ss A')
                      }</TableCell>
										<TableCell>
                      {stock?.products_locales?.length > 0?
                        getdataByKey(stock.products_locales, 'en', 'title'):
                        "-"}
                    </TableCell>
										<TableCell>{stock?.documentType}</TableCell>
										<TableCell>{stock?.documentId}</TableCell>
                    {/* <TableCell>{stock?.createdBy}</TableCell> */}
										<TableCell>{stock?.user?.full_name || "Unknown"}</TableCell>
										<TableCell>{stock?.uomId}</TableCell>
										<TableCell>{stock?.uom?.uomName}</TableCell>
										<TableCell>{stock?.uom?.relationWithBase}</TableCell>
										<TableCell>
                      { stock.uom.uom_barcode_relations.length > 0?
                      getAllBarcode(stock.uom.uom_barcode_relations): "-"}
                    </TableCell>
										<TableCell>{stock?.qty_inward}</TableCell>
										<TableCell>{stock?.qty_outward}</TableCell>
										<TableCell>{stock?.balance}</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>:
            <NoDataFound text={"No Stock Ledger Found"} />
            }
              {total > 1?<Pagination
                count={total}
                page={page}
                color="primary"
                onChange={(e, newpage) => {
                  page = newpage;
                  setPage(page)
                  getStockList();
                }}
              />: null}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default StockLedger;
