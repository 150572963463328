import { useNavigate, useParams } from "react-router-dom";
import {
  Typography, TextField, Button, Switch, Grid, Tooltip, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, MenuItem, Dialog, DialogContent,
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import arrowLeftBlackIcon from "../../../assets/images/structure/arrow-left-black.svg";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@material-ui/icons/Close";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useEffect, useState } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { encode } from "base-64";
import { currentPermission } from "../../../redux/reducers/permission";
import {getdataByKey} from "../../helper/custom";

let searchTimer = null;
// image
export default function CuisineListing() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canAdd, setCanAdd] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canView, setCanView] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);

  let [search, setSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: "" });
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  let [page, setPage] = useState(1);
  const [isDelete, setIsDelete] = useState(false);
  const [id, setId] = useState(0);
  let [categoryName, setCategoryName] = useState("");
  const params = useParams();

  const callApi = () => {
    dispatch(changeLoader(true));
    GlobalService(
      API.CUISINE.GET_CUISINE_USING_CATEGORY_ID,
      {
        search: search,
        pages: page,
        category_id: params?.category_id,
        limit: 1000,
      },
      "get"
    )
      .then((da) => {
        if (da.ack == 1) {
          setData(da.data);
          setCount(da.count);
        }
      })
      .catch((err) => {
        let msg = err.response.body.msg;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: msg,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    categoryName = sessionStorage.getItem("category-name");
    setCategoryName(categoryName);
    callApi();
  }, [page]);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      callApi();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanAdd(permission?.cuisine?.create === 1)
      setCanEdit(permission?.cuisine?.edit === 1)
      setCanDelete(permission?.cuisine?.delete === 1)
      setCanView(permission?.cuisine?.read === 1)
      setCanUpdateStatus(permission?.cuisine?.actions?.status === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
      setCanView(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);

  const DeleteModel = ({ handleClose, submit }) => {
    return (
      <>
        <Dialog open={true} onClose={handleClose} className="alert-delete">
          <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
          <DialogContent>
            <Typography variant="h6" component="h6" textAlign={"left"} mb={1}>
              Delete
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500} mb={0} textAlign={"left"}>
                    Are you sure you want to delete this Cuisine?
                  </Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                  <Button variant="contained" size="medium" sx={{ marginTop: "16px" }} onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ marginTop: "16px", marginLeft: "16px" }}
                    onClick={submit}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const deleteApi = (id) => {
    dispatch(changeLoader(true));
    GlobalService(API.CUISINE.DELETE(id), {}, "delete")
      .then((data) => {
        if (data.ack == 1) {
          callApi();
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        setId(0);
      })
      .catch((err) => {
        let msg = err.response.body.msg;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: msg,
            state: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  const updateStatus = (id, status) => {
    GlobalService(
      API.CUISINE.UPDATE_STATUS,
      {
        cuisineId: id,
        status: status,
      },
      "put"
    )
      .then((data) => {})
      .catch((err) => {});
  };

  const ShowData = ({ data, canEdit, canDelete, canUpdateStatus }) => {
    
    let locale = data?.cuisines_locales;
    let eng = getdataByKey(data?.cuisines_locales, 'en', 'name') // locale[0] ? locale[0]?.name : "";
    let ar = getdataByKey(data?.cuisines_locales, 'ar', 'name') // locale[1] ? locale[1]?.name : "";

    const [status, setStatus] = useState(data?.status == "active" ? true : false);

    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">{eng}</TableCell>
          <TableCell>{ar}</TableCell>
          <TableCell align="center" className="nowrap">
            <Switch
              checked={status}
              onChange={(e) => {
                let check = e.target.checked;
                setStatus(check);
                updateStatus(data?.id, check ? "active" : "inactive");
              }}
              disabled={!canUpdateStatus}
            />
          </TableCell>
          { canEdit || canDelete ?
            <TableCell align="center" className="nowrap">
            {canEdit?<Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
              <BorderColorIcon
                color="action"
                fontSize="small"
                onClick={() => {
                  let datas = encodeURIComponent(encode(JSON.stringify({id: data?.id})));
                  sessionStorage.setItem("cuisineData", JSON.stringify({
                    name_en: eng,
                    name_ar: ar,
                    description_en: getdataByKey(data?.cuisines_locales, 'en', 'description'),
										description_ar: getdataByKey(data?.cuisines_locales, 'ar', 'description'),
                    id: data?.id,
                    cuisineCategoryId: data?.cuisineCategoryId,
                    image: data?.image,
                    businessTypeId: data?.businessTypeId
                  }));
                  navigate(`/add-cuisine/${datas}`);
                }}
              />
            </Tooltip>: null}
            {canDelete?<Tooltip title="Delete" placement="top">
              <DeleteForever
                onClick={() => {
                  setId(data.id);
                  setIsDelete(true);
                }}
                color="error"
                fontSize="small"
              />
            </Tooltip>: null}
          </TableCell>: null}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <div>
                <img src={arrowLeftBlackIcon} alt="..." onClick={() => navigate("/cuisine-category-listing")}
                  style={{ cursor: "pointer" }}
                />
                <Typography variant="h6" component="h6" mb={3} fontWeight={500} style={{
                  float: "right",
                  marginLeft: "10px",
                }}>
                  Cuisine Listing ({categoryName})
                </Typography>
              </div>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    placeholder="Search Cuisine"
                    value={search}
                    onChange={(e) => {
                      let value = e.target.value;
                      setSearch(value);
                    }}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search ? (
                        <CloseIcon
                          onClick={() => {
                            setSearch("");
                          }}
                        />
                      ) : null,
                    }}
                  />
                </div>
              </div>
            </div>

            {data.length ? (
              <>
                <TableContainer className="height-fix">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>English Name</TableCell>
                        <TableCell>Arabic Name</TableCell>
                        <TableCell align="center" className="nowrap">Status</TableCell>
                        { canEdit || canDelete ?
                          <TableCell className="nowrap" align="center">Action</TableCell>: null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((obj, ind) => 
                        <ShowData
                          key={ind} data={obj} categoryName={obj?.name}
                          canEdit={canEdit} canDelete={canDelete}
                          canUpdateStatus={canUpdateStatus}
                        />
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <NoDataFound text={"No Cuisine Found"} />
            )}
            {count > 1?<Pagination
              onChange={(event, value) => {
                setPage(value);
              }}
              count={count}
              page={page}
              color="primary"
            />: null}
          </Box>
        </Box>
      </Box>
      {isDelete ? (
        <DeleteModel
          handleClose={() => setIsDelete(false)}
          submit={() => {
            setIsDelete(false);
            deleteApi(id);
          }}
        />
      ) : null}
    </>
  );
}
