import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Checkbox
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import modalClose from "../../../assets/images/structure/close-modal.png";
import services from "../../../services";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import constant from "../../../constant";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { ImageCropper, imagesToBase64 } from "../../../dialog/Cropper";
import { getdataByKey, blobToBase64 } from "../../helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function SystemSettingDetail() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [storeList, setStoreList] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [subCategorySearch, setSubCategorySearch] = useState([]);
  const [subSubCategorySearch, setSubSubCategorySearch] = useState([]);
  const [storeSearch, setStoreSearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");
  let [brandList, setBrandList] = useState([]);
  let [brand, setBrand] = useState(null);
  const [isBrandDivVisibleAutocomplate, setIsBrandDivVisibleAutocomplate] = useState(false);
  let [product, setProduct] = useState([]);
  const [isProductDivVisibleAutocomplate, setIsProductDivVisibleAutocomplate] =
    useState(false);
  const [isStoreDivVisibleAutocomplate, setIsStoreDivVisibleAutocomplate] = useState(false);
  let [Categorys, setCategorys] = useState([]);
  const [
    isCategoryDivVisibleAutocomplate,
    setIsCategoryDivVisibleAutocomplate,
  ] = useState(false);
  let [products, setProducts] = useState([]);
  const [openRejectReason, setOpenRejectReason] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [base64img, setBase64img] = useState("");
  const [base64img2, setBase64img2] = useState("");
  const [url, setUrl] = useState("");
  const [store_id, setStore_id] = useState(1);
  const [searchEngErr, setSearchEngErr] = useState(false);
  const [searchArbErr, setSearchArbErr] = useState(false);
  const [isEdit, setIsEdit] = useState(location.state);
  const [businesstype, setBusinessType] = useState([]);
  let [defaultVal, setDefaultVal] = useState("");
  const [urlArebic, setUrlArebic] = useState("");
  const [isEditAble, setIsEditAble] = useState(true);
  
  const [store, setStore] = useState([]);
  const [platform, setPlatform] = useState("app");
  let [Category, setCategory] = useState([]);
  let [subCategoryList, setSubCategoryList] = useState([]);
  let [subSubCategoryList, setSubSubCategoryList] = useState([]);

  let [cropperData, setCropperData] = useState({ croppedImage: "", isOpen: false });
  let [cropperData2, setCropperData2] = useState({ croppedImage: "", isOpen: false });
  let [allCusines, setAllCusines] = useState([]);
  let [settingCusine, setSettingCusine] = useState(false);

  let [cusineSearch, setCusineSearch] = useState('')
  let [cusine, setCusine] = useState(null);
  const [isCusineDivVisibleAutocomplate, setIsCusineDivVisibleAutocomplate] = useState(false);
  const [isSubCategoryDivVisibleAutocomplate, setIsSubCategoryDivVisibleAutocomplate] = useState(false);
  let [selectedSubCategories, setSelectedSubCategories] = useState([]);
  
  const [isSubSubCategoryDivVisibleAutocomplate, setIsSubSubCategoryDivVisibleAutocomplate] = useState(false);
  let [selectedSubSubCategories, setSelectedSubSubCategories] = useState([]);


  const routerStoreListing = () => {
    setSearchArbErr(false);
    setSearchEngErr(false);
    const path = `/banner-management`;
    navigate(path);
  };

  const handleClose = () => {
    setOpenRejectReason(false);
  };

  const handleClose2 = () => {
    setOpenAlertDelete(false);
  };

  const imageBase64 = async (e) => {
    // const imageUrl = await imagesToBase64([file]);
    const file = e.target.files[0];
    const compressedFile = await imageCompression(file, options);
    const imageUrl = await blobToBase64(compressedFile);
    cropperData = {
      croppedImage: imageUrl,
      isOpen: true,
    };
    setCropperData(cropperData);
    document.getElementById("uploadImage").value = "";
  };

  const imageBase642 = async (e) => {
    const file = e.target.files[0];
    // const imageUrl = await imagesToBase64([file]);
    const compressedFile = await imageCompression(file, options);
    const imageUrl = await blobToBase64(compressedFile);
    cropperData2 = {
      croppedImage: imageUrl,
      isOpen: true,
    };
    setCropperData2(cropperData2);
    document.getElementById("uploadImage2").value = "";
  };

  const getAllCategories = async () => {
    if (store) {
      
      if (store && store[0]?.value) {
        let url = API.GET_ALL_STORES_CATEGORIES(categorySearch);
        url += `&storeId=${store[0]?.value}`;
        const response = await GlobalService(url);
        setCategory(response?.data);
        if (response?.ack === 1) {
          Category = response.data.map((item) => {
            item.label = getdataByKey(item?.category_locales, "en", "name");
            return item;
          });
          setCategory(Category);
        }
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: "Please select store first",
            state: "error",
          })
        );
      }
    }
  };

  const getSubCategory = async (item) => {
    if (!item) return;
    try {
      const query = new URLSearchParams({
        parent_id: item?.id,
        search: subCategorySearch
      }).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubCategoryList(data.map((item) => {
        item.label = getdataByKey(item?.category_locales, "en", "name");
        return item;
      }));
    } catch (error) {
      console.log(error);
    }
  }

  const getSubSubCategory = async (item) => {
    if (!item) return;
    try {
      const query = new URLSearchParams({
        parent_id: item?.id,
        search: subSubCategorySearch
      }).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubSubCategoryList(data.map((item) => {
        item.label = getdataByKey(item?.category_locales, "en", "name");
        return item;
      }));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      getSubCategory(Categorys[0]);
    }, 500);
    return () => clearTimeout(getData);
  }, [subCategorySearch]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllCategories();
    }, 500);
    return () => clearTimeout(getData);
  }, [store, categorySearch]);

  const getAllBrands = async () => {
    if (defaultVal !== "") {
      try {
        const response = await GlobalService(API.GET_ALL_BRANDS_BY_CATEGORY(defaultVal));
        if (response?.ack === 1) {
          brandList = response.stores.map((item) => {
            return {
              value: item.id,
              label: getdataByKey(item?.stores_locales, "en", "business_name"),
            };
          });
          setBrandList(brandList);
        }
      } catch (error) {
        console.log("Error_In_store Listing", error);
      }
    }
  };

  const getAllStores = async () => {
    try {
      if (defaultVal === "" || brand === null) return;
      let url = API.GET_STORE_BY_CATEGORY(defaultVal) + `?brandId=${brand?.value}`;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        storeList = response.stores.map((item) => {
          return {
            value: item.id,
            label: getdataByKey(item?.stores_locales, "en", "business_name"),
            business_address: item?.business_address
          };
        });
        setStoreList(storeList);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const saveData = async () => {
    let isValid = true;
    if (location?.state?.banner?.id === undefined && base64img === "") {
      isValid = false;
      dispatch(changeSnackbar({
        isOpen: true,
        message: "Please upload banner image",
        state: "error",
      }));
    }
    if (isValid) {
      const data = {
        url_en: url,
        url_ar: urlArebic,
        image: base64img,
        imageAr: base64img2,
        business_id: defaultVal,
        type: platform,
        cuisine_id: cusine ? cusine.value: 0,
        brand_id: cusine ? 0 : brand ? brand.value : 0,
        store_id:   cusine ? 0 :  store ? store.map((item) => item.value).join(",") : 0,
        category_Id: cusine ? 0 :   Categorys.length > 0 ? Categorys[0].id : 0,
        subCategoryId: cusine ? 0 :  selectedSubCategories.length > 0 ? selectedSubCategories[0].id : 0,
        childCategoryId: cusine ? 0 :  selectedSubSubCategories.length > 0 ? selectedSubSubCategories[0].id : 0,
        product_id: cusine ? 0 :  products.length > 0 ? products[0].id : 0
      };
      let response;
      dispatch(changeLoader(true));
      if (location?.state.state === "add") {
        response = await services.systemSetting.ADD_BANNER_NEW(data);
        // response = await GlobalService(API.BANNER.ADD_BANNER_NEW, data, 'post');

      } else if (location?.state.state === "edit") {
        response = await services.systemSetting.UPDATE_BANNER_NEW(
          data,
          location.state.banner.id
        );
      }

      if (response?.data?.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          message: response?.data?.msg,
          state: "success",
        }));
        dispatch(changeLoader(false));
        routerStoreListing();
      } else {
        dispatch(changeLoader(false));

        if (response?.data?.errMsg) {
          let message = "";
          response?.data?.errMsg.forEach((element) => {
            for (const [key, value] of Object.entries(element)) {
              message += value + "\n";
            }
          });
          dispatch(changeSnackbar({
            isOpen: true,
            message: message,
            state: "error",
          }));
        } else {
          if (response !== undefined) {
            dispatch(changeSnackbar({
              isOpen: true,
              message: response?.data?.msg,
              state: "error",
            }));
          } else {
            dispatch(changeSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            }));
          }
        }
      }
    }
  };

  const getStoreCategory = async () => {
    dispatch(changeLoader(true));
    const response = await services.systemSetting.GET_BUSINESSTYPE();
    if (response?.data?.ack == 1) {
      if (response?.data?.businesstypes) {
        setBusinessType(response?.data?.businesstypes);
        if (location?.state.state !== "edit")
          defaultVal = response?.data?.businesstypes[0]?.id;
        setDefaultVal(defaultVal);
      }
    }
    getAllBrands();
    dispatch(changeLoader(false));
  };

  const getSearchLKeywords = () => {
    const bannerData = location.state.banner;
    location.state.banner.header_banner_locales?.forEach((url) => {
      if (url?.locale === "en") {
        setUrl(url?.url);
      }
      if (url.locale === "ar") {
        setUrlArebic(url?.url);
      }
    });
    // getAllStores();
  };

  // const getAllCusines = async () => {
  //   const response = await GlobalService(API.CUISINE.GET_ALL_CUS);
  //   allCusines = [];
  //   response.data.forEach(element => {
  //     const allc = element.cuisines.map((cuisine) => {
  //       return { value: cuisine.id, label: cuisine.cuisines_locales[0].name }
  //     })
  //     allCusines = [...allCusines, ...allc]
  //   });
  //   setAllCusines(allCusines)
  // }
  const getAllCusines = async () => {
    try {
      const response = await GlobalService(API.CUISINE.GET_ALL_CUS);
      console.log('Response:', response);
  
      const allCusines = response.data.flatMap(element => {
        return element.cuisines
          .filter(cuisine => cuisine.businessTypeId == defaultVal)
          .map(cuisine => ({
            value: cuisine.id,
            label: cuisine.cuisines_locales[0].name
          }));
      });
  
      
  
      setAllCusines(allCusines)
      return allCusines;
    } catch (error) {
      console.error('Error fetching cuisines:', error);
      // Handle error as needed
    }
  };

  const getBannerSpecific = async () => {
    const response = await GlobalService(API.GET_BANNER_SPECIFIC(location.state.banner.id));
    setIsEditAble(response.banner.is_editable)
    setUrl(getdataByKey(response.banner.header_banner_locales, "en", "url"));
    setUrlArebic(getdataByKey(response.banner.header_banner_locales, "ar", "url"));
    defaultVal = response.banner.businessId;
    setDefaultVal(defaultVal);
    await getAllBrands();
    const selectedBrand = brandList.find((item) => item.value === response.banner.brandId);
    brand = selectedBrand;
    setBrand(brand);
    await getAllStores();
    console.log("storeList" ,storeList);
    const selectedStore = storeList.find((item) => item.value === parseInt(response.banner.storeId));
    selectedStore ? setStore([selectedStore]) : setStore([]);
    console.log("selectedStore", selectedStore);
    // setStore([selectedStore]);
    setPlatform(response.banner.type);
    products = [];
    if (response.products) {
      products = [{
        id: response.products.id,
        name: getdataByKey(response.products.products_locales, "en", "title"),
        storeId: response.banner.storeId,
      }];
    }
    setProducts(products);
    if (response?.category) {
      Categorys = [{
        ...response?.category,
        label: getdataByKey(response.category.category_locales, "en", "name")
      }];
    }
    setCategorys(Categorys);
    getSubCategory(Categorys[0]);
    if (response?.subCategory) {
      selectedSubCategories = [{
        ...response?.subCategory,
        label: getdataByKey(response.subCategory.category_locales, "en", "name")
      }]
      setSelectedSubCategories(selectedSubCategories);
      console.log(selectedSubCategories);
      getSubSubCategory(selectedSubCategories[0]);
    }
    if (response?.childCategory) {
      selectedSubSubCategories = [{
        ...response?.childCategory,
        label: getdataByKey(response.childCategory.category_locales, "en", "name")
      }]
      setSelectedSubSubCategories(selectedSubSubCategories);
      
    }
    (async () => {
      try {
          setSettingCusine(response.banner.cuisineId !== 0);
          const allCusines = await getAllCusines();
          const matchingCuisines = [];
          allCusines.forEach(c => {
          if (c.value === response.banner.cuisineId) {
            matchingCuisines.push(c);
          }
        });
        
        
        if (matchingCuisines.length > 0) {
          
            setCusine(matchingCuisines[0]);
          
        } else {
       
        
        }
      } catch (error) {
        console.error('Error setting or fetching cuisine:', error);
 
      }
    })();
    
    
    
  
  };

  useEffect(() => {
    if (location?.state?.state === "edit") getSearchLKeywords();
    getStoreCategory();
  }, []);



  useEffect(() => {
    getAllProducts()
  },[Categorys])


  const getAllProducts = async (arg) => {
    try {
      if (Categorys.length === 0) {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: "Please select category first",
            state: "error",
          })
        );
        return;
      }
      let url = API.GET_ALL_PRODUCTS(productSearch);
      url += `&storeId=${store[0]?.value}`;
      url += `&categoryId=${Categorys[0]?.id}`;
      
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        product = response.data.map((item) => {
          return {
            id: item.id,
            name: item?.products_locales[0].title,
            storeId: store?.value,
          };
        });
        setProduct(product);
        // setIsProductDivVisibleAutocomplate(true);
      } else {
        product = [];
        setProduct(product);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  useEffect(() => {
    if (productSearch !== "") {
      const getData = setTimeout(() => {
        getAllProducts("add");
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setProduct([]);
    }
  }, [productSearch]);

  useEffect(() => {
    if (location?.state?.banner?.id) {
      getBannerSpecific();
    }
  }, [location.state.banner]);

  useLayoutEffect(() => {
    getAllCusines()
  }, [])

  useEffect(() => {
    getAllCusines()

    if (settingCusine == false) {
      setCusine(null);
    }
  }, [settingCusine])

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  {location?.state?.state === "edit" ? "Update" : "Add"} Banner
                </Typography>
              </Grid>
              <Grid item xs={2} align="right"></Grid>
              <Grid item xs={10}>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <Typography variant="h6" component="h6" mb={2} fontWeight={500}> Banner Image English</Typography>
                    <div className="upload-img upload-banner-img">
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => { imageBase64(e); }}
                          id="uploadImage"
                        />
                        {isEdit.state == "edit" && !base64img ?
                          <img
                            alt="img" className="upload-img" style={{ height: "190px" }}
                            src={`${constant.IMG_BASE_URL}${isEdit?.banner?.image}`}
                          />: null
                        }
                        {base64img ?
                          <img src={base64img} className="upload-img" style={{ height: "5rem !important" }} alt=""/>:
                          null
                        }
                      </div>
                    </div>


                    <Typography variant="h6" component="h6" mb={2} fontWeight={500}> Banner Image Arabic</Typography>
                    <div className="upload-img upload-banner-img">
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => { imageBase642(e); }}
                          id="uploadImage2"
                        />
                        {isEdit.state == "edit" && !base64img2 ?
                          <img
                            alt="img" className="upload-img" style={{ height: "190px" }}
                            src={`${constant.IMG_BASE_URL}${isEdit?.banner?.image_ar}`}
                          />: null
                        }
                        {base64img2 ?
                          <img src={base64img2} className="upload-img" style={{ height: "5rem !important" }} alt=""/>:
                          null
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-group">
                      <TextField
                        id="outlined-required"
                        label="Please enter search keyword in english"
                        defaultValue=""
                        value={url}
                        onChange={(e) => {
                          setUrl(e.target.value);
                          setSearchEngErr(false);
                        }}
                      />
                      {searchEngErr && (
                        <span style={{ color: "red" }}>
                          Please fill search keywords in english
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <TextField
                        id="outlined-required"
                        label="Please enter search keyword in arabic"
                        defaultValue=""
                        value={urlArebic}
                        onChange={(e) => {
                          setUrlArebic(e.target.value);
                          setSearchArbErr(false);
                        }}
                      />
                      {searchArbErr && (
                        <span style={{ color: "red" }}>
                          Please fill search keywords in ababic
                        </span>
                      )}
                    </div>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">
                        Select Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-select-small"
                        value={defaultVal}
                        label="Select Business Type"
                        size="small"
                        disabled={!isEditAble} // Example: Disable if not editable
                        className="w-full-height-56"
                      >
                        {businesstype?.map((val, index) => {
                          return (
                            <MenuItem
                              value={val?.id}
                              key={index}
                              onClick={() => {
                                setStore_id(val?.id);
                                defaultVal = val?.id;
                                setDefaultVal(defaultVal);
                                setStore(null);
                                setCusine(null)
                                setSettingCusine(false)
                                setStoreList([]);
                                setCategory([]);
                                setSubCategoryList([])
                                setSubSubCategoryList([])
                                setBrand(null);
                                getAllBrands();
                                setStore([]);
                                setCategory([]);
                                setCategorys([]);
                                setProducts([]);
  
                              }}
                            >
                              {val?.business_type_locales[0].name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <Divider className="my-2" />
                    {defaultVal != 2 ? <>
                      <FormControl fullWidth >
                        <FormControlLabel
                          control={<Checkbox
                            checked={settingCusine}
                            onChange={(e) => setSettingCusine(e.target.checked) }
                          />}
                          label={`Select Cousines`}
                      />
                      </FormControl>
                      <Divider className="my-2" />
                      {settingCusine?<>
                        <Grid item xs={14}>
                          <FormControl fullWidth >
                            <TextField
                              label="Select cusine"
                              value={cusineSearch}
                              id="cusineSearch"
                              placeholder="Select cusine"
                              autoComplete="off"
                              onClick={() => setIsCusineDivVisibleAutocomplate(!isCusineDivVisibleAutocomplate) }
                              onChange={(e) => setCusineSearch(e.target.value.trimStart()) }
                            />
                            {isCusineDivVisibleAutocomplate && (
                            <ul className="show-hide-div">
                              {allCusines && allCusines.length > 0?
                              allCusines.filter((item) => item?.label?.toLowerCase().indexOf(cusineSearch.toLowerCase()) > -1)
                              .map((item, index) => (
                                <li
                                  key={`allCusines-${index}`}
                                  onClick={() => {
                                    cusine = item
                                    setCusine(cusine)
                                    setIsCusineDivVisibleAutocomplate(!isCusineDivVisibleAutocomplate);
                                  }}
                                >{item?.label}</li>
                              )): null}
                            </ul>)}
                          </FormControl>
                          {cusine && (
                            <Chip key={`cusine-chip-0`} label={cusine?.label} sx={{ mr: 1, mt: 2 }} onDelete={() => setCusine(null)} />
                          )}
                        </Grid>
                        <Divider className="my-2" />
                      </>: null}
                    </>: null}
                    {!settingCusine?
                      <>
                      <Grid item xs={14}>
                        <FormControl fullWidth >
                          <TextField
                            label="Select Brand"
                            value={brandSearch}
                            id="brandSearch"
                            placeholder="Select Brand"
                            autoComplete="off"
                            onClick={() =>
                              setIsBrandDivVisibleAutocomplate(!isBrandDivVisibleAutocomplate)
                            }
                            onChange={(e) => setBrandSearch(e.target.value.trimStart()) }
                          />
                          {isBrandDivVisibleAutocomplate && (
                          <ul className="show-hide-div">
                            {brandList && brandList.length > 0?
                            brandList.filter((item) => item?.label?.toLowerCase().indexOf(brandSearch.toLowerCase()) > -1)
                            .map((item, index) => (
                              <li
                                key={`brandList-${index}`}
                                onClick={() => {
                                  brand = item;
                                  setBrand(brand);
                                  setIsBrandDivVisibleAutocomplate(!isBrandDivVisibleAutocomplate);
                                  setCategory([]);
                                  setSubCategoryList([])
                                  setSubSubCategoryList([])
                                  getAllStores();
                                }}
                              >{item?.label}</li>
                            )): null}
                          </ul>)}
                        </FormControl>
                        {brand && (
                          <Chip
                            key={`brand-chip-0`}
                            label={brand?.label}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => {
                              setBrand(null);
                              setStore([]);
                              setCategory([]);
                              setCategorys([]);
                              setProducts([]);

                            }}
                          />
                        )}
                      </Grid>
                      <Divider className="my-2" />
                      <Grid item xs={14}>
                        <FormControl fullWidth >
                          <TextField
                            label="Select Store"
                            value={storeSearch}
                            id="storeSearch"
                            placeholder="Select Store"
                            autoComplete="off"
                            onClick={() =>
                              setIsStoreDivVisibleAutocomplate(!isStoreDivVisibleAutocomplate)
                            }
                            onChange={(e) => setStoreSearch(e.target.value.trimStart()) }
                          />
                          {isStoreDivVisibleAutocomplate && (
                          <ul className="show-hide-div">
                            {storeList && storeList.length > 0?
                            storeList.filter((item) => item?.label?.toLowerCase().indexOf(storeSearch.toLowerCase()) > -1)
                            .map((item, index) => (
                              <li
                                key={`storeList-${index}`}
                                onClick={() => {
                                  // let stores = store || [];
                                  let stores = [];

                                  location?.state.state == 'add' ?  stores = store || [] :  stores = [];
                                  const isExist = stores.find((sto) => sto?.value === item?.value);
                                  if (!isExist) {
                                    stores.push(item);
                                    setStore(stores);
                                  } else {
                                    setStore(stores.filter((sto) => sto?.value !== item?.value));
                                  }
                                  setIsStoreDivVisibleAutocomplate(!isStoreDivVisibleAutocomplate);
                                  setCategory([]);
                                  setCategorys([]);
                                  setProducts([]);
                                  getAllCategories();
                                }}
                              >
                              {item?.label} <br />
                              {item?.business_address}
                              </li>
                            )): null}
                          </ul>)}
                        </FormControl>
                        {/* {store && store.map((sto, index) => (
                          <Chip
                            key={`store-chip-0`}
                            label={`${sto?.label} - ${sto?.business_address}`}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => {
                              let stores = store;
                              stores = store.filter((sto) => sto?.value !== sto?.value);
                              setStore(stores);
                              setCategory([]);
                               setCategorys([]);
                               setProducts([]);
                               getAllCategories();
                            }}
                          />
                        ))} */}
                          {store && store.map((sto, index) => (
                                <Chip
                                  key={`store-chip-${index}`}
                                  label={`${sto?.label} - ${sto?.business_address}`}
                                  sx={{ mr: 1, mt: 2 }}
                                  onDelete={() => {
                                    // Filter out the specific store item being deleted
                                    const updatedStores = store.filter((item, idx) => idx !== index);
                                    
                                    // Update state with the filtered array
                                    setStore(updatedStores);
                                    
                                    // Additional state updates as needed
                                    setCategory([]);
                                    setCategorys([]);
                                    setProducts([]);
                                    
                                    // Assuming getAllCategories() is a function to fetch categories
                                    getAllCategories();
                                  }}
                                />
                              ))}

                      </Grid>
                      <Divider className="my-2" />
                      <Grid item xs={14}>
                        <FormControl fullWidth>
                          <TextField
                            label="Select Category"
                            value={categorySearch}
                            id="categorySearch"
                            placeholder="Select Category"
                            onClick={() =>
                              setIsCategoryDivVisibleAutocomplate(!isCategoryDivVisibleAutocomplate)
                            }
                            onChange={(e) => setCategorySearch(e.target.value.trimStart()) }
                          />
                          {isCategoryDivVisibleAutocomplate && (
                            <ul className="show-hide-div">
                            {Category && Category.length > 0 ? 
                              Category.map((item, index) => (
                              <li
                                key={`Category-${index}`}
                                onClick={() => {
                                  setCategorys([item]);
                                  setIsCategoryDivVisibleAutocomplate(!isCategoryDivVisibleAutocomplate);
                                  setSelectedSubCategories([]);
                                  setSubSubCategoryList([]);
                                  setSelectedSubSubCategories([]);
                                  getSubCategory(item);
                                  setProducts([]);
                                  
                                }}
                              > {item?.label}</li>)): null}
                            </ul>
                          )}
                        </FormControl>
                        {Categorys.length > 0 && (
                          <Chip
                            key={`Category-chip-0`}
                            label={store ? Categorys[0]?.label : setCategorys([])}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => { setCategorys([]); setProducts([]) }}
                          />
                        )}
                      </Grid>

                      {subCategoryList.length > 0 &&
                        <>
                        <Divider className="my-2" />
                      <Grid item xs={14}>
                        <FormControl fullWidth>
                          <TextField
                            label="Select Sub-Category"
                            value={subCategorySearch}
                            id="subCategorySearch"
                            placeholder="Select Sub-Category"
                            onClick={() =>
                              setIsSubCategoryDivVisibleAutocomplate(!isSubCategoryDivVisibleAutocomplate)
                            }
                            onChange={(e) => setSubCategorySearch(e.target.value.trimStart()) }
                          />
                          {isSubCategoryDivVisibleAutocomplate && (
                            <ul className="show-hide-div">
                            {subCategoryList && subCategoryList.length > 0 ? 
                              subCategoryList.map((item, index) => (
                              <li
                                key={`Category-${index}`}
                                onClick={() => {
                                  setSelectedSubCategories([item]);
                                  setIsSubCategoryDivVisibleAutocomplate(!isSubCategoryDivVisibleAutocomplate);
                                  setSelectedSubSubCategories([]);
                                  getSubSubCategory(item);
                                }}
                              > {item?.label}</li>)): null}
                            </ul>
                          )}
                        </FormControl>
                        {selectedSubCategories.length > 0 && (
                          <Chip
                            key={`Category-chip-0`}
                            label={selectedSubCategories ? selectedSubCategories[0]?.label : setSelectedSubCategories([])}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => { setSelectedSubCategories([]); }}
                          />
                        )}
                      </Grid>
                      </>}

                      {subSubCategoryList.length > 0 &&
                      <>
                      <Divider className="my-2" />
                      <Grid item xs={14}>
                        <FormControl fullWidth>
                          <TextField
                            label="Select Sub Sub-Category"
                            value={subSubCategorySearch}
                            id="subCategorySearch"
                            placeholder="Select Sub-Category"
                            onClick={() =>
                              setIsSubSubCategoryDivVisibleAutocomplate(!isSubSubCategoryDivVisibleAutocomplate)
                            }
                            onChange={(e) => setSubSubCategorySearch(e.target.value.trimStart()) }
                          />
                          {isSubSubCategoryDivVisibleAutocomplate && (
                            <ul className="show-hide-div">
                            {subSubCategoryList && subSubCategoryList.length > 0 ? 
                              subSubCategoryList.map((item, index) => (
                              <li
                                key={`Category-${index}`}
                                onClick={() => {
                                  setSelectedSubSubCategories([item]);
                                  setIsSubSubCategoryDivVisibleAutocomplate(!isSubSubCategoryDivVisibleAutocomplate);
                                }}
                              > {item?.label}</li>)): null}
                            </ul>
                          )}
                        </FormControl>
                        {selectedSubSubCategories.length > 0 && (
                          <Chip
                            key={`Category-chip-0`}
                            label={store ? selectedSubSubCategories[0]?.label : setSelectedSubSubCategories([])}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => { setSelectedSubSubCategories([]); }}
                          />
                        )}
                      </Grid>
                      </>}

                      <Divider className="my-2" />
                      <Grid item xs={14}>
                        <FormControl fullWidth>
                          <TextField
                            label="Select Product"
                            value={productSearch}
                            id="productSearch"
                            placeholder="Enter your product name which you want to search"
                            onClick={() =>
                              setIsProductDivVisibleAutocomplate(!isProductDivVisibleAutocomplate)
                            }
                            onChange={(e) => setProductSearch(e.target.value.trimStart()) }
                          />
                          {isProductDivVisibleAutocomplate && (
                            <ul className="show-hide-div">
                              {product && product.length > 0 ?
                              product.map((item, index) => (
                              <li
                                key={`product-${index}`}
                                onClick={() => {
                                  setProducts([item]);
                                  setIsProductDivVisibleAutocomplate(!isProductDivVisibleAutocomplate);
                                }}
                              >{item?.name}</li>)): null}
                            </ul>
                          )}
                        </FormControl>

                        {products.length > 0 && (
                          <Chip
                            key={`product-chip-0`}
                            label={products[0]?.name}
                            sx={{ mr: 1, mt: 2 }}
                            onDelete={() => {
                              setProducts([]);
                            }}
                          />
                        )}
                      </Grid>
                    </>: null}
                    <FormControl
                      fullWidth
                      required
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel id="demo-simple-select-label">Select Platform</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Platform"
                        size="small"
                        className="w-full-height-56"
                        value={platform}
                        onChange={(e) => { setPlatform(e.target.value); }}
                      >
                        <MenuItem value="app">App</MenuItem>
                        <MenuItem value="web">Web</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={routerStoreListing}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={saveData}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openAlertDelete}
        onClose={handleClose2}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose2}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={openRejectReason} onClose={handleClose}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField required label="Reason" multiline rows={2} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => {
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={(cropped) => {
            setBase64img(cropped.croppedImage);
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{
            aspectRatio: 4 / 2.1,
          }}
        />
      ) : null}


      {cropperData2.isOpen ? (
        <ImageCropper
          cropperImage={cropperData2.croppedImage}
          closepopup={() => {
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={(cropped) => {
            setBase64img2(cropped.croppedImage);
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{
            aspectRatio: 4 / 2.1,
          }}
        />
      ) : null}

    </>
  );
}
