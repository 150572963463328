import { Button, Typography, Grid, Box } from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { decode } from "base-64";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import arrowLeftBlackImg from "../../../assets/images/structure/arrow-left-black.svg";
import EditIcon from "@mui/icons-material/Edit";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import constant from "../../../constant";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import {getdataByKey} from "../../helper/custom"
import { blobToBase64, base64toFile } from "../../helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function AddCuisine() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");

  const [cuisineCategoryId, setCuisineCategoryId] = useState(0);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState('');
  let [cuisineImage, setCuisineImage] = useState({ file: null, imagePreviewUrl: '' });
  let [businessList, setBusinessList] = useState([]);
  const params = useParams();
  let data = params.data;
  const [existData, setExistData] = useState(null);

  const editImageChangeHandler = async (e) => {
		if (e.target.files[0]) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
			cuisineImage = {
				file: base64toFile(imageUrl),
				imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
			};
      setCuisineImage(cuisineImage);
		} else {
			cuisineImage = { file: '', imagePreviewUrl: '' };
			setCuisineImage(cuisineImage);
		}
  };

  const handleChange = (event, newValue) => {
    setCuisineCategoryId(newValue?.id);
    setValue(newValue);
  };

  useEffect(() => {
    if (data !== undefined) {
      let d = decodeURIComponent(decode(data));
      try {
        let json = JSON.parse(sessionStorage.getItem("cuisineData"));
        setExistData(json);
      } catch (e) {
        navigate(`/cuisine-category-listing`);
      }
    }
  }, [data]);

  useEffect(() => {
    if (existData !== null) {
      setNameAr(existData?.name_ar);
      setNameEn(existData?.name_en);
      setDescriptionAr(existData?.description_ar);
      setDescriptionEn(existData?.description_en);
      setCuisineCategoryId(existData?.cuisineCategoryId);
      cuisineImage = { file: '', imagePreviewUrl: constant.IMG_BASE_URL + existData?.image };
      setCuisineImage(cuisineImage);
      setSelectedBusinessId(existData?.businessTypeId)
    }
  }, [existData]);

  const getCuisineCategory = () => {
    dispatch(changeLoader(true));
    GlobalService(API.CUISINE.GET_ALL_CATEGORY, {}, "get")
      .then((result) => {
        if (existData !== null) {
          let find = result?.data.find((a) => a.id == existData?.cuisineCategoryId);
          setValue(find);
        }
        if (result.ack == 1) {
          setOptions(result?.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    getCuisineCategory();
  }, [existData]);

  const addCuisine = () => {
    if (nameEn && nameAr && cuisineCategoryId && cuisineImage.imagePreviewUrl !="" ) {
      dispatch(changeLoader(true));
      let url = API.CUISINE.ADD;
      let bodyFormData = new FormData();
      bodyFormData.append("name_en", nameEn);
      bodyFormData.append("name_ar", nameAr);
      bodyFormData.append("description_en", descriptionEn);
      bodyFormData.append("description_ar", descriptionAr);
      bodyFormData.append("cuisineCategoryId", cuisineCategoryId);
      bodyFormData.append("businessTypeId", selectedBusinessId);
      bodyFormData.append("image", cuisineImage.file);
      let method = "post";
      if (existData !== null) {
        url = API.CUISINE.EDIT;
        bodyFormData.append("id", existData?.id);
        method = "put";
      }

      GlobalService(url, bodyFormData, method)
        .then((data) => {
          if (data?.ack == 1) {
            navigate(`/cuisine-category-listing`);
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data?.msg,
              state: data?.ack == 1 ? "success" : "error",
            })
          );
        })
        .catch((err) => {
          let msg = err.response.body.msg;
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: msg,
              state: "error",
            })
          );
        })
        .finally(() => {
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "All field must be filled ",
          state: "error",
        })
      );
    }
  };

  const getAllBusiness = async () => {
    const response = await GlobalService(API.CUISINE.GET_ALL_BUSINESS_TYPE);
    businessList = response.businesstype;
    setBusinessList(businessList)
  }

  useEffect(() => {
    getAllBusiness();
  }, [])

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30 min-h-89vh">
            <Grid container spacing={3} xs={12} md={6}>
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2} width={'100%'}>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <img
                      src={arrowLeftBlackImg} alt="back-icon" style={{ cursor: 'pointer' }}
                      onClick={() => navigate(-1)}
                    />
                    <Typography variant="h6" component="h6" style={{ marginLeft: "12px" }} fontWeight={500}>
                    {existData == null ? "Add" : "Edit"} Cuisine
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box><p>Upload Image for Cuisine.</p></Box>
                <Box>
                  <div className='upload-img' style={{
                    margin: '0',
                  }}>
                    {cuisineImage.imagePreviewUrl === ''?<div className="before-upload">
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                      />
                    </div>: <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          cuisineImage = { file: '', imagePreviewUrl: '' };
                          setCuisineImage(cuisineImage);
                        }}
                      />
                      <img src={cuisineImage.imagePreviewUrl} alt=".." className="upload-icon"/>
                      <input
                        type="file" accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)} />
                    </div>}
                  </div>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
              <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                      Business Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBusinessId}
                        label="Business Type"
                        onChange={(e)=> setSelectedBusinessId(e.target.value)}
                      >
                        <MenuItem value={0}>Select Business Type</MenuItem>
                        {businessList?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                             {getdataByKey(item.business_type_locales, 'en', 'name')} 
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={value}
                  onChange={handleChange}
                  options={options}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Choose Category" />}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-required"
                  value={nameEn}
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => {
                    let value = e.target.value;
                    setNameEn(value);
                  }}
                  label="Cuisine English Name *"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    let value = e.target.value;
                    setNameAr(value);
                  }}
                  inputProps={{ maxLength: 80 }}
                  value={nameAr}
                  id="outlined-required"
                  label="Cuisine Arabic Name *"
                />
              </Grid>


              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setDescriptionEn(e.target.value);
                  }}
                  multiline={true}
                  rows={4}
                  inputProps={{ maxLength: 160 }}
                  value={descriptionEn}
                  id="outlined-required"
                  label="Cuisine English Description *"
                />
                <span style={{ float: 'right' }}>
                  {descriptionEn?.length}/160
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => {
                    setDescriptionAr(e.target.value);
                  }}
                  multiline={true}
                  rows={4}
                  inputProps={{ maxLength: 160 }}
                  value={descriptionAr}
                  id="outlined-required"
                  label="Cuisine Arabic Description *"
                />
                <span style={{ float: 'right' }}>
                  {descriptionAr?.length}/160
                </span>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={addCuisine} variant="contained" size="large" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
