import { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, Divider, Box } from "@mui/material";
import { API } from "../../ENUM";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import GlobalService from "../../../services/GlobalService";
import { currentBaladiInfo } from "../../../redux/reducers/baladiInfo";
import { getdataByKey } from "../../helper/custom";
import BannerComponent from "./WidgetComponent/BannerComponent";
import BrandComponent from "./WidgetComponent/BrandComponent";
import CategoryComponent from "./WidgetComponent/CategoryComponent";
import ProductComponent from "./WidgetComponent/ProductComponent";
import { changeSnackbar } from "../../../redux/reducers/snackbar";

// import landingScreen1 from "../../../assets/images/product/landing-screen1.png";
// import landingScreen2 from "../../../assets/images/product/landing-screen2.png";
// import landingScreen3 from "../../../assets/images/product/landing-screen3.png";
// import landingScreen4 from "../../../assets/images/product/landing-screen4.png";
// import landingScreen5 from "../../../assets/images/product/landing-screen5.png";
// import landingScreen6 from "../../../assets/images/product/landing-screen6.png";
// import landingScreen7 from "../../../assets/images/product/landing-screen7.png";
// import landingScreen8 from "../../../assets/images/product/landing-screen8.png";
// import landingScreen9 from "../../../assets/images/product/landing-screen9.png";
// import landingScreen10 from "../../../assets/images/product/landing-screen10.png";
// import landingScreen11 from "../../../assets/images/product/landing-screen11.png";

export default function BaladiLanding() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const baladiData = useSelector(currentBaladiInfo);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [widgets, setWidgets] = useState([]);
  let [products, setProducts] = useState([]);
  const [businessTypeList, setBusinessTypeList] = useState([
    {
      brandID: 3126,
      type: "baladi",
      name: "Baladi Express",
      BALADI_STORE_Id: 1,
    },
    {
      brandID: 4824,
      type: "anaqah",
      name: "Anaqah Market Place",
      BALADI_STORE_Id: 4825,
    },
  ]);
  const [selectedType, setSelectedType] = useState(businessTypeList[0]);

  const getBusinessTypes = async () => {
    const response = await GlobalService(API.GET_ALL_BUSINESS);
    setBusinessTypes(response.businesstype.rows);
  };

  // const getAllWidget = async () => {
  //   const response = await GlobalService(API.WIDGET.GET_ALL_WIDGETS(type));
  //   setWidgets(response?.data);
  // };

  const resetState = () => {
    setWidgets([]);
    setProducts([]);
  };

  const getAllWidget = async () => {
    let response;
    if (type == 'home' || type == 'web_home') {
        response = await GlobalService(API.WIDGET.GET_ALL_WIDGETS(type));
    } else if(type == 'baladi') {
        response = await GlobalService(
        API.WIDGET.GET_ALL_WIDGETS(selectedType.type)
      );
    }
     setWidgets(response?.data);
  };

  // const getBaladiProducts = async () => {
  //   if (!baladiData.BALADI_BRAND_Id) return;
  //   const response = await GlobalService(
  //     API.WIDGET.GET_ALL_PRODUCTS(baladiData.BALADI_BRAND_Id)
  //   );
  //   products = response.data.map((product) => {
  //     return {
  //       id: product.id,
  //       name: product.products_locales[0].title,
  //     };
  //   });
  //   setProducts(products);
  // };

  const getBaladiProducts = async () => {
    if (!selectedType) return;
    const response = await GlobalService(
      API.WIDGET.GET_ALL_PRODUCTS(selectedType.brandID)
    );

    const products = response.data.map((product) => ({
      id: product.id,
      name: product.products_locales[0].title,
    }));
    setProducts(products);

    console.log("Products after mapping:", products);
  };

  const handleChangeWidgetStatus = async (changeData) => {
    const response = await GlobalService(
      API.WIDGET.UPDATE_WIDGET_STATUS(changeData.id),
      {
        status: changeData.status,
      },
      "PUT"
    );
    if (response.ack === 1) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "success",
          message: "Widget status updated successfully.",
        })
      );
      const updatedWidgets = widgets.map((widget) => {
        if (widget.id === changeData.id) {
          widget.status = changeData.status;
        }
        return widget;
      });
      setWidgets(updatedWidgets);
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Something went wrong! Please try again.",
        })
      );
    }
  };

  // useEffect(() => {
  //   if (type === "baladi") {
  //     getBaladiProducts();
  //   }
  //   getAllWidget();
  // }, [type]);

  useLayoutEffect(() => {
    setSelectedType(businessTypeList[0]);
    getBusinessTypes();
   
  }, []);

  // useEffect(() => {
  //   if (type === "baladi") {
  //     getBaladiProducts();
  //   }
  // }, [baladiData]);

  useEffect(() => {
    if(type === "baladi") {
      if (selectedType) {
        resetState();
        getBaladiProducts();
        getAllWidget();
        console.log("Products after type switch:", products);
        console.log("Selected Type:", selectedType);
      }
    } else {
      getAllWidget();
    }
  }, [selectedType , type]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  {type === "baladi" ? "Baladi Landing" : "Home"} Page
                </Typography> */}
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                 
                { type === 'baladi' ? `${selectedType?.name} Landing Page` : 'Home Page' }

                </Typography>

                <Divider />
              </Grid>
              {
                type === 'baladi' && <>
                <Grid item xs={12}>
                <Box
                  style={{
                    padding: "10px",
                    minWidth: "200px",
                    marginBottom: "20px",
                  }}
                >
                  <select
                    value={selectedType?.brandID}
                    onChange={(e) => {
                      const selected = businessTypeList.find(
                        (item) => item.brandID === Number(e.target.value)
                      );
                      setSelectedType(selected);
                    }}
                    style={{
                      width: "100%",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {businessTypeList.map((business) => (
                      <option key={business.brandID} value={business.brandID}>
                        {business.name}
                      </option>
                    ))}
                  </select>
                </Box>
              </Grid></>
                  }
              

              {widgets.map((widget, index) => {
                if (widget.widget_style === "banner") {
                  return (
                    <span key={`bannerComponent-${index}`}>
                      <BannerComponent
                        widgetIndex={index + 1}
                        businessTypes={businessTypes}
                        widget={widget}
                        getWidgetData={getAllWidget}
                        type={type}
                        showAllBanner={
                          type === "home" || type === "web_home" ? true : false
                        }
                        // showBusinessDropdown={type === 'baladi' ? false : true}
                        // showBusinessDropdown={false}
                        // showBusinessDropdown={selectedType.type !== "baladi"}
                        showBusinessDropdown={type === 'baladi' ? false : true}
                        changeWidgetStatus={handleChangeWidgetStatus}
                      />
                      <Grid item xs={12}>
                        <Divider className="mt-4" />
                      </Grid>
                    </span>
                  );
                } else if (widget.widget_style === "brand") {
                  return (
                    <span key={`BrandComponent-${index}`}>
                      <BrandComponent
                        widgetIndex={index + 1}
                        businessTypes={businessTypes}
                        widget={widget}
                        getWidgetData={getAllWidget}
                        type={type}
                        showBusinessDropdown={type === 'baladi' ? false : true}
                        changeWidgetStatus={handleChangeWidgetStatus}
                      />
                      <Grid item xs={12}>
                        <Divider className="mt-4" />
                      </Grid>
                    </span>
                  );
                } else if (widget.widget_style === "product") {
                  console.log("Store ID:", selectedType?.BALADI_STORE_Id);
                  return (
                    <span key={`ProductComponent-${index}`}>
                      <ProductComponent
                        widgetIndex={index + 1}
                        widget={widget}
                        getWidgetData={getAllWidget}
                        type={type}
                        products={products}
                        changeWidgetStatus={handleChangeWidgetStatus}
                        storeID={selectedType.BALADI_STORE_Id}
                        storeSlug={selectedType.name}
                      />
                      <Grid item xs={12}>
                        <Divider className="mt-4" />
                      </Grid>
                    </span>
                  );
                } else if (widget.widget_style === "category") {
                  return (
                    <span key={`CategoryComponent-${index}`}>
                      <CategoryComponent
                        widgetIndex={index + 1}
                        businessTypes={businessTypes}
                        widget={widget}
                        getWidgetData={getAllWidget}
                        type={type}
                        showBusinessDropdown={
                          type === "baladi" ||
                          (type === "home" && index + 1 === 2)
                            ? false
                            : true
                        }
                        changeWidgetStatus={handleChangeWidgetStatus}
                      />
                      <Grid item xs={12}>
                        <Divider className="mt-4" />
                      </Grid>
                    </span>
                  );
                }
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
