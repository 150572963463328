import React, { useState, useEffect } from "react";
import {
  Typography, Box, Pagination, TextField, Tooltip, Collapse, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { API } from "../../../ENUM";
import SearchIcon from "@mui/icons-material/Search";
import _debounce from "lodash/debounce";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import PickerOrderDetail from "../PickerOrderDetail";
import GlobalService from "../../../../services/GlobalService";
import moment from "moment";

const OtherPickerPerformance = () => {
  
  const limit = 10;
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [sort_by, setSort_by] = useState("id");
  const [order_by, setOrder_by] = useState("desc");
  const [openSubtableIndex, setOpenSubtableIndex] = useState(null);
  let [valueDatepickerEnd, setValueDatepickerEnd] = useState(null);
  let [valueDatepickerStart, setValueDatepickerStart] = useState(null);
  let [page, setPage] = useState(1);
  
  let [count, setCount] = useState();

  const getUsers = async () => {
    const query = new URLSearchParams({
      page, limit, sort_by, order_by, search,
      start_date: valueDatepickerStart
        ? moment(valueDatepickerStart?.$d).format("YYYY-MM-DD+00:00:00")
        : "",
      end_date: valueDatepickerStart
        ? moment(valueDatepickerEnd?.$d).format("YYYY-MM-DD+23:59:59")
        : "",
    });
    try {
      let url = API.PICKER_TRACKING.GET_PICKERS(query);
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        count = Math.ceil(response.count / limit);
        setCount(count);
        setData(response?.picker);
        createData();
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const clearAllInputs = () => {
    setValueDatepickerEnd(null);
    setValueDatepickerStart(null);
    setSearch("");
  };

  const createData = () => {
    let array =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          name: `${item?.first_name} ${item?.last_name}`,
          OrderID: item?.id,
          Priority: item?.total_orders,
          OrderDateTime: item?.total_qty,
        };
      });

    return array;
  };

  function Row(props) {
    const { row } = props;
    const isOpen = openSubtableIndex === row.OrderID;
    const toggleSubtable = (id) => {
      if (id === openSubtableIndex) {
        setOpenSubtableIndex(null);
      } else {
        setOpenSubtableIndex(id);
      }
    };
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            <div className="table-img-text">
              <img
                src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                alt=".."
                className="round36"
              />
              <Typography variant="body2" component="div">
                {row?.name}
              </Typography>
            </div>
          </TableCell>
          <TableCell>{row?.OrderID}</TableCell>
          <TableCell>{row.Priority}</TableCell>
          <TableCell>{row.OrderDateTime ? row.OrderDateTime : 0}</TableCell>
          <TableCell>
            <Tooltip title="Order Details" placement="top" followCursor>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => toggleSubtable(row.OrderID)}
              >
                {isOpen ? (
                  <img src={minusIcon} alt="plus-icon" />
                ) : (
                  <img src={plusIcon} alt="plus-icon" />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="p-0 px-0" colSpan={7}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <PickerOrderDetail ID={row.OrderID} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getUsers();
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getUsers();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, order_by, valueDatepickerStart, valueDatepickerEnd]);

  return (
    <>
      <div className="top-heading-search" style={{ marginTop: "30px" }}>
        <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
          All Picker Tracking
        </Typography>
        <div
          className="search-box"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: "auto",
            width: "560px",
            gap: 10,
          }}
        >
          <div className="search-box mr-20" style={{ width: "190px" }}>
            <TextField
              id="combo-box-demo"
              size="small"
              placeholder="Search Picker"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: "8px" }} />,
              }}
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="width160"
              value={valueDatepickerStart}
              onChange={(newValue) => {
                valueDatepickerStart = newValue;
                setValueDatepickerStart(valueDatepickerStart);
              }}
              label="Start Date"
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="start date" />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="width160"
              value={valueDatepickerEnd}
              onChange={(newValue) => {
                valueDatepickerEnd = newValue;
                setValueDatepickerEnd(valueDatepickerEnd);
              }}
              label="End Date"
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="End date" />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>

          <span className="clear-all" onClick={() => clearAllInputs()}>
            {" "}
            Clear{" "}
          </span>
        </div>
      </div>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Picker Name</TableCell>
              <TableCell>Picker ID</TableCell>
              <TableCell>Total picked order</TableCell>
              <TableCell>Total Items of Picker Order</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {createData() &&
              createData().map((row) => <Row key={row.name} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 1 ? (
        <Pagination
          count={count}
          page={page}
          onChange={(e, value) => handlePagination(e, value)}
          color="primary"
        />
      ) : null}
    </>
  );
};
export default OtherPickerPerformance;
