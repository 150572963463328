import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography, TextField, Grid, Select, MenuItem,
  FormControl, InputLabel, Button, FormControlLabel, Switch, FormGroup
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {getdataByKey} from "../../../helper/custom";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../../../../redux/reducers/loader";
import uploadImg from "../../../../assets/images/structure/upload-img.png";
import EditIcon from "@mui/icons-material/Edit";
import constant from "../../../../constant";
import { ImageCropper, imagesToBase64 } from "../../../../dialog/Cropper";
import { blobToBase64 } from "../../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function BrandComponent({
  widgetIndex, businessTypes, widget, getWidgetData, showBusinessDropdown = false, type, changeWidgetStatus
}) {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dispatch = useDispatch();
  const loader = useSelector(currentLoader);
  let [brands, setBrands] = useState([]);
  let [unavailableBusinessTypes, setUnavailableBusinessTypes] = useState([])
  let [widgetTypeId, setWidgetTypeId] = useState(0);
  let [cropperData, setCropperData] = useState({ croppedImage: "", isOpen: false });
	const [searchAvailableBrands, setSearchAvailableBrands] = useState("");
  const [searchSelectedBrands, setSearchSelectedBrands] = useState("");
  
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetSubtitle, setWidgetSubtitle] = useState("");
  const [widgetTitleArabic, setWidgetTitleArabic] = useState("");
  const [widgetSubtitleArabic, setWidgetSubtitleArabic] = useState("");
  const [widgetIcon, setWidgetIcon] = useState("");
  const [widgetTypeIdError, setWidgetTypeIdError] = useState('');
  const [widgetTitleError, setWidgetTitleError] = useState("");
  const [widgetSubtitleError, setWidgetSubtitleError] = useState("");
  const [widgetTitleArabicError, setWidgetTitleArabicError] = useState("");
  const [widgetSubtitleArabicError, setWidgetSubtitleArabicError] = useState("");
  const [widgetBanner, setWidgetBanner] = useState("");
  const [titleGifEn, setTitleGifEn] = useState("");
  const [titleGifAr, setTitleGifAr] = useState("");
  
  let [newSelectedBrands, setNewSelectedBrands] = useState([]);
  let [removeSelectedBrands, setRemoveSelectedBrands] = useState([]);
  let [entities, setEntities] = useState([]);
  let [removedEntities, setRemovedEntities] = useState([]);

  const handleIconUpload = async (cropped) => {
    setCropperData({ croppedImage: "", isOpen: false, });
    const response = await GlobalService(API.WIDGET.UPLOAD_ICON, { image: cropped.croppedImage }, 'post');
    if (response?.ack === 1) {
      setWidgetIcon(response?.imageName);
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: 'Something went wrong, please try again later'
      }))
    }
  }

	const editImageChangeHandler = async (e) => {
    const imageType = e.target.files[0].type;
    if (
      imageType === "image/png" ||
      imageType === "image/jpeg" ||
      imageType === "image/jpg"
    ) {
      const file = e.target.files[0];
      const compressedFile = await imageCompression(file, options);
      const imageUrl = await blobToBase64(compressedFile);
      cropperData = {
        croppedImage: imageUrl,
        isOpen: true,
      };
      setCropperData(cropperData);
      document.getElementById("upload-image" + widget?.id).value = "";
    }
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...entities];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setEntities(copyListItems);
  };


  const getBrands = async () => {
    dispatch(changeLoader(true));
    if (!widgetTypeId) {
      dispatch(changeLoader(false));
      return;
    };
    const response = await GlobalService(API.WIDGET.GET_ALL_BRANDS(widgetTypeId));
    brands = response?.data?.map((brand) => {
      return { id: brand.id, name: brand.stores_locales[0].business_name };
    });
    setBrands(brands);
    dispatch(changeLoader(false));
  };

  const selectUnselectBrand = (brandId) => {
    const totalEntitues = entities?.length + newSelectedBrands?.length;
    if (newSelectedBrands?.includes(brandId)) {
      setNewSelectedBrands(newSelectedBrands.filter((id) => id !== brandId));
    } else {
      if (totalEntitues >= 10){
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "You can select maximum 10 brands"
        }))
      } else {
        setNewSelectedBrands([...newSelectedBrands, brandId]);
      }
    }
  }

  const selectUnselectRemoveBrand = (brandId) => {
    if (removeSelectedBrands?.includes(brandId)) {
      setRemoveSelectedBrands(removeSelectedBrands.filter((id) => id !== brandId));
    } else {
      setRemoveSelectedBrands([...removeSelectedBrands, brandId]);
    }
  }

  const handleForwardClick = () => {
    if (newSelectedBrands.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select at least one brand"
      }))
    } else {
      setEntities([...entities, ...newSelectedBrands]);
      setNewSelectedBrands([]);
    }
  };

  const handleBackwardClick = () => {
    if (removeSelectedBrands.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select at least one brand"
      }))
    } else {
      setRemovedEntities([...removedEntities, ...removeSelectedBrands]);
      entities = entities.filter((entity) => !removeSelectedBrands.includes(entity));
      setEntities(entities);
      setRemoveSelectedBrands([]);
    }
  };

  const handleSaveWidgetData = async () => {
    dispatch(changeLoader(true));
    let isValid = true;

    if (widgetTitle === "") {
      setWidgetTitleError("Please enter widget title");
      isValid = false;
    }
    if (widgetSubtitle === "") {
      setWidgetSubtitleError("Please enter widget subtitle");
      isValid = false;
    }
    if (widgetTitleArabic === "") {
      setWidgetTitleArabicError("Please enter widget title in Arabic");
      isValid = false;
    }
    if (widgetSubtitleArabic === "") {
      setWidgetSubtitleArabicError("Please enter widget subtitle in Arabic");
      isValid = false;
    }
    if (widgetTypeId == 0 || widgetTypeId == null) {
      setWidgetTypeIdError("Please select business type");
      isValid = false;
    }
    if (widget?.id === undefined) {
      if (entities.length === 0) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: "Please select at least one brand"
        }))
        isValid = false;
      }
    }
    if (isValid) {
      let data = {
        type_id: widgetTypeId,
        widget_name:widgetTitle,
        widget_name_ar:widgetTitleArabic,
        widget_desc:widgetSubtitle,
        widget_desc_ar:widgetSubtitleArabic,
        type: type,
        widget_icon: widgetIcon,
        banner: widgetBanner,
        title_gif_en: titleGifEn,
        title_gif_ar: titleGifAr,
        slug: widget?.widget_slug,
        grid_style: "thumbnail",
        entity:entities.map(
          (entity, index) => {
            return {entity_id: entity, sort_id: index + 1}
          }
        ),
        widget_style: 'brand'
      }
      if (widget?.id) {
        data.id = widget?.id;
        if (removedEntities.length > 0) {
          data.removed_entity = removedEntities.join(",");
        }
      }
      const response = await GlobalService(API.WIDGET.UPDATE_ALL_WIDGET, data, 'post');
      if (response?.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "success",
          message: 'Widget saved successfully'
        }))
        getWidgetData();
      } else {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "error",
          message: 'Something went wrong, please try again later'
        }))
      } 
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (widget) {
      setWidgetTitle(getdataByKey(widget?.widget_locales, "en", "name"));
      setWidgetSubtitle(getdataByKey(widget?.widget_locales, "en", "description"));
      setWidgetTitleArabic(getdataByKey(widget?.widget_locales, "ar", "name"));
      setWidgetSubtitleArabic(getdataByKey(widget?.widget_locales, "ar", "description"));
      widgetTypeId = widget?.type_id;
      setWidgetTypeId(widgetTypeId);
      entities = widget?.widget_mappings?.sort(
        (a, b) => a.sort_id - b.sort_id
      )?.map((entity) => entity.entity_id) || [];
      setEntities(entities);
      setWidgetIcon(widget?.widget_icon);
      getBrands();
    }
  }, [widget]);

  return (
    <Grid
			container item spacing={2} xs={12}
			className="white-box-20-30 p-20"
		>
      <Grid item xs={12}  className="d-flex justify-between ">
        <FormControl component="fieldset" variant="standard">
          <Typography variant="p" component="p" mb={0} fontWeight={500} >
            Widget {widgetIndex} - X type of stores of a specific business type
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ justifyContent: "flex-end", marginLeft: 0 }}
              value=""
              control={<Switch
                color="primary"
                checked={widget?.status === 'active' ? true : false}
                onChange={(e) => {
                  const data = {
                    id: widget?.id,
                    status: e.target.checked ? 'active' : 'inactive'
                  };
                  changeWidgetStatus(data);
                }}
              />}
              label="Change Widget status"
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 10,
        }}>
          <Button
            variant="contained" className="" color="info" style={{ whiteSpace: "nowrap" }}
            onClick={handleSaveWidgetData}
            disabled={loader}
          > Save </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="upload-img mx-0 mt-1">
          {widgetIcon === "" ? <div
            className="before-upload" style={{ cursor: "pointer" }}
            onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
          >
            <img src={uploadImg} alt=".." className="upload-icon" />
            <p className="upload-text">Upload Image</p>
            <input
              type="file" accept="image/png, image/jpeg" id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>: <div className="after-upload">
            <EditIcon
              className="delete-icon"
              onClick={() => { setWidgetIcon(""); }}
            />
            <img
              alt=".." className="upload-icon" style={{ cursor: "pointer" }}
              onClick={() => { document.getElementById("upload-image" + widget?.id).click(); }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = uploadImg;
              }}
              src={constant.IMG_BASE_URL + widgetIcon}
            />
            <input
              type="file" accept={`image/png, image/jpeg`} id={"upload-image" + widget?.id}
              onChange={editImageChangeHandler} style={{ position: "absolute", left: "-10000px" }}
            />
          </div>}
        </div>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name of Widget - English"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetTitle(e.target.value)
              setWidgetTitleError('')
            }}
            fullWidth={true}
            value={widgetTitle}
          />
          <span style={{ color: "red" }}>{widgetTitleError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Subtitle of Widget - English"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetSubtitle(e.target.value)
              setWidgetSubtitleError('')
            }}
            fullWidth={true}
            value={widgetSubtitle}
          />
          <span style={{ color: "red" }}>{widgetSubtitleError}</span>
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name of Widget - Arabic"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetTitleArabic(e.target.value)
              setWidgetTitleArabicError('')
            }}
            fullWidth={true}
            value={widgetTitleArabic}
          />
          <span style={{ color: "red" }}>{widgetTitleArabicError}</span>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Subtitle of Widget - Arabic"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setWidgetSubtitleArabic(e.target.value)
              setWidgetSubtitleArabicError('')
            }}
            fullWidth={true}
            value={widgetSubtitleArabic}
          />
          <span style={{ color: "red" }}>{widgetSubtitleArabicError}</span>
        </Grid>
      </Grid>
      {showBusinessDropdown?<>
        <Grid item xs={12}>
          <Typography variant="p" component="p" mb={0} fontWeight={500}>
            Choose Business Type
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className="d-flex items-center">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Business Type"
                value={widgetTypeId}
                onChange={(e) => {
                  widgetTypeId = e.target.value;
                  setWidgetTypeId(widgetTypeId);
                  setWidgetTypeIdError('');
                  if (widget?.type_id !== widgetTypeId) {
                    removedEntities = entities;
                    setRemovedEntities(removedEntities);
                    entities = [];
                    setEntities(entities);
                  } else {
                    removedEntities = [];
                    setRemovedEntities(removedEntities);
                    entities = widget?.widget_mappings?.map((entity) => entity.entity_id) || [];
                    setEntities(entities);
                  }
                  getBrands();
                }}
              >
                {businessTypes?.filter((type) => !unavailableBusinessTypes?.includes(type.id))?.map((type, index) => (
                  <MenuItem
                    value={type?.id}
                    key={`businessType-${index}`}
                  >{ type?.business_type_locales[0]?.name }</MenuItem>
                ))}
              </Select>
              <span style={{ color: "red" }}>{widgetTypeIdError}</span>
            </FormControl>
          </div>
        </Grid>
      </>: null}
      <Grid container spacing={2} item xs={9}>
        <Grid item xs={5}>
          <div className="ranking-box">
            <div className="ranking-head">
              <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500}>Available Brands</Typography>
            </div>
            <div className="search-box">
              <TextField
                id="outlined-basic" size="small" placeholder="Search.." variant="outlined"
                type="search" value={searchAvailableBrands}
								onChange={(e) => setSearchAvailableBrands(e.target.value)}
              />
            </div>
            <div className="ranking-content">
              {brands?.filter(
                (brand) => brand?.name?.toLowerCase().includes(searchAvailableBrands.toLowerCase())
                && !entities?.includes(brand.id)
              )?.map((brand, index) => 
              <div
                className={`ranking-row ${ newSelectedBrands?.includes(brand.id) ? "active" :""}`}
                key={`brand-available-${index}`}
                onClick={() => selectUnselectBrand(brand.id) }  
              > <p className="ranking-name">{brand?.name}</p> </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid
					item={true} xs={1} display={"flex"} alignItems={"center"}
					justifyContent={"center"} flexDirection={"column"}
				>
          <ArrowForwardIcon onClick={handleForwardClick} />
          <ArrowBackIcon onClick={handleBackwardClick} />
        </Grid>
        <Grid item xs={5}>
          <div className="ranking-box">
            <div className="ranking-head">
              <Typography variant="h6" component="h6" fontSize={"16px"} fontWeight={500}>
                Selected Brands
              </Typography>
            </div>
            <div className="search-box">
              <TextField
                id="outlined-basic" type="search" variant="outlined"
                size="small" value={searchSelectedBrands} placeholder="Search.."
								onChange={(e) => setSearchSelectedBrands(e.target.value)}
              />
            </div>
            <div className="ranking-content">
              {entities
              .filter((entity) => {
                const brand = brands.find((brand) => brand?.id === entity);
                return brand?.name?.toLowerCase().includes(searchSelectedBrands.toLowerCase());
              })
              .map((entity, index) => {
              const brand = brands.find((brand) => brand?.id === entity);
              return (
                <div
                  className={`ranking-row ${ removeSelectedBrands?.includes(brand?.id) ? "active" :""}`}
                  key={`brand-available-${index}`}
                  onClick={() => selectUnselectRemoveBrand(entity)}
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  draggable
                > <p className="ranking-name">{brand?.name}</p> </div>
              )})}
            </div>
          </div>
        </Grid>
      </Grid>
      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => { setCropperData({ croppedImage: "", isOpen: false });}}
          setCropImage={handleIconUpload}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}
    </Grid>
  );
}