import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";

const ConfirmBox = ({
  isOpen = false,
  handleClose,
  submit,
  message = "Are you sure you want to delete this record?",
}) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal theme-dialog">
      <DialogContent>
        <h2 className="h3" style={{ marginBottom: "8px" }}>Alert</h2>
        <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>{message}</p>
        <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
          <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>No</Button>
          <Button type="button" size="medium" variant="text" color="error" onClick={() => submit()}>Yes</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmBox;