import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography, TextField, Button } from "@mui/material";
import modalClose from "../assets/images/structure/close-modal.png";

const RejectProduct = ({ handleClose, handleReject, product }) => {
    
	let [reason, setReason] = useState("");
	let [error, setError] = useState("");

	const onReject = (e) => {
		e.preventDefault();
		if (reason === "") {
			setError("Please enter reason");
			return;
		}
		handleReject(product, reason);
	};

  return (
    <Dialog open={true} onClose={handleClose}>
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close"/>
      <DialogContent>
        <Typography variant="h6" component="h6" mb={3}>Reject Reason</Typography>
        <form onSubmit={onReject}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Reason*"
                multiline
                rows={2}
								value={reason}
								onChange={(e) => {
									setReason(e.target.value);
									setError("");
								}}
              />
              <div className="error">{error}</div>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button type="submit" variant="contained" size="large" sx={{ width: 1, marginTop: "16px" }} >Save</Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RejectProduct;