import { createSlice } from "@reduxjs/toolkit";

export const baladiInfo = createSlice({
  name: "baladiInfo",
  initialState: {
    value: false,
  },
  reducers: {
    changeBaladiInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeBaladiInfo } = baladiInfo.actions;
export const currentBaladiInfo = (state) => state.baladiInfo.value;
export default baladiInfo.reducer;
