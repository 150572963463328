import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, Typography, Grid, CircularProgress } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert, } from "@mui/material";
import { currentUserId } from "../../../redux/reducers/manageUser";
import axios from "axios";
import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
import { blobToBase64, base64toFile } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function BulkUpdateData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState("");
  const [uomList, setUomList] = useState([]);
  const [barcode, setBarcode] = useState("");

  const [successFile, setSuccessFile] = useState([]);
  const [successDownload, setSuccessDownload] = useState(false);
  let userId = useSelector(currentUserId);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const handleDownloadSample = () => {
    window.open(
      `${constant.IMG_BASE_URL}/filetemplate/filetemplate.csv`,
      "_blank"
    );
  };
  const getUserData = async () => {
    dispatch(changeLoader(true));
    const response = await services?.manageUser?.GET_USER_DISCRIPTIONS(userId);

    if (response?.data?.ack == 1) {
      setFirstName(response?.data?.picker?.first_name);
      setLastName(response?.data?.picker?.last_name);
      setEmail(response?.data?.picker?.email);
      setRole(response?.data?.picker?.role);
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, []);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const compressedFile = await imageCompression(file, options);
    const imageUrl = await blobToBase64(compressedFile);
    setFile(base64toFile(imageUrl));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create form data

    setShowAlert(false);
    setAlertMsg("");
    setSeverity("success");
    setErrorDownload(false);
    setSuccessDownload(false);
    const formData = new FormData();
    let typedata = "";
    
    // formData.append("bulkimage", file);
    // file.forEach((data, index) => {
    //   // formData.append('bulkimage', data);
    // });
    for (let key in file) {
      if (file.hasOwnProperty(key)) {
        const filess = file[key];
        formData.append('bulkimage', filess);
      }
    }
    // return;
    setIsLoading(true); // Set loading state to true

    axios
      .post(constant.BASE_URL + api.Bulk.BulkUpdate, formData, {
        headers: {
          "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
        },
      })
      .then((response) => {
        response = response.data;
        setIsLoading(false); // Set loading state to false on response
        if (response.status == "fail") {
          setShowAlert(true);
          setAlertMsg(response.message);
          setSeverity("warning");

          setErrorFile([]);
          setErrorDownload(true);
          setType("");
          setFile(null);
          formRef.current.reset();
          disableAlert();
        } else {
          setShowAlert(true);
          setAlertMsg(response.message);
          setSeverity("success");
          setSuccessDownload(true);
          setSuccessFile(response.data.data.bulkimage);
          setType("");
          setFile(null);
          disableAlert();
          formRef.current.reset();
        }
      })
      .catch((error) => {
        // Handle error
        // ...
        if (error.response.data.status == false) {
          setShowAlert(true);
          setAlertMsg(error.response.data.message);
          setSeverity("error");
          disableAlert();
        }
        setIsLoading(false); // Set loading state to false on response
      });
  };

  const handlesuccessDownload = () => {
    const jsonData = successFile;

    // Create a CSV string from the JSON data
    const csvData = convertJsonToCsv(jsonData);

    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "Image_Report.csv";

    // Append the link to the document body
    document.body.appendChild(link);

    // Simulate a click event on the link to trigger the download
    link.click();

    // Clean up by removing the link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const handleDownload = () => {
    const jsonData = errorFile;

    // Create a CSV string from the JSON data
    const csvData = convertJsonToCsv(jsonData);

    // Create a Blob object with the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = "error_report.csv";

    // Append the link to the document body
    document.body.appendChild(link);

    // Simulate a click event on the link to trigger the download
    link.click();

    // Clean up by removing the link element and revoking the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const convertJsonToCsv = (jsonData) => {
    const header = Object.keys(jsonData[0]).join(",");
    const rows = jsonData.map((data) => Object.values(data).join(","));
    return [header , ...rows].join("\n");
  };

  const handleBarcodeChange = (event) => {
    setBarcode(event.target.value);
  };

  const handleBarcodeSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(
        constant.BASE_URL + api.Bulk.getAllbarcode + barcode
      );
      const { success, message, data, LogId } = response.data;
      if (success) {
        setUomList(data);
        setLogId(LogId);
      } else {
        setUomList([]);
        setAlertMsg(message);
        setSeverity("warning");
        setShowAlert(true);
        disableAlert();
      }
    } catch (error) {
      setAlertMsg(error.message);
      setSeverity("warning");
      setShowAlert(true);
      disableAlert();
      setUomList([]);
    }
  };
  const [editingRow, setEditingRow] = useState(null);

  const handleEdit = (rowId) => {
    setEditingRow(rowId);
  };

  const handleStockQuantityChange = (rowId, value) => {
    if (value == "") {
      value = 0;
    }
    setUomList((prevData) => {
      if (!prevData || prevData.length === 0) {
        return prevData;
      }

      let calculatedValue = 0;
      let updatedData = prevData.map((uom) => {
        const updatedUom = {
          ...uom,
          stockQuantity: 0, // Set the stock quantity to 0 for all UOMs
        };
        return updatedUom;
      });

      updatedData = prevData.map((uom) => {
        if (uom.id === rowId) {
          calculatedValue = value * uom.relationWithBase;
          const updatedUom = {
            ...uom,
            stockQuantity: parseInt(value),
          };
          return updatedUom;
        }
        return uom;
      });

      const updatedUomList = updatedData.map((uomItem) => {
        if (uomItem.id !== rowId) {
          const updatedQuantity = parseInt(
            calculatedValue / uomItem.relationWithBase
          );
          return { ...uomItem, stockQuantity: updatedQuantity };
        }
        return uomItem;
      });

      return updatedUomList;
    });
  };

  const handleSave = () => {
    // Create a new array with the desired properties
    const newData = uomList.map((uom) => ({
      id: uom.id,
      stockQuantity: uom.stockQuantity,
      status: uom.stockQuantity > 0 ? "active" : "inactive",
    }));

    // Create the formData object to send the newData

    // Make the API request using Axios
    axios
      .post(
        constant.BASE_URL + api.Bulk.BarcodeUpdate,
        { barcodeData: newData, LogId: logId },
        {
          headers: {
            "x-api-key": X_API_KEY, // Replace X_API_KEY with the actual value
          },
        }
      )
      .then((response) => {
        response = response.data;
        // setIsLoading(false); // Set loading state to false on response
        if (response.status === "fail") {
          setLogId(null);
          setEditingRow(null);
          setAlertMsg("UOM data not update.");
          setSeverity("warning");
          setShowAlert(true);
          disableAlert();
        } else if (response.status === "success") {
          setEditingRow(null);
          setAlertMsg("UOM data saved successfully.");
          setSeverity("success");
          setShowAlert(true);
          disableAlert();
        }
      })
      .catch((error) => {
        // Handle error
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === false
        ) {
          setShowAlert(true);
          setAlertMsg(error.response.data.message);
          setSeverity("error");
          disableAlert();
        }
        // setIsLoading(false); // Set loading state to false on response
      });

    // Clear the editing state and display a success message
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h5" gutterBottom>
                  Bulk Image Upload Form
                </Typography>
               
              </div>
              <form ref={formRef} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  
                  <Grid item xs={6}>
                  <input
                  type="file"
                  id="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    border: '1px solid grey',
                    borderRadius: '5px',
                    fontSize: '16px',
                    width: '500px', // Adjust the width as needed
                  }}
                />


                  {/* <TextField
                    fullWidth
                    type="file"
                    id="file"
                    multiple // Enable multi-image selection
                    accept="image/*" // Specify that only image files are accepted
                    // label="File"
                    onChange={handleFileChange}
                  /> */}
                  </Grid>
                </Grid>
                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  Submit
                </Button>
                {isLoading && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <CircularProgress size={24} />
                  </div>
                )}
                
                {successDownload && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handlesuccessDownload}
                    >
                      Download Report
                    </Button>
                  </div>
                )}
              </form>
            </div>
          </Box>

       
        </Box>
      </Box>
    </>
  );
}
