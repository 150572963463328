import React , { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Boxed } from "../Styles-Elements/Box";
import { Autocomplete, Button, Checkbox, Dialog, DialogContent, List, ListItem, ListItemText, TextField } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import GlobalService from "../services/GlobalService";
import { API } from "../pages/ENUM";
import { changeSnackbar } from "../redux/reducers/snackbar";

const ProductInactiveModal = ({
  handleClose,
  selectedProduct,
  selectedStatus,
  getAllProducts
}) => {

  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  let [allStoresOptions, setAllStoresOptions] = useState([]);

  // Handle change when options are selected
  const handleOptionsChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllStores = async () => {
    const response = await GlobalService(API.GET_ALL_STORES_BU_BRAND(selectedProduct.brandId))
    allStoresOptions = response.stores.filter((store) => store.is_approved === null || store.is_approved )
    .map((store) => ({
      value: store.id,
      label: store.stores_locales[0].business_name,
      secondLabel: store.business_address,
    }));
    setAllStoresOptions(allStoresOptions);
    setValue(allStoresOptions);
  };

  const updateProductStatus = async () => {
    if (value.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Please select atleast one store"
      }));
      return;
    }
    const data = {
      status: selectedStatus,
      productId: selectedProduct.id.toString(),
      storeId: value.map((store) => store.value).join(","),
      brand_id: selectedProduct.brandId.toString()
    }
    const response = await GlobalService(API.UPDATE_PRODUCT_STORE_STATUS, data, "post");
    getAllProducts();
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: response.msg
      }));
      handleClose();
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: response.msg
      }));
    }
  }

  useEffect(() => {
    getAllStores();
  }, [selectedProduct]);

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px' }}>
        Do you want to make this product {selectedStatus}?
        </h4>
        <p className="p3" style={{ marginBottom: "16px" }}>
          {selectedStatus=== 'inactive' ? 
            "Once deactivated, all selected products will be automatically disabled across chosen stores, ensuring that no order is received for that product.":
            "Once activated, all selected products will be automatically enabled across chosen stores, ensuring that orders can be received for that product."
          }
        </p>
        <div className="background-box" style={{ marginBottom: "24px"}}>
          <h6 className="h6" style={{ marginBottom: "8px",marginTop: '8px' }}>
            #{selectedProduct.itemCode}
          </h6>
          <p className="p2" style={{ marginBottom: "8px",marginTop: '8px' }}>
            {selectedProduct.products_locales[0].title}
          </p>
        </div>
        <div className="form-group" >
          {/* style={{pointerEvents: 'none', opacity: '0.4' }} */}
          <Autocomplete
            required
            options={allStoresOptions}
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={handleOptionsChange}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableCloseOnSelect
            disabled={allStoresOptions.length === 1}
            multiple
            renderInput={(params) => (
              <TextField {...params} label="Stores" variant="outlined" />
            )}
            renderOption={(props, option) => (
              <List className="store-dropdown">
                <ListItem {...props}>
                  <Checkbox
                    checked={value.findIndex((v) => v.value === option.value) > -1}
                  />
                  <ListItemText
                    primary={option.label}
                    secondary={option.secondLabel}
                  />
                </ListItem>
              </List>
            )}
            renderTags={(value, getTagProps) => (
              value.length === allStoresOptions.length && allStoresOptions.length > 1 ? (
                <div>All Stores</div>
              ) : (
                value.map((option, index) => (
                  <div key={index} {...getTagProps({ index })}>
                    {option.label} {index !== value.length - 1 ? ', ' : ''}
                  </div>
                ))
              )
            )}
          />
        </div>
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={updateProductStatus} 
          >Save</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default ProductInactiveModal;
