export default {
  // GET_ALL_ORDERS: (query) =>
  //   `/orders/admin/get-all-orders?${query ? query : ""}`,
  GET_ALL_ORDERS: (query) =>
    `/orders/admin/get-all-orders-new?${query ? query : ""}`,
    
  // GET_ALL_ORDERS_COUNT: (query) => `/orders/admin/get-all-order-count?${query}`,

  // GET_ALL_ORDERS_COUNT: (query) =>
  //   `/orders/admin/get-all-order-count-business-typeid?${query}`,
  GET_ALL_ORDERS_COUNT: (query) =>
    `/orders/admin/get-all-order-count-business-typeid-new?${query}`,
  GET_ORDER_BY_ID: (id) => `/orders/admin/get-order/${id}`,
  DELETE_ORDER: (id) => `/orders/delete-order/${id}`,
  UPDATE_ORDER: (id) => `/orders/admin/update-order/${id}`,
  GET_ORDER_INVOICE: (id) => `/orders/invoice/${id}`,
  CONFIRM_ORDER: (id) => `/orders/admin/update-pending-order/${id}`,
  REJECT_PENDING_ORDER: (id) => `/orders/admin/reject-pending-order/${id}`,
  COMPLETE_ORDER_BY_ADMIN: (id) =>`/orders/admin/complete-order-by-admin/${id}`, 
};
