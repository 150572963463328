import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./AddProduct.scss";
import {getdataByKey} from "../../helper/custom"
import { Chip, Box, Button, Typography, Grid, Divider, Alert } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import { useState, useEffect } from "react";
import Header from "../../layout/header/Header";
import services from "../../../services";
import DetailBox from "./DetailBox";
import UomDetailBox from "./UomDetailBox";
import NewUomDetailBox from "./NewUomDetailBox";
import RejectProduct from "../../../dialog/RejectProduct";
import { currentPermission } from "../../../redux/reducers/permission";
import { useSelector } from "react-redux";

export default function AddProduct() {

  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  const { id, request_id } = useParams();
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "", });
  let [categories, setCategories] = useState([]);
  let [editProductData, setEditProductData] = useState(null);
  let [requestProductData, setRequestProductData] = useState(null);
  let [requestProductUom, setRequestProductUom] = useState(null);
  let [shoeRejectDialog, setShowRejectDialog] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [searchParams] = useSearchParams();
  
  const routerProductListing = () => {
    navigate(`/edit-product-request?${searchParams.toString()}`);
  }

  const getUnit = async () => {
    try {
      const data = (await services.Unit.GET_ALL_UNITS()).data.units.rows;
      if (data) {
        setUnitData(data);
      }
    } catch (error) {
      console.log("Error_In_product_type", error);
    }
  };
  
  const handleApprove = async (id,type="approve", reason="") => {
    try {
      let data = {status: type};
      const responseMsg = type === 'approve' ? 'Product Request approved' : 'Product Request rejected';
      if (type === 'reject') {
        setShowRejectDialog(false);
        data.reason_of_reject = reason;
      }
      const response = await services.Products.UPDATE_EDIT_STATUS_APPROVE(id, data);
      if (response?.data?.ack) {
        setToaster({ status: "success", text: responseMsg, visibilty: true, });
        setTimeout(() => {
          setToaster({ status: "", text: ``, visibilty: false, });
        }, 6000);
        routerProductListing();
      }
    } catch (error) {
      setToaster({ status: "error", text: `${error}`, visibilty: true, });
      setTimeout(() => {
        setToaster({ status: "", text: ``, visibilty: false, });
      }, 2500);
    }
  };

  const getProductById = async (id) => {
    try {
      const response = (await services.Products.GET_PRODUCT(id)).data;
      const categoryLevel = response.product.store.business_type.category_level
      categories = []
      response.categories.forEach((element) => {
        const parent = element?.find((item) => item?.parent_id === 0);
        const subCategory = categoryLevel > 1?element?.find((item) => item?.parent_id === parent?.id): null;
        const subSubCategory = categoryLevel > 2?element?.find((item) => item?.parent_id === subCategory?.id): null;
        categories.push({parent, subCategory, subSubCategory})
      });
      setCategories(categories)
      const getData = response.product;
      const requestData = (await services.Products.GET_EDIT_REQUEST_PRODUCTS(request_id))
      requestProductData = requestData.data.data;
      setRequestProductData(requestProductData)
      requestProductUom = requestData.data.data.uom_data!==''?
        JSON.parse(requestData.data.data.uom_data).filter((uom) => {
          const total = getData?.uoms?.filter((uomData) => uomData.id !== uom.uomId && uomData.status === 'pending')
          if (total.length > 0) {
            return uom
          }
        }) : [];
      setRequestProductUom(requestProductUom)
      editProductData = getData;
      setEditProductData(editProductData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnit();
    getProductById(id);
  }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanUpdateStatus(permission?.productRequest?.actions?.approveReject === 1)
    } else {
      setCanUpdateStatus(true)
    }
  }, [permission]);

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        {toaster.visibilty && (<Alert severity={toaster.status}>{toaster.text}</Alert>)}
        <Box className="white-box-20-30">

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" mb={2} fontWeight={500}>Update Product Request</Typography>
            </Grid>
            {editProductData?<Grid item xs={10}>
              <Grid container spacing={3} mb={4}>
                <Grid item xs={3}>
                  <DetailBox
                    type="text"
                    title="Brand Name"
                    value={getdataByKey(editProductData?.store?.stores_locales,'en','business_name')}
                    changeValue={''}
                  />
                </Grid>
                <Grid item xs={3}>
                 <DetailBox
                    type="text"
                    title="Product Title"
                    value={getdataByKey(editProductData?.products_locales,'en','title')}
                    changeValue={requestProductData?.title}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailBox
                    type="text"
                    title="Arabic Product Title"
                    value={getdataByKey(editProductData?.products_locales,'ar','title')}
                    changeValue={requestProductData?.title_arabic}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DetailBox
                    type="text"
                    title="Item Code"
                    value={'#'+editProductData?.itemCode}
                    changeValue={''}
                  />
                </Grid>
                {categories.length > 0 && categories.map((item, index) => 
                    <Grid item xs={12} key={`Category-${index}`}>
                    <DetailBox
                      type="text"
                      title="Category"
                      value={
                        `
                          ${getdataByKey(item?.parent?.category_locales, "en", "name") || item?.parent?.category_locales[0].name}
                          ${item?.subCategory ? ' >> ' + getdataByKey(item?.subCategory?.category_locales, "en", "name") || item?.subCategory?.category_locales[0].name: ''}
                          ${item?.subSubCategory ? ' >> ' + getdataByKey(item?.subSubCategory?.category_locales, "en", "name") || item?.subSubCategory?.category_locales[0].name: ''}
                        `
                      }
                      changeValue={""}
                    />
                  </Grid>)}

                {/* {editProductData?.store?.business_type?.category_level == 1 && (
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Category"
                      value={
                        getdataByKey(editProductData?.category?.category_locales, 'en', 'name') || editProductData?.category?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                )}
                {editProductData?.store?.business_type?.category_level == 2 && (
                  <>
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Category"
                      value={
                        getdataByKey(editProductData?.category?.parent?.category_locales, 'en', 'name') || editProductData?.category?.parent?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Sub Category"
                      value={
                        getdataByKey(editProductData?.category?.category_locales, 'en', 'name') || editProductData?.category?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                  </>
                )}
                {editProductData?.store?.business_type?.category_level == 3 && (
                  <>
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Category"
                      value={
                        getdataByKey(editProductData?.category?.parent?.parent?.category_locales, 'en', 'name') || editProductData?.category?.parent?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Sub Category"
                      value={
                        getdataByKey(editProductData?.category?.parent?.category_locales, 'en', 'name') || editProductData?.category?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DetailBox
                      type="text"
                      title="Sub Sub Category"
                      value={
                        getdataByKey(editProductData?.category?.category_locales, 'en', 'name') || editProductData?.category?.category_locales[0].name
                      }
                      changeValue={''}
                    />
                  </Grid>
                  </>
                )} */}
                
                <Grid item xs={12}>
                  <DetailBox
                    type="html"
                    title="Description"
                    value={getdataByKey(editProductData?.products_locales,'en','description')}
                    changeValue={''}
                  />
                </Grid>
                {/*arabic paragrapg */}
                <Grid item xs={12}>
                  <DetailBox
                    type="html"
                    title="Description Arabic"
                    value={getdataByKey(editProductData?.products_locales,'ar','description')}
                    changeValue={''}
                  />
                </Grid>
                {editProductData?.product_tag_relations.length > 0?
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="#999999"
                    component="h6"
                    fontSize={"12px"}
                    fontWeight={500}
                  >Tags</Typography>
                  { editProductData?.product_tag_relations.length > 0
                    ? editProductData?.product_tag_relations.map((obj, ind) => 
                    obj?.tag?.tag !== '' ?<Chip
                      key={`tag-${ind}`}
                      className={`select-chips`}
                      label={obj?.tag?.tag}
                      style={{marginRight: '5px'}}
                    />: null
                  ): null}
                </Grid>: null}

                {editProductData?.uoms.filter(uo => uo.status !== 'pending').length > 0 ?<Grid item xs={12} mt={2}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                    <Typography variant="h6" component="h6" fontWeight={500}>Unit of Measurement</Typography>
                  </Box>
                  <div className="table-block mb-30">
                    <div className="table-wrapper">
                    {editProductData?.uoms.map((uom, index) => {
                      if (uom.status !== 'pending') {
                        const changedUoms = requestProductData?.uom_data !== ''?JSON.parse(requestProductData?.uom_data): [];
                        const cUom = changedUoms.find(cuom => cuom?.uomId === uom.id);
                        const changed = cUom?.uomId === uom.id?true:false;
                        const changedUom = changed? cUom: {};
                        return (<span key={`uom-${index}`} >
                          <UomDetailBox uom={uom} changedUom={changedUom} changed={changed}/>
                          <Divider />
                        </span>)
                      }
                    })}
                    </div>
                  </div>
                </Grid>: null}

                {requestProductUom?.length > 0?<Grid item xs={12} mt={2}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                    <Typography variant="h6" component="h6" fontWeight={500}>New Unit of Measurement</Typography>
                  </Box>
                  {requestProductUom.map((requestProduct)=><div className="table-block mb-30">
                    <div className="table-wrapper">
                      <span key={`uom`} >
                        <NewUomDetailBox uom={requestProduct} unitData={unitData}/>
                        <Divider />
                      </span>
                    </div>
                  </div>)}
                </Grid>:null}
              </Grid>
              <Divider />

              <div className="bottom-btn-row">
                <Button
                  type="button"
                  variant="standard"
                  size="large"
                  sx={{ marginRight: "10px" }}
                  onClick={routerProductListing}
                >Back</Button>
                {requestProductData?.status=='pending' && canUpdateStatus?
                
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{ marginRight: "10px" }}
                    onClick={() => handleApprove(requestProductData.id)}>Approve</Button>
                  <Button
                    disabled={requestProductData?.status != 'pending' ? true : false}
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => setShowRejectDialog(true) }>Reject</Button>
                </span>:null}
              </div>
            </Grid>: null}
          </Grid>
        </Box>
      </Box>

      {shoeRejectDialog?<RejectProduct
        handleClose={() => setShowRejectDialog(false)}
        handleReject={(product, reason) => {
          handleApprove(product.id, 'reject', reason);
        }}
        product={requestProductData}
      />:null}

    </Box>
  );
}
