import { useRef, useState } from "react";
import { Box, Button, Typography, Dialog, DialogContent } from "@mui/material";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { reduce_image_file_size } from "../pages/helper/custom";

const ImageCropper = ({ cropperImage, closepopup, setCropImage, otherParams={} }) => {  

  
  const [croppedImage, setCroppedImage] = useState('');
  const cropperRef = useRef(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL())
  };

  const handleCropperdImage = async () => {
    const newCropImage = await reduce_image_file_size(croppedImage);
    setCropImage({
      croppedImage: newCropImage,
    });
  }

  return (
    <>

    <Dialog open={true} onClose={closepopup} className="maxwidth-600">
      <DialogContent >
        <div className="p-6 relative">
                <Typography
                  className="font-proximanova text-black_900 mb-3"
                  variant="h5"
                  as="h3"
                > Image Cropper </Typography>
                
                <Cropper
                  src={cropperImage}
                  style={{ height: 300, width: "100%" }}
                  guides={false}
                  responsive={true}
                  restore={true}
                  crop={onCrop}
                  ref={cropperRef}
                  {...otherParams}
                />
                <Box display={'flex'} justifyContent={'flex-end'} marginTop={2} >
                  <Button
                    variant="standard"
                    size="small"
                    onClick={closepopup}
                  >Cancel</Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleCropperdImage}
                  >Crop</Button>
                </Box>
              </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={() => closepopup(false)}></div>
      </DialogContent>
    </Dialog>
    </>
  );
};

export {ImageCropper};


export const imagesToBase64 = async (filesArray) => {
  try {
    const base64Promises = filesArray.map((file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          reject(new Error("An error occurred while reading the file."));
        };
        fileReader.readAsDataURL(file);
      });
    });

    const base64Images = await Promise.all(base64Promises);
    return base64Images[0];
  } catch (error) {
    console.error(error);
    throw new Error("Failed to convert images to Base64.");
  }
};

export const base64toFile = (base64, name) => {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const extension = mime.split('/')[1];
  const filename = `${name}.${extension}`;
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}