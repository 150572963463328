import { API } from "../pages/ENUM";
import { useEffect, useState } from "react";
import { Boxed } from "../Styles-Elements/Box";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader, currentLoader } from "../redux/reducers/loader";
import { Button, FormControl, Dialog, DialogContent, TextField, Chip, Grid, Divider } from "@mui/material";

import GlobalService from "../services/GlobalService";
import CircularProgress from '@mui/material/CircularProgress';
import modalClose from "../assets/images/structure/close-black.svg";

const DynamicTags = ({ handleClose, businessId, getList }) => {
	const dispatch = useDispatch();
	const loader = useSelector(currentLoader);

	const [tags, setTags] = useState([]);
	const [arabicTag, setArabicTag] = useState('');
	const [englishTag, setEnglishTag] = useState('');
	const [arabicTagError, setArabicTagError] = useState('');
	const [englishTagError, setEnglishTagError] = useState('');

	const getTags = async () => {
		const response = await GlobalService(API.TAGS.GET, { businessTypeId: businessId });
		setTags(response.result);
	}

	const handleSave = async (e) => {
		e.preventDefault();
		if (!loader) {
			dispatch(changeLoader(true));
			let isValid = true;
			if (englishTag.trim() === '') {
				setEnglishTagError('English Tag is required');
				isValid = false;
			}
			if (arabicTag.trim() === '') {
				setArabicTagError('Arabic Tag is required');
				isValid = false;
			}
			if (isValid) {
				const data = {
					name_en: englishTag.trim(),
					name_ar: arabicTag.trim(),
					businessTypeId: businessId
				}
				const response = await GlobalService(API.TAGS.ADD, data, 'post');
				if (response.ack === 1) {
					dispatch(changeSnackbar({
						isOpen: true,
						message: response.msg,
						state: 'success'
					}));
					getTags();
					getList();
					setEnglishTag('');
					setArabicTag('');
				} else {
					dispatch(changeSnackbar({
						isOpen: true,
						message: response.msg,
						state: 'error'
					}));
				}
			}
			dispatch(changeLoader(false));
		}
	}

	const handleDelete = async (id) => {
		if (!loader) {
			dispatch(changeLoader(true));
			const response = await GlobalService(API.TAGS.DELETE(id), {}, 'put');
			if (response.ack === 1) {
				dispatch(changeSnackbar({
					isOpen: true,
					message: response.msg,
					state: 'success'
				}));
				getTags();
				getList();
			} else {
				dispatch(changeSnackbar({
					isOpen: true,
					message: response.msg,
					state: 'error'
				}));
			}
			dispatch(changeLoader(false));
		}
	}

	useEffect(() => { getTags(); }, [businessId]);

  return (
    <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-765">
      <DialogContent>
        <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{ marginBottom: "16px" }}>
          <h2 className="h4" style={{ marginBottom: "8px" }}>Tags</h2>
          <img src={modalClose} alt="..." style={{width: '24px', height: '24px'}} onClick={() => handleClose() } className="modal-close"/>
        </Boxed>
				<Boxed>
					<form mt={'12px'} onSubmit={handleSave}>
						<Grid container spacing={2}>
							<Grid item xs={5}>
								<FormControl fullWidth>
									<TextField
										label="English Tag" placeholder="English Tag" autoComplete='off'
										onChange={(e) => { setEnglishTag(e.target.value); setEnglishTagError('') }}
										value={englishTag} error={englishTagError} helperText={englishTagError}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={5}>
								<FormControl fullWidth>
									<TextField
										label="Arabic Tag" placeholder="Arabic Tag" autoComplete='off'
										onChange={(e) => { setArabicTag(e.target.value); setArabicTagError('') }}
										value={arabicTag} error={arabicTagError} helperText={arabicTagError}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={2}>
								<FormControl fullWidth>
									<Button variant="contained" sx={{ height: "55px" }} type="submit" disabled={loader} >
										{loader?<CircularProgress size={20} color="inherit" />: 'Add'}
									</Button>
								</FormControl>
							</Grid>
						</Grid>
					</form>
					<Divider style={{ margin: '15px 0' }} />
					{tags?.map((item) => <Chip
						style={loader
							? { marginRight: '10px', marginTop: '10px', alignSelf: 'flex-start', opacity: 0.5, pointerEvents: 'none'}
							: { marginRight: '10px', marginTop: '10px', alignSelf: 'flex-start' }
						}
						label={`${item.name_en} | ${item.name_ar}`} key={`tag-${item.id}`} onDelete={() => handleDelete(item.id) }
					/>)}
				</Boxed>
      </DialogContent>
    </Dialog>
  );
};
export default DynamicTags;