import { Grid, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import "../AddProduct.scss";
import {getdataByKey} from "../../../helper/custom"
import { useState, useEffect } from "react";
import services from "../../../../services";

const CategoryComponent = ({
    categoryLevel,
		store,
		categories,
		updateParent,
		updateSubCategory,
		updateSubSubCategory,
    categoryItem,
    isEdit,
    categoryArray,
    categoryIndex
}) => {

  const [selectedCategoryArray, setSelectedCategoryArray] = useState([])
	const [category, setCategory] = useState({
    val: "",
    err: "please select category",
    isValid: false,
  });
  const [subCategory, setSubCategory] = useState({
    val: "",
    err: "please select sub category",
    isValid: false,
  });
  const [subSubCategory, setSubSubCategory] = useState({
    val: "",
    err: "please select sub sub category",
    isValid: false,
  });

	let [categoryData, setCategoryData] = useState([]);
  let [subCategoryList, setSubCategoryList] = useState([]);
  let [subSubCategoryList, setSubSubCategoryList] = useState([]);

	const handleCategory = (id) => {
		updateParent(id);
		setCategory({ val: id, err: "", isValid: true, });
    if (categoryLevel > 1) {
      setSubCategory({
        val: "",
        err: "please select sub category",
        isValid: false,
      });
      setSubSubCategory({
        val: "",
        err: "please select sub sub category",
        isValid: false,
      });
      getSubCategory(id);
      setSubSubCategoryList([]);
    }
	};

	const getSubCategory = async (id) => {
    try {
      const query = new URLSearchParams({ parent_id: id, }).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

	const handleSubCategory = (id) => {
		updateSubCategory(id);
		setSubCategory({ val: id, err: "", isValid: true });
    setSubSubCategory({
      val: "",
      err: "please select sub sub category",
      isValid: false,
    });
    if (categoryLevel > 2) {
      getSubSubCategory(id);
    }
	};

	const getSubSubCategory = async (id) => {
    try {
      const query = new URLSearchParams({ parent_id: id, }).toString();
      const data = (await services.Category.GET_CATEGORIES(query)).data.categories;
      setSubSubCategoryList(data);
    } catch (error) {
      console.log(error);
    }
  };

	const handleSubSubCategory = (id) => {
		updateSubSubCategory(id);
    setSubSubCategory({ val: id, err: "", isValid: true });
  }

  const loadEditData = async () => {
    setCategory({ val: categoryItem?.parent, err: "", isValid: true, });
    await handleCategory(categoryItem?.parent);
    setSubCategory({ val: categoryItem?.subCategory, err: "", isValid: true, });
    await handleSubCategory(categoryItem?.subCategory);
    setSubSubCategory({ val: categoryItem?.subSubCategory, err: "", isValid: true, });
  };

	useEffect(() => {
		if (categories) {
      setCategoryData(categories);
		}
	}, [categories]);

  useEffect(() => {
    if (isEdit) {
      loadEditData()
    }
  }, [isEdit]);

  useEffect(() => {
    const selected = categoryArray.filter((item, index) => index != categoryIndex)
    switch (categoryLevel) {
      case 1:
        setSelectedCategoryArray(selected.map(item => item.parent))
        break;
      case 2:
        setSelectedCategoryArray(selected.map(item => item.subCategory))
        break;
      case 3:
        setSelectedCategoryArray(selected.map(item => item.subSubCategory))
        break;
      default:
        break;
    } 
  }, [categoryArray]);



  return (
    <Grid container spacing={3}>
			{categoryLevel > 0 && (
        <Grid item xs={4}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category.val}
              disabled={(categoryData && categoryData.length === 0) ? true : false}
              label="Category"
              onChange={(e) => {
                handleCategory(e.target.value);
                updateSubCategory("")
                updateSubSubCategory("")
              }}
            >
              {categoryData &&
                categoryData.length > 0 &&
                categoryData.filter(
                  (item) => !selectedCategoryArray.includes(item.id)
                ).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {getdataByKey(item?.category_locales, 'en', 'name')}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {categoryLevel >= 2 && (
        <Grid item xs={4}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Sub Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={subCategory.val}
              disabled={(subCategoryList && subCategoryList.length === 0) ? true : false}
              label="Sub Category"
              onChange={(e) => {
                handleSubCategory(e.target.value)
                updateSubSubCategory("")
              }}
            >
              {subCategoryList.filter(
                  (item) => !selectedCategoryArray.includes(item.id)
                ).map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {getdataByKey(item?.category_locales, 'en', 'name')}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {categoryLevel >= 3 && (
        <Grid item xs={4}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Sub Sub Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={subSubCategory.val}
              disabled={(subSubCategoryList && subSubCategoryList.length === 0) ? true : false}
              label="Sub Sub Category"
              onChange={(e) => handleSubSubCategory(e.target.value)}
            >
              {subSubCategoryList.filter(
                  (item) => !selectedCategoryArray.includes(item.id)
                ).map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {getdataByKey(item?.category_locales, 'en', 'name')}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      </Grid>
    );

};

export default CategoryComponent;