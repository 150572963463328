import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, TextField } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import {
  currentFaq,
  currentRole,
  currentId,
} from "../../../redux/reducers/faq";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
export default function FaqAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OrderNo, setorderNo] = useState("");
  const [questionInEng, setQuaetionIneng] = useState("");
  const [questionInArb, setQuaetionInArb] = useState("");
  const [answerInEng, setAnswerInEng] = useState("");
  const [answerInArb, setAnswerInArb] = useState("");
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const isEdit = useSelector(currentFaq);
  const current_id = useSelector(currentId);
  const current_role = useSelector(currentRole);
  // state for show error
  const [questionInEngErr, setQuestionInEngErr] = useState(false);
  const [questionInArbErr, setQuestionInArbErr] = useState(false);
  const [answerInEngErr, setAnswerInEngErr] = useState(false);
  const [answerInArbErr, setAnswerInArbErr] = useState(false);
  const [orderNoErr, setOrderNoErr] = useState(false);
  const [orderNoErrText, setOrderNoErrText] = useState("");
  const [questionInEngErrText, setQuestionInEngErrText] = useState("");
  const [questionInArbErrText, setQuestionInArbErrText] = useState("");
  const [answerInEngErrText, setAnswerInEngErrText] = useState("");
  const [answerInArbErrText, setAnswerInArbErrText] = useState("");

  const getFaqById = async () => {
    const response = await services.faq.GET_FAQ_BY_ID(
      current_role,
      current_id,
      1
    );
    if (response?.data?.ack == 1) {
      setorderNo(response.data?.cms?.sequence);
      response.data?.cms?.cms_locales.forEach((val) => {
        if (val.locale == "en") {
          setQuaetionIneng(val.question);
          setAnswerInEng(val.answer);
        }
        if (val.locale == "ar") {
          setQuaetionInArb(val.question);
          setAnswerInArb(val.answer);
        }
      });
    }
  };

  useEffect(() => {
    if (isEdit == "edit") {
      getFaqById();
    }
  }, []);

  const AddFaq = async () => {
    const data = {
      role: current_role,
      faqid: current_id,
      question_en: questionInEng,
      answer_en: answerInEng,
      question_ar: questionInArb,
      answer_ar: answerInArb,
      sequence: OrderNo,
    };
    if (isEdit == "add") data.faqid = 0;
    const response = await services.faq.ADD_FAQ(data);
    if (response?.data.ack == 1) {
      navigate("/faq");
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: response?.data?.msg,
          state: "success",
        })
      );
    }
  };
  const validate = () => {
    if (!OrderNo) {
      setOrderNoErr(true);
      setOrderNoErrText("Please enter order no.");
    }
    if (isNaN(OrderNo)) {
      setOrderNoErr(true);
      setOrderNoErrText("Please enter valid order no.");
    }
    if (!questionInEng) {
      setQuestionInEngErr(true);
      setQuestionInEngErrText("Please enter question in english");
    }
    if (!questionInArb) {
      setQuestionInArbErr(true);
      setQuestionInArbErrText("Please enter question in arabic");
    }
    if (!answerInEng) {
      setAnswerInEngErr(true);
      setAnswerInEngErrText("Please enter answer in english");
    }
    if (!answerInArb) {
      setAnswerInArbErr(true);
      setAnswerInArbErrText("Please enter answer in arabic");
    }
    if (
      OrderNo &&
      questionInEng &&
      questionInArb &&
      answerInEng &&
      answerInArb &&
      !isNaN(OrderNo)
    ) {
      AddFaq();
    }
  };

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <div className="top-heading-search">
              <Typography
                variant="h6"
                component="h6"
                mb={3}
                mt={1}
                fontWeight={500}
              >
                Add FAQ
              </Typography>
            </div>
            <div>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="Order No."
                      variant="outlined"
                      value={OrderNo}
                      onChange={(e) => {
                        setorderNo(e.target.value);
                        setOrderNoErr(false);
                      }}
                    />
                    {orderNoErr && (
                      <span style={{ color: "red" }}>{orderNoErrText}</span>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      required
                      variant="h6"
                      component="h6"
                      mb={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      Question in English
                    </Typography>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="Question"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={questionInEng}
                      onChange={(e) => {
                        setQuaetionIneng(e.target.value);
                        setQuestionInEngErr(false);
                      }}
                    />
                    {questionInEngErr && (
                      <span style={{ color: "red" }}>
                        {questionInEngErrText}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      required
                      variant="h6"
                      component="h6"
                      mb={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      Question in Arabic
                    </Typography>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="Question"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={questionInArb}
                      onChange={(e) => {
                        setQuaetionInArb(e.target.value);
                        setQuestionInArbErr(false);
                      }}
                    />
                    {questionInArbErr && (
                      <span style={{ color: "red" }}>
                        {questionInArbErrText}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      required
                      variant="h6"
                      component="h6"
                      mb={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      Answer in English
                    </Typography>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="Answer"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={answerInEng}
                      onChange={(e) => {
                        setAnswerInEng(e.target.value);
                        setAnswerInEngErr(false);
                      }}
                    />
                    {answerInEngErr && (
                      <span style={{ color: "red" }}>{answerInEngErrText}</span>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      required
                      variant="h6"
                      component="h6"
                      mb={1}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      Answer in Arabic
                    </Typography>
                    <TextField
                      required
                      id="outlined-multiline-static"
                      label="Answer"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={answerInArb}
                      onChange={(e) => {
                        setAnswerInArb(e.target.value);
                        setAnswerInArbErr(false);
                      }}
                    />
                    {answerInArbErr && (
                      <span style={{ color: "red" }}>{answerInArbErrText}</span>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ marginTop: "16px" }}
                      onClick={validate}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}
