import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  UPDATE_CMS: (data, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.cms.UPDATE_CMS(type),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_CMS: (type, role) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.cms.GET_CMS(type, role),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
// UPDATE_CMS
