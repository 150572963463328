import { API_GET_GEOLOCATION, VALIDATE_COORDINATES, X_API_KEY } from "../ENUM";

import axios from "axios";

const getLatLng = (zoneNo, streetNo, buildingNo, callback) => {
  const api = API_GET_GEOLOCATION;
  let params = {
    f: "json",
    returnGeometry: true,
    spatialRel: "esriSpatialRelIntersects",
    where: `ZONE_NO=${zoneNo} and STREET_NO=${streetNo} and BUILDING_NO=${buildingNo}`,
    outFields: "ZONE_NO,STREET_NO,BUILDING_NO,QARS,ELECTRICITY_NO,WATER_NO,QTEL_ID",
  };

  axios({ method: "get", url: api, params: params })
    .then((res) => {
      const geometry = res?.data.features;
      if (geometry.length === 0) {
        callback({
          lat: 0,
          lng: 0,
          message: "the zone no, street no and building number is not valid ",
          ack: 0,
        });
      } else {
        const cordinates = geometry[0].geometry;
        const api = VALIDATE_COORDINATES;
        let params = {
          x: cordinates.x,
          y: cordinates.y,
          s_srs: "2932",
          t_srs: "4326",
        };
        axios
          .get(api, {
            params,
          })
          .then((res) => {
            if (res.data) {
              callback({
                lat: res.data.y,
                lng: res.data.x,
                message: "success",
                ack: 1,
              });
            }
          })
          .catch((err) => {
            callback({
              lat: 0,
              lng: 0,
              message: "error " + err,
              ack: 0,
            });
          });
      }
    })
    .catch((err) => {
      callback({
        lat: 0,
        lng: 0,
        message: "error 1 " + err,
        ack: 0,
      });
    });
};

export default getLatLng;
