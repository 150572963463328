import Box from "@mui/material/Box";
import {
  Typography,
  Grid,
} from "@mui/material";
import { getdataByKey, getAllBarcode } from "../../helper/custom"
import { useEffect, useState } from "react";
import constant from "../../../constant";

import defaultImage from "../../../assets/images/structure/default.jpeg";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const UomDetailBox = ({uom, changedUom, changed, newUom}) => {

	let [images, setImage] = useState([
    {name: "Front View (Featured)", type: "front", product_image: defaultImage },
    {name: "Open View", type: "open",product_image: defaultImage },
    {name: "Close-Up View", type: "closeup",product_image: defaultImage },
    {name: "Top View", type: "top",product_image: defaultImage },
    {name: "Side View", type: "side",product_image: defaultImage },
  ])

	let [changedImage, setChangedImage] = useState([
    {name: "Front View (Featured)", type: "front", product_image: defaultImage },
    {name: "Open View", type: "open",product_image: defaultImage },
    {name: "Close-Up View", type: "closeup",product_image: defaultImage },
    {name: "Top View", type: "top",product_image: defaultImage },
    {name: "Side View", type: "side",product_image: defaultImage },
  ]);
	let [changeForImage, setChangeForImage] = useState(false);

	const setImages = () => {
		const changedImages = changedUom?.addingImage ?
			changedUom?.addingImage: [];
			changeForImage = changedImages?.length > 0 ? true: false;
			setChangeForImage(changeForImage)
		let frontChanged = {};
		let openChanged = {};
		let closeupChanged = {};
		let topChanged = {};
		let sideChanged = {};
		
		if(changedImages?.length > 0) {
			frontChanged = changedImages?.find((image) => image.type === 'front');
			openChanged = changedImages?.find((image) => image.type === 'open');
			closeupChanged = changedImages?.find((image) => image.type === 'closeup');
			topChanged = changedImages?.find((image) => image.type === 'top');
			sideChanged = changedImages?.find((image) => image.type === 'side');
		}
		const uomImageGet = newUom? JSON.parse(newUom.image) :uom?.uom_images;
		const front = uomImageGet?.find((image) => image.type === 'front');
		const open = uomImageGet?.find((image) => image.type === 'open');
		const closeup = uomImageGet?.find((image) => image.type === 'closeup');
		const top = uomImageGet?.find((image) => image.type === 'top');
		const side = uomImageGet?.find((image) => image.type === 'side');
		
		images = [
			{
				name: "Front View (Featured)", 
				type: "front",
				product_image: front? constant.IMG_BASE_URL + (front?.name? front?.name: front?.image): defaultImage,
			},
			{
				name: "Open View", 
				type: "open",
				product_image: open? constant.IMG_BASE_URL + (open?.name? open?.name: open?.image): defaultImage,
			},
			{
				name: "Close-Up View",
				type: "closeup",
				product_image: closeup? constant.IMG_BASE_URL + (closeup?.name? closeup?.name: closeup?.image): defaultImage,
			},
			{
				name: "Top View",
				type: "top",
				product_image: top? constant.IMG_BASE_URL + (top?.name ? top?.name: top?.image): defaultImage, 
			},
			{
				name: "Side View",
				type: "side",
				product_image: side? constant.IMG_BASE_URL + (side?.name ? side?.name : side?.image): defaultImage,
			},
		]
		setImage(images)
		changedImage = [
			{
				name: "Front View (Featured)", 
				type: "front",
				product_image: frontChanged? constant.IMG_BASE_URL + (frontChanged?.name? frontChanged?.name: frontChanged?.image): defaultImage,
			},
			{
				name: "Open View", 
				type: "open",
				product_image: openChanged? constant.IMG_BASE_URL + (openChanged?.name? openChanged?.name: openChanged?.image): defaultImage,
			},
			{
				name: "Close-Up View",
				type: "closeup",
				product_image: closeupChanged? constant.IMG_BASE_URL + (closeupChanged?.name? closeupChanged?.name: closeupChanged?.image): defaultImage,
			},
			{
				name: "Top View",
				type: "top",
				product_image: topChanged? constant.IMG_BASE_URL + (topChanged?.name ? topChanged?.name: topChanged?.image): defaultImage, 
			},
			{
				name: "Side View",
				type: "side",
				product_image: sideChanged? constant.IMG_BASE_URL + (sideChanged?.name ? sideChanged?.name : sideChanged?.image): defaultImage,
			},
		]
		setChangedImage(changedImage)
	}	

	useEffect(() => {
		setImages()
	}, [uom])

    return (
			<>
			<Grid container spacing={3} mb={2} mt={2}>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>
								<span
									style={{
										maxWidth: "150px", display: "inline-block", overflow: "hidden",
										textOverflow: "ellipsis", whiteSpace: "nowrap"
									}}
								>Barcode</span>
								{uom?.isDefault === 1?<sup style={{
              marginLeft: "5px", color: "#000000", fontSize: "12px",
              padding: "3px", backgroundColor: "rgb(187 229 223)", borderRadius: "5px"
            }}>Default</sup>:null}
							</Typography>
						<Typography
							color={changedUom?.addingBarcode? "#999999": "#000000"}
							style={changedUom?.addingBarcode? {textDecoration: 'line-through'}: null}
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>
							<span>#{(uom?.uom_barcode_relations)? getAllBarcode(uom?.uom_barcode_relations): '-'}</span>
						
						</Typography>
						{changedUom?.addingBarcode?<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>#{changedUom?.addingBarcode}</Typography>: null}
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>UOM Code And Name</Typography>
						<Typography
							color={changedUom?.uomName? "#999999": "#000000"}
							style={changedUom?.uomName? {textDecoration: 'line-through'}: null}
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.uomName}</Typography>
						{changedUom?.uomName?<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{changedUom?.uomName}</Typography>: null}
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>UOM Unit</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{getdataByKey(uom?.unit?.unit_locales, 'en', 'name')}</Typography>
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Relation With Base</Typography>
						<Typography
							color={changedUom?.relationWithBase? "#999999": "#000000"}
							style={changedUom?.relationWithBase? {textDecoration: 'line-through'}: null}
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.relationWithBase}</Typography>
						{changedUom?.relationWithBase?<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{changedUom?.relationWithBase}</Typography>: null}
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Price</Typography>
						<Typography
							color={changedUom?.price? "#999999": "#000000"}
							style={changedUom?.price? {textDecoration: 'line-through'}: null}
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>QAR {uom?.price}</Typography>
						{changedUom?.price?<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>QAR {changedUom?.price}</Typography>: null}
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Sell Price</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
							color={changedUom?.salePrice? "#999999": "#000000"}
							style={changedUom?.salePrice? {textDecoration: 'line-through'}: null}
          	>{uom?.salePrice !== 0 ? <span>QAR {uom?.salePrice}</span>: '-'}</Typography>
						{changedUom?.salePrice?<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>QAR {changedUom?.salePrice}</Typography>: null}
          </Box>
        </Grid>
				<Grid item xs={12} sm={6} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography
							variant="h6" color="#999999" component="h6"
							fontSize={"12px"} fontWeight={500}>Stock</Typography>
						<Typography
							variant="h6" component="h6"
							fontSize={"14px"} fontWeight={400}
          	>{uom?.stock === 1 ? "Active" : "Inactive"}</Typography>
          </Box>
        </Grid>
      </Grid>
			<Grid container spacing={3} mb={2} mt={2}>
			{images.map((obj, index) => 
        <div className="align-center" key={index} style={{padding: '10px',}}>
          <div className={`upload-multiple`}>
						<img
							src={obj.product_image} alt=".."
							className={`id-img  ${changed && changeForImage ?'disabled':''}`}
							style={{maxWidth:'100px',}}/>
          </div>
          <p className="p2">{obj.name}</p>
        </div>)}
			</Grid>
			<Grid item xs={12} sm={12} md={12}>

			{uom?.uom_addon_relations?.length?(
				<Box sx={{display: 'flex', flexDirection: 'column'}}>
					<Typography
									variant="h6" color="#999999" component="h6"
									fontSize={"12px"} fontWeight={500}>Modifiers</Typography>
								<Stack direction="row" spacing={1} mb={'16px'}>
								{uom?.uom_addon_relations.map((itemsaddon)=>{
									return(<>
									<Chip label={getdataByKey(itemsaddon?.addon?.addons_locales,'en','name')} /></>)
								})
							}
								</Stack>
				</Box>
			):''}			

        </Grid>
			{changed && changeForImage?<Grid container spacing={3} mb={2} mt={2}>
				{changedImage.map((obj, index) => 
          <div className="align-center" key={index} style={{padding: '10px',}}>
            <div className="upload-multiple">
							<img src={obj?.product_image} alt=".." className="id-img" style={{maxWidth:'100px',}}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = defaultImage;
							}}
							/>
            </div>
            <p className="p2">{obj.name}</p>
          </div>
        )}
			</Grid>: null}
			</>
      
    );
};

export default UomDetailBox;