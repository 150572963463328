import {
  Typography, TextField, Button, Grid, Alert, Switch, Box, Table, TableBody, Dialog, createFilterOptions,
  TableCell, TableContainer, TableHead, TableRow, Pagination, MenuItem, Tooltip, Select, DialogContent
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { getdataByKey } from "../../helper/custom";
import { currentPermission } from "../../../redux/reducers/permission";
import { changeLoader } from "../../../redux/reducers/loader";
import { useSelector, useDispatch } from "react-redux";
import { API } from "../../../pages/ENUM";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SyncIcon from '@mui/icons-material/Sync';
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import modalClose from "../../../assets/images/structure/close-modal.png";
import defaultImg from "../../../assets/images/structure/default.jpeg";
import constant from "../../../constant";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import GlobalService from "../../../services/GlobalService";
import ActiveInactiveStore from "../../../dialog/ActiveInactiveStore";
import ProductInactiveModal from "../../../dialog/ProductInactiveModal";


export default function ProductListing() {

  const limit = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const [searchParams] = useSearchParams();
  const [productData, setProductData] = useState([]);
  const [businesstypeList, setBusinesstypeList] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  let [storeList, setStoreList] = useState([]);
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "" });
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState();
  let [deleteModel, setdeleteModel] = useState({ isOpen: false, id: null });
  let [brandList, setBrandList] = useState([]);
	let [openStatusActiveInactive, setOpenStatusActiveInactive] = useState(false);
	let [openProductInactiveModal, setOpenProductInactiveModal] = useState(false);
  let [storeForActiveInactive, setStoreForActiveInactive] = useState([]);
  let [selectedProduct, setSelectedProduct] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState(null);
  let [search, setSearch] = useState("");
  let [filter, setFilter] = useState(0);
  let [filterStore, setStoreFilter] = useState(0);


  const getBrandList = async () => {
    const response = await GlobalService(API.GET_ALL_BRANDS_DROPDOWN);
    storeList = response?.stores.map((obj) => {
      obj.business_name = obj.stores_locales[0].business_name;
      return obj;
    })
    setStoreList(storeList);
    if (searchParams.get('store')) {
      filterStore = storeList.find(sto => sto.id === parseInt(searchParams.get('store')))
      setStoreFilter(filterStore)
    }
  }

  const filterOptions = createFilterOptions({
    stringify: (option) => option.business_name,
  });

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllProducts = async () => {
    dispatch(changeLoader(true));
    try {
      const query =
        filter || search || filterStore
          ? new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              search: search ? search : "",
              isApproved: 1,
              storeId: filterStore===0?"":filterStore.id,
              businessTypeId: filter === 0 ? "" : filter,
            }).toString()


          : new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              isApproved: 1,
              order_by,
              order_type,
            }).toString();
            
      let url = '/product-listing';
      let options = [];
      
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (filterStore.id) { options.push(`store=${filterStore.id}`) }
      if (page) { options.push(`page=${page}`) }
      if (options.length > 0) { url += `?${options.join('&')}` }
      navigate(url)
      const response = await services.Products.GET_ALL_PRODUCTS(query);
      if (response) {
        setProductData(response.data.products.rows);
        count = Math.ceil(response.data.products.count / limit);
        setCount(count);
      }
    } catch (error) {
      console.log("Error_In_product Listing", error);
    }
    dispatch(changeLoader(false));
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllProducts();
  };

  const handleEditProduct = async (id) => {
    try {
      let path = `/product-list-detail/${id}`
      let options = [];
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (filterStore.id) { options.push(`store=${filterStore.id}`) }
      if (page) { options.push(`page=${page}`) }
      if (options.length > 0) { path += `?${options.join('&')}` }
      window.location.href = path;
      // navigate(path);
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = () => {
    const path = `/add-product`;
    window.location.href = path;
    // navigate(path);
  };

  const GetAllBusinessType = async () => {
    try {
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeList(response.data.businesstype.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteHandler = (id) => {
    deleteModel = { isOpen: true, id: id };
    setdeleteModel(deleteModel);
  };

  const onSyncHandler = async (id) => {
   
      try {
        const { data, status } = await services.Products.SYNC_PRODUCT(
          id
        );
        if (data.ack === 1) {
          
          setToaster({
            status: "success",
            text: data.msg,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 6000);
          getAllProducts();
        } else {
        
          setToaster({
            status: "error",
            text: data.msg,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 2500);
        }
      } catch (error) {
     
      }
   
  
  }

  const handleDelete = async () => {
    try {
      const { data, status } = await services.Products.DELETE_PRODUCT(
        deleteModel.id
      );
      if (data.ack === 1) {
        setdeleteModel({ isOpen: false, id: null });
        setToaster({
          status: "success",
          text: data.msg,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllProducts();
      } else {
        setdeleteModel({ isOpen: false, id: null });
        setToaster({
          status: "error",
          text: data.msg,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 2500);
      }
    } catch (error) {
      setdeleteModel({ isOpen: false, id: null });
    }
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission?.addProduct?.edit === 1);
      setCanDelete(permission?.addProduct?.delete === 1);
      setCanAdd(permission?.addProduct?.create === 1);
      setCanUpdateStatus(permission?.addProduct?.actions?.status === 1);
    } else {
      setCanEdit(true)
      setCanDelete(true)
      setCanAdd(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllProducts();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, filter, order_by, order_type,filterStore]);

  useEffect(() => {
    page = searchParams.get('page');
    setPage(page)
    if (searchParams.get('search')) {
      search = searchParams.get('search')
      setSearch(search)
    }
    if (searchParams.get('filter')) {
      filter = searchParams.get('filter')
      setFilter(filter)
    }
  }, []);
  
  useLayoutEffect(() => {
    getBrandList();
    GetAllBusinessType();
  }, [])

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster?.visibilty && (
            <Alert severity={toaster?.status}>{toaster?.text}</Alert>
          )}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Product Listing
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    placeholder="Search.."
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search ? <CloseIcon onClick={() => setSearch("") } /> : null,
                    }}
                    value={search}
                  />
                </div>
                <Autocomplete
                  className="status-drop"
                  style={{ width: 240, }}
                  options={storeList}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.business_name}
                  renderInput={(params) => <TextField {...params} label="Select Brand" size="small" />}
                  value={filterStore}
                  onChange={(e, value) => {
                    setStoreFilter(value?value:0 );
                  }}
                />
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={"All"}
                  value={filter}
                  
                  size="small"
                  onChange={(e) => setFilter(e.target.value)}
                  className="status-drop"
                >
                  <MenuItem value={0}>{"All"}</MenuItem>
                  {businesstypeList &&
                    businesstypeList.length > 0 &&
                    businesstypeList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {getdataByKey(
                          item?.business_type_locales,
                          "en",
                          "name"
                        )}
                      </MenuItem>
                    ))}
                </Select>

                {canAdd?<Button
                  variant="contained"
                  size="medius"
                  onClick={addProduct}
                  startIcon={<AddCircleOutlineIcon />}
                > Add Product </Button>: null}
              </div>
            </div>
            {productData.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Brand Name
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("store_type", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("store_type", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className="short-div">
                        Store Address
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("store_address", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("store_address", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell> */}
                    <TableCell>
                      <div className="short-div">
                        Product Title
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("product_title", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("product_title", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Item Code
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("itemCode", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("itemCode", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell align="center">
                      <div className="short-div">
                        Suggested
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => {
                              changeOrderBy("Suggested", "asc");
                            }}
                          />
                          <ArrowDropDownIcon
                            onClick={() => {
                              changeOrderBy("Suggested", "desc");
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Sell Price
                        <div className="short-box">
                          <ArrowDropUpIcon />
                          <ArrowDropDownIcon />
                        </div>
                      </div>
                    </TableCell> */}
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Active Stores</TableCell> */}
                    <TableCell align="center">Inactive Stores</TableCell>
                    { canEdit || canDelete?
                      <TableCell align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData.map((item, index) => {
                    return (
                      <TableRow key={"fun" + index}>
                        <TableCell component="th" scope="row">
                          <div className="table-img-text">
                            <img
                              src={item.uoms[0]?.uom_images[0]?.image ?
                                (constant?.IMG_BASE_URL + item.uoms[0]?.uom_images[0]?.image): defaultImg}
                              alt=".."
                              className="square36"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultImg;
                              }}
                            />
                            {item?.brand_business_name} 
                            {/* {getdataByKey(item?.store?.stores_locales, "en", "business_name")} */}
                          </div>
                        </TableCell>
                        {/* <TableCell>{item?.store?.business_address}</TableCell> */}
                        <TableCell>
                          {getdataByKey(item?.products_locales, "en", "title")}
                        </TableCell>
                        <TableCell>{item?.itemCode}</TableCell>
                        {/* <TableCell align="center">
                          <Switch
                            checked={item.status === "active" ? true : false} />
                        </TableCell> */}
                        <TableCell align="center">
                          <Switch
                            checked={item.status === "active" ? true : false}
                            onClick={(e) => {
                              selectedProduct = item;
                              setSelectedProduct(selectedProduct)
                              selectedStatus = e.target.checked ? "active" : "inactive"
                              setSelectedStatus(selectedStatus)
                              setOpenProductInactiveModal(true);
                            }}
                            disabled={!canUpdateStatus}
                          />
                        </TableCell>

                        {/* <TableCell align="center"><span className="cursor-pointer" onClick={() => setOpenStatusActiveInactive(true)}>{item.manage_store_products.length}</span></TableCell> */}
                        <TableCell align="center"
                          style={item.manage_store_products.length === 0?{
                            pointerEvents: 'none',
                          }:{}}
                        >
                          <span className="cursor-pointer"
                            onClick={() => {
                            storeForActiveInactive = item.manage_store_products;
                            setStoreForActiveInactive(storeForActiveInactive)
                            selectedProduct = item;
                            setSelectedProduct(selectedProduct)
                            setOpenStatusActiveInactive(true)
                          }}>{item.manage_store_products.length}</span>
                        </TableCell>
                        { canEdit || canDelete?
                          <TableCell align="center" style={{ width: "100px" }}>
                           
                            {item.brandId == 3126 ? <Tooltip title="Sync to algolia" placement="top" sx={{ marginRight: 1 }}>
                              <SyncIcon
                                color="error"
                                fontSize="small"
                                onClick={() => onSyncHandler(item.id)}
                              />
                            </Tooltip>  : null}
                            {canEdit ? <Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }}>
                            <BorderColorIcon
                              color="action"
                              fontSize="small"
                              onClick={() => handleEditProduct(item.id)}
                            />
                          </Tooltip>: null }
                          {canDelete?<Tooltip title="Delete" placement="top">
                            <DeleteForever
                              color="error"
                              fontSize="small"
                              onClick={() => onDeleteHandler(item.id)}
                            />
                            </Tooltip> : null}
                         
                        </TableCell>: null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>:
            <NoDataFound text={"No Product Found"} />}
            {count > 1 ?
            <Pagination count={count} page={page} color="primary" onChange={handlePagination}/>:
            null}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={deleteModel.isOpen}
        onClose={() => setdeleteModel({ ...deleteModel, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setdeleteModel({ ...deleteModel, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Delete Product
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Product ?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setdeleteModel({ ...deleteModel, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {openStatusActiveInactive? <ActiveInactiveStore
				handleClose={() => setOpenStatusActiveInactive(false)}
        storeForActiveInactive={storeForActiveInactive}
        selectedProduct={selectedProduct}
        getAllProducts={getAllProducts}
			/>:null}
      {openProductInactiveModal? <ProductInactiveModal
				handleClose={() => setOpenProductInactiveModal(false)}
        selectedProduct={selectedProduct}
        selectedStatus={selectedStatus}
        getAllProducts={getAllProducts}
			/>:null}
    </>
  );
}
