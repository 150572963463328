
import { Typography, Box, Switch } from "@mui/material";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../../pages/ENUM";
import { priceHandler, getUomImage } from "../../../helper/custom";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { changeSnackbar } from "../../../../redux/reducers/snackbar";
import defaultImage from "../../../../assets/images/structure/default.jpeg"
import { useState, useEffect } from "react";

const ProductComponent = ({
	product,
	getList,
	selectedCategoryId,
	selectedId
}) => {

	const dispatch = useDispatch();
	const [productStatus, setProductStatus] = useState(product?.status === 'active' ? true : false);

	const updateStatusOfProduct = async (status, productId) => {
		dispatch(changeLoader(true));
		const response = await GlobalService(
			API.UPDATE_STATUS_BY_ID(productId),
			{status: status ? 'active' : 'inactive' , categoryId : selectedCategoryId  , storeId : selectedId} ,
			"put"
		);
		if (response.ack === 1) {
			dispatch(changeLoader(false));
			dispatch(changeSnackbar({
				isOpen: true,
				message: response.msg,
				status: 'success'
			}));
			getList();
		} else {
			dispatch(changeSnackbar({
				isOpen: true,
				message: response.msg,
				status: 'error'
			}));
			dispatch(changeLoader(false));
		}
	};

	useEffect(() => {
		const status = product.manage_store_products.find((item) => item.storeId == selectedId && item.entity_id == product.id)
		setProductStatus(status ? false: true);
	}, [product]);

    return (
      <Box
				p={'16px'} mb={'16px'}
				style={{ background: '#fff', boxShadow: '0 1px 2px rgba(0,0,0,.1), 0 2px 6px 2px rgba(0,0,0,.1)', }}
				display={'flex'} alignItems={'center'} justifyContent={'space-between'}
			>
        <Box display={'flex'}>
          <img
						src={getUomImage(product?.uoms[0]?.uom_images)}
						style={{ width: '50px', height: '50px' }} alt=""
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = defaultImage;
						}}
					/>
        </Box>
        <Typography variant="body1" component="body1" style={{ margin: "0px 10px", width: "350px" }} fontWeight={600}>
          {product?.products_locales[0]?.title}
        </Typography>
        <Typography variant="body1" component="body1" fontWeight={500}>
          QAR {priceHandler(product?.uoms[0]?.salePrice !== 0 ? product?.uoms[0]?.salePrice: product?.uoms[0]?.price)}
        </Typography>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="body1" component="body1" fontWeight={500} mr={1} >Status</Typography>
          <Switch
						checked={productStatus}
						onChange={(e) => updateStatusOfProduct(e.target.checked, product?.id)}
					/>
        </Box>
      </Box>
    )
};

export default ProductComponent;