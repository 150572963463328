import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Boxed } from "../../../../Styles-Elements/Box";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import SearchIcon from "@mui/icons-material/Search";
import TableRow from "@mui/material/TableRow";
import BackIcon from "../../../../assets/images/structure/arrow-left-black.svg";
import {
  Typography,
  Box,
  Pagination,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Menu,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import plusIcon from "../../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../../assets/images/structure/minus-icon.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PickerOrderDetail from "../PickerOrderDetail";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import moment from "moment";

const TopPickerPerformance = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  let [valueDatepickerStart, setValueDatepickerStart] = useState(null);
  let [valueDatepickerEnd, setValueDatepickerEnd] = useState(null);

  const [openSubtableIndex, setOpenSubtableIndex] = useState(null);
  const clearAllInputs = () => {
    setValueDatepickerEnd(null);
    setValueDatepickerStart(null);
    setSearch("");
  };
  const createData = () => {
    if (!data || data.length === 0) {
      return [];
    }

    return (
      data.length > 0 &&
      data.map((item) => {
        return {
          name: `${item?.picker_user?.first_name || ""} ${
            item?.picker_user?.last_name || ""
          }`,
          OrderID: item?.pickerId,
          NoOfItems: item?.order_count,
          Priority: item?.deliver,
          OrderDateTime: item?.deliver,
          PlacementDateTime: item?.createdAt,
          ID: item?.pickerId,
          TotalItem: item?.total_quantity_sum,
        };
      })
    );
  };

  function Row(props) {
    const { row } = props;
    const isOpen = openSubtableIndex === row.ID;
    const toggleSubtable = (id) => {
      if (id === openSubtableIndex) {
        setOpenSubtableIndex(null);
      } else {
        setOpenSubtableIndex(id);
      }
    };
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            <div className="table-img-text">
              <img
                src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                alt=".."
                className="round36"
              />
              <Typography variant="body2" component="div">
                {row?.name}
              </Typography>
            </div>
          </TableCell>
          <TableCell>{row.OrderID}</TableCell>
          <TableCell>{row.NoOfItems}</TableCell>
          <TableCell>{row.TotalItem}</TableCell>
          <TableCell>
            <Tooltip title="Order Details" placement="top" followCursor>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => toggleSubtable(row.ID)}
              >
                {isOpen ? (
                  <img src={minusIcon} alt="plus-icon" />
                ) : (
                  <img src={plusIcon} alt="plus-icon" />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="p-0 px-0" colSpan={7}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <PickerOrderDetail
                  ID={row?.ID}
                  start_date={
                    valueDatepickerStart
                      ? moment(valueDatepickerStart?.$d).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : moment(new Date()).format("YYYY-MM-DD")
                  }
                  end_date={
                    valueDatepickerStart
                      ? valueDatepickerEnd
                        ? moment(valueDatepickerEnd?.$d).format(
                            "YYYY-MM-DD 23:59:59"
                          )
                        : moment(valueDatepickerEnd?.$d).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                      : moment(new Date()).format("YYYY-MM-DD")
                  }
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const goBack = () => navigate("/picker-tracking-system");
  const Top_5Picker = async () => {
    const object = {
      count: 5,
      search,
      start_date: valueDatepickerStart
        ? moment(valueDatepickerStart?.$d).format("YYYY-MM-DD HH:mm:ss")
        : "",
      end_date: valueDatepickerStart
        ? valueDatepickerEnd
          ? moment(valueDatepickerEnd?.$d).format("YYYY-MM-DD 23:59:59")
          : moment(valueDatepickerEnd?.$d).format("YYYY-MM-DD HH:mm:ss")
        : "",
    };
    const query = new URLSearchParams(object);
    try {
      let url = API.PICKER_TRACKING.GET_TOP_5PICKER(query);
      const response = await GlobalService(url);
      setData(response?.topPickers);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Top_5Picker();
  }, [search, valueDatepickerStart, valueDatepickerEnd]);
  return (
    <>
      <div className="store-detail-head" style={{ marginBottom: "0px" }}>
        <img
          src={BackIcon}
          alt="back-icon"
          style={{ cursor: "pointer" }}
          onClick={goBack}
        />
        <Typography variant="h6" component="h6" mb={0} fontWeight={500}>
          Back
        </Typography>
      </div>

      <div className="top-heading-search">
        <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
          Top 5 Picker Tracking
        </Typography>
        <div
          className="search-box"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: "auto",
            width: "560px",
            gap: 10,
          }}
        >
          {/* <div className="search-box mr-20" style={{ width: "190px" }}>
            <TextField
              id="combo-box-demo"
              size="small"
              placeholder="Search Picker"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: "8px" }} />,
              }}
            />
          </div> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="width160"
              value={valueDatepickerStart}
              onChange={(newValue) => {
                valueDatepickerStart = newValue;
                setValueDatepickerStart(valueDatepickerStart);
              }}
              label="Start Date"
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="start date" />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="width160"
              value={valueDatepickerEnd}
              onChange={(newValue) => {
                valueDatepickerEnd = newValue;
                setValueDatepickerEnd(valueDatepickerEnd);
              }}
              label="End Date"
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="End date" />
              )}
              inputFormat="DD-MM-YYYY"
            />
          </LocalizationProvider>

          <span className="clear-all" onClick={() => clearAllInputs()}>
            {" "}
            Clear{" "}
          </span>
        </div>
      </div>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Picker Name</TableCell>
              <TableCell>Picker ID</TableCell>
              <TableCell>Total picked order</TableCell>
              <TableCell>Total Items of Picker Order</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map((row) => (
              <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                onClick={() => navigate("/picker-detail-page")}
              >
                <TableCell component="th" scope="row">
                  <div className="table-img-text">
                    <img
                      src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png"
                      alt=".."
                      className="round36"
                    />
                    <Typography variant="body2" component="div">
                      John Doe
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>{row.PickerId}</TableCell>
                <TableCell>{row.NoOfItems}</TableCell>
                <TableCell>{row.CurrentOrder}</TableCell>                
                <TableCell>{row.DurationTime}</TableCell>
                <TableCell>{row.LastOrderTime}</TableCell>
              </TableRow>
            ))} */}
            {createData() &&
              createData().map((row) => <Row key={row.name} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Pagination count={5} page={2} color="primary" /> */}
    </>
  );
};
export default TopPickerPerformance;
