import React from "react";
import moneyAdded from "../../../assets/images/structure/money-added.svg";
import moneyPaid from "../../../assets/images/structure/money-paid.svg";
import constant from "../../../constant";
import {priceHandler} from "../../helper/custom";

const WalletDetailsMain = ({ data }) => {
  return Object.keys(data).map((key) => {

    const value = data[key];
    const now = new Date(value?.createdAt);
    let hours = now.getHours();
    const minutes = now.getMinutes();
    let amOrPm = hours < 12 ? "AM" : "PM";
    hours = hours % 12 || 12; // convert to 12-hour format
    let addedby = value?.card_number ? value?.card_number : "Admin";
    if (addedby == "") {
      addedby = value?.updated_by == constant.ADMIN_ID ? "Admin" : "";
    }
    if (value.type === "top_up" && value?.amount > 0) {
      return (
        <>
          <div className="wallet-row">
            <div className="wallet-row-left">
              <img src={moneyAdded} className="money-icon" alt="" />
              <div className="relative">
                <h5 className="h6">Money added to wallet from {addedby}</h5>
                <p className="p3">
                  {`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`}| Txn
                  ID: {value?.transaction_id}
                </p>
              </div>
            </div>
            <div className="wallet-row-right">
              <div className="relative">
                <h5 className="h6 green latter-spacing1">
                  QAR {priceHandler(value?.amount)}
                </h5>
                {value?.status != "pending" ? (
                  <p className="p3 latter-spacing1">
                    Closing bal: {priceHandler(value?.wallet_closing_balance)}
                  </p>
                ) : (
                  <p className="p3 latter-spacing1">Pending</p>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (value.type === "top_up" && value?.amount < 0) {
      return (
        <>
          <div className="wallet-row">
            <div className="wallet-row-left">
              <img src={moneyPaid} className="money-icon" alt="" />
              <div className="relative">
                <h5 className="h6">Money deducted from wallet by Admin</h5>
                <p className="p3">
                  {`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`}| Txn
                  ID: {value?.transaction_id}
                </p>
              </div>
            </div>
            <div className="wallet-row-right">
              <div className="relative">
                <h5 className="h6 green latter-spacing1">
                  - QAR {priceHandler(Math.abs(value?.amount))}
                </h5>
                {value?.status != "pending" ? (
                  <p className="p3 latter-spacing1">
                    Closing bal: {priceHandler(value?.wallet_closing_balance)}
                  </p>
                ) : (
                  <p className="p3 latter-spacing1">Pending</p>
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (value.type === "refund") {
      return (
        <div className="wallet-row">
          <div className="wallet-row-left">
            <img src={moneyAdded} className="money-icon" alt="" />
            <div className="relative">
              <h5 className="h6">Refunded back on order ID - {value?.order_id}</h5>
              <p className="p3">{`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`} | Txn ID: {value?.transaction_id}</p>
            </div>
          </div>
          <div className="wallet-row-right">
            <div className="relative">
              <h5 className="h6 green latter-spacing1">QAR {priceHandler(value?.amount)}</h5>
              <p className="p3 latter-spacing1">Closing bal: QAR {priceHandler(value?.wallet_closing_balance)}</p>
            </div>
          </div>
        </div>
      );
    }
    else if (value.type === "deduction") {
      return (
        <div className="wallet-row">
          <div className="wallet-row-left">
            <img src={moneyPaid} className="money-icon" alt="" />
            <div className="relative">
            <h5 className="h6">
              Deduction by Admin 
              {value?.order_id ? ` on order ID - ${value.order_id}` : ''}  | 
              <span style={{textTransform:'capitalize'}}>
                {value.createdByUser.full_name}
              </span>
              : {value.createdByUser.email}
            </h5>   
           <p className="p3">{`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`} | Txn ID: {value?.transaction_id} | Reason: {value?.reason || 'N/A'}</p>
            </div>
          </div>
          <div className="wallet-row-right">
            <div className="relative">
              <h5 className="h6 green latter-spacing1">- QAR {priceHandler(value?.amount)}</h5>
              <p className="p3 latter-spacing1">Closing bal: QAR {priceHandler(value?.wallet_closing_balance)}</p>
            </div>
          </div>
        </div>
      );
    } 
    else if (value.type === "purchase") {
      return (
        <div className="wallet-row">
          <div className="wallet-row-left">
            <img src={moneyPaid} className="money-icon" alt="" />
            <div className="relative">
              <h5 className="h6">Paid on purchase of order ID - {value?.order_id}</h5>
              <p className="p3">{`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`} | Txn ID: {value?.transaction_id}</p>
            </div>
          </div>
          <div className="wallet-row-right">
            <div className="relative">
              <h5 className="h6 latter-spacing1">- QAR {priceHandler(value?.amount)}</h5>
              <p className="p3 latter-spacing1">
                Closing bal: QAR {priceHandler(value?.wallet_closing_balance)}
              </p>
            </div>
          </div>
        </div>
      );
    }else if (value.type === "gifted") {
      return (
        <div className="wallet-row">
          <div className="wallet-row-left">
            <img src={moneyAdded} className="money-icon" alt="" />
            <div className="relative">
              <h5 className="h6">Gifted to customer on order ID - {value?.order_id}  by Admin | <span style={{textTransform:'capitalize'}}>{value.createdByUser.full_name }</span>:{value.createdByUser.email } </h5>
              <p className="p3">{`${hours}:${minutes < 10 ? 0 : ""}${minutes} ${amOrPm}`} | Txn ID: {value?.transaction_id} | Reason: {value?.reason || 'N/A'}</p>
            </div>
          </div>
          <div className="wallet-row-right">
            <div className="relative">
              <h5 className="h6 latter-spacing1">+ QAR {priceHandler(value?.amount)}</h5>
              <p className="p3 latter-spacing1">
                Closing bal: QAR {priceHandler(value?.wallet_closing_balance)}
              </p>
            </div>
          </div>
        </div>
      );
    }
  });
};

export default WalletDetailsMain;
