import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Popover,
  TextField,
  InputAdornment,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
export default function TrafficLightSystem() {
  const dispatch = useDispatch();
  const [newOrder, setNewOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [processingOrder, setProcessingOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [readyToPickOrder, setReadyToPickOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [shippedOrder, setShippedOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [completedOrder, setCompletedOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [rejectedOrder, setRejectedOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [cancelledOrder, setCancelledOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [pendingOrder, setPendingOrder] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });

  const getAll = async () => {
    dispatch(changeLoader(true));
    const res = await GlobalService(API.TRAFFIC_LIGHT_SYSTEM.GET_ALL);
    if (res.ack === 1) {
      const data = res.results;
      const setNew = data.find((item) => item.order_status === "placed");
      setNewOrder({
        color: setNew?.color ? setNew?.color : "#d60404",
        time: setNew?.time ? setNew?.time : "",
        showPicker: false,
        anchorEl: null,
      });
      const setProcessing = data.find(
        (item) => item.order_status === "processing"
      );
      setProcessingOrder({
        color: setProcessing?.color ? setProcessing?.color : "#d60404",
        time: setProcessing?.time ? setProcessing?.time : "",
        showPicker: false,
        anchorEl: null,
      });
      const setReadyToPick = data.find(
        (item) => item.order_status === "readytopick"
      );
      setReadyToPickOrder({
        color: setReadyToPick?.color ? setReadyToPick?.color : "#d60404",
        time: setReadyToPick?.time ? setReadyToPick?.time : "",
        showPicker: false,
        anchorEl: null,
      });
      const setShipped = data.find((item) => item.order_status === "shipped");
      setShippedOrder({
        color: setShipped?.color ? setShipped?.color : "#d60404",
        time: setShipped?.time ? setShipped?.time : "",
        showPicker: false,
        anchorEl: null,
      });

      // const setCompleted = data.find((item) => item.order_status === "completed");
      // setCompletedOrder({
      //   color: setCompleted?.color?setCompleted?.color:"#d60404",
      //   time: setCompleted?.time ? setCompleted?.time: '',
      //   showPicker: false, anchorEl: null
      // });
      // const setRejected = data.find((item) => item.order_status === "rejected");
      // setRejectedOrder({
      //   color: setRejected?.color?setRejected?.color:"#d60404",
      //   time: setRejected?.time ? setRejected?.time: '',
      //   showPicker: false, anchorEl: null
      // });
      // const setCancelled = data.find((item) => item.order_status === "cancelled");
      // setCancelledOrder({
      //   color: setCancelled?.color?setCancelled?.color:"#d60404",
      //   time: setCancelled?.time ? setCancelled?.time: '',
      //   showPicker: false, anchorEl: null
      // });

      const setPending = data.find((item) => item.order_status === "pending");
      setPendingOrder({
        color: setPending?.color ? setPending?.color : "#d60404",
        time: setPending?.time ? setPending?.time : "",
        showPicker: false,
        anchorEl: null,
      });
    } else {
      setNewOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setProcessingOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setReadyToPickOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setShippedOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setCompletedOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setRejectedOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setCancelledOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
      setPendingOrder({
        color: "#d60404",
        time: "",
        showPicker: false,
        anchorEl: null,
      });
    }

    dispatch(changeLoader(false));
  };

  const saveData = async () => {
    dispatch(changeLoader(true));
    const traffic = [
      {
        order_status: "placed",
        time: newOrder.time !== "" ? newOrder.time : "0",
        color: newOrder.color,
        status: newOrder.time !== "" ? "active" : "inactive",
      },
      {
        order_status: "processing",
        time: processingOrder.time !== "" ? processingOrder.time : "0",
        color: processingOrder.color,
        status: processingOrder.time !== "" ? "active" : "inactive",
      },
      {
        order_status: "readytopick",
        time: readyToPickOrder.time !== "" ? readyToPickOrder.time : "0",
        color: readyToPickOrder.color,
        status: readyToPickOrder.time !== "" ? "active" : "inactive",
      },
      {
        order_status: "shipped",
        time: shippedOrder.time !== "" ? shippedOrder.time : "0",
        color: shippedOrder.color,
        status: shippedOrder.time !== "" ? "active" : "inactive",
      },
      // { order_status: "completed", time: completedOrder.time, color: completedOrder.color, status: completedOrder.time !== ''? 'active': 'inactive'  },
      // { order_status: "rejected", time: rejectedOrder.time, color: rejectedOrder.color, status: rejectedOrder.time !== ''? 'active': 'inactive'  },
      // { order_status: "cancelled", time: cancelledOrder.time, color: cancelledOrder.color, status: cancelledOrder.time !== ''? 'active': 'inactive'  },
      {
        order_status: "pending",
        time: pendingOrder.time !== "" ? pendingOrder.time : "0",
        color: pendingOrder.color,
        status: pendingOrder.time !== "" ? "active" : "inactive",
      },
    ];
    const res = await GlobalService(
      API.TRAFFIC_LIGHT_SYSTEM.UPDATE_ALL,
      traffic,
      "put"
    );
    if (res.ack === 1) {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: res?.msg,
          state: "success",
        })
      );
    } else {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: res?.msg,
          state: "error",
        })
      );
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <div>
      <>
        <Box className="dashboard-parent">
          <Header />
          <Aside />
          <Box className="main-wrapper">
            <Box className="white-box-20">
              <div className="top-heading-search">
                <Typography
                  variant="h6"
                  component="h6"
                  mb={2}
                  mt={1}
                  fontWeight={500}
                >
                  Traffic Light System
                </Typography>
              </div>

              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid item xs={3}>
                  <Typography
                    variant="body"
                    component="h6"
                    mb={2}
                    mt={1}
                    fontWeight={500}
                  >
                    New
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    className="small"
                    id="outlined-basic"
                    label="Enter Time"
                    variant="outlined"
                    value={newOrder.time}
                    onChange={(e) =>
                      setNewOrder({ ...newOrder, time: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setNewOrder({
                        ...newOrder,
                        showPicker: true,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: newOrder.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={newOrder.showPicker}
                    anchorEl={newOrder.anchorEl}
                    onClose={() =>
                      setNewOrder({ ...newOrder, showPicker: false })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={newOrder.color}
                      onChangeComplete={(col) =>
                        setNewOrder({ ...newOrder, color: col.hex })
                      }
                    />
                  </Popover>
                </Grid>
              </Grid>
              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid item xs={3}>
                  <Typography
                    variant="body"
                    component="h6"
                    mb={2}
                    mt={1}
                    fontWeight={500}
                  >
                    Processing
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    className="small"
                    id="outlined-basic"
                    label="Enter Time"
                    variant="outlined"
                    value={processingOrder.time}
                    onChange={(e) =>
                      setProcessingOrder({
                        ...processingOrder,
                        time: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setProcessingOrder({
                        ...processingOrder,
                        showPicker: !processingOrder.showPicker,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: processingOrder.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={processingOrder.showPicker}
                    anchorEl={processingOrder.anchorEl}
                    onClose={() =>
                      setProcessingOrder({
                        ...processingOrder,
                        showPicker: false,
                      })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={processingOrder.color}
                      onChangeComplete={(col) =>
                        setProcessingOrder({
                          ...processingOrder,
                          color: col.hex,
                        })
                      }
                    />
                  </Popover>
                </Grid>
              </Grid>
              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid item xs={3}>
                  <Typography
                    variant="body"
                    component="h6"
                    mb={2}
                    mt={1}
                    fontWeight={500}
                  >
                    Ready to Pick
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    className="small"
                    id="outlined-basic"
                    label="Enter Time"
                    variant="outlined"
                    value={readyToPickOrder.time}
                    onChange={(e) =>
                      setReadyToPickOrder({
                        ...readyToPickOrder,
                        time: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setReadyToPickOrder({
                        ...readyToPickOrder,
                        showPicker: !readyToPickOrder.showPicker,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: readyToPickOrder.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={readyToPickOrder.showPicker}
                    anchorEl={readyToPickOrder.anchorEl}
                    onClose={() =>
                      setReadyToPickOrder({
                        ...readyToPickOrder,
                        showPicker: false,
                      })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={readyToPickOrder.color}
                      onChangeComplete={(col) =>
                        setReadyToPickOrder({
                          ...readyToPickOrder,
                          color: col.hex,
                        })
                      }
                    />
                  </Popover>
                </Grid>
              </Grid>
              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid item xs={3}>
                  <Typography
                    variant="body"
                    component="h6"
                    mb={2}
                    mt={1}
                    fontWeight={500}
                  >
                    Shipped
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    className="small"
                    id="outlined-basic"
                    label="Enter Time"
                    variant="outlined"
                    value={shippedOrder.time}
                    onChange={(e) =>
                      setShippedOrder({ ...shippedOrder, time: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setShippedOrder({
                        ...shippedOrder,
                        showPicker: !shippedOrder.showPicker,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: shippedOrder.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={shippedOrder.showPicker}
                    anchorEl={shippedOrder.anchorEl}
                    onClose={() =>
                      setShippedOrder({ ...shippedOrder, showPicker: false })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={shippedOrder.color}
                      onChangeComplete={(col) =>
                        setShippedOrder({ ...shippedOrder, color: col.hex })
                      }
                    />
                  </Popover>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
              <Grid item xs={3}>
                <Typography variant="body" component="h6" mb={2} mt={1} fontWeight={500}>Completed</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField size="small" className="small"
                  id="outlined-basic" label="Enter Time" variant="outlined" value={completedOrder.time}
                  onChange={(e) => setCompletedOrder({ ...completedOrder, time: e.target.value })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className="bg-pink">Minute</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="pick-color"
                  onClick={(e) => { setCompletedOrder({ ...completedOrder, showPicker: !completedOrder.showPicker, anchorEl: e.currentTarget });}}
                  style={{ backgroundColor: completedOrder.color, minWidth: 40, minHeight: 40, marginTop: 0, }}
                ></Button>
                <Popover
                  open={completedOrder.showPicker} anchorEl={completedOrder.anchorEl}
                  onClose={() => setCompletedOrder({ ...completedOrder, showPicker: false })}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                  transformOrigin={{ vertical: "top", horizontal: "center", }}
                >
                  <SketchPicker
                    color={ completedOrder.color }
                    onChangeComplete={(col) => setCompletedOrder({ ...completedOrder, color: col.hex })}
                  />
                </Popover>
              </Grid>
            </Grid>
            <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
              <Grid item xs={3}>
                <Typography variant="body" component="h6" mb={2} mt={1} fontWeight={500} >Rejected</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField size="small" className="small"
                  id="outlined-basic" label="Enter Time" variant="outlined" value={rejectedOrder.time}
                  onChange={(e) => setRejectedOrder({ ...rejectedOrder, time: e.target.value })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className="bg-pink">Minute</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="pick-color"
                  onClick={(e) => setRejectedOrder({ ...rejectedOrder, showPicker: !rejectedOrder.showPicker, anchorEl: e.currentTarget })}
                  style={{ backgroundColor: rejectedOrder.color, minWidth: 40, minHeight: 40, marginTop: 0, }}
                ></Button>
                <Popover
                  open={rejectedOrder.showPicker} anchorEl={rejectedOrder.anchorEl}
                  onClose={() => setRejectedOrder({ ...rejectedOrder, showPicker: false })}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                  transformOrigin={{ vertical: "top", horizontal: "center", }}
                >
                  <SketchPicker
                    color={ rejectedOrder.color}
                    onChangeComplete={(col) => setRejectedOrder({ ...rejectedOrder, color: col.hex })}
                  />
                </Popover>
              </Grid>
            </Grid>
            <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
              <Grid item xs={3}>
                <Typography variant="body" component="h6" mb={2} mt={1} fontWeight={500}>Cancelled</Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField size="small" className="small"
                  id="outlined-basic" label="Enter Time" variant="outlined" value={cancelledOrder.time}
                  onChange={(e) => setCancelledOrder({ ...cancelledOrder, time: e.target.value })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" className="bg-pink">Minute</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="pick-color"
                  onClick={(e) => setCancelledOrder({ ...cancelledOrder, showPicker: !cancelledOrder.showPicker, anchorEl: e.currentTarget })}
                  style={{ backgroundColor: cancelledOrder.color, minWidth: 40, minHeight: 40, marginTop: 0, }}
                ></Button>
                <Popover
                  open={cancelledOrder.showPicker} anchorEl={cancelledOrder.anchorEl}
                  onClose={() => setCancelledOrder({ ...cancelledOrder, showPicker: false })}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                  transformOrigin={{ vertical: "top", horizontal: "center", }}
                >
                  <SketchPicker
                    color={ cancelledOrder.color}
                    onChangeComplete={ (col) => setCancelledOrder({ ...cancelledOrder, color: col.hex })}
                  />
                </Popover>
              </Grid>
            </Grid> */}

              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid item xs={3}>
                  <Typography
                    variant="body"
                    component="h6"
                    mb={2}
                    mt={1}
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    className="small"
                    id="outlined-basic"
                    label="Enter Time"
                    variant="outlined"
                    value={pendingOrder.time}
                    onChange={(e) =>
                      setPendingOrder({ ...pendingOrder, time: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setPendingOrder({
                        ...pendingOrder,
                        showPicker: !pendingOrder.showPicker,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: pendingOrder.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={pendingOrder.showPicker}
                    anchorEl={pendingOrder.anchorEl}
                    onClose={() =>
                      setPendingOrder({ ...pendingOrder, showPicker: false })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={pendingOrder.color}
                      onChangeComplete={(col) =>
                        setPendingOrder({ ...pendingOrder, color: col.hex })
                      }
                    />
                  </Popover>
                </Grid>
              </Grid>
              <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
                <Grid
                  item={true}
                  xs={8}
                  mt={1}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  onClick={saveData}
                >
                  <Button variant="contained" size="large">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
      <></>
    </div>
  );
}
