import Box from "@mui/material/Box";
import { Grid, Button, Typography } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import services from "../../../services";
import { Alert } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentPermission } from "../../../redux/reducers/permission";

export default function WarrentyPolicy() {

  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  const dispatch = useDispatch();
  const [role, setRole] = useState("user");
  const [description_en, setDescription_en] = useState("");
  const [description_ar, setDescription_ar] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [status, setStatus] = useState("active");
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const disableAlert = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription_en(data);
  };
  const handleEditorChange2 = (event, editor) => {
    const data = editor.getData();
    setDescription_ar(data);
  };
  const SetAlert = (type, msg) => {
    setSeverity(type);
    setAlertMsg(msg);
    setShowAlert(true);
    disableAlert();
  };
  const updateDisclaimer = async () => {
    if (!description_en || !description_ar) {
      SetAlert("error", "Please fill all the fields");
      return;
    }
    dispatch(changeLoader(true));
    const data = { description_en, description_ar, role, is_active, status };
    const response = await services.cms.UPDATE_CMS(data, "warrenty_policy");
    if (response?.data?.ack == 1) {
      SetAlert("success", response?.data?.msg);
    }
    dispatch(changeLoader(false));
  };

  const getDisclaimer = async () => {
    dispatch(changeLoader(true));
    const response = await services.cms.GET_CMS("warrenty_policy", role);
    if (response?.data?.ack == 1) {
      response?.data?.cms?.cms_locales.forEach((item) => {
        if (item?.locale == "en") setDescription_en(item?.description);
        if (item?.locale == "ar") setDescription_ar(item?.description);
      });
    } else {
      setDescription_ar("");
      setDescription_en("");
    }
    dispatch(changeLoader(false));
  };
  useEffect(() => {
    getDisclaimer();
  }, [role]);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanEdit(permission.cms.edit === 1)
    } else {
      setCanEdit(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
            <Grid
              item
              xs={12}
              mt={1}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant={role === "user" ? "contained" : "outlined"}
                color="error"
                style={{ marginRight: "10px" }}
                size="large"
                onClick={() => setRole("user")}
              >
                User
              </Button>
              <Button
                variant={role === "vendor" ? "contained" : "outlined"}
                color="error"
                size="large"
                onClick={() => setRole("vendor")}
              >
                Vendor
              </Button>
            </Grid>
            <div className="top-heading-search">
              <Typography
                variant="h6"
                component="h6"
                mb={2}
                mt={1}
                fontWeight={500}
              >
                Warranty Policy
              </Typography>
            </div>
            <Typography
              variant="h6"
              component="h6"
              mb={2}
              fontSize={"14px"}
              fontWeight={500}
            >
              Description in English
            </Typography>
            <Grid item xs={12} mb={3}>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "|",
                      "link",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "undo",
                      "redo",
                    ],
                  },
                }}
                editor={ClassicEditor}
                onChange={handleEditorChange}
                data={description_en}
                disabled={!canEdit}
              />
            </Grid>
            <Typography
              variant="h6"
              component="h6"
              mb={2}
              fontSize={"14px"}
              fontWeight={500}
            >
              Description in Arabic
            </Typography>
            <Grid item xs={12}>
              <CKEditor
                config={{
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "|",
                      "link",
                      "|",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "undo",
                      "redo",
                    ],
                  },
                }}
                editor={ClassicEditor}
                onChange={handleEditorChange2}
                data={description_ar}
                disabled={!canEdit}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mt={3}
              display={"flex"}
              justifyContent={"flex-end"}
            >
             {canEdit? <Button
                variant="contained"
                size="large"
                onClick={updateDisclaimer}
              >
                Save
              </Button>: null}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
