import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid, Typography, TextField, Button, Alert, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, MenuItem, Select, Dialog, DialogContent
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aside from "../../layout/aside/Aside";
import services from "../../../services";
import Header from "../../layout/header/Header";
import { stateWithValidation } from "../../helper/Validation";
import { getdataByKey } from "../../helper/custom";
import modalClose from "../../../assets/images/structure/close-modal.png";
import CloseIcon from "@material-ui/icons/Close";
import constant from "../../../constant";
import { checkIfImageExists } from "../../helper/custom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import RejectProduct from "../../../dialog/RejectProduct";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";
import defaultImg from "../../../assets/images/structure/default.jpeg";

export default function ProductRequestList() {

  const limit = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const [searchParams] = useSearchParams();
  const [toaster, setToaster] = useState({ status: "", text: "", visibilty: false, });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: null, });
  const [productData, setProductData] = useState([]);
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [rejection, setRejection] = useState({ isOpen: false, err: "", val: "", isValid: false, id: null, });
  const [filterStatus, setFilterStatus] = useState('all');
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [page, setPage] = useState(1);
  let [count, setCount] = useState();
  let [search, setSearch] = useState("");
  let [filter, setFilter] = useState(0);
  let [shoeRejectDialog, setShowRejectDialog] = useState(false);
  let [requestProductData, setRequestProductData] = useState(null);

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllRequest = async () => {
    dispatch(changeLoader(true));
    try {
      const query =
        filter || search
          ? new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              search: search ? search : "",
              isApproved: filterStatus === 'all'? "0,2": filterStatus,
              list_type: "edit-request",
              searchById: filter === 0 ? "" : filter,
            }).toString()
          : new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              list_type: "edit-request",
              isApproved: filterStatus === 'all'? "0,2": filterStatus,
              order_by,
              order_type,
            }).toString();
      let url = '/edit-product-request';
      let options = [];
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (page) { options.push(`page=${page}`) }
      if (options.length > 0) { url += `?${options.join('&')}` }
      navigate(url)
      const response = await services.Products.GET_ALL_PRODUCTS(query);
      if (response?.data?.ack == 1) {
        setProductData(response.data.products.map((item) => {
          if(!item?.uoms) {
            item.uoms = item?.uom_data !== '' ? JSON.parse(item?.uom_data) : [];
          }
          return item;
        }));
        count = Math.ceil(response.data.totalCount / limit);
        setCount(count);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log("Error_In_product Listing", error);
    }
  };

  const GetAllBusinessType = async () => {
    try {
      dispatch(changeLoader(true));
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeData(response.data.businesstype.rows);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  const editProductRequest = (id, request_id) => {
    navigate(`/product-edit-request-detail/${id}/${request_id}?${searchParams.toString()}`);
  };

  const handlePagination = (e, value) => {
    if (count >= value) {
      page = value;
      setPage(page);
      getAllRequest();
    }
  };

  const handleApprove = async (id, type = "approve", reason) => {
    dispatch(changeLoader(true));
    try {
      let data = {status: type};
      if (type === 'reject') {
        setShowRejectDialog(false);
        data.reason_of_reject = reason;
      }
      const response = await services.Products.UPDATE_EDIT_STATUS_APPROVE(id, data);
      if (response?.data?.ack === 1) {
        setToaster({
          status: "success",
          text: response?.data?.msg,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 6000);
        getAllRequest();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({
          status: "",
          text: ``,
          visibilty: false,
        });
      }, 2500);
    }
  };

  const handleRejection = async () => {
    dispatch(changeLoader(true));
    try {
      const response = await services.Products.UPDATE_STATUS_APPROVE(
        rejection.id,
        {
          status: "reject",
          reason_of_reject: rejection.val,
        }
      );
      if (response?.data?.ack) {
        setRejection({ ...rejection, isOpen: false });
        setToaster({
          status: "success",
          text: `Product Request Rejected`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllRequest();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      setRejection({ ...rejection, isOpen: false });
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ ...toaster, visibilty: false });
      }, 2500);
    }
  };

  const handleDelete = async () => {
    dispatch(changeLoader(true));
    try {
      const response = await services.Products.DELETE_PRODUCT(deleteModal.id);
      if (response?.data?.ack) {
        setDeleteModal({ id: null, isOpen: false });
        setToaster({
          status: "success",
          text: `${response?.data?.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ text: "", status: "", visibilty: false });
        }, 6000);
        getAllRequest();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ text: "", status: "", visibilty: false });
      }, 6000);
      getAllRequest();
    }
  };

  const GetTableData = ({ item, index, canUpdateStatus }) => {
    let [address, setAddress] = useState("");

    const getGeoCode = async () => {
      let code = "No address found ";
      if (item?.product) {
        code = item?.product?.store?.business_address;
      }
      setAddress(code);
    };

    useEffect(() => {
      getGeoCode();
      // dispatch(changeLoader(true));
    }, []);

    return (
      <TableRow key={index} style={{ cursor: "pointer" }}>
        <TableCell
          component="th"
          scope="row"
          onClick={() => editProductRequest(item?.productId, item?.id)}
        >
          <div className="table-img-text">
            <img
              src={checkIfImageExists(
                constant?.IMG_BASE_URL +  item?.uoms.length > 0?item?.uoms[0]?.uom_images[0]?.image: ''
              )}
              alt=".."
              className="upload-icon square36"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultImg;
              }}
            />
            {item?.product?.brand_business_name}
            {/* {getdataByKey(
              item?.product?.store?.stores_locales,
              "en",
              "business_name"
            )} */}
          </div>
        </TableCell>
        {/* <TableCell
          onClick={() => editProductRequest(item?.productId, item?.id)}
        >
          {address}
        </TableCell> */}
        <TableCell
          onClick={() => editProductRequest(item?.productId, item?.id)}
        >
          {getdataByKey(item?.product?.products_locales, "en", "title")}
        </TableCell>
        <TableCell onClick={() => editProductRequest(item?.productId, item?.id)} > #{item?.product?.itemCode}
        </TableCell>
        {canUpdateStatus?
        <TableCell className="nowrap" align="center">
          {item?.status == "pending" ?
            <Button
              variant="outlined" size="small" color="primary" sx={{ marginRight: "10px" }}
              onClick={() => handleApprove(item.id)}
            > Approve </Button> : null}
          <Button
            disabled={item?.status != "pending" ? true : false}
            variant="outlined" size="small" color="error"
            onClick={() => {
              requestProductData = item;
              setRequestProductData(requestProductData);
              setShowRejectDialog(true)
            }}
          > {item?.status == "reject" ? "Rejected" : "Reject"} </Button>
        </TableCell>: null}
      </TableRow>
    );
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanUpdateStatus(permission?.productRequest?.actions?.approveReject === 1)
    } else {
      setCanUpdateStatus(true)
    }
  }, [permission]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (page !== 1) {
        page = 1;
        setPage(page);
      }
      getAllRequest();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, filter, order_by, order_type, filterStatus]);

  useEffect(() => {
    page = searchParams.get('page')
    setPage(page)
    if (searchParams.get('search')) {
      search = searchParams.get('search')
      setSearch(search)
    }
    if (searchParams.get('filter')) {
      filter = searchParams.get('filter')
      setFilter(filter)
    }
    GetAllBusinessType();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (<Alert severity={toaster.status}>{toaster.text}</Alert>)}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Edit Product Request
              </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small" size="small" placeholder="Search.." value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                      endAdornment: search ? <CloseIcon onClick={() => { setSearch(""); }} />: null,
                    }}
                  />
                </div>
                <Select
                  labelId="demo-select-small" id="demo-select-small" defaultValue={"All"} value={filter} size="small" className="status-drop"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {businesstypeData &&
                    businesstypeData.length > 0 &&
                    businesstypeData.map((item, index) => (
                      <MenuItem key={index} value={item.id}>{getdataByKey(item?.business_type_locales, "en", "name")}</MenuItem>
                    ))}
                </Select>
                {/* <Select
                  labelId="demo-select-small" id="demo-select-small" value={filterStatus}
                  size="small" className="status-drop"
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'0'}>Pending Approval</MenuItem>
                  <MenuItem value={'2'}>Rejected</MenuItem>
                </Select> */}
              </div>
            </div>
            {productData.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Brand Name
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("store_type", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("store_type", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Product Title
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("product_title", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("product_title", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Item Code
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("item_code", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("item_code", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    {canUpdateStatus? <TableCell align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData.map((item, index) => (
                    <GetTableData
                      key={`table-${index}`}
                      item={item}
                      index={index}
                      canUpdateStatus={canUpdateStatus}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound text={"No Product Request Found"} />}
            {count > 1 ? (
              <Pagination
                count={count} page={page} color="primary"
                onChange={(e, value) => handlePagination(e, value)}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={rejection.isOpen}
        onClose={() => setRejection({ ...rejection, isOpen: false })}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setRejection({ ...rejection, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Reason"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    setRejection({
                      ...rejection,
                      ...stateWithValidation(e.target.value, "rejectReason"),
                    });
                  }}
                />
                {!rejection.isValid && (
                  <div className="error">{rejection.err}</div>
                )}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={!rejection.isValid}
                  onClick={() => handleRejection()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* delete Modal */}
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Product ?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {shoeRejectDialog?<RejectProduct
        handleClose={() => setShowRejectDialog(false)}
        handleReject={(product, reason) => {
          handleApprove(product.id, 'reject', reason);
        }}
        product={requestProductData}
      />:null}

    </>
  );
}
