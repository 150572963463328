import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import { Upload } from "antd";
import uploadImg from "../../../assets/images/structure/upload-black-icon.svg";
import BackIcon from "../../../assets/images/structure/arrow-left-black.svg";
import cancelRed from "../../../assets/images/structure/cancel-red.svg";
import getBase64 from "../../../global-modules/getBase64";
import constant from "../../../constant";
import "./AddOffers.scss";
// import AddItemsOffer from "../../../dialog/AddItemsOffer";
// import ThankyouOffer from "../../../dialog/ThankyouOffer";
import {
  Button,
  TextField,
  Grid,
  FormControlLabel,
  InputAdornment,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  Chip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography } from "antd";
import ThankyouOffer from "../../../dialog/ThankyouOffer";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { currentPermission } from "../../../redux/reducers/permission";
import { amountMask } from "../../../global-modules/MaskedInputs";
import { generateHours, convertTimeTo12Hour, getdataByKey, getFormatedDateDb } from "../../../pages/helper/custom";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { changeLoader } from "../../../redux/reducers/loader";

const days = [
  { value: 'monday', label: "Monday" },
  { value: 'tuesday', label: "Tuesday" },
  { value: 'wednesday', label: "Wednesday" },
  { value: 'thursday', label: "Thursday" },
  { value: 'friday', label: "Friday" },
  { value: 'saturday', label: "Saturday" },
  { value: 'sunday', label: "Sunday" },
];

const AddOffer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const permission = useSelector(currentPermission);
  
  let [offerImage, setOfferImage] = useState({
    name: "Offer Image",
    ifUploaded: false,
    product_image: null,
    isRequired: true,
    isUploaded: false,
    path: ""
  });
  const [discountName, setDiscountName] = useState("");
  const [discountNameError, setDiscountNameError] = useState("");
  const [DiscountType, setDiscountType] = useState("flat");
  const [DiscountAmount, setDiscountAmount] = useState("");
  const [DiscountAmountError, setDiscountAmountError] = useState("");
  const [MinDiscountValue, setMinDiscountValue] = useState("");
  const [MinDiscountValueError, setMinDiscountValueError] = useState("");
  const [MaxDiscountValue, setMaxDiscountValue] = useState("");
  const [MaxDiscountValueError, setMaxDiscountValueError] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [endDateError, setEndDateError] = useState("");
  const [happyHours, setHappyHours] = useState([
    { starttime: "", endtime: "", day: "" }
  ]);
  let [businessType, setBusinessType] = useState([]);
  let [storeList, setStoreList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [product, setProduct] = useState([]);
  let [selectedBusiness, setSelectedBusiness] = useState([]);
  const [openThankyou, setOpenThankyou] = useState(false);
  const [store, setStore] = useState([]);
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [RadioParentCheck, setRadioParentCheck] = useState(1);
  const [RadioParentCheckChildBusiness, setRadioParentCheckChildBusiness] = useState(1);
  const [RadioParentCheckChildStore, setRadioParentCheckChildStore] = useState(1);
  const [RadioParentCheckChildProduct, setRadioParentCheckChildProduct] = useState(1);
  const [storeSearch, setStoreSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [isDivVisibleAutocomplate, setIsDivVisibleAutocomplate] = useState(false);
  const [isCategoryDivVisibleAutocomplate, setIsCategoryDivVisibleAutocomplate] = useState(false);
  const [isProductDivVisibleAutocomplate, setIsProductDivVisibleAutocomplate] = useState(false);
  let [selectBusiness1, setSelectBusiness1] = useState('');
  let [selectBusiness2, setSelectBusiness2] = useState('');
  let [selectBusiness3, setSelectBusiness3] = useState('');
  let [selectStore1, setSelectStore1] = useState('');
  let [selectStore2, setSelectStore2] = useState('');
  let [selectCategory1, setSelectCategory1] = useState('');
  let [editBusinessData, setEditBusinessData] = useState([]);
  let [deletedIds, setDeletedIds] = useState([]);
  let [oldIds, setOldIds] = useState([]);
  
  const getEditData = async () => {
    const response = await GlobalService(API.DISCOUNT_AND_OFFERS.GET_EDIT_DATA(id));
    if (response?.ack === 1) {
      setDiscountName(response?.data?.name);
      setDiscountType(response?.data?.type);
      setDiscountAmount(response?.data?.amount.toString());
      setMinDiscountValue(response?.data?.min_amount.toString());
      setMaxDiscountValue(response?.data?.max_amount.toString());
      setStartDate(new Date(response?.data?.start_date));
      setEndDate(new Date(response?.data?.end_date));
      setOfferImage({
        name: "Offer Image",
        ifUploaded: false,
        product_image: null,
        isRequired: true,
        isUploaded: false,
        path: response?.data?.image
      });
      if (typeof response?.data?.happy_hours === "object" && response?.data?.happy_hours.length > 0) {
        setHappyHours(response?.data?.happy_hours);
      }
      oldIds = response?.detail.map((item) => item?.id);
      setOldIds(oldIds);
      switch (response?.data?.apply_type) {
        case "business_type":
          setRadioParentCheck(1);
          if (response?.data?.apply_on === "specific") {
            setSelectedBusiness([...new Set(response?.detail.map((item) => item.businessTypeId))]);
            setEditBusinessData(response?.detail.map((item) => {
              return {
                id: item.businessTypeId,
                name: getdataByKey(item?.business_type?.business_type_locales, "en", "name"),
                relationId: item?.id,
              }
            }))
          }
          break;
        case "store":
          setRadioParentCheck(3);
          setSelectedBusiness([...new Set(response?.detail.map((item) => item.businessTypeId))]);
          setEditBusinessData(response?.detail.map((item) => {
            return {
              id: item.businessTypeId,
              name: getdataByKey(item?.business_type?.business_type_locales, "en", "name"),
              relationId: item?.id,
            }
          }))
          if (response?.data?.apply_on === "specific") {
            setStore([...new Map(response?.detail.map((item) => {
              return {
                id: item.storeId,
                name: getdataByKey(item?.store?.stores_locales, "en", "business_name"),
                businessId: item.businessTypeId,
                relationId: item?.id,
              }
            }).map(item => [item["id"], item])).values()])
          }
          break;
        case "category":
          setRadioParentCheck(4);
          setSelectedBusiness([...new Set(response?.detail.map((item) => item.businessTypeId))]);
          setStore([...new Map(response?.detail.map((item) => {
            return {
              id: item.storeId,
              name: getdataByKey(item?.store?.stores_locales, "en", "business_name"),
              businessId: item.businessTypeId,
              relationId: item?.id,
            }
          }).map(item => [item["id"], item])).values()])

          if (response?.data?.apply_on === "specific") {
            setCategory([...new Map(response?.detail.map((item) => {
              return {
                id: item.categoryId,
                name: getdataByKey(item?.category?.category_locales, "en", "name"),
                businessId: item.businessTypeId,
                storeId: item.storeId,
                relationId: item?.id,
              }
            }).map(item => [item["id"], item])).values()])
          }
          break;
        case "product":
          setRadioParentCheck(5);
          setSelectedBusiness([...new Set(response?.detail.map((item) => item.businessTypeId))]);
          const productStore = response?.detail.map((item) => {
            return {
              id: item.storeId,
              name: getdataByKey(item?.store?.stores_locales, "en", "business_name"),
              businessId: item.businessTypeId,
              relationId: item?.id,
            }
          })
          setStore([...new Map(response?.detail.map((item) => {
            return {
              id: item.storeId,
              name: getdataByKey(item?.store?.stores_locales, "en", "business_name"),
              businessId: item.businessTypeId,
              relationId: item?.id,
            }
          }).map(item => [item["id"], item])).values()])
          setCategory([...new Map(response?.detail.map((item) => {
            return {
              id: item.categoryId,
              name: getdataByKey(item?.category?.category_locales, "en", "name"),
              businessId: item.businessTypeId,
              storeId: item.storeId,
              relationId: item?.id,
            }
          }).map(item => [item["id"], item])).values()]) 
   
          if (response?.data?.apply_on === "specific") {
            setProducts(response?.detail.map((item) => {
              return {
                id: item.productId,
                name: getdataByKey(item?.product?.products_locales, "en", "title"),
                businessId: item.businessTypeId,
                storeId: item.storeId,
                categoryId: item.categoryId,
                relationId: item?.id,
              }
            }))
          }
          break;
        default:
          setRadioParentCheck(1);
          break;
      }
    }
  };

  const uploadSingleImage = async (productImage, dataCallback) => {
    const response = await GlobalService(API.DISCOUNT_AND_OFFERS.UPLOAD_IMAGE, {
      image: productImage,
    }, "post");
    if (response?.ack === 1) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `Image Uploaded Successfully`,
          state: "success",
        })
      );
      dataCallback(response?.imageName);
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: `Image Upload failed`,
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  const beforeUploads = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type == "image/jpg";
    if (!isJpgOrPng) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Uploaded Image",
          state: "error",
        })
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Uploaded Image should be Smaller 5MB",
          state: "error",
        })
      );
    }
    let f = isJpgOrPng && isLt5M;
    return f;
  };

  const deleteImage = async () => {
    setOfferImage({
      name: "Offer Image",
      ifUploaded: false,
      product_image: null,
      isRequired: true,
      isUploaded: false,
      path: ""
    });
  };

  const onImageChange = async ({ fileList }) => {
    dispatch(changeLoader(true));
    let base64 = null;
    if (fileList.length > 0) { base64 = await getBase64(fileList[fileList.length - 1]?.originFileObj); }
    let a = offerImage
    a.ifUploaded = false;
    a.product_image = base64;
    setOfferImage(a);
    uploadSingleImage(base64, (data) => {
      a.path = data;
      a.isUploaded = true;
      setOfferImage(a);
      dispatch(changeLoader(false));
    });
  };

  const handleChange = (event) => {
    setDiscountType(event.target.value);
    if (event.target.value === "percentage") {
      const value = parseFloat(DiscountAmount) > 100 ? "100" : DiscountAmount;
      setDiscountAmount(value);
    }
  };

  const getAvailableDays = (index) => {
    const selectedDays = happyHours.filter((happyHour, happyIndex) => happyIndex !== index).map((happyHour) => happyHour.day);
    return days.filter((day) => !selectedDays.includes(day.value));
  };

  const getAllStores = async () => {
    try {
      if (selectBusiness1 !== "") {
        let url = API.GET_ALL_STORES(storeSearch);
        url += `&businessTypeId=${selectBusiness1}`;
        const response = await GlobalService(url);
        if (response?.ack === 1) {
          storeList = response.data.map((item) => {
            return {
              id: item?.id,
              name: getdataByKey(item?.stores_locales, "en", "business_name"),
              businessId: selectBusiness1,
            };
          });
          setStoreList(storeList);
          setIsDivVisibleAutocomplate(true);
        } else {
          storeList = [];
          setStoreList(storeList);
        }
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: "Please select business type",
            state: "error",
          })
        );
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getAllCategories = async () => {
    if (selectBusiness2 !== '' && selectStore1 !== '') {
      let url = API.GET_ALL_STORES_CATEGORIES(categorySearch);
      url += `&storeId=${selectStore1}`;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        const data = response.data.map((item) => {
          return {
            id: item?.id,
            name: getdataByKey(item?.category_locales, "en", "name"),
            businessId: selectBusiness2,
            storeId: selectStore1,
          };
        });
        setCategoryList(data);
        setIsCategoryDivVisibleAutocomplate(true);
      } else {
        setCategoryList([]);
        setIsCategoryDivVisibleAutocomplate(false);
      }
    } else {
      setCategoryList([]);
      setIsCategoryDivVisibleAutocomplate(false);
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please select business type and store",
          state: "error",
        })
      );
    }
  };

  const getAllProducts = async () => {
    try {
      let url = API.GET_ALL_PRODUCTS(productSearch);
      const selectedCategory = category.find((item) => item?.id === selectCategory1);
      url += `&storeId=${selectedCategory.storeId}&categoryId=${selectedCategory?.id}`;
      const response = await GlobalService(url);
      if (response?.ack === 1) {
        product = response.data.map((item) => {
          return {
            id: item?.id,
            name: item?.title,
            storeId: selectedCategory.storeId,
            categoryId: selectedCategory?.id,
            businessId: selectBusiness3,
          };
        });
        setProduct(product);
        setIsProductDivVisibleAutocomplate(true);
      } else {
        product = [];
        setProduct(product);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const getAllBusiness = async () => {
    try {
      const response = await GlobalService(API.GET_ALL_BUSINESS);
      if (response?.ack === 1) {
        businessType = response.businesstype.rows;
        setBusinessType(businessType);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const handleSave = async () => {
    let isValid = true;
    dispatch(changeLoader(true));
    if (offerImage.path === "") {
      isValid = false;
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: "Please upload offer image",
          state: "error",
        })
      );
    }
    if (discountName === "") {
      isValid = false;
      setDiscountNameError("Discount Name is required");
    }
    if (DiscountType !== "bogo") {
      if (DiscountAmount === "") {
        isValid = false;
        setDiscountAmountError("Discount Amount is required");
      }
      if (DiscountType === "flat" && MinDiscountValue === "") {
        isValid = false;
        setMinDiscountValueError("Min Discount Value is required");
      }
      if (DiscountType === "percentage" && MinDiscountValue === "") {
        isValid = false;
        setMinDiscountValueError("Min Discount Value is required");
      }
      if (DiscountType === "percentage" && MaxDiscountValue === "") {
        isValid = false;
        setMaxDiscountValueError("Max Discount Value is required");
      } else if (DiscountType === "percentage" && parseFloat(MaxDiscountValue) < parseFloat(MinDiscountValue)) {
        isValid = false;
        setMaxDiscountValueError("Max Discount Value should be greater than Min Discount Value");
      }
    }
    if (startDate === "" || startDate === null) {
      isValid = false;
      setStartDateError("Start Date is required");
    }
    if (endDate === "" || endDate === null) {
      isValid = false;
      setEndDateError("End Date is required");
    } else if (startDate > endDate) {
      isValid = false;
      setEndDateError("End Date should be greater than Start Date");
    }

    let type = "";
    let apply_on = "";
    let itemId = "";
    switch (RadioParentCheck) {
      case 1:
        type = "business_type";
        if (selectedBusiness?.length > 0) {
          apply_on = "specific";
          itemId = selectedBusiness?.map((item) => {
            let newIten = {
              businessTypeId: item,
              storeId: 0,
              categoryId: 0,
              productId: 0,
            }
            if (editBusinessData.length > 0) {
              const findData = editBusinessData.find((data) => data?.id === item);
              if (findData) {
                newIten.id = findData.relationId;
              }
            }
            if (id) {
              newIten.discountsAndOfferId = id;
            }
            return newIten
          });
        } else {
          apply_on = "all";
          itemId = businessType.map((item) => {
            let newIten = {
              businessTypeId: item?.id,
              storeId: 0,
              categoryId: 0,
              productId: 0,
            };
            if (editBusinessData.length > 0) {
              const findData = editBusinessData.find((data) => data?.id === item);
              if (findData) {
                newIten.id = findData.relationId;
              }
            }
            if (id) {
              newIten.discountsAndOfferId = id;
            }
            return newIten 
          });
        }
        break;
      case 3:
        type = "store";
        if (store.length > 0) {
          apply_on = "specific";
          itemId = store.map((item) => {
            let newIten = {
              businessTypeId: item.businessId,
              storeId: item?.id,
              categoryId: 0,
              productId: 0,
            }
            if (id) {
              newIten.discountsAndOfferId = id;
              newIten.id = item.relationId
            }
            return newIten
          });
        } else {
          if (selectedBusiness?.length === 0) {
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Please select business type",
                state: "error",
              })
            );
            isValid = false;
          }
          apply_on = "all";
          itemId = selectedBusiness?.map((item) => {
            let newIten = {
              businessTypeId: item,
              storeId: 0,
              categoryId: 0,
              productId: 0,
            }
            if (editBusinessData.length > 0) {
              const findData = editBusinessData.find((data) => data?.id === item);
              if (findData) {
                newIten.id = findData.relationId;
              }
            }
            if (id) {
              newIten.discountsAndOfferId = id;
            }
            return newIten
          });
        }
        break;
      case 4:
        type = "category";
        if (category.length > 0) {
          apply_on = "specific";
          itemId = category.map((item) => {
            let newIten = {
              businessTypeId: item.businessId,
              storeId: item.storeId,
              categoryId: item?.id,
              productId: 0,
            }
            if (id) {
              newIten.discountsAndOfferId = id;
              newIten.id = item.relationId
            }
            return newIten
          });
        } else {
          if (store.length === 0) {
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Business type and store is required",
                state: "error",
              })
            );
            isValid = false;
          }
          apply_on = "all";
          itemId = store.map((item) => {
            let newIten = {
              businessTypeId: item.businessId,
              storeId: item?.id,
              categoryId: 0,
              productId: 0,
            };
            if (id) {
              newIten.discountsAndOfferId = id;
              newIten.id = item.relationId
            }
            return newIten
          });
        }
        break;
      case 5:
        type = "product";
        if (products.length > 0) {
          apply_on = "specific";
          itemId = products.map((item) => {
            let newIten = {
              businessTypeId: item.businessId,
              storeId: item.storeId,
              categoryId: item.categoryId,
              productId: item?.id,
            }
            if (id) {
              newIten.discountsAndOfferId = id;
              newIten.id = item.relationId
            }
            return newIten;
          });
        } else {
          if (category.length === 0) {
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Business type, store and category is required",
                state: "error",
              })
            );
            isValid = false;
          }
          apply_on = "all";
          itemId = category.map((item) => {
            let newIten = {
              businessTypeId: item.businessId,
              storeId: item.storeId,
              categoryId: item?.id,
              productId: 0,
            }
            if (id) {
              newIten.discountsAndOfferId = id;
              newIten.id = item.relationId
            }
            return newIten
          });
        }
        break;
      default:
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: "Please select apply type",
            state: "error",
          })
        );
        isValid = false;
        break;
    }
    const removedIds = oldIds.filter((oldId) => !itemId.map((item) => item?.id).includes(oldId));
    if (happyHours.length > 0) {
      happyHours.forEach(element => {
        if (element.day !== "" || element.starttime !== "" || element.endtime !== "") {
          if (element.day === "" || element.starttime === "" || element.endtime === "") {
            isValid = false;
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Please select day, start time and end time",
                state: "error",
              })
            );
          }
        }
      });
    }
    if (isValid) {
      const data = {
        name: discountName,
        type: DiscountType,
        amount: DiscountAmount,
        min_amount: MinDiscountValue,
        max_amount: MaxDiscountValue,
        start_date: getFormatedDateDb(startDate) + " 00:00:00",
        end_date: getFormatedDateDb(endDate) + " 23:59:59",
        status: 'active',
        apply_type:type,
        apply_on,
        itemIds: itemId,
        happy_hours: happyHours.filter((happyHour) => happyHour.day !== ""),
        image: offerImage.path,
      };
      if (removedIds.length > 0) {
        data.deletedItemIds = removedIds.join(",");
      }
      try {
        let url = API.DISCOUNT_AND_OFFERS.ADD;
        let method = "post";
        if (id) {
          url = API.DISCOUNT_AND_OFFERS.EDIT(id);
          method = "put";
        }
        const response = await GlobalService(url, data, method);
        if (response?.ack === 1) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: response.msg,
              state: "success",
            })
          );
          navigate("/offer-listing");
        } else {
          if (response?.msg === '') {
            let errMsg = "";
            response?.errMsg.forEach(element => {
              for (const [key, value] of Object.entries(element)) {
                errMsg += `${value}`;
              }
            });
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: errMsg,
                state: "error",
              })
            );
          } else {
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: response?.msg,
                state: "error",
              })
            );
          }
        }
      } catch (error) {
        console.log("Error_In_store Listing", error);
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(changeLoader(false));
    }
  }

  const handleBusinessUpdate = async () => {
    let storeListUpdate = store.filter((item) => selectedBusiness?.includes(item.businessId));
    let categoryListUpdate = category.filter((item) => selectedBusiness?.includes(item.businessId));
    let productUpdate = products.filter((item) => selectedBusiness?.includes(item.businessId));
    setStore(storeListUpdate);
    setCategory(categoryListUpdate);
    setProducts(productUpdate);
  }

  const handleStoreUpdate = async () => {
    const storeIds = store.map((item) => item?.id);
    let categoryListUpdate = category.filter((item) => storeIds.includes(item.storeId));
    let productUpdate = products.filter((item) => storeIds.includes(item.storeId));
    setCategory(categoryListUpdate);
    setProducts(productUpdate);
  }

  const handleCategoryUpdate = async () => {
    const categoryIds = category.map((item) => item?.id);
    let productUpdate = products.filter((item) => categoryIds.includes(item.categoryId));
    setProducts(productUpdate);
  };

  const goBack = () => {
    navigate("/offer-listing");
  };

  useEffect(() => {
    handleBusinessUpdate();
  }, [selectedBusiness]);

  useEffect(() => {
    handleStoreUpdate();
  }, [store]);

  useEffect(() => {
    handleCategoryUpdate();
  }, [category]);

  useEffect(() => {
    if (storeSearch !== "") {
      const getData = setTimeout(() => {
        getAllStores();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setStoreList([]);
    }
  }, [storeSearch]);

  useEffect(() => {
    if (categorySearch !== "") {
      const getData = setTimeout(() => {
        getAllCategories();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setStoreList([]);
    }
  }, [categorySearch]);

  useEffect(() => {
    if (productSearch !== "") {
      const getData = setTimeout(() => {
        getAllProducts();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      setProduct([]);
    }
  }, [productSearch]);

  useEffect(() => {
    getAllBusiness();
  }, []);

  useEffect(() => {
    if (id) {
      getEditData();
    }
  }, [id]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
            <div
                  className="store-detail-head"
                  // style={{ marginBottom: "0px" }}
                >
            <img
                    src={BackIcon}
                    alt="back-icon"
                    style={{ cursor: "pointer" }}
                    onClick={goBack}
                  />
              <h6 className="h6" style={{ marginBottom: "0px" }}> { id ? "Update" : "Add" } Offer </h6>
              </div>
            </div>
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
              xs={12}
              sm={12}
              md={12}
              lg={10}
            >
              <Grid item xs={12} className="mb-4">
              <div className="">
                <div className="upload-multiple">
                  {offerImage.product_image !== null ? <>
                    <img src={cancelRed} alt=".." onClick={deleteImage} className="delete-icon" />
                    <img
                      src={offerImage.product_image} alt=".."
                      className="id-img" style={{ maxWidth: "150px", }} />
                  </>: offerImage.path !== "" ? <>
                    <img src={cancelRed} alt=".." onClick={deleteImage} className="delete-icon" />
                    <img
                      src={`${constant.IMG_BASE_URL}${offerImage.path}`}
                      alt=".." className="id-img" style={{ maxWidth: "150px", }} />
                  </>: <>
                    <Upload
                      listStyle={{ borderRadius: "50%" }} listType="picture-card" showUploadList={false}
                      beforeUpload={(files) => beforeUploads(files) } accept={".jpg,.jpeg,.png"}
                      onChange={(file) => onImageChange(file)}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">{"Upload"}</p>
                    </Upload>
                    {/* <ImgCrop></ImgCrop> */}
                  </>}
                </div>
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField label="Discount Name*"
                    value={discountName}
                    onChange={(e) => setDiscountName(e.target.value)}
                  />
                  <span className="redspan">{discountNameError}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Discount Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" value={DiscountType}
                    label="Discount Type" onChange={handleChange}
                  >
                    <MenuItem value={'flat'}>Flat Discount</MenuItem>
                    <MenuItem value={'percentage'}>Percentage Discount</MenuItem>
                    <MenuItem value={'bogo'}>BOGO (Buy one get one)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {DiscountType !=="bogo"?<Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    label={DiscountType ==="percentage"?"Discount Percentage*":"Discount Amount*"}
                    id="DiscountAmount" value={DiscountAmount}
                    onChange={(e) => {
                      setDiscountAmount(
                        (DiscountType ==="percentage" && parseFloat(e.target.value) > 100)?
                        '100': e.target.value
                      )
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" className="bg-pink">
                        {DiscountType ==="percentage"?"%":"QAR"}</InputAdornment>,
                      inputComponent: amountMask,
                    }}
                  />
                  <span className="redspan">{DiscountAmountError}</span>
                </div>
              </Grid>: null}
              {DiscountType !=="bogo"?<Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    label="Min Discount Value*" id="MinDiscountValue" value={MinDiscountValue}
                    onChange={(e) => setMinDiscountValue(e.target.value)}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end" className="bg-pink">QAR</InputAdornment>),
                      inputComponent: amountMask,
                    }}
                  />
                  <span className="redspan">{MinDiscountValueError}</span>
                </div>
              </Grid>: null}
              {DiscountType ==="percentage"?<Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    label="Max Discount Value" id="MacDiscountValue" value={MaxDiscountValue}
                    onChange={(e) => setMaxDiscountValue(e.target.value)}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end" className="bg-pink">QAR</InputAdornment>),
                      inputComponent: amountMask,
                    }}
                  />
                  <span className="redspan">{MaxDiscountValueError}</span>
                </div>
              </Grid>: null}
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="calender-icon" value={startDate} inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => setStartDate(newValue)}
                    minDate={new Date()} label="Start Date*"
                    inputProps={{ "aria-label": "Without label" }}
                    renderInput={(params) => (<TextField {...params} helperText={null} />)}
                  />
                </LocalizationProvider>
                <span className="redspan">{startDateError}</span>
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="calender-icon" value={endDate} inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => setEndDate(newValue) }
                    minDate={startDate} label="End Date*"
                    inputProps={{ "aria-label": "Without label" }}
                    renderInput={(params) => (<TextField {...params} helperText={null} />)}
                  />
                </LocalizationProvider>
                <span className="redspan">{endDateError}</span>
              </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box className="top-flex-row" mb={"10px"}>
                    <h6 className="h6">Add Happy Hours</h6>
                    <Box display={"flex"} alignItems={"center"}>
                      {happyHours.length < 7?<Button
                        type="button" variant="contained" size="medium" sx={{ width: "150px" }}
                        onClick={() => {
                          setHappyHours([
                            ...happyHours,
                            { starttime: "", endtime: "", day: "" }
                          ]);
                        }}
                      >Add Day</Button>: null}
                    </Box>
                  </Box>
                </Grid>

                {happyHours.map((happyHour, happyIndex) => <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  container
                  key={`happy-hour-${happyIndex}`}
                  columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
                >
                  <Grid item xs={12} sm={6} md={3} lg={3} className="mb-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                      <Select
                        labelId="demo-simple-select-label" id="demo-simple-select"
                        value={happyHour.starttime} label="Start Time"
                        onChange={(e) => {
                          const allHappyHours = [...happyHours];
                          allHappyHours[happyIndex].starttime = e.target.value;
                          setHappyHours(allHappyHours);
                        }}
                      >
                        {generateHours(15).filter((hour) => (hour < happyHour.endtime || happyHour.endtime === ""))
                          .map((hour, index) =>
                          <MenuItem
                            value={hour} key={`hour-start-${happyIndex}${index}`}
                          >{convertTimeTo12Hour(hour)}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3} className="mb-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                      <Select
                        labelId="demo-simple-select-label" id="demo-simple-select"
                        value={happyHour.endtime} label="End Time"
                        onChange={(e) => {
                          const allHappyHours = [...happyHours];
                          allHappyHours[happyIndex].endtime = e.target.value;
                          setHappyHours(allHappyHours);
                        }}
                      >
                        {generateHours(15).filter((hour) => hour > happyHour.starttime)
                          .map((hour, index) =>
                          <MenuItem
                            value={hour} key={`hour-end-${happyIndex}${index}`}
                          >{convertTimeTo12Hour(hour)}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} className="mb-4">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Day</InputLabel>
                      <Select
                        labelId="demo-simple-select-label" id="demo-simple-select"
                        value={happyHour.day} label="Day"
                        onChange={
                          (e) => {
                            const allHappyHours = [...happyHours];
                            allHappyHours[happyIndex].day = e.target.value;
                            setHappyHours(allHappyHours);
                          }
                        }
                      >
                        {getAvailableDays(happyIndex).map((day, index) => (
                          <MenuItem
                            value={day.value} key={`day-${happyIndex}${index}`}
                          >{day.label}</MenuItem>))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2} className="mb-4">
                    <Button
                      variant="contained" size="medium" type="button"
                      sx={{ width: "150px", marginTop: "20px" }}
                      onClick={() => {
                        const allHappyHours = [...happyHours];
                        allHappyHours.splice(happyIndex, 1);
                        setHappyHours(allHappyHours);
                      }}
                    > Remove Day </Button>
                  </Grid>
                </Grid>)}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {happyHours.length < 7?<Button
                    variant="contained" size="medium" type="button"
                    sx={{ width: "150px", marginTop: "20px" }}
                    onClick={() => {
                      setHappyHours([
                        ...happyHours,
                        { starttime: "", endtime: "", day: "" }
                      ]);
                    }}
                  > Add Day </Button>: null}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className="white-box-20" style={{ marginTop: 20 }}>
            <div className="top-heading-search"> <h6 className="h6">Apply Discount On</h6> </div>
            <Grid container={true} columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }} xs={12} sm={12} md={12} lg={10}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label" name="storeligiblity"
                    style={{ display: "flex", flexDirection: "row", gap: "20px" ,marginBottom: '20px' }}
                    value={RadioParentCheck} onChange={(e) => setRadioParentCheck(parseInt(e.target.value))}
                  >
                    <FormControlLabel value={1} control={<Radio/>} label="Business Type" />
                    <FormControlLabel value={2} control={<Radio/>} label="Brand" />
                    <FormControlLabel value={3} control={<Radio/>} label="Store" />
                    <FormControlLabel value={4} control={<Radio/>} label="Category" />
                    <FormControlLabel value={5} control={<Radio/>} label="Product" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container={true} columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }} xs={12} sm={12} md={12} lg={10}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    multiple={true} value={selectedBusiness}
                    onChange={(e) => setSelectedBusiness(e.target.value)}
                  >
                    {businessType.length > 0? businessType.map((business, index) => (
                      <MenuItem value={business.id} key={`business-${index}`} >
                        {getdataByKey( business?.business_type_locales, "en", "name" )}
                      </MenuItem>
                    )): null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {selectedBusiness?.map((select, key) => {
                  let business = businessType.find((business) => business.id === select);
                  return (
                    <Chip
                      label={getdataByKey(business?.business_type_locales,"en","name")}
                      key={`business-${key}`} sx={{ mr: 1, mt: 2 }}
                      onDelete={() => {
                        const allBusiness = [...selectedBusiness,];
                        allBusiness.splice(key, 1);
                        setSelectedBusiness(allBusiness);
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid>
            {RadioParentCheck > 1 && selectedBusiness?.length > 0?
            <Grid container={true} columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }} xs={12} sm={12} md={12} lg={10} className="mt-4">
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectBusiness1} onChange={(e) => setSelectBusiness1(e.target.value)}
                  >
                    {selectedBusiness?.map((select, index) => {
                        let business = businessType.find((business) => business.id === select);
                        return (
                          <MenuItem value={business.id} key={`business-${index}`} >
                            {getdataByKey( business?.business_type_locales, "en", "name" )}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Select Store" value={storeSearch} id="storeSearch"
                    placeholder="Enter your Store Name which you want to search"
                    onClick={() =>setIsDivVisibleAutocomplate(!isDivVisibleAutocomplate)}
                    onChange={(e) =>setStoreSearch(e.target.value)}
                  />
                  {isDivVisibleAutocomplate && (
                  <ul className="show-hide-div">
                    {storeList && storeList.length > 0 ? storeList.map((item, index) => (<li
                      key={`store-${index}`}
                      onClick={() => {
                        const allStore = [...store];
                        let index = allStore.findIndex((store) =>store.id === item?.id);
                        if (index === -1) {
                          allStore.push(item);
                        } else {
                          allStore.splice(index, 1);
                        }
                        setStore(allStore);
                      }}
                      className={store.findIndex((store) => store.id === item?.id) !== -1 ? "active" : ""}
                    >{item?.name}</li>)): null}
                  </ul>)}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {store.map((sto) => (<Chip
                  key={`store-chip-${sto.id}`} label={sto.name} sx={{ mr: 1, mt: 2 }}
                  onDelete={() => {
                    const allStore = [...store];
                    let index = allStore.findIndex((store) => store.id === sto.id);
                    if (index !== -1) {allStore.splice(index, 1);}
                    setStore(allStore);
                  }}
                />))}
              </Grid>
            </Grid>: null}

            {RadioParentCheck > 3 && store.length > 0?<Grid container={true} columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }} xs={12} sm={12} md={12} lg={10} className="mt-4">
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectBusiness2} onChange={(e) => setSelectBusiness2(e.target.value)}
                  >
                  {selectedBusiness?.map((select, index) => {
                    let business = businessType.find((business) => business.id === select);
                    return (
                      <MenuItem value={business.id} key={`selectBusiness2-${index}`} >
                        {getdataByKey( business?.business_type_locales, "en", "name" )}
                      </MenuItem>
                    );
                  })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Stores</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectStore1} onChange={(e) => setSelectStore1(e.target.value)}
                  >
                  {store.filter((sto) => sto.businessId === selectBusiness2).map((sto) => (
                    <MenuItem value={sto.id} key={`selectStore1-${sto.id}`} >{sto.name}</MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Select Category" value={categorySearch}
                    placeholder="Enter your Category Name which you want to search"
                    onClick={() =>setIsCategoryDivVisibleAutocomplate(!isCategoryDivVisibleAutocomplate)}
                    onChange={(e) =>setCategorySearch(e.target.value)}
                  />
                  {isCategoryDivVisibleAutocomplate && (
                  <ul className="show-hide-div">
                    {categoryList && categoryList.length > 0 ? categoryList.map((item, index) => (<li
                      key={`categoryList-${index}`}
                      onClick={() => {
                        const allCategory = [...category];
                        let index = allCategory.findIndex((category) =>category.id === item?.id);
                        if (index === -1) {
                          allCategory.push(item);
                        } else {
                          allCategory.splice(index, 1);
                        }
                        setCategory(allCategory);
                      }}
                      className={category.findIndex((category) => category.id === item?.id) !== -1 ? "active" : ""}
                    >{item?.name}</li>)): null}
                  </ul>)}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {category.map((cat) => (<Chip
                  key={`category-chip-${cat.id}`} label={cat.name} sx={{ mr: 1, mt: 2 }}
                  onDelete={() => {
                    const allCategory = [...category];
                    let index = allCategory.findIndex((category) => category.id === cat.id);
                    if (index !== -1) {allCategory.splice(index, 1);}
                    setCategory(allCategory);
                  }}
                />))}
              </Grid>
            </Grid>: null}
            {RadioParentCheck > 4 && category.length > 0?<Grid container={true} columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }} xs={12} sm={12} md={12} lg={10} className="mt-4">
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectBusiness3} onChange={(e) => setSelectBusiness3(e.target.value) }
                  >
                  {selectedBusiness?.map((select, index) => {
                    let business = businessType.find((business) => business.id === select);
                    return (
                      <MenuItem value={business.id} key={`selectBusiness3-${index}`} >
                        {getdataByKey( business?.business_type_locales, "en", "name" )}
                      </MenuItem>
                    );
                  })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Stores</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectStore2} onChange={(e) => setSelectStore2(e.target.value)}
                  >
                  {store.filter((sto) => sto.businessId === selectBusiness3).map((sto) =>
                    <MenuItem value={sto.id} key={`selectStore2-${sto.id}`} >{sto.name}</MenuItem>
                  )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label" id="demo-simple-select" label="Business Type"
                    value={selectCategory1} onChange={(e) => setSelectCategory1(e.target.value)}
                  >
                    {category.filter((sto) => sto.storeId === selectStore2).map((cat) =>
                      <MenuItem value={cat.id} >{cat.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Select Product" value={productSearch} id="productSearch"
                    placeholder="Enter your product name which you want to search"
                    onClick={() =>setIsProductDivVisibleAutocomplate( !isProductDivVisibleAutocomplate )}
                    onChange={(e) =>setProductSearch(e.target.value)}
                  />
                  {isProductDivVisibleAutocomplate && (
                    <ul className="show-hide-div">
                      {product && product.length > 0? product.map((item, index) => (
                      <li
                        key={`product-${index}`}
                        onClick={() => {
                          const allProduct = [...products,];
                          let index = allProduct.findIndex((prod) =>prod.id === item?.id);
                          if (index === -1) {
                            allProduct.push(item);
                          } else {
                            allProduct.splice(index,1);
                          }
                          setProducts(allProduct);
                        }}
                      >{item?.name}</li>)): null}
                    </ul>
                  )}
                </FormControl>
              </Grid>
              <Grid  item xs={12}>
                {products.map((selectedProd, index) => (
                <Chip
                  key={`product-chip-${index}`} label={selectedProd.name} sx={{ mr: 1, mt: 2 }}
                  onDelete={() => {
                    const allProduct = [...products];
                    allProduct.splice(index, 1);
                    setProducts(allProduct);
                  }}
                />))}
              </Grid>
            </Grid>: null}
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => handleSave()} type="button" variant="contained"
              size="medium" sx={{ width: "150px", marginTop: "20px" }}
            >Save</Button>
          </Box>
          {openThankyou?<ThankyouOffer
            isOpen={openThankyou}
            handleClose={() => setOpenThankyou(false)}
          />: null}
        </Box>
      </Box>
    </>
  );
};

export default AddOffer;
