import React, { useState, useEffect } from "react";
import {Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { API } from "../../../ENUM";
import GlobalService from "../../../../services/GlobalService";
import PickerIdleRow from "../TrackingTabRow/PickerIdle";

const PickerIdle = ({ picker_state, search }) => {
  
  const limit = 20;
  const [pickers, setPickers] = useState([]);
  const [count, setCount] = useState(0);
  let [page, setPage] = useState(1);

  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllOrder();  
    }, 500);
    return () => clearTimeout(getData);
  }, [search, picker_state]);
 
  const getAllOrder = async () => {
    const object = { page, limit, search, picker_state };
    const query = new URLSearchParams(object);
    try {
      let url = API.PICKER_TRACKING.GET_PICKER_IDLE(query);
      const response = await GlobalService(url);    
      setPickers(response.picker);
      setCount(Math.ceil(response?.count / limit || 1));
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllOrder();
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Picker Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Event type</TableCell>
              <TableCell>Last Picked Order</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pickers?.map((row, index) => (
              <PickerIdleRow key={`${row?.id}-${index}`} row={row} /> ))}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 1 ? 
        <Pagination count={count} page={page} onChange={handlePagination} color="primary" />: null}
    </>
  );
};
export default PickerIdle;
