import React, { useState, useEffect } from "react";
import { useNavigate, useParams, createSearchParams, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getStoreNameFromLocale,
  getStoreNameBusinessFromLocale,
} from "../../helper/custom";
import { ImageCropper, imagesToBase64, base64toFile } from "../../../dialog/Cropper";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Alert,
  IconButton 
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import updateStateWithValidation, {
  stateWithValidation,
} from "../../helper/Validation";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import modalClose from "../../../assets/images/structure/close-modal.png";
import plusIcon from "../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../assets/images/structure/minus-icon.svg";
import constant from "../../../../src/constant";
import { checkIfImageExists } from "../../helper/custom";
import getLatLng from "../../global/getLatLng";
import {
  convertTimeTo12Hour, generateHours, arrangeDaysInWeek
} from "../../helper/custom";
import { currentPermission } from "../../../redux/reducers/permission";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useSelector, useDispatch } from "react-redux";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { blobToBase64 } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function StoreListingDetail() {

  const permission = useSelector(currentPermission);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  let [canEdit, setCanEdit] = useState(true);
  let [canApprove, setCanApprove] = useState(true);
  let [canAssign, setCanAssign] = useState(true);
  const navigate = useNavigate();
  const { id, type, page } = useParams();
  const [regIdErr, setRegIdErr] = useState(false);
  let [cropperData, setCropperData] = useState({
    croppedImage: "",
    isOpen: false,
  });
  let [cropperData2, setCropperData2] = useState({
    croppedImage: "",
    isOpen: false,
  });
  const [businessLogo, setBusinessLogo] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });
  let [bannerImage, setBannerImage] = useState({
    file: "",
    isValid: false,
    err: "Please upload Banner Image",
  });
  const [rejection, setRejection] = useState({
    isOpen: false,
    err: "",
    val: "",
    isValid: false,
    id: null,
  });
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [isValidate, setIsValidate] = useState(false);
  const [businesstypeList, setBusinesstypeList] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState({
    val: null,
    name: "",
    err: "required",
    isValid: false,
  });
  const [OpenDate, setOpenDate] = useState("10:00 am");
  const [CloseDate, setCloseDate] = useState("06:00 pm");
  const [geoBusinessAddress, setGeoBusinessAddress] = useState("");
  // business detail
  const [date, setDate] = useState("");
  const [isOnline, setIsOnline] = useState("online");
  const [companyRegIdList, setCompanyRegIdList] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [addImages, setAddImages] = useState([]);
  const [brandName, setBrandName] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [brandAcountManager, setBrandAcountManager] = useState("");
  const [brandNameArabic, setBrandNameArabic] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessName, setBusinessName] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [arabicStoreName, setArabicStoreName] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessEmail, setBusinessEmail] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessAddress, setBusinessAddress] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessZone, setBusinessZone] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessStreet, setBusinessStreet] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessBuilding, setBusinessBuilding] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessCountry, setBusinessCountry] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessLongitute, setBusinessLongitute] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [businessLatitude, setBusinessLatitude] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  //   user detail
  const [ownerName, setOwnerName] = useState({
    val: "",
    err: "",
    isValid: false,
  });

  
  const [storeUser, setStoreUser] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [ownerContact, setOwnerContact] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [storeContact, setStoreContact] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [ownerEmail, setOwnerEmail] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  //   user account detail
  const [benificiaryName, setBenificiaryName] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  const [accountNumber, setAccountNumber] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  const [iban, setIban] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  const [swiftCode, setSwiftcode] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  //   system setting
  const [commission, setCommission] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  const [rejectionTime, setRejectionTime] = useState({
    val: "",
    err: "required",
    isValid: false,
  });
  const [deliveryCharge, setDeliveryCharge] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [MinimumOrder, setMinimumOrder] = useState({
    val: "",
    err: "",
    isValid: false,
  });
  const [paginationAllowed, setPaginationAllowed] = useState('no');
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [itemsPerPageError, setItemsPerPageError] = useState('');
  const [customDelivery, setCustomDelivery] = useState('no');
  const [customDeliveryCharge, setCustomDeliveryCharge] = useState(5);
  const [customMinimumOrderValue, setCustomMinimumOrderValue] = useState(0);
  const [customDeliveryChargeError, setCustomDeliveryChargeError] = useState('');
  let [marketPlaceMerchant, setMarketPlaceMerchant] = useState('no');
  let [deliveryPartner, setDeliveryPartner] = useState('tookan');
  localStorage.setItem("isOnline", isOnline);
  const storeTime = generateHours(15);
  // const [workingHoursList, setWorkingHoursList] = useState([]);
  let [open247, setOpen247] = useState(false);
  const [workingHoursList, setWorkingHoursList] = useState([
    {
      day: "sunday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }]
    },
    {
      day: "monday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }],
    },
    {
      day: "tuesday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }],
    },
    {
      day: "wednesday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }],
    },
    {
      day: "thursday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }]
    },
    {
      day: "friday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }]
    },
    {
      day: "saturday",
      open: true,
      time: [{
        starttime: "01:00",
        endtime: "18:00",
        starttimeErr: "",
        endtimeErr: "",
      }]
    },
  ]);
  const [isApproved, setIsApproved] = useState(false);
  const [data, setData] = useState({});
  const [ownerPersnalEmail, setOwnerPersnalEmail] = useState("");

  function removeCompanyReg(index, isNew = false) {
    let arr = [...companyRegIdList];
    if (!isNew) {
      let removedImagesArr = arr.splice(index, 1);
      setRemovedImages([...removedImages, ...removedImagesArr]);
    } else {
      arr.splice(index, 1);
    }
    setCompanyRegIdList(arr);
  }

  useEffect(() => {
    getStoreById(id);
    getAllBusinessType();
  }, [id]);

  const getStoreById = async (id) => {
    try {
      const getData = (await services.Stores.GET_STORE_BY_ID(id)).data.store;


      setData(getData);

      console.log("getData?.brands?.account_manager", getData?.brands?.account_manager);
      setBrandAcountManager(getData?.brands?.account_manager || 'N/A');
      setBrandNameArabic({
        val: getStoreNameBusinessFromLocale(getData.brands.stores_locales, "ar"),
        err: "",
        isValid: false,
      })
      setBrandName({
        val: getStoreNameBusinessFromLocale(getData.brands.stores_locales, "en"),
        err: "",
        isValid: false,
      });
      setBusinessLogo({
        file: getData.business_logo,
        isValid: true,
        err: "",
      });
      setBannerImage({
        file: getData.banner_image,
        isValid: getData.banner_image !== ''?true: false,
        err: "",
      });
      setDate(new Date(getData.createdAt));
      setIsApproved(getData.is_approved);
      setSelectedBusiness({
        val: getData.businessTypeId,
        name: getData?.business_type?.name,
        isValid: true,
        err: "",
      });
      setBusinessAddress({ val: getData.business_address, isValid: true, err: "", });
      setBusinessZone({ val: getData.zone_number, isValid: true, err: "" });
      setBusinessLatitude({ val: getData.latitude, isValid: true, err: "" });
      setBusinessLongitute({ val: getData.longitude, isValid: true, err: "" });
      setBusinessStreet({ val: getData.street_number, isValid: true, err: "" });
      setBusinessBuilding({ val: getData.building_number, isValid: true, err: "", });
      setBusinessCountry({ val: getData.country, isValid: true, err: "" });
      setOwnerPersnalEmail(getData?.brands.user?.other_email);
      setBusinessName({
        val: getStoreNameBusinessFromLocale(getData?.stores_locales, "en"), isValid: true, err: "",
      });
      setArabicStoreName({
        val: getStoreNameBusinessFromLocale(getData?.stores_locales, "ar"), isValid: true, err: "",
      });
      setBusinessEmail({ val: getData.brands.user.email, isValid: true, err: "" });
      // (moment(getData.createdAt, "DD-MM-YYYY HH:mm:ss").format("MM/DD/YYYY"));
      setOwnerName({ val: getData.brands.user.full_name, isValid: true, err: "" });
      setOwnerContact({ val: getData.brands.user.contact_number, isValid: true, err: "" });
      setStoreContact({ val: getData.user.contact_number, isValid: true, err: "" });
      setStoreUser({ val: getData.user.id, isValid: true, err: ""});
      setOwnerEmail({ val: getData.brands.user.email, isValid: true, err: "" });
      setCommission({ val: getData?.admin_commission, isValid: true, err: "" });
      setIsOnline(getData.online_status === 1 ? "online" : "offline");
      setMarketPlaceMerchant(getData?.market_place === 1? 'yes': 'no');
      setDeliveryPartner(getData?.deliveryPartner);
      setPaginationAllowed(getData?.pagination === 1? 'yes': 'no');
      setItemsPerPage(getData?.items_per_page);
      setCustomDelivery(getData?.customDelivery);
      setCustomDeliveryCharge(getData?.customDeliveryCharge);
      setCustomMinimumOrderValue(getData?.minimum_order_value);

      const workingHourData = arrangeDaysInWeek(getData?.manage_working_hours);
      open247 = getData?.open247;
      setOpen247(open247 == 1);
      if (workingHourData.length) {
        setWorkingHoursList(
          workingHourData.map((item) => {
            const time = item.timejson ? JSON.parse(item.timejson) : [];
            return {
              day: item.day,
              open: item.open,
              time: time.map((time) => {
                return {
                  starttime: item.open? time.starttime: '01:00',
                  endtime: item.open? time.endtime === '23:59'?'24:00':time.endtime: '18:00',
                  starttimeErr: "",
                  endtimeErr: "",
                };
              })
            };
          })
        );
      }
      setBenificiaryName({ val: getData?.payout_detail?.beneficiaryName, isValid: true, err: "",});
      setAccountNumber({ val: getData?.payout_detail?.bankAccountNumber, isValid: true, err: "", });
      setRejectionTime({ val: getData.order_accept_time, isValid: true, err: "", });
      setIban({ val: getData?.payout_detail?.iban, isValid: true, err: "" });
      setSwiftcode({ val: getData?.payout_detail?.swiftCode, isValid: true, err: "", });
      setCompanyRegIdList(getData?.company_registration_ides);
      setDeliveryCharge({ val: getData?.delivery_charges, isValid: true, err: "", });
      setMinimumOrder({ val: getData?.minimum_order_value, isValid: true, err: "", });
    } catch (error) {
      console.log(error);
    }
  };

  const addTime = (index) => {
    let arr = [...workingHoursList];
    let newArr = [...arr[index].time];
    let prevEndTime = newArr[newArr.length - 1]?.endtime || '00:00';
    let newStartTime = generateHours(15).find(time => parseInt(time.split(':').join('')) > parseInt(prevEndTime.split(':').join('')))
    let newEndTime = generateHours(60).find(time => parseInt(time.split(':').join('')) > parseInt(newStartTime.split(':').join('')))
    newArr.push({
      starttime: newStartTime,
      endtime: newEndTime,
      starttimeErr: "",
      endtimeErr: "",
    });
    arr[index].time = newArr;
    setWorkingHoursList(arr);
  }

  const subTime = (index, timeIndex) => {
    let arr = [...workingHoursList];
    let newArr = [...arr[index].time];
    newArr.splice(timeIndex, 1);
    arr[index].time = newArr;
    setWorkingHoursList(arr);
  }

  const changeTime = (e, type, index, index1) => {
    let temp = [...workingHoursList];
    let currentTimeSlot = workingHoursList[index].time[index1];
    if (type === 'start') {
      let prevEndTime = temp[index].time[index1 - 1]?.endtime || '00:00';
      const startTime = e.target.value;
      const prevEndTimeValue = parseInt(prevEndTime.split(':').join(''));
      const startTimeValue = parseInt(startTime.split(':').join(''));
      currentTimeSlot.starttimeErr = (index1 > 0 && prevEndTimeValue > startTimeValue)
        ? "Shift time are overlapping" : '';
      currentTimeSlot.starttime = startTime;
    } else {
      let nextStartTime = temp[index].time[index1 + 1]?.starttime;
      const endTime = e.target.value;
      const currentStartTimeValue = parseInt(currentTimeSlot.starttime.split(':').join(''));
      const endTimeValue = parseInt(endTime.split(':').join(''));
      const nextStartTimeValue = parseInt(nextStartTime?.split(':').join(''));
      currentTimeSlot.endtimeErr = (currentStartTimeValue > endTimeValue || nextStartTimeValue < endTimeValue)?
      "Shift time are overlapping": '';
      currentTimeSlot.endtime = endTime;
    }
    temp[index].time.splice(index1, 1, currentTimeSlot);
    setWorkingHoursList(temp)
  }

  const getAllBusinessType = async () => {
    const query = null;
    try {
      const data = (
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY(query)
      ).data.businesstype.rows;
      if (data) {
        setBusinesstypeList(data);
      }
    } catch (error) {
      console.log("Error_In_store_type", error);
    }
  };

  const businessNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessName);
    setBusinessName(updatedState);
  };

  const storeArabicNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessName);
    setArabicStoreName(updatedState);
  };

  const businessEmailChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, businessEmail, type);
    setBusinessEmail(updatedState);
  };

  const businessZoneChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessZone);
    setBusinessZone(updatedState);
  };

  const businessStreetChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessStreet);
    setBusinessStreet(updatedState);
  };

  const businessBuildingChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessBuilding);
    setBusinessBuilding(updatedState);
  };

  const businessLongChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessLongitute);
    setBusinessLongitute(updatedState);
  };

  const businessLatChange = (e) => {
    const updatedState = updateStateWithValidation(e, businessLatitude);
    setBusinessLatitude(updatedState);
  };

  const ownerNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, ownerName);
    setOwnerName(updatedState);
  };

  const ownerContactChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, ownerContact, type);
    setOwnerContact(updatedState);
  };
  const storeContactChange = (e, type) => {
    const updatedState = updateStateWithValidation(e, storeContact, type);
    setStoreContact(updatedState);
  };
  const storeStoreAddress = (e, type) => {
    const updatedState = updateStateWithValidation(e, businessAddress, type);
    setBusinessAddress(updatedState);
  };
  // const storeBusinessAdddress = (e, type) => {
  //   const updatedState = updateStateWithValidation(e, storeContact, type);
  //   setStoreContact(updatedState);
  // };
  // account details
  const benificiaryNameChange = (e) => {
    const updatedState = updateStateWithValidation(e, benificiaryName);
    setBenificiaryName(updatedState);
  };

  const accountNumberChange = (e) => {
    const updatedState = updateStateWithValidation(e, accountNumber);
    setAccountNumber(updatedState);
  };

  const ibanChange = (e) => {
    const updatedState = updateStateWithValidation(e, iban);
    setIban(updatedState);
  };

  const swiftcodeChange = (e) => {
    const updatedState = updateStateWithValidation(e, swiftCode);
    setSwiftcode(updatedState);
  };

  const handleRejection = async () => {
    try {
      if (rejection.val.trim() === '') {
        setRejection({ ...rejection, isValid:false, err: 'Reject reason is required' });
        return false
      }
      const body = {
        store: {
          is_approved: false,
          reason_of_reject: rejection.val,
        },
      };
      const bodyFormData = new FormData();
      Object.keys(body).forEach((key) => {
        bodyFormData.append(key, JSON.stringify(body[key]));
      });
      const response = await services.Stores.UPDATE_STORE(
        rejection.id,
        bodyFormData
      );
      if (response?.data?.ack == 1) {
        setRejection({ ...rejection, isOpen: false });
        setToaster({
          status: "error",
          text: `Store Request Rejected`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
          navigate("/store-request");
        }, 2500);
      }
    } catch (error) {
      setRejection({ ...rejection, isOpen: false });
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ ...toaster, visibilty: false });
      }, 2500);
    }
  };

  const onSaveHandler = async () => {
    // for checking store is already approved or not
    if (isApproved) {
      let validation;
      if (companyRegIdList.length == 0) setRegIdErr(true);
      if (paginationAllowed === 'yes') {
        if (itemsPerPage === '') {
          dispatch(changeSnackbar({
            isOpen: true,
            state: "error",
            message: "Per page items can not be empty"
          }))
          return false;
        } else if (itemsPerPage < 10){
          dispatch(changeSnackbar({
            isOpen: true,
            state: "error",
            message: "Per page items can not be less then 10"
          }))
          return false;
        }
      }

      if (customDelivery === 'yes') {
        if (customDeliveryCharge === '') {
          dispatch(changeSnackbar({
            isOpen: true,
            state: "error",
            message: "Delivery Charge can not be empty"
          }))
          return false;
        } else if (customDeliveryCharge < 0 || customDeliveryCharge > 10) {
          dispatch(changeSnackbar({
            isOpen: true,
            state: "error",
            message: "Delivery Charge can not be less then 0 or greater then 10"
          }))
          return false;
        }
      }

      const updatedData = {
        store: {
          businessTypeId: selectedBusiness.val,
          business_name: businessName.val,
          business_name_arabic: arabicStoreName.val,
          zone_number: businessZone.val,
          street_number: businessStreet.val,
          building_number: businessBuilding.val,
          latitude: businessLatitude.val,
          longitude: businessLongitute.val,
          business_address : businessAddress.val,
          contact_number: ownerContact.val,
          country: businessCountry.val,
          admin_commission: commission.val,
          order_accept_time: rejectionTime.val,
          open247: open247? 1: 0,
          market_place: marketPlaceMerchant === 'yes'? 1: 0,
          deliveryPartner: deliveryPartner,
          cuisineId:[],
          delivery_charges: deliveryCharge.val,
          minimum_order_value: MinimumOrder.val,
          pagination: paginationAllowed === 'yes'? 1: 0,
          items_per_page: itemsPerPage,
          customDelivery: customDelivery,
          customDeliveryCharge: customDeliveryCharge,
          minimum_order_value: customMinimumOrderValue,
        },
        user: {
          contact_number: storeContact.val,
          id : storeUser.val
        },
        brand: {
          brand_name: brandName.val,
          brand_name_arabic: brandNameArabic.val,
          full_name: ownerName.val,
          email: businessEmail.val,
          contact_number: ownerContact.val,
        },
        // {
        //   id: data.userId,
        //   email: businessEmail.val,
        //   contact_number: ownerContact.val,
        //   commission: 0,
        //   full_name: ownerName.val,
        // },
        payoutDetail: {},
        workingHour: [],
      };
  
      const reqValidation =
        selectedBusiness.isValid &&
        businessName.isValid &&
        businessEmail.isValid &&
        businessZone.isValid &&
        businessStreet.isValid &&
        businessBuilding.isValid &&
        businessCountry.isValid &&
        commission.isValid &&
        rejectionTime.isValid &&
        ownerContact.isValid &&
        companyRegIdList.length > 0;
  
      if (type === "list") {
        //validation
        let listValidation =
          benificiaryName.isValid &&
          accountNumber.isValid &&
          iban.isValid &&
          swiftCode.isValid;
        validation = listValidation && reqValidation;
        // data
        updatedData.payoutDetail.bankAccountNumber = accountNumber.val;
        updatedData.payoutDetail.beneficiaryName = benificiaryName.val;
        updatedData.payoutDetail.iban = iban.val;
        updatedData.payoutDetail.swiftCode = swiftCode.val;
        updatedData.commission = 0;
        if (isOnline) {
          updatedData.store.online = isOnline;
          isOnline == 'online' ? updatedData.store.online_status = 1 : updatedData.store.online_status = 0;
        }
        if (workingHoursList) {
          let updatedWorkingHours = workingHoursList.map((item) => {
            return {
              day: item.day,
              open: item.open,
              time: item.time.map((time) => {
                return {
                  starttime: time.starttime,
                  endtime: time.endtime,
                };
              }),
            }
          });
          updatedData.workingHour = JSON.stringify(updatedWorkingHours);
        }
      } else {
        updatedData.store.is_approved = true;
        validation = reqValidation;
      }
      if (removedImages) {
        updatedData.remove_images = removedImages;
      }
      if (validation) {
        try {
          const bodyFormData = new FormData();
          Object.keys(updatedData).forEach((key) => {
            bodyFormData.append(key, JSON.stringify(updatedData[key]));
          });
          if (businessLogo && businessLogo.file) {
            bodyFormData.append("business_logo", businessLogo.file);
          }
          if (addImages) {
            for (let index = 0; index < addImages.length; index++) {
              const element = addImages[index];
              bodyFormData.append("image", element.file);
            }
          }
          if (bannerImage && bannerImage.file) {
            bodyFormData.append("banner_image", bannerImage.file);
          }
          const response = await services.Stores.UPDATE_STORE(id, bodyFormData);
          // response.data.ack
          if (response?.data?.ack === 1) {
            dispatch(changeSnackbar({
              isOpen: true,
              state: "success",
              message: "Store Updated Successfuly"
            }))
            if (type === "request") {
              navigate(`/store-request?${searchParams.toString()}`);
            } else {
              navigate(`/store-listing?${searchParams.toString()}`);
            }
          } else {
            let message = "";
            if (response?.data?.errMsg?.length > 0) {
              response?.data?.errMsg.forEach(element => {
                for (const [key, value] of Object.entries(element)) {
                  message += `${value} \n`;
                }
              });
            } else {
              message = response?.data?.msg;
            }
            setToaster({
              status: "error",
              text: message,
              visibilty: true,
            });
            setTimeout(() => {
              setToaster({
                status: "",
                text: ``,
                visibilty: false,
              });
            }, 6000);
          }
        } catch (error) {
          setToaster({
            status: "error",
            text: `${error}`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({
              status: "",
              text: ``,
              visibilty: false,
            });
          }, 2500);
        }
      } else {
        setIsValidate(true);
      }
    } else {
      const response = await GlobalService(API.STORE_REQUEST.APPROVE(id));
      if (response.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          state: "success",
          message: "Store Updated Successfuly"
        }))
        if (type === "request") {
          navigate(`/store-request?${searchParams.toString()}`);
        } else {
          navigate(`/store-listing?${searchParams.toString()}`);
        }
      } else {
        setToaster({ status: "error", text: `${response.msg}`, visibilty: true });
        setTimeout(() => {
          setToaster({ status: "", text: ``, visibilty: false });
        }, 6000);
      }
    }
  };

  const handleOpen247 = (e) => {
    setOpen247(e.target.checked);
    if (e.target.checked) {
      setWorkingHoursList(
        workingHoursList.map((item) => {
          return {
            ...item,
            time: [{
              starttime: "00:00",
              endtime: "23:59",
              starttimeErr: "",
              endtimeErr: "",
            }],
          };
        })
      );
    }
  }

  useEffect(() => {
    if (businessZone?.val && businessStreet?.val && businessBuilding?.val) {
      getLatLng(
        businessZone.val,
        businessStreet.val,
        businessBuilding.val,
        async (obj) => {
          if (obj.ack == 1) {
            let geo = await geocode(obj.lat, obj.lng);
            setGeoBusinessAddress(geo);
          }
        }
      );
    }
  }, [businessZone, businessStreet, businessBuilding]);

  useEffect(() => {
    if (permission.role !== "admin") {
      if (type === "request") {
        setCanEdit(permission?.storeRequest.edit === 1);
        setCanApprove(permission?.storeRequest.actions.approveReject === 1);
      } else {
        setCanEdit(permission?.storeListing?.edit === 1);
      }
    } else {
      setCanEdit(true);
      setCanApprove(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Store Details
                </Typography>
              </Grid>
              <Grid item xs={4} align="right">
                {isApproved === false ? (
                  <div>
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      disabled={
                        isApproved === false
                          ? true
                          : false || commission.val === 0
                          ? true
                          : false
                      }
                      // onClick={() => handleRejection()}
                    >
                      Rejected
                    </Button>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <div className="left-side">

                  <div className="upload-img">
                    {!(businessLogo && businessLogo.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Image</p>
                        <input
                          type="file"
                          id="businessLogo"
                          accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        {isApproved?
                        <EditIcon
                          className="delete-icon"
                          onClick={() => {
                            document.getElementById("businessLogo").click();
                          }}
                        />: null}
                        <input
                          type="file"
                          id="businessLogo"
                          accept="image/png, image/jpeg"
                          // multiple
                          onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData(cropperData);
                            }
                          }}
                        />
                        {businessLogo.file.type ? (
                          <img
                            src={URL.createObjectURL(businessLogo.file)}
                            alt=".."
                            className="upload-icon"
                          />
                        ) : (
                          <img
                            src={checkIfImageExists(
                              constant?.IMG_BASE_URL + businessLogo?.file
                            )}
                            // src={URL.createObjectURL(businessLogo.file)}
                            alt=".."
                            className="upload-icon"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {!businessLogo.isValid && isValidate && (
                    <div className="error">{businessLogo.err}</div>
                  )}
                  <div
                    className="owner-detail"
                    align="center"
                    style={{ margin: "20px 0px" }}
                  >
                    <p className="name-text mb-2">
                      {data?.business_name ? data?.business_name : ""}
                    </p>
                    {selectedBusiness && selectedBusiness.val && (
                      <p className="name-text mb-2" key={selectedBusiness.val}>
                        {data?.business_type?.name
                          ? data?.business_type?.name
                          : ""}
                      </p>
                    )}

                    <p className="number-text">{data?.business_address}</p>
                  </div>

                  <div className="upload-img-banner"
                    style={isApproved?
                      { pointerEvents: 'auto', opacity: '1' }:
                      { pointerEvents: 'none', opacity: '0.4' }
                    }
                  >
                    {!(bannerImage && bannerImage.isValid) ? (
                      <div className="before-upload">
                        <img src={uploadImg} alt=".." className="upload-icon" />
                        <p className="upload-text">Upload Banner Image</p>
                        <input type="file" accept="image/png, image/jpeg" onChange={async (e) => {
                            const imageType = e.target.files[0].type;
                            if (
                              imageType === "image/png" ||
                              imageType === "image/jpeg" ||
                              imageType === "image/jpg" ||
                              imageType === "application/msword" ||
                              imageType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                              imageType === "application/pdf"
                            ) {
                              const file = e.target.files[0];
                              const compressedFile = await imageCompression(file, options);
                              const imageUrl = await blobToBase64(compressedFile);
                              cropperData2 = {
                                croppedImage: imageUrl,
                                isOpen: true,
                              };
                              setCropperData2(cropperData2);
                              
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="after-upload">
                        <CancelIcon
                          className="delete-icon"
                          onClick={() => {
                            setBannerImage({
                              file: "",
                              isValid: false,
                              err: "Please upload business logo",
                            });
                          }}
                        />
                        {bannerImage?.file?.type ? (
                          <img
                            src={URL.createObjectURL(bannerImage.file)}
                            alt=".."
                            className="upload-icon"
                          />
                        ) : (
                          <img
                            src={checkIfImageExists(
                              constant?.IMG_BASE_URL + bannerImage?.file
                            )}
                            
                            alt=".."
                            className="upload-icon"
                          />
                        )}
                      </div>
                    )}
                    {!bannerImage.isValid && isValidate && (
                      <div className="error">{bannerImage.err}</div>
                    )}
                  </div>

                  {isApproved === true && (
                    <Box className="alert-setting" mt={1} mb={2}>
                      <FormControlLabel
                        value="Status"
                        control={
                          <Switch
                            color="primary"
                            // defaultChecked={isOnline}
                            checked={isOnline == "online"}
                            onClick={(e) =>
                              setIsOnline(
                                isOnline == "online" ? "offline" : "online"
                              )
                            }
                          />
                        }
                        disabled={!canEdit}
                        label={isOnline == "online" ? "Online" : "Offline"}
                        labelPlacement="start"
                        className="switch-row"
                      />
                    </Box>
                  )}
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                >Brand Information</Typography>
                <Grid container spacing={3} mb={4} disabled="true">
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Brand Name - English"
                      disabled={!isApproved || !canEdit}
                      onChange={(e) => {
                        setBrandName({
                          ...brandName,
                          val: e.target.value,
                          err: "",
                          isValid: true,
                        });
                      }}
                      value={brandName.val}
                    />
                    {!brandName.isValid && isValidate && (
                      <div className="error">{brandName.err}</div>
                    )}
                    {/*  helperText="Max limit 256 characters" */}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Brand Name - Arabic"
                      disabled={!isApproved || !canEdit}
                      onChange={(e) => {
                        setBrandNameArabic({
                          ...brandNameArabic,
                          val: e.target.value,
                          err: "",
                          isValid: true,
                        });
                      }}
                      value={brandNameArabic.val}
                    />
                    {!brandNameArabic.isValid && isValidate && (
                      <div className="error">{brandNameArabic.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Account Manager"
                      disabled
                      
                      value={brandAcountManager}
                    />
                   
                  </Grid>

                  
                </Grid>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                > Store Information </Typography>
                <Grid container spacing={3} mb={4} disabled="true">
                  
                <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Store Contact Number"
                      disabled={!isApproved || !canEdit}
                      value={storeContact.val}
                      onChange={(e) => storeContactChange(e, "contact")}
                    />
                    {!storeContact.isValid && isValidate && (
                      <div className="error">{storeContact.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Store Name"
                      disabled={!isApproved || !canEdit}
                      value={businessName.val}
                      onChange={(e) => {
                        businessNameChange(e);
                      }}
                    />
                    {!businessName.isValid && isValidate && (
                      <div className="error">{businessName.err}</div>
                    )}
                    {/*  helperText="Max limit 256 characters" */}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Store Name Arabic"
                      disabled={!isApproved || !canEdit}
                      value={arabicStoreName.val}
                      onChange={(e) => {
                        storeArabicNameChange(e);
                      }}
                    />
                    {!arabicStoreName.isValid && isValidate && (
                      <div className="error">{arabicStoreName.err}</div>
                    )}
                    {/*  helperText="Max limit 256 characters" */}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      disabled={!isApproved}
                      key={selectedBusiness.val}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Store Type
                      </InputLabel>
                      <Select
                        value={selectedBusiness?.val}
                        labelId="demo-simple-select-label"
                        id="store-type-select"
                        label="Store Type *"
                        disabled={true}
                        onChange={(e) => {
                          if (e.target.value && e.target.value !== 0) {
                            setSelectedBusiness({
                              ...selectedBusiness,
                              err: "",
                              val: e.target.value,
                              isValid: true,
                            });
                          } else {
                            setSelectedBusiness({
                              ...selectedBusiness,
                              err: "Required",
                              val: e.target.value,
                              isValid: false,
                            });
                          }
                        }}
                      >
                        <MenuItem value={0}>Select Business Type</MenuItem>
                        {businesstypeList?.map((item, index) => (
                          <MenuItem value={item.id} key={`businesstypeList-${index}`}>
                            {getStoreNameFromLocale(
                              item?.business_type_locales
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!selectedBusiness.isValid && isValidate && (
                      <div className="error">{selectedBusiness.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Registered Date"
                        disabled="true"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      disabled={!isApproved || !canEdit}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Market Place Merchant
                      </InputLabel>
                      <Select
                        value={marketPlaceMerchant}
                        labelId="demo-simple-select-label"
                        id="store-type-select"
                        label="Market Place Merchant"
                        onChange={(e) => {
                          setMarketPlaceMerchant(e.target.value);
                        }}
                      >
                        <MenuItem value={'no'}>NO</MenuItem>
                        <MenuItem value={'yes'}>YES</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      disabled={!isApproved || !canEdit}
                    >
                      <InputLabel id="demo-simple-select-label">
                      Delivery Partner
                      </InputLabel>
                      <Select
                        value={deliveryPartner}
                        labelId="demo-simple-select-label"
                        id="store-type-select"
                        label="Delivery Partner"
                        onChange={(e) => {
                          setDeliveryPartner(e.target.value);
                        }}
                      >
                        <MenuItem value={'tookan'}>Tookan</MenuItem>
                        <MenuItem value={'shipsy'}>Shipsy</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      disabled={!isApproved || !canEdit}
                    >
                   
                      <TextField
                      required
                      id="outlined-required"
                      label="Store Business Address"
                      disabled={!isApproved || !canEdit}
                      value={businessAddress.val}
                      onChange={(e) => storeStoreAddress(e, "")}
                    />
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  fontWeight={500}
                >
                  Store Address
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Enter Zone Number"
                      disabled={!isApproved || !canEdit}
                      value={businessZone.val}
                      onChange={(e) => businessZoneChange(e)}
                    />
                    {!businessZone.isValid && isValidate && (
                      <div className="error">{businessZone.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Street Number"
                      disabled={!isApproved || !canEdit}
                      value={businessStreet.val}
                      onChange={(e) => businessStreetChange(e)}
                    />
                    {!businessStreet.isValid && isValidate && (
                      <div className="error">{businessStreet.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Building Number"
                      disabled={!isApproved || !canEdit}
                      value={businessBuilding.val}
                      onChange={(e) => businessBuildingChange(e)}
                    />
                    {!businessBuilding.isValid && isValidate && (
                      <div className="error">{businessBuilding.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Country"
                      disabled="true"
                      value={businessCountry.val}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Latitude"
                      disabled={!isApproved || !canEdit}
                      value={businessLatitude.val}
                      onChange={(e) => businessLatChange(e)}
                      
                    />
                    
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Longitute"
                      disabled={!isApproved || !canEdit}
                      value={businessLongitute.val}  
                      onChange={(e) => businessLongChange(e)}
                      keyboardType="numeric"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="uploads-row"
                      style={isApproved?
                        { pointerEvents: 'auto', opacity: '1' }:
                        { pointerEvents: 'none', opacity: '0.4' }
                      }
                    >
                      {companyRegIdList && companyRegIdList.length < 3 && (
                        <div className="upload-multiple">
                          {" "}
                          <img
                            src={uploadImg}
                            alt=".."
                            className="upload-icon"
                          />
                          <p className="upload-text">
                            Company registration ID
                            <span style={{ color: "red" }}>*</span>
                          </p>
                          <span>JPEG or PNG (3 files)</span>
                          <input
                            type="file"
                            accept=".png,.jpeg,.jpg,.png,.doc,.docx,.pdf"
                            onChange={(e) => {
                              const imageType = e.target.files[0].type;
                              if (
                                imageType === "image/png" ||
                                imageType === "image/jpeg" ||
                                imageType === "image/jpg" ||
                                imageType === "application/msword" ||
                                imageType ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                imageType === "application/pdf"
                              ) {
                                setCompanyRegIdList((companyRegIdList) => [
                                  ...companyRegIdList,
                                  {
                                    index: parseInt(Math.random() * 1000),
                                    file: e.target.files[0],
                                    name: e.target.files[0].name,
                                  },
                                ]);
                                setAddImages((addImages) => [
                                  ...addImages,
                                  {
                                    index: parseInt(Math.random() * 1000),
                                    file: e.target.files[0],
                                    name: e.target.files[0].name,
                                  },
                                ]);
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {companyRegIdList &&
                        companyRegIdList.map((item, index) => {
                          return (

                              <Grid
                                container
                                spacing={2}
                                style={{
                                  backgroundColor: "#4caf77",
                                  borderRadius: "10px",
                                  padding: "5px",
                                  color: "white",
                                  marginTop: "5px",
                                }}
                                key={`companyRegIdList-${index}`}
                              >
                                <Grid
                                  style={{ paddingTop: "0px" }}
                                  item
                                  xs="11"
                                >
                                  <div
                                    onClick={() => {
                                      if (item?.image)
                                        window.open(
                                          `${constant?.IMG_BASE_URL}${item?.image}`
                                        );
                                    }}
                                  >
                                    {item?.name
                                      ? item.name
                                      : item?.image
                                      ? item?.image.substring(
                                          item?.image.lastIndexOf("/") + 1,
                                          item?.image.length
                                        )
                                      : ""}
                                  </div>
                                </Grid>
                                <Grid style={{ paddingTop: "0px" }} item xs="1">
                                  {isApproved?<span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (item.file) {
                                        removeCompanyReg(index, true);
                                        let imageArr = [...addImages];
                                        imageArr.splice(index, 1);
                                        setAddImages(imageArr);
                                      } else if (item.image) {
                                        removeCompanyReg(index);
                                      }
                                    }}
                                  >
                                    {" "}
                                    X{" "}
                                  </span>: null}
                                </Grid>
                              </Grid>
                            
                          );
                        })}
                      {companyRegIdList && companyRegIdList.length > 3 && (
                        <div className="error">Maximum 3 files allowed</div>
                      )}{" "}
                      {regIdErr &&
                        companyRegIdList &&
                        companyRegIdList.length == 0 && (
                          <div className="error">Required</div>
                        )}
                    </div>
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  mb={3}
                  mt={3}
                  fontWeight={500}
                >
                 Brand Owner Information   
                </Typography>
                <Grid container spacing={3} mb={4}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Full Name"
                      disabled={!isApproved || !canEdit}
                      value={ownerName.val}
                      onChange={(e) => ownerNameChange(e, ownerName)}
                    />
                  </Grid>
                  {!ownerName.isValid && isValidate && (
                    <div className="error">{ownerName.err}</div>
                  )}
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Email Address"
                      disabled={!isApproved || !canEdit}
                      value={businessEmail.val}
                      onChange={(e) => businessEmailChange(e, "email")}
                    />
                    {!businessEmail.isValid && isValidate && (
                      <div className="error">{businessEmail.err}</div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Business Contact Number"
                      disabled={!isApproved || !canEdit}
                      value={ownerContact.val}
                      onChange={(e) => ownerContactChange(e, "contact")}
                    />
                    {!ownerContact.isValid && isValidate && (
                      <div className="error">{ownerContact.err}</div>
                    )}
                  </Grid>

                  {/* <Grid item xs={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Owner Email Address"
                      disabled="true"
                      value={ownerPersnalEmail}
                    />
                  </Grid> */}
                </Grid>

                { (
                  <div
                    style={isApproved !== true?{
                      pointerEvents: "none",
                      opacity: "0.4",
                    }:{}}
                  >
                    <Divider />
                    <Typography variant="h6" component="h6" fontSize={"16px"} mb={3} mt={3} fontWeight={500}>Working Hours</Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox
                            checked={open247}
                            disabled={(isApproved === false) || !canEdit}
                          />}
                          onChange={handleOpen247}
                          label={'Open 24/7'}
                        />
                        <Divider />
                      </Grid>
                      <Grid item xs={12} className={open247?'disable-hours': ''}>
                        {workingHoursList && workingHoursList?.length > 0 &&
                        workingHoursList.sort((a, b) => { if (a.ind < b.ind) { return -1; } else { return 1; } })
                        .map((item, ind) => <div className="time-row-working" key={`row-working-${ind}`}>
                          <FormControlLabel
                            control={<Checkbox checked={item.open} />}
                            onChange={(e) => {
                              let updatedWorkingHours = [...workingHoursList];
                              updatedWorkingHours[ind].open = e.target.checked;
                              setWorkingHoursList(updatedWorkingHours);
                            }}
                            label={item.day}
                          />
                          <Grid>
                            {item?.time.map((time, timeIndex) => 
                            <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }} mb={1} key={`timeIndex-${timeIndex}`}>

                              <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                <FormControl fullWidth={true} sx={{ marginLeft: 2 }} disabled={!item.open}>
                                  <span style={{ textTransform: "capitalize" }} className="p2">Shift {timeIndex + 1}</span>
                                </FormControl>
                              </Grid>
                              <Grid  item xs={6} sm={5}>
                                <FormControl fullWidth={true} sx={{ marginLeft: 2 }} disabled={!item.open}>
                                  <InputLabel id="demo-simple-select-label">Open Time *</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={time.starttime}
                                    label="Open Time *"
                                    required={true}
                                    disabled={(isApproved === false) || !canEdit}
                                    onChange={(e) => { changeTime(e, 'start', ind, timeIndex); }}
                                  >
                                    {storeTime.map((item, index) =>
                                      <MenuItem value={item} key={`Time-${index}`}>{convertTimeTo12Hour(item)}</MenuItem>)}
                                  </Select>
                                  <div className="error">{time.starttimeErr}</div>
                                </FormControl>
                              </Grid>
                              <Grid  item xs={6} sm={5}>
                                <FormControl fullWidth={true} sx={{ marginLeft: 2 }} disabled={!item.open}>
                                  <InputLabel id="demo-simple-select-label">Close Time *</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={time.endtime}
                                    label="Close Time *"
                                    required={true}
                                    disabled={(isApproved === false) || !canEdit}
                                    onChange={(e) => { changeTime(e, 'end', ind, timeIndex); }}
                                  >
                                    {storeTime.map((item, index) =>
                                      <MenuItem value={item} key={`Close-Time-${index}`}>{convertTimeTo12Hour(item)}</MenuItem>)}
                                  </Select>
                                  <div className="error">{time.endtimeErr}</div>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} sm={2} style={{paddingTop: '10px'}}>
                              {timeIndex === 0?
                                item?.time[item?.time.length - 1].endtime !== '23:59'?
                                <IconButton onClick={() => {addTime(ind);}}><img src={plusIcon} alt="" className="" /></IconButton>: null:
                                <IconButton onClick={() => {subTime(ind, timeIndex);}}><img src={minusIcon} alt="" className="" /></IconButton>}
                              </Grid>
                            </Grid>)}
                          </Grid>
                        </div>)}
                      </Grid>
                    </Grid>

                    <Divider />
                    <Typography
                      variant="h6"
                      component="h6"
                      fontSize={"16px"}
                      mb={3}
                      mt={3}
                      fontWeight={500}
                    >
                      Payout Detail
                    </Typography>
                    <Grid container spacing={3} mb={4}>
                      <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Beneficiary Name"
                          value={benificiaryName.val}
                          onChange={(e) => benificiaryNameChange(e)}
                          disabled={(isApproved === false) || !canEdit}
                        />
                        {!benificiaryName.isValid && isValidate && (
                          <div className="error">{benificiaryName.err}</div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Bank Account Number"
                          value={accountNumber.val}
                          onChange={(e) => accountNumberChange(e)}
                          disabled={(isApproved === false) || !canEdit}
                        />
                        {!accountNumber.isValid && isValidate && (
                          <div className="error">{accountNumber.err}</div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="IBAN"
                          value={iban.val}
                          onChange={(e) => ibanChange(e)}
                          disabled={(isApproved === false) || !canEdit}
                        />
                        {!iban.isValid && isValidate && (
                          <div className="error">{iban.err}</div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Swift code"
                          value={swiftCode.val}
                          onChange={(e) => swiftcodeChange(e)}
                          disabled={(isApproved === false) || !canEdit}
                        />
                        {!swiftCode.isValid && isValidate && (
                          <div className="error">{swiftCode.err}</div>
                        )}
                      </Grid>
                    </Grid>

                    <Divider />
                  </div>
                )}
                {(isApproved === true || isApproved === null) && (
                  <div>
                    <Typography
                      variant="h6"
                      component="h6"
                      fontSize={"16px"}
                      mb={3}
                      mt={3}
                      fontWeight={500}
                    >
                      System setting
                    </Typography>
                    <Grid container spacing={3} mb={4}>
                      <Grid item xs={6}>
                        <TextField
                          label="Commission"
                          id="outlined-start-adornment"
                          disabled={!isApproved || !canEdit}
                          value={commission.val}
                          onChange={(e) => {
                            // setIsValidate(true);
                            setCommission(
                              updateStateWithValidation(
                                e,
                                commission,
                                "commission"
                              )
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {!commission.isValid && isValidate && (
                          <div className="error">{commission.err}</div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Rejection time for order"
                          id="outlined-start-adornment"
                          disabled={!isApproved || !canEdit}
                          value={rejectionTime.val}
                          onChange={(e) => {
                            // setIsValidate(true);
                            setRejectionTime(
                              updateStateWithValidation(
                                e,
                                rejectionTime,
                                "rejectionTime"
                              )
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Minute
                              </InputAdornment>
                            ),
                          }}
                        />
                        {!rejectionTime.isValid && isValidate && (
                          <div className="error">{rejectionTime.err}</div>
                        )}
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Delivery Charge"
                          value={deliveryCharge.val}
                          type="number"
                          disabled={!isApproved || !canEdit}
                          onChange={(e) => {
                            setDeliveryCharge({
                              val: e.target.value,
                              isValid: true,
                              err: "",
                            });
                          }}
                          sx={{ margin: "5px 0 15px" }}
                        />
                      </Grid> */}

                      <Grid item xs={6} style={!isApproved?{
                        pointerEvents: 'none',
                        opacity: '0.5'
                      }:{}}>
                        <FormControl fullWidth={true} disabled={!isApproved}>
                          <InputLabel id="demo-simple-select-label">Pagination Allowed</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paginationAllowed}
                            label="Pagination Allowed"
                            disabled={(isApproved === false) || !canEdit}
                            onChange={(e) => { setPaginationAllowed(e.target.value); }}
                          >
                            <MenuItem value={'no'} >No</MenuItem>
                            <MenuItem value={'yes'} >Yes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {paginationAllowed === 'yes' &&
                        <Grid item xs={6}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Items Per Page"
                          value={itemsPerPage}
                          type="number"
                          disabled={!isApproved || !canEdit}
                          onChange={(e) => { 
                            if (e.target.value < 10) {
                              setItemsPerPageError('Pagination can not be less then 10')
                              // if (itemsPerPage < e.target.value) {
                              // }
                              setItemsPerPage(e.target.value);
                            } else {
                              setItemsPerPageError('')
                              setItemsPerPage(e.target.value);
                            }
                          }}
                          sx={{ margin: "5px 0 15px" }}
                        />
                        <div className="error">{itemsPerPageError}</div>
                      </Grid>}

                      <Grid item xs={6} style={!isApproved?{
                        pointerEvents: 'none',
                        opacity: '0.5'
                      }:{}}>
                        <FormControl fullWidth={true} disabled={!isApproved}>
                          <InputLabel id="demo-simple-select-label">Custom Delivery Charge</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customDelivery}
                            label="Custom Delivery Charge"
                            disabled={(isApproved === false) || !canEdit}
                            onChange={(e) => { setCustomDelivery(e.target.value); }}
                          >
                            <MenuItem value={'no'} >No</MenuItem>
                            <MenuItem value={'yes'} >Yes</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {customDelivery === 'yes' &&
                      <>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Delivery Charge"
                            value={customDeliveryCharge}
                            type="number"
                            inputProps={{
                              min: 0,
                              max: 10
                            }}
                            disabled={!isApproved || !canEdit}
                            onChange={(e) => { 
                              if (e.target.value < 0 || e.target.value > 10) {
                                setCustomDeliveryChargeError('Delivery Charge can not be less then 0 or greater then 10')
                                setCustomDeliveryCharge(e.target.value);
                              } else {
                                setCustomDeliveryChargeError('')
                                setCustomDeliveryCharge(e.target.value);
                              }
                            }}
                            sx={{ margin: "5px 0 15px" }}
                          />
                          <div className="error">{customDeliveryChargeError}</div>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Minimum order value"
                            value={customMinimumOrderValue}
                            type="number"
                            disabled={!isApproved || !canEdit}
                            onChange={(e) => setCustomMinimumOrderValue(e.target.value) }
                            sx={{ margin: "5px 0 15px" }}
                          />
                        </Grid>
                      </>
                      }


                      {/* <Grid item xs={5}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Minimum Order Amount"
                          value={MinimumOrder.val}
                          type="number"
                          onChange={(e) => {
                            setMinimumOrder({
                              val: e.target.value,
                              isValid: true,
                              err: "",
                            });
                          }}
                          sx={{ margin: "5px 0 15px" }}
                        />
                      </Grid> */}
                      {/* <Grid item xs={5}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={transectionFee.checked}
                              onChange={(e) => {
                                if (!e.currentTarget.checked) {
                                  setTransectionFee({
                                    val: "",
                                    err: "required",
                                    isValid: false,
                                    checked: e.currentTarget.checked,
                                  });
                                } else {
                                  setTransectionFee({
                                    ...transectionFee,
                                    err: "",
                                    isValid: true,
                                    checked: e.currentTarget.checked,
                                  });
                                }
                              }}
                            />
                          }
                          label="Vendor Will Pay Transaction Fee"
                          sx={{ marginTop: 1, fontSize: "14px" }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          disabled={
                            isApproved === false
                              ? true
                              : !transectionFee.checked
                              ? true
                              : false
                          }
                          // disabled={!transectionFee.checked}
                          label="Transaction Fee Charge From Vendor"
                          id="outlined-start-adornment"
                          value={transectionFee.val}
                          onChange={(e) => {
                            // setIsValidate(true);
                            setTransectionFee({
                              ...transectionFee,
                              ...updateStateWithValidation(
                                e,
                                transectionFee,
                                "commission"
                              ),
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {transectionFee.checked &&
                          !transectionFee.isValid &&
                          isValidate && (
                            <div className="error">{transectionFee.err}</div>
                          )}
                      </Grid> */}
                    </Grid>
                  </div>
                )}
                {isApproved === false ? (
                  <Grid item xs={12}>
                    <InputLabel>Rejection Reason</InputLabel>
                    <TextField
                      required
                      id="outlined-required"
                      disabled="true"
                      value={data.reason_of_reject}
                      sx={{ margin: "5px 0 15px" }}
                    />
                  </Grid>
                ) : null}



                <Divider />
                <div className="bottom-btn-row">
                  <Button
                    variant="standard"
                    size="large"
                    sx={{ marginRight: "10px" }}
                    onClick={() => {
                      if (type === "request") {
                        navigate(`/store-request?${searchParams.toString()}`);
                      } else {
                        navigate(`/store-listing?${searchParams.toString()}`);
                      }
                    }}
                  >
                    Back
                  </Button>
                    
                    {(isApproved === true || isApproved === null) && (

                      <div>
                        
                        {/* canApprove */}
                        { type === "request" && canApprove?
                          <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          // onClick={routerStoreListing}
                          disabled={
                            ((!selectedBusiness?.isValid ||
                              !businessName?.isValid ||
                              !businessEmail?.isValid ||
                              !businessZone?.isValid ||
                              !businessStreet.isValid ||
                              !businessBuilding.isValid ||
                              !businessCountry.isValid ||
                              !accountNumber.isValid ||
                              !benificiaryName.isValid ||
                              !iban.isValid ||
                              !swiftCode.isValid ||
                              !commission.isValid ||
                              !ownerContact.isValid ||
                              !rejectionTime.isValid) &&
                              isValidate) ||
                            companyRegIdList?.length > 3
                          }
                          onClick={() => onSaveHandler()}
                        >
                          {/* Save &  */}
                          {isApproved === null ? "Approve" : "Save"}
                        </Button>:
                        type !== "request" && canEdit ?<Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        // onClick={routerStoreListing}
                        disabled={
                          ((!selectedBusiness?.isValid ||
                            !businessName?.isValid ||
                            !businessEmail?.isValid ||
                            !businessZone?.isValid ||
                            !businessStreet.isValid ||
                            !businessBuilding.isValid ||
                            !businessCountry.isValid ||
                            !accountNumber.isValid ||
                            !benificiaryName.isValid ||
                            !iban.isValid ||
                            !swiftCode.isValid ||
                            !commission.isValid ||
                            !ownerContact.isValid ||
                            !rejectionTime.isValid) &&
                            isValidate) ||
                          companyRegIdList?.length > 3
                        }
                        onClick={() => onSaveHandler()}
                      >
                        {/* Save &  */}
                        {isApproved === null ? "Approve" : "Save"}
                      </Button>: null}
                        
                        {isApproved === null && (
                          <Button
                            variant="outlined"
                            size="medium"
                            color="error"
                            sx={{ marginLeft: "10px" }}
                            onClick={() =>
                              setRejection({
                                ...rejection,
                                isOpen: true,
                                id: id,
                                err: ''
                              })
                            }
                          >
                            Reject
                          </Button>
                        )}
                      </div>
                    )}


                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* REJECTION DIALOG */}
      <Dialog
        open={rejection.isOpen}
        onClose={() => setRejection({ ...rejection, isOpen: false })}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setRejection({ ...rejection, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Reason"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    setRejection({
                      ...rejection,
                      ...stateWithValidation(e.target.value, "rejectReason"),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={!rejection.isValid}
                  onClick={() => handleRejection()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => {
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'storeLogo.png')
            setBusinessLogo({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}
      {cropperData2.isOpen ? (
        <ImageCropper
          cropperImage={cropperData2.croppedImage}
          closepopup={() => {
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'bannerImage.png')
            bannerImage = {
              file: response,
              isValid: true,
              err: "",
            };
            setBannerImage(bannerImage);
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 16/9,}}
        />
      ) : null}
    </>
  );
}
