import { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { Boxed } from "../Styles-Elements/Box";
import { API } from "../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import GlobalService from "../services/GlobalService";

const AddCancellationDepartment = ({ handleClose, submit, handleConfirm, editData, isEdit }) => {

	const dispatch = useDispatch();
	const [reason, setReason] = useState("");
	let [reasonError, setReasonError] = useState('');

	const handleSubmit = async () => {
		dispatch(changeLoader(true));
		if (reason.trim() === '') {
			setReasonError("Please enter department");
			dispatch(changeLoader(false));
		} else {
			const response = await GlobalService(API.ADD_CANCELLATION_DEPARTMENT, { reason: reason }, 'post');
			if (response.ack === 1) {
				dispatch(changeLoader(false));
				dispatch(
					changeSnackbar({
						isOpen: true,
						message: response?.msg,
						state: "success"
					})
				);
				handleConfirm();
			} else {
				dispatch(changeLoader(false));
				setReasonError(response.msg);
			}
		}
	};

	const handleUpdate = async () => {
		dispatch(changeLoader(true));
		if (reason === '') {
			setReasonError("Please enter department");
			dispatch(changeLoader(false));
		} else {
			const response = await GlobalService(API.UPDATE_CANCELLATION_DEPARTMENT(editData.id), {
				reason: reason,
				status: editData.status
			}, 'put');
			if (response.ack === 1) {
				dispatch(changeLoader(false));
				dispatch(
					changeSnackbar({
						isOpen: true,
						message: response?.msg,
						state: "success"
					})
				);
				handleConfirm();
			} else {
				dispatch(changeLoader(false));
				setReasonError(response.msg);
			}
		}
	};

	useEffect(() => {
		if (isEdit) {
			setReason(editData.department_name);
		}
	}, [editData]);

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-600">
        <DialogContent>
          <h4 className="h4" style={{ marginBottom: "8px" }}>{isEdit?'Update':'Add'} Cancellation Department</h4>
					<Boxed style={{position: "relative",}} >
						<TextField
							id="outlined-basic" label="Department*" multiline={true} value={reason}
							onChange={(e) => {
								setReason(e.target.value);
								setReasonError('');
							}} rows={2} variant="outlined"
							fullWidth={true} margin="normal"
						/>
						<span className="redspan">{reasonError}</span>
					</Boxed>
          <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>Cancel</Button>

            {isEdit ?
							<Button type="button" size="medium" variant="text" color="error" onClick={handleUpdate}>Update</Button>:
							<Button type="button" size="medium" variant="text" color="error" onClick={handleSubmit}>Submit</Button>}
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddCancellationDepartment;