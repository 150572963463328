import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const storeRequestDefault = {
	moduleName: "StoreRequest", all: 0, read: 0, create: 0, edit: 0,
	actions: { approveReject: 0, addEditAssociates: 0 }, delete: 0, expiry_date: null
}
const storeListingDefault = {
	moduleName: "StoreListing", all: 0, read: 0, create: 0, edit: 0, delete: 0,
	actions: { recommended: 0, status: 0, addEditAssociates: 0 },
	expiry_date: null
}
const storeTypeDefault = {
	moduleName: "StoreType", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const businessCategoryDefault = {
	moduleName: "BusinessCategory", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const uomDefault = {
	moduleName: "UOM", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const productRequestDefault = {
	moduleName: "ProductRequest", all: 0, read: 0, create: 0, edit: 0, actions: { approveReject: 0 }, delete: 0,
	expiry_date: null
}
const addProductDefault = {
	moduleName: "AddProduct", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const orderListingDefault = {
	moduleName: "OrderListing", all: 0, read: 0, create: 0, edit: 0,
	actions: { readyToPick: 0, cancelOrder: 0, acceptOrRejectOrder: 0, refundOrder: 0, confirmPendingOrder: 0,},
	availableTypes: [], delete: 0, expiry_date: null
}
const cmsDefault = {
	moduleName: "CMS", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const customerManagementDefault = {
	moduleName: "CustomerManagement", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const walletManagementDefault = {
	moduleName: "WalletManagement", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const manageUsersDefault = {
	moduleName: "ManageUsers", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const rolesPermissionsDefault = {
	moduleName: "RolesPermissions", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const payoutManagementDefault = {
	moduleName: "PayoutManagement", all: 0, read: 0, create: 0, edit: 0,
	actions: { settlePayout: 0, exportCSV: 0 }, delete: 0,
	expiry_date: null
}
const driverOnboardingDefault = {
	moduleName: "DriverOnboarding", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const sectionManagementDefault = {
	moduleName: "SectionManagement", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const zoneDefault = {
	moduleName: "Zone", all: 0, read: 0, create: 0, edit: 0, actions: {}, delete: 0,
	expiry_date: null
}
const bannerDefault = {
	moduleName: "Banner", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const couponDefault = {
	moduleName: "Coupon", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const cuisineDefault = {
	moduleName: "Cuisine", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
	expiry_date: null
}
const adminSettingDefault ={
	moduleName: "AdminSetting", all: 0, read: 0, create: 0, edit: 0, actions: { }, delete: 0,
	expiry_date: null
}
const offerDiscountDefault = {
	moduleName: "OfferDiscount", all: 0, read: 0, create: 0, edit: 0, actions: { status: 0 }, delete: 0,
}
const pickerTrackingDefault = {
	moduleName: "PickerTracking", all: 0, read: 0, create: 0, edit: 0, actions: { }, delete: 0,
}
const AreaZoneDefault = {
	moduleName: "AreaZone", all: 0, read: 0, create: 0, edit: 0, actions: { }, delete: 0,
}
const checkDaysDifference = (expiry_date) => {
	const today = new Date();
	const expiryDate = new Date(expiry_date).setHours(23, 59, 59, 0);
	const diffTime = expiryDate - today;
	return diffTime;
};

const getPermission = () => {
  let permission = {
		role: 'admin',
		storeRequest: null, storeListing: null, storeType: null, businessCategory: null, uom: null,
		productRequest: null, addProduct: null, orderListing: null, cms: null, customerManagement: null,
		walletManagement: null, manageUsers: null, rolesPermissions: null, payoutManagement: null,
		driverOnboarding: null, sectionManagement: null, zone: null, banner: null, coupon: null, cuisine: null,
		adminSetting: null, offerDiscount: null, pickerTracking: null, AreaZone: null
  };
  if (localStorage.getItem("access_token")) {
		const decoded = jwt_decode(localStorage.getItem("access_token"));
		const decodedPermissions = decoded.permissions || [];
		if (decodedPermissions.length > 0) {
			let storeRequest = decodedPermissions.find((p) => p.moduleName === "StoreRequest");
			if (storeRequest?.expiry_date !== null && checkDaysDifference(storeRequest?.expiry_date) < 0) { storeRequest = storeRequestDefault; }
			let storeListing = decodedPermissions.find((p) => p.moduleName === "StoreListing");
			if (storeListing?.expiry_date !== null && checkDaysDifference(storeListing?.expiry_date) < 0) { storeListing = storeListingDefault; }
			let storeType = decodedPermissions.find((p) => p.moduleName === "StoreType");
			if (storeType?.expiry_date !== null && checkDaysDifference(storeType?.expiry_date) < 0) { storeType = storeTypeDefault; }
			let businessCategory = decodedPermissions.find((p) => p.moduleName === "BusinessCategory");
			if (businessCategory?.expiry_date !== null && checkDaysDifference(businessCategory?.expiry_date) < 0) { businessCategory = businessCategoryDefault; }
			let uom = decodedPermissions.find((p) => p.moduleName === "UOM");
			if (uom?.expiry_date !== null && checkDaysDifference(uom?.expiry_date) < 0) { uom = uomDefault; }
			let productRequest = decodedPermissions.find((p) => p.moduleName === "ProductRequest");
			if (productRequest?.expiry_date !== null && checkDaysDifference(productRequest?.expiry_date) < 0) { productRequest = productRequestDefault; }
			let addProduct = decodedPermissions.find((p) => p.moduleName === "AddProduct");
			if (addProduct?.expiry_date !== null && checkDaysDifference(addProduct?.expiry_date) < 0) { addProduct = addProductDefault; }
			let orderListing = decodedPermissions.find((p) => p.moduleName === "OrderListing");
			if (orderListing?.expiry_date !== null && checkDaysDifference(orderListing?.expiry_date) < 0) { orderListing = orderListingDefault; }
			let cms = decodedPermissions.find((p) => p.moduleName === "CMS");
			if (cms?.expiry_date !== null && checkDaysDifference(cms?.expiry_date) < 0) { cms = cmsDefault; }
			let customerManagement = decodedPermissions.find((p) => p.moduleName === "CustomerManagement");
			if (customerManagement?.expiry_date !== null && checkDaysDifference(customerManagement?.expiry_date) < 0) { customerManagement = customerManagementDefault; }
			let walletManagement = decodedPermissions.find((p) => p.moduleName === "WalletManagement");
			if (walletManagement?.expiry_date !== null && checkDaysDifference(walletManagement?.expiry_date) < 0) { walletManagement = walletManagementDefault; }
			let manageUsers = decodedPermissions.find((p) => p.moduleName === "ManageUsers");
			if (manageUsers?.expiry_date !== null && checkDaysDifference(manageUsers?.expiry_date) < 0) { manageUsers = manageUsersDefault; }
			let rolesPermissions = decodedPermissions.find((p) => p.moduleName === "RolesPermissions");
			if (rolesPermissions?.expiry_date !== null && checkDaysDifference(rolesPermissions?.expiry_date) < 0) { rolesPermissions = rolesPermissionsDefault; }
			let payoutManagement = decodedPermissions.find((p) => p.moduleName === "PayoutManagement");
			if (payoutManagement?.expiry_date !== null && checkDaysDifference(payoutManagement?.expiry_date) < 0) { payoutManagement = payoutManagementDefault; }
			let driverOnboarding = decodedPermissions.find((p) => p.moduleName === "DriverOnboarding");
			if (driverOnboarding?.expiry_date !== null && checkDaysDifference(driverOnboarding?.expiry_date) < 0) { driverOnboarding = driverOnboardingDefault; }
			let sectionManagement = decodedPermissions.find((p) => p.moduleName === "SectionManagement");
			if (sectionManagement?.expiry_date !== null && checkDaysDifference(sectionManagement?.expiry_date) < 0) { sectionManagement = sectionManagementDefault; }
			let zone = decodedPermissions.find((p) => p.moduleName === "Zone");
			if (zone?.expiry_date !== null && checkDaysDifference(zone?.expiry_date) < 0) { zone = zoneDefault; }
			let AreaZone = decodedPermissions.find((p) => p.moduleName === "AreaZone");
			if (AreaZone?.expiry_date !== null && checkDaysDifference(AreaZone?.expiry_date) < 0) { AreaZone = AreaZoneDefault; }
			 
			let banner = decodedPermissions.find((p) => p.moduleName === "Banner");
			if (banner?.expiry_date !== null && checkDaysDifference(banner?.expiry_date) < 0) { banner = bannerDefault; }
			let coupon = decodedPermissions.find((p) => p.moduleName === "Coupon");
			if (coupon?.expiry_date !== null && checkDaysDifference(coupon?.expiry_date) < 0) { coupon = couponDefault; }
			let cuisine = decodedPermissions.find((p) => p.moduleName === "Cuisine");
			if (cuisine?.expiry_date !== null && checkDaysDifference(cuisine?.expiry_date) < 0) { cuisine = cuisineDefault; }
			let adminSetting = decodedPermissions.find((p) => p.moduleName === "AdminSetting");
			if (adminSetting?.expiry_date !== null && checkDaysDifference(adminSetting?.expiry_date) < 0) { adminSetting = adminSettingDefault; }
			let offerDiscount = decodedPermissions.find((p) => p.moduleName === "OfferDiscount");
			if (offerDiscount && offerDiscount?.expiry_date !== null && checkDaysDifference(offerDiscount?.expiry_date) < 0) { offerDiscount = offerDiscountDefault; }
			let pickerTracking = decodedPermissions.find((p) => p.moduleName === "PickerTracking");
			if (pickerTracking && pickerTracking.expiry_date !== null && checkDaysDifference(pickerTracking.expiry_date) < 0) { pickerTracking = pickerTrackingDefault; }
			
			
			permission = {
				role: decoded.role, storeRequest: storeRequest, storeListing: storeListing, storeType: storeType,
				businessCategory: businessCategory, uom: uom, productRequest: productRequest, addProduct: addProduct,
				orderListing: orderListing, cms: cms, customerManagement: customerManagement, walletManagement: walletManagement,
				manageUsers: manageUsers, rolesPermissions: rolesPermissions, payoutManagement: payoutManagement,
				driverOnboarding: driverOnboarding, sectionManagement: sectionManagement, zone: zone, banner: banner,
				coupon: coupon, cuisine: cuisine, adminSetting: adminSetting,
				offerDiscount: offerDiscount, pickerTracking: pickerTracking,AreaZone: AreaZone
			}
		}
  }
  return permission;
};

export const permission = createSlice({
  name: "permission",
  initialState: {
    value: getPermission(),
  },
  reducers: {
    changePermission: (state, action) => {
      state.value = getPermission();
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePermission } = permission.actions;
export const currentPermission = (state) => state.permission.value;
export default permission.reducer;
