import { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import { Boxed } from "../Styles-Elements/Box";
import { API } from "../pages/ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { changeSnackbar } from "../redux/reducers/snackbar";
import GlobalService from "../services/GlobalService";

const UpdateAdminSetting = ({ handleClose, editData, handleSuccess }) => {

	const dispatch = useDispatch();
	let [reason, setReason] = useState("");
	let [reasonError, setReasonError] = useState('');

	const handleUpdate = async () => {
		dispatch(changeLoader(true));
		if (!['welcome_text_ar', 'welcome_text'].includes(editData.name) && reason === '') {
			setReasonError("Please enter value");
			dispatch(changeLoader(false));
		} else {
			const response = await GlobalService(API.UPDATE_SETTING_VALUE(editData.id), { 
				name: editData.name,
				value: reason,
				status: editData.status,
			}, 'put');
			if (response.ack === 1) {
				dispatch(changeLoader(false));
				dispatch(changeSnackbar({
					isOpen: true,
					message: response.msg,
					status: 'success',
				}));
				handleSuccess();
			} else {
				dispatch(changeLoader(false));
				if (response.msg && response.msg !== '') {
					dispatch(changeSnackbar({ 
						isOpen: true,
						message: response.msg,
						status: 'error',
					}));
				} else {
					let message = "";
					response?.errMsg.forEach((element) => {
						for (const [key, value] of Object.entries(element)) {
						message += value + "\n";
						}
					});
					dispatch(changeSnackbar({
						isOpen: true,
						message: message,
						state: "error",
					}));
				}
			}
		}
	};

	useEffect(() => {
		if (editData) {
			setReason(editData.value);
		}
	}, [editData]);

  return (
    <>
      <Dialog open={true} onClose={handleClose} className="main-modal theme-dialog maxwidth-600">
        <DialogContent>
          <h4 className="h4" style={{ marginBottom: "8px" }}>Update setting value</h4>
					<Boxed style={{position: "relative",}} >
						<TextField
							id="outlined-basic" label="Value *" value={reason}
							rows={2} variant="outlined" fullWidth={true} margin="normal"
							onChange={(e) => {setReason(e.target.value); setReasonError('')}}
						/>
						<span className="redspan">{reasonError}</span>
					</Boxed>
          <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>Cancel</Button>
						<Button type="button" size="medium" variant="text" color="error" onClick={handleUpdate}>Update</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UpdateAdminSetting;