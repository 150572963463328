import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_SECTIONS: (role, is_admin, limit, page) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.sectionManager.GET_SECTIONS(role, is_admin, limit, page),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_SECTION_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.sectionManager.GET_SECTION_BY_ID(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  ADD_SECTION: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.sectionManager.ADD_SECTION,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_SECTION: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.sectionManager.DELETE_SECTION(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error, "errror in get by id business type");
      }
    });
  }, 
// GET_FAQ_BY_ID: (role, faqId, isAdmin) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await Axios.get(
//         constant.BASE_URL + api.faq.GET_FAQ_BY_ID(role, faqId, isAdmin),
//         {
//           headers: {
//             "x-api-key": constant.X_API_KEY,
//           },
//         }
//       );
//       resolve(response);
//     } catch (error) {
//       reject(error);
//     }
//   });
// },
UPDATE_SECTION: (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        constant.BASE_URL + api.sectionManager.UPDATE_SECTION(id),
        data,
        {
          headers: {
            "x-api-key": constant.X_API_KEY,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
},
};
