import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid, Typography, TextField, Button, Alert, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, MenuItem, Select, Dialog, DialogContent
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Aside from "../../layout/aside/Aside";
import services from "../../../services";
import Header from "../../layout/header/Header";
import { stateWithValidation } from "../../helper/Validation";
import { getdataByKey, checkIfImageExists } from "../../helper/custom";
import constant from "../../../constant";
import modalClose from "../../../assets/images/structure/close-modal.png";
import CloseIcon from "@material-ui/icons/Close";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";
import { changeLoader } from "../../../redux/reducers/loader";
import { useSelector, useDispatch } from "react-redux";
import defaultImg from "../../../assets/images/structure/default.jpeg";

export default function ProductRequestList() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);
  const [searchParams] = useSearchParams();
  const [toaster, setToaster] = useState({ status: "", text: "", visibilty: false, });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: null });
  const [productData, setProductData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [rejection, setRejection] = useState({ isOpen: false, err: "", val: "", isValid: false, id: null, });
  let [canView, setCanView] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  let [count, setCount] = useState();
  let [sort, setSort] = useState({ sort: "asc", key: "id" });

  let [search, setSearch] = useState("");
  let [filter, setFilter] = useState(0);
  let [filterStatus, setFilterStatus] = useState('all');
  let [page, setPage] = useState(1);

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllRequest = async () => {
    dispatch(changeLoader(true));
    try {
      const query =
        filter || search
          ? new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              search: search ? search : "",
              isApproved: filterStatus === 'all'? "0,2": filterStatus,
              searchById: filter === 0 ? "" : filter,
            }).toString()
          : new URLSearchParams({
              page: page ? page:1,
              limit: limit,
              isApproved: filterStatus === 'all'? "0,2": filterStatus,
              order_by,
              order_type,
            }).toString();
      let url = '/product-request';
      let options = [];
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (filterStatus) { options.push(`status=${filterStatus}`) }
      if (page) { options.push(`page=${page}`) }
      if (options.length > 0) { url += `?${options.join('&')}` }
      navigate(url)
      const response = await services.Products.GET_ALL_PRODUCTS(query);
      if (response) {
        setProductData(response.data.products.rows);
        count = Math.ceil(response.data.products.count / limit);
        setCount(count);
      }
    } catch (error) {
      console.log("Error_In_product Listing", error);
    }
    dispatch(changeLoader(false));
  };

  const GetAllBusinessType = async () => {
    try {
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeData(response.data.businesstype.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editProductRequest = (id) => {
    navigate(`/product-request-detail/${id}?${searchParams.toString()}`);
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllRequest();
  };

  const handleApprove = async (id) => {
    try {
      const response = await services.Products.UPDATE_STATUS_APPROVE(id, {
        status: "approve",
      });
      if (response?.data?.ack) {
        getAllRequest();
        setToaster({
          status: "success",
          text: `Product Request approved`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 6000);
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({
          status: "",
          text: ``,
          visibilty: false,
        });
      }, 2500);
    }
  };

  const handleRejection = async () => {
    try {
      const response = await services.Products.UPDATE_STATUS_APPROVE(
        rejection.id,
        {
          status: "reject",
          reason_of_reject: rejection.val,
        }
      );
      if (response?.data?.ack) {
        setRejection({ ...rejection, isOpen: false });
        setToaster({
          status: "success",
          text: `Product Request Rejected`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllRequest();
      }
    } catch (error) {
      setRejection({ ...rejection, isOpen: false });
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ ...toaster, visibilty: false });
      }, 2500);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await services.Products.DELETE_PRODUCT(deleteModal.id);
      if (response?.data?.ack) {
        setDeleteModal({ id: null, isOpen: false });
        setToaster({
          status: "success",
          text: `${response?.data?.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ text: "", status: "", visibilty: false });
        }, 6000);
        getAllRequest();
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ text: "", status: "", visibilty: false });
      }, 6000);
      getAllRequest();
      console.log(error);
    }
  };

  const GetTableData = ({ item, index, canUpdateStatus }) => {
    let [address, setAddress] = useState("");

    const getGeoCode = async () => {
      let code = "No address found ";
      if (item?.store) {
        code = item?.store?.business_address;
      }
      setAddress(code);
    };

    useEffect(() => {
      getGeoCode();
    }, []);

    return (
      <TableRow key={index} style={{ cursor: "pointer" }}>
        {/* <TableCell align="center" onClick={() => editProductRequest(item.id)}>
          <span className="tag-chips">New</span>
        </TableCell> */}
        <TableCell
          component="th"
          scope="row"
          onClick={() => editProductRequest(item.id)}
        >
          <div className="table-img-text">
            <img
              src={checkIfImageExists(constant?.IMG_BASE_URL + item.uoms[0]?.uom_images[0]?.image)}
              alt=".."
              className="square36"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultImg;
              }}
            />
            {item?.brand_business_name}
            {/* {getdataByKey(item?.store?.stores_locales, "en", "business_name")} */}
          </div>
        </TableCell>
        {/* <TableCell onClick={() => editProductRequest(item.id)}>{address}</TableCell> */}
        <TableCell onClick={() => editProductRequest(item.id)}>{getdataByKey(item?.products_locales, "en", "title")}</TableCell>
        <TableCell onClick={() => editProductRequest(item.id)}>#{item?.itemCode}</TableCell>
        {/* <TableCell align="center">{item?.uoms[0]?priceSet(item?.uoms[0]?.salePrice):0}</TableCell> */}
        {canUpdateStatus?<TableCell className="nowrap" align="center">
          {item?.is_approved === 0 ? (
            canUpdateStatus?<Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={item.is_approved === 2 ? true : false}
              sx={{ marginRight: "10px" }}
              onClick={() => handleApprove(item.id)}
            >
              Approve
            </Button>: null
          ) : null}
          {item?.is_approved === 0 || item?.is_approved === 2 ? (
            canUpdateStatus?<Button
              disabled={item?.is_approved === 2 ? true : false}
              variant="outlined"
              size="small"
              color="error"
              onClick={() =>
                setRejection({
                  ...rejection,
                  isOpen: true,
                  id: item.id,
                })
              }
            >
              {item?.is_approved === 2 ? "Rejected" : "Reject"}
            </Button>: null
          ) : null}
        </TableCell>: null}
      </TableRow>
    );
  };
  
  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllRequest();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, filter, order_by, order_type, filterStatus]);

  useEffect(() => {
    page = searchParams.get('page')
    setPage(page)
    if (searchParams.get('search')) {
      search = searchParams.get('search')
      setSearch(search)
    }
    if (searchParams.get('filter')) {
      filter = searchParams.get('filter')
      setFilter(filter)
    }
    if (searchParams.get('status')) {
      filterStatus = searchParams.get('status')
      setFilterStatus(filterStatus)
    }
    GetAllBusinessType();
  }, []);

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanView(permission?.productRequest?.read === 1)
      setCanUpdateStatus(permission?.productRequest?.actions?.approveReject === 1)
    } else {
      setCanView(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);
  
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (<Alert severity={toaster.status}>{toaster.text}</Alert>)}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>Product Request</Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small" size="small" placeholder="Search.."
                    onChange={(e) => setSearch(e.target.value)} value={search}
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ marginRight: "3px" }} /> ,
                      endAdornment: search ? <CloseIcon onClick={() => { setSearch(""); }} /> : null,
                    }}
                  />
                </div>
                <Select
                  labelId="demo-select-small" id="demo-select-small" defaultValue={"All"}
                  value={filter} size="small" className="status-drop"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {businesstypeData && businesstypeData.length > 0 && businesstypeData.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {getdataByKey( item?.business_type_locales, "en", "name" )}
                      </MenuItem>
                    ))}
                </Select>
                <Select
                  labelId="demo-select-small" id="demo-select-small" value={filterStatus}
                  size="small" className="status-drop"
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'0'}>Pending Approval</MenuItem>
                  {/* <MenuItem value={'1'}>Approved</MenuItem> */}
                  <MenuItem value={'2'}>Rejected</MenuItem>
                </Select>
              </div>
            </div>
           {productData.length >0? <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Brand Name
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("store_type", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("store_type", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Product Title
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("product_title", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("product_title", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="short-div">
                        Item Code
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("itemCode", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("itemCode", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    { canUpdateStatus ?
                      <TableCell align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData.map((item, index) => {
                    return (
                      <GetTableData
                        item={item} index={"table-item-" + item.id} key={index}
                        canView={canView} canUpdateStatus={canUpdateStatus}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound
              text={"No Product Request Found"}
            />}
            
            {count > 1 ? (
              <Pagination
                count={count} page={page} color="primary"
                onChange={(e, value) => handlePagination(e, value)}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={rejection.isOpen}
        onClose={() => setRejection({ ...rejection, isOpen: false })}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setRejection({ ...rejection, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Reject Reason
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Reason"
                  multiline
                  rows={2}
                  onChange={(e) => {
                    setRejection({
                      ...rejection,
                      ...stateWithValidation(e.target.value, "rejectReason"),
                    });
                  }}
                />
                {!rejection.isValid && (
                  <div className="error">{rejection.err}</div>
                )}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={!rejection.isValid}
                  onClick={() => handleRejection()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* delete Modal */}
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Product ?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
