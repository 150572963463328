import * as React from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import {
  Typography, TextField, Button, Alert, Switch, Grid, Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Pagination, MenuItem, Dialog, Tooltip, Select, DialogContent
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useEffect, useState } from "react";
import services from "../../../services";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { getdataByKey } from "../../helper/custom";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";
import jwt_decode from "jwt-decode";
// image
export default function StoreListing() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const permission = useSelector(currentPermission);
  const [canEdit, setCanEdit] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdateStatus, setCanUpdateStatus] = useState(true);
  const [canRecommend, setCanRecommend] = useState(true);
  const [canAssign, setCanAssign] = useState(true);
  const [storeData, setStoreData] = useState([]);
  const [businesstypeData, setBusinesstypeData] = useState([]);
  const [order_by, setOrder_by] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [limit, setLimit] = useState(10);
  const [toaster, setToaster] = useState({ visibilty: false, status: "", text: "", });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: null, });
  let [filter, setFilter] = useState(0);
  let [search, setSearch] = useState("");
  let [count, setCount] = useState();
  let [pages, setPages] = useState(1);
  let newArray = [];

  const changeOrderBy = (value, order) => {
    if (order === "asc") setOrder_type("asc");
    else if (order === "desc") setOrder_type("desc");
    setOrder_by(value);
  };

  const getAllStores = async () => {
    try {
      const query =
        filter || search
          ? new URLSearchParams({
              page: pages ? pages:1,
              limit: limit,
              isApproved: 1,
              search: search ? "" + search.trim() : "",
              businessTypeId: filter === 0 ? "" : filter,
            }).toString()
          : new URLSearchParams({
              page: pages ? pages:1,
              limit: limit,
              isApproved: 1,
              order_by: order_by,
              order_type: order_type,
            }).toString();
      let url = '/store-listing';
      let options = [];
      if (search) { options.push(`search=${search}`) }
      if (filter) { options.push(`filter=${filter}`) }
      if (pages) { options.push(`pages=${pages}`) }
      if (options.length > 0) { url += `?${options.join('&')}` }
      navigate(url)
      dispatch(changeLoader(true));
      const data = (await services.Stores.GET_ALL_STORE(query)).data.stores;
      if (data) {
        setStoreData(data.rows);
        count = Math.ceil(data.count / limit);
        setCount(count);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const GetAllBusinessType = async () => {
    try {
      dispatch(changeLoader(true));
      const response =
        await services.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY();
      if (response) {
        setBusinesstypeData(response.data.businesstype.rows);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (e, value) => {
    pages = value;
    setPages(pages);
    getAllStores();
  };

  const editStore = (id) => {

     
      if (id === 1) {
        const token = localStorage.getItem("access_token");
        const decoded = jwt_decode(token);
    
     
        if (decoded.id === 157781) {
          const path = `/store-listing-detail/${id}/list/${pages}?${searchParams.toString()}`;
          navigate(path);
        }
      } else {
        const path = `/store-listing-detail/${id}/list/${pages}?${searchParams.toString()}`;
          navigate(path);
      }
    


   
  };

  const handleToggle = async (id, index, toggle) => {
    try {
      const updatedData = {
        store: {
          ...(toggle == "status"
            ? {
                status:
                  storeData[index].status === "active" ? "inactive" : "active",
              }
            : {
                recommended:
                  storeData[index].recommended === true ? false : true,
              }),
        },
        payoutDetail: {},
        workingHour: {},
      };
      console.log("updatedData", updatedData);
      const bodyFormData = new FormData();
      Object.keys(updatedData).forEach((key) => {
        bodyFormData.append(key, JSON.stringify(updatedData[key]));
      });
      dispatch(changeLoader(true));
      const response = await services.Stores.UPDATE_STORE(id, bodyFormData);

      if (response.data.ack) {
        setToaster({
          status: "success",
          text: `${response.data.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ text: "", status: "", visibilty: false });
        }, 2500);

        getAllStores();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error.data.msg}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ text: "", status: "", visibilty: false });
      }, 2500);
      console.log(error);
    }
  };

  const handleOnDelete = async () => {
    try {
      const response = await services.Stores.DELETE_STORE(deleteModal.id);
      if (response.data.ack) {
        setDeleteModal({ id: null, isOpen: false });
        setToaster({
          status: "success",
          text: `Store Deleted Successfuly`,
          visibilty: true,
        });
        getAllStores();
        setTimeout(() => {
          setToaster({ text: "", status: "", visibilty: false });
        }, 2500);
      }
    } catch (error) {
      setToaster({
        status: "error",
        text: `${error}`,
        visibilty: true,
      });
      setTimeout(() => {
        setToaster({ text: "", status: "", visibilty: false });
      }, 1500);
      console.log(error);
    }
  };

  const GetTableData = ({ item, index, canEdit, canDelete, canUpdateStatus, canRecommend, canAssign }) => {
    
    let [address, setAddress] = useState("");

    const getGeoCode = async () => {
      setAddress((item?.business_address) ? item?.business_address : "No address found ");
    };

    const getStoreName = (data) => {
      let newData = "";
      data?.forEach((item) => {
        if (item?.locale == "en") newData = item?.name;
      });
      return newData;
    };

    const getStoreNameBusiness = (data) => {
      let newData = "";
      data?.forEach((item) => {
        if (item?.locale == "en") newData = item?.business_name;
      });
      return newData;
    };

    useEffect(() => {
      getGeoCode();
    }, []);

    return (
      <TableRow key={index}>
        <TableCell component="th" scope="row"
          
            onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
          // onDoubleClick={() => editStore(item.id)}
        >
          {getStoreName(item?.business_type?.business_type_locales)}
        </TableCell>
        <TableCell
          // onDoubleClick={() => editStore(item.id)}
          onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
        >
          <a target="_blank" href={`https://baladiexpress.com/store/` + item.slug}>
            {getStoreNameBusiness(item?.stores_locales)}
          </a>
        </TableCell>
        <TableCell
          // onDoubleClick={() => editStore(item.id)}
          onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
        >{getStoreNameBusiness(item?.brands?.stores_locales)}</TableCell>
        <TableCell
          // onDoubleClick={() => editStore(item.id)}
          onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
        >{item?.brands?.user?.email}</TableCell>
        <TableCell
          // onDoubleClick={() => editStore(item.id)}
           onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
        >{item?.user?.email || '-'}</TableCell>
        <TableCell
          // onDoubleClick={() => editStore(item.id)}
           onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
        ><div className="address-table">{address}</div></TableCell>
        <TableCell
           onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
          // onDoubleClick={() => editStore(item.id)}
        
        >
          <div className="owner-detail">
            {item?.brands?.user?.full_name && item?.brands?.user?.contact_number ? (
              <>
                <p className="name-text">{item?.brands?.user?.full_name}</p>
                <p className="number-text">{item?.user?.contact_number || item?.brands?.user?.contact_number}</p>
              </>
            ) : "---"}
          </div>
        </TableCell>
        <TableCell
             onDoubleClick={canEdit ? () => editStore(item.id) : undefined}
          // onDoubleClick={() => editStore(item.id)}
        
        >
          {moment(item.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY")}
        </TableCell>
        <TableCell>
          <Switch
            disabled={!canUpdateStatus}
            defaultChecked={item.status === "active" ? true : false}
            onClick={() => handleToggle(item.id, index, "status")}
          />
        </TableCell>
        <TableCell>
          <Switch
            disabled={!canRecommend}
            defaultChecked={item?.recommended == true ? true : false}
            onClick={() => handleToggle(item.id, index, "recommended")}
          />
        </TableCell>
        {canAssign ?
        <>
           { canEdit || canDelete?<TableCell><Link to={`/associate-zone/${item.id}`}><small>Add/Edit associates</small></Link></TableCell> : null}
          <TableCell><Link to={`/associate-categories/${item.id}`}><small>Add/Edit Categories</small></Link></TableCell>
          <TableCell><Link to={`/associate-cuisine/${item.id}`}><small>Add/Edit Cuisine</small></Link></TableCell>
        </>: null}
        { canEdit || canDelete?
        <TableCell align="center" className="nowrap">
          {canEdit?<Tooltip title="Edit" placement="top" sx={{ marginRight: 1 }} onClick={() => editStore(item.id)} >
            <BorderColorIcon color="action" fontSize="small" />
          </Tooltip>: null}
          {canDelete?<Tooltip title="Delete" placement="top">
            <DeleteForever color="error" fontSize="small"
                onClick={async () => {
                  // alert(item.id);
                  if (item.id === 1) {
                    const token = localStorage.getItem("access_token");
                    const decoded = await jwt_decode(token);
               
                    if (decoded.id === 157781) {
                      setDeleteModal({ isOpen: true, id: item.id, }) 
                    }
                  } else {
                    setDeleteModal({ isOpen: true, id: item.id, }) 
                  }
                
                }
                
                 
                }
            />
          </Tooltip>: null}
        </TableCell>: null}
      </TableRow>
    );
  };

  const handleBusinessName = () => {
    storeData.map((store, index) => {
      businesstypeData.map((item) => {
        if (item.id === store.businessTypeId) {
          newArray[store.businessTypeId] = item.name;
        }
      });
    });
  };

  useEffect(() => {
    pages = 1;
    setPages(pages);
    const getData = setTimeout(() => {
      getAllStores();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, order_by, order_type, filter]);

  useEffect(() => {
    pages = searchParams.get('pages')
    setPages(pages);
    if (searchParams.get('search')) {
      search = searchParams.get('search')
      setSearch(search)
    }
    if (searchParams.get('filter')) {
      filter = searchParams.get('filter')
      setFilter(filter)
    }
    GetAllBusinessType();
    handleBusinessName();
  }, []);

  useEffect(() => {

    const token = localStorage.getItem("access_token");
    const decoded = jwt_decode(token);
    
    console.log(decoded, "decoded");
    console.log("permission", permission);
 // 157781
  
    
    if (permission.role !== "admin") {
        setCanEdit(permission?.storeListing?.edit === 1);
        setCanDelete(permission?.storeListing?.delete === 1);
        setCanUpdateStatus(permission?.storeListing?.actions?.status === 1);
        setCanRecommend(permission?.storeListing?.actions?.recommended === 1);
        setCanAssign(permission?.storeListing?.actions?.addEditAssociates === 1);
    } else {

    
        setCanEdit(true)
        setCanDelete(true)
   
        setCanUpdateStatus(true)
      setCanRecommend(true)
      setCanAssign(true)
    }

    console.log("canEdit", canEdit);
    console.log("canEdit", canDelete);
  }, [permission]);


  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (<Alert severity={toaster.status}>{toaster.text}</Alert>)}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}> Store Listing </Typography>
              <div className="right-filter">
                <div className="search-box">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    
                    placeholder="Search Store  "
                    value={search}
                    onChange={(e) => setSearch(e.target.value.trimStart())}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ marginRight: "3px" }} />
                      ),
                      endAdornment: search ? (
                        <CloseIcon
                          onClick={() => {
                            setSearch("");
                          }}
                        />
                      ) : null,
                    }}
                  />
                </div>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={"All"}
                  value={filter}
                  
                  size="small"
                  onChange={(e) => {
                    setFilter(e.target.value);
                    pages = 1;
                    setPages(pages);
                  }}
                  className="status-drop"
                >
                  <MenuItem value={0}>All</MenuItem>
                  {businesstypeData &&
                    businesstypeData.length > 0 &&
                    businesstypeData.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {getdataByKey(item.business_type_locales, "en", "name")}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
           {storeData?.length > 0? <TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Store Type
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("name", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("name", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Store Name
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => {changeOrderBy("business_name", "asc");}} />
                          <ArrowDropDownIcon onClick={() => {changeOrderBy("business_name", "desc");}} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Brand Name
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => {changeOrderBy("business_name", "asc");}} />
                          <ArrowDropDownIcon onClick={() => {changeOrderBy("business_name", "desc");}} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Business Email
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("business_email", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("business_email", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>Branch Email</TableCell>
                    <TableCell>
                      <div className="short-div">
                        Business Address
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("business_address", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("business_address", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        User Details
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("user_name", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("user_name", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        Registered Date
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => { changeOrderBy("createdAt", "asc"); }} />
                          <ArrowDropDownIcon onClick={() => { changeOrderBy("createdAt", "desc"); }} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell> <div className="short-div">Status</div> </TableCell>
                    <TableCell> <div className="short-div">Recommended</div> </TableCell>
                    {canAssign?<>
                      { canEdit || canDelete ?  <TableCell>Associate Zone </TableCell> : null}
                      <TableCell>Associate Category </TableCell>
                      <TableCell>Associate Cuisine </TableCell>
                    </>: null}
                    { canEdit || canDelete ?
                      <TableCell className="nowrap" align="center">Action</TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {storeData.map((item, index) => {
                    return <GetTableData
                      key={`store-${index}`}
                      item={item}
                      index={index}
                      canEdit={canEdit}
                      canDelete={canDelete}
                      canUpdateStatus={canUpdateStatus}
                      canRecommend={canRecommend}
                      canAssign={canAssign}
                    />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound text={"No Store Found"} />}
            {count > 1 && storeData.length>0 ?
            <Pagination
              color="primary"
              count={count} page={pages}
              onChange={handlePagination}
            />: null}
          </Box>
        </Box>
      </Box>
      {/* delete Modal */}
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Store?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleOnDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
