import { useState, useEffect, useRef } from "react";
import { Typography, Box, Switch } from "@mui/material";
import deleteBlackImg from "../../../../assets/images/structure/delete-black.svg";
import uploadBlackIcon from "../../../../assets/images/structure/upload-dark-icon.svg";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../../pages/ENUM";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import constant from "../../../../constant";

const ChildCategory = ({
    childCategory,
		handleStatusUpdate,
		handleSetDeleteId,
		setOpenDeletePopup,
		id,
		selectedId,
		handleSelectedId,
    openUploadImage
}) => {

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [childCategoryList, setChildCategoryList] = useState([]);

    const dragStart = (e, position) => {
      dragItem.current = position;
    };
    
    const dragEnter = (e, position) => {
      dragOverItem.current = position;
    };
    
    const drop = async (e, index) => {
      const copyListItems = [...childCategoryList];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      let newIndex = copyListItems.findIndex((item) => item.id === dragItemContent.id);
      setChildCategoryList(copyListItems);
      const newSequence = copyListItems.map((item, index) => {
        return {
          catStoreId: item.catStoreId,
          sequence: index + 1,
        };
      });
      await GlobalService(API.UPDATE_CATEGORY_SEQUENCE, newSequence, 'post');
    };

    useEffect(() => {
        setChildCategoryList(childCategory);
    }, [childCategory]);

    return (
      <>
        {childCategoryList?.map((childCategoryItem, index) =>
				<Box p={'8px 44px 8px 46px'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}
        style={{ cursor: 'pointer' }}
					onClick={() => handleSelectedId(childCategoryItem.id, childCategoryItem.name)}
					onDragStart={(e) => dragStart(e, index)}
					onDragEnter={(e) => dragEnter(e, index)}
					onDragEnd={(e) => drop(e, index)}
					draggable
				>
          <DragIndicatorIcon sx={{ marginRight: "3px",color: "#a7a7a7" }}  />
					<Typography variant="body2" component="body2" width={"100%"} fontSize={"16px"} mb={0} fontWeight={500}>
						{childCategoryItem.name}
					</Typography>
					<Box display={'flex'} alignItems={'center'}>
						<Switch
							checked={childCategoryItem.status === 'active' ? true : false}
							onChange={(e) => {
								handleStatusUpdate(e.target.checked ? 'active' : 'inactive', childCategoryItem.id)
							}}
						/>
						<img src={deleteBlackImg} alt=""
							onClick={() => {
								handleSetDeleteId(childCategoryItem.id)
								setOpenDeletePopup(true);
							}}
						/>
            <img src={childCategoryItem.image?
                      constant?.IMG_BASE_URL + childCategoryItem.image:
                      uploadBlackIcon}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = uploadBlackIcon
                      }}
              width={'20px'}
              onClick={() => {
                openUploadImage(childCategoryItem.id, childCategoryItem.name, childCategoryItem.image)
              }}
            alt=""/>
					</Box>
				</Box>)}
    	</>
    );
};

export default ChildCategory;