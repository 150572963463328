import { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";

import BannerComponent from "./WidgetComponent/BannerComponent";
import BrandComponent from "./WidgetComponent/BrandComponent";
import CategoryComponent from "./WidgetComponent/CategoryComponent";

import uploadImg from "../../../assets/images/structure/upload-img.png";
import plusIcon from "../../../assets/images/structure/plus-icon.svg";
import landingScreen1 from "../../../assets/images/product/landing-screen1.png";
import landingScreen2 from "../../../assets/images/product/landing-screen2.png";
import landingScreen3 from "../../../assets/images/product/landing-screen3.png";
import landingScreen4 from "../../../assets/images/product/landing-screen4.png";
import landingScreen5 from "../../../assets/images/product/landing-screen5.png";
import landingScreen6 from "../../../assets/images/product/landing-screen6.png";
import landingScreen7 from "../../../assets/images/product/landing-screen7.png";
import landingScreen8 from "../../../assets/images/product/landing-screen8.png";
import landingScreen9 from "../../../assets/images/product/landing-screen9.png";
import landingScreen10 from "../../../assets/images/product/landing-screen10.png";
import landingScreen11 from "../../../assets/images/product/landing-screen11.png";
import EditIcon from "@mui/icons-material/Edit";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";

export default function BaladiLanding() {
  const { type } = useParams();
  const [selectedParentCategory, setSelectedParentCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const getBusinessTypes = async () => {
    const response = await GlobalService(API.GET_ALL_BUSINESS);
    setBusinessTypes(response.businesstype.rows);
  };

  const getAllWidget = async () => {
    const response = await GlobalService(API.WIDGET.GET_ALL_WIDGETS(type));
    setWidgets(response?.data);
  };

  useEffect(() => {
    getAllWidget();
  }, []);

  useLayoutEffect(() => {
    getBusinessTypes();
  }, []);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
                  Baladi Landing Page
                </Typography>
                <Divider />
              </Grid>

              {/* section 1 */}
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 1 - Hot Deals
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Categories"
                        control={<Switch color="primary" />}
                        label="Categories"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Sub Category"
                          multiple={true}
                          value={selectedSubSubCategory}
                          onChange={(e) =>
                            setSelectedSubSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    {/* {selectedParentCategory?.map((select, key) => {
                    let Product = ProductType.find((Product) => Product.id === select);
                    return (
                      <Chip
                        label={getdataByKey(Product?.Product_type_locales,"en","name")}
                        key={`Product-${key}`} sx={{ mr: 1, mt: 2 }}
                        onDelete={() => {
                          const allProduct = [...selectedParentCategory,];
                          allProduct.splice(key, 1);
                          setSelectedParentCategory(allProduct);
                        }}
                      />
                    );
                  })} */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen1}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>

              {/* section 2 */}
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 2 - Try Our New Offerings
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen2}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>

              {/* section 3 */}
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 3 - Baladi Premium Products
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen3}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 4 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 4 - Season's Favorites
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Drag & Drop option should be available to change the order and can select max 10"
                        control={<Switch color="primary" />}
                        label="Drag & Drop option should be available to change the order and can select max 10"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen4}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 5 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 5 - Household Favorites
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Categories"
                        control={<Switch color="primary" />}
                        label="Categories"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen5}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 6 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 6 - Banner
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Banner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Banner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Banner"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={landingScreen6}
                      alt=""
                      className=""
                      style={{
                        width: 400,
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: 16,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 7 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 7 - Exclusive Beauty Deals
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Categories"
                        control={<Switch color="primary" />}
                        label="Categories"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    {/* {selectedParentCategory?.map((select, key) => {
                    let Product = ProductType.find((Product) => Product.id === select);
                    return (
                      <Chip
                        label={getdataByKey(Product?.Product_type_locales,"en","name")}
                        key={`Product-${key}`} sx={{ mr: 1, mt: 2 }}
                        onDelete={() => {
                          const allProduct = [...selectedParentCategory,];
                          allProduct.splice(key, 1);
                          setSelectedParentCategory(allProduct);
                        }}
                      />
                    );
                  })} */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen7}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 8 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 8 - Natural Skincare
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Drag & Drop option should be available to change the order and can select max 10"
                        control={<Switch color="primary" />}
                        label="Drag & Drop option should be available to change the order and can select max 10"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen8}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 9 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 9 - Banner
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Choose Banner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Banner
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Choose Banner"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={landingScreen9}
                      alt=""
                      className=""
                      style={{
                        width: 400,
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: 16,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* section 10 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 10 - Need Something? We’re here for...
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Categories"
                        control={<Switch color="primary" />}
                        label="Categories"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Parent Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sub Category"
                          multiple={true}
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                      <Button>
                        <img src={plusIcon} alt="" className="" />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Fruits & Vegetables"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    {/* {selectedParentCategory?.map((select, key) => {
                    let Product = ProductType.find((Product) => Product.id === select);
                    return (
                      <Chip
                        label={getdataByKey(Product?.Product_type_locales,"en","name")}
                        key={`Product-${key}`} sx={{ mr: 1, mt: 2 }}
                        onDelete={() => {
                          const allProduct = [...selectedParentCategory,];
                          allProduct.splice(key, 1);
                          setSelectedParentCategory(allProduct);
                        }}
                      />
                    );
                  })} */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen10}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>

              {/* section 11 */}
              <Grid item xs={12}>
                <Divider className="mt-4" />
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <Typography
                      variant="p"
                      component="p"
                      mb={0}
                      fontWeight={500}
                    >
                      Widget 11 - Have you tried these yet?
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        style={{ justifyContent: "flex-end", marginLeft: 0 }}
                        value="Drag & Drop option should be available to change the order and can select max 10"
                        control={<Switch color="primary" />}
                        label="Drag & Drop option should be available to change the order and can select max 10"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="upload-img mx-0 mt-1">
                    <div
                      className="before-upload"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById("upload-image").click();
                      }}
                    >
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div>
                    {/* <div className="after-upload">
                      <EditIcon
                        className="delete-icon"
                        onClick={() => {
                          imageData = { file: "", imagePreviewUrl: "" };
                          setImageData(imageData);
                        }}
                      />
                      <img
                        src={imageData.imagePreviewUrl}
                        alt=".."
                        className="upload-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("upload-image2").click();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <input
                        type="file"
                        accept={`image/png, image/jpeg`}
                        onChange={(e) => editImageChangeHandler(e)}
                        id="upload-image2"
                        style={{ position: "absolute", left: "-10000px" }}
                      />
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - English"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Subtitle of Widget - Arabic"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={4}>
                    <div className="d-flex items-center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Choose Product
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Parent Category"
                          multiple={true}
                          value={selectedParentCategory}
                          onChange={(e) =>
                            setSelectedParentCategory(e.target.value)
                          }
                        >
                          <MenuItem value={0}>one</MenuItem>
                          <MenuItem value={1}>one</MenuItem>
                          <MenuItem value={2}>one</MenuItem>
                          <MenuItem value={3}>one</MenuItem>
                          <MenuItem value={4}>one</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                    <Chip
                      label="Bisleri Bottle"
                      sx={{ mr: 1, mb: 2 }}
                      onDelete={() => {
                        setSelectedParentCategory(allProduct);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={landingScreen11}
                    alt=""
                    className=""
                    style={{
                      width: 400,
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 16,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
