import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Popover,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Alert,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import { useState } from "react";
import { useDispatch } from "react-redux";
import plusIcon from "../../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../../assets/images/structure/minus-icon.svg";
import { API } from "../../ENUM";
import { useEffect } from "react";
import GlobalService from "../../../services/GlobalService";

export default function PickupTrafficLightSystem() {
  const dispatch = useDispatch();
  const [PickingTime, setPickingTime] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [QcTime, setQcTime] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [Below, setBelow] = useState(1);
  const [BelowError, setBelowError] = useState("");
  const [PickError, setPickError] = useState("");
  const [QcError, setQcError] = useState("");

  const [StartPicking, setStartPicking] = useState({
    color: "#d60404",
    time: "",
    showPicker: false,
    anchorEl: null,
  });
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [GetData, setGetData] = useState("");
  const clearItem = () => {
    setBelow(1);
    setQcTime({
      color: "#d60404",
      time: "",
      showPicker: false,
      anchorEl: null,
    });
    setPickingTime({
      color: "#d60404",
      time: "",
      showPicker: false,
      anchorEl: null,
    });
  };

  const GET_Time = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_TIME;
      const response = await GlobalService(url);
      setGetData(response.results);
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };
  useEffect(() => {
    GET_Time();
    GET_Time_Start_Picking();
  }, []);
  const DeleteApi = async (id) => {
    try {
      const response = await GlobalService(
        API.TRAFFIC_LIGHT.DELETE_TRAFFIC_LIGHT(id),
        {},
        "post"
      );
      if (response.ack === 1) {
        setTimeout(() => {
          GET_Time();
        }, 100);
      }

      if (response) {
        setToaster({
          status: "success",
          text: `Remove successfully`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 2000);
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const CreateTraffic = async () => {
    let isValid = true;
    if (!PickingTime.time) {
      setPickError("Please Enter Picking Time");
      isValid = false;
    }
    if (!QcTime.time) {
      setQcError("Please Enter Qc Time");
      isValid = false;
    }
    if (Below===0) {
      setBelowError("Please Enter a Value More Than 0")
      isValid = false;
    }
    if (isValid) {
      const data = {
        inPickTime: PickingTime.time,
        inQcTime: QcTime.time,
        inPickColor: PickingTime.color,
        inQcColor: QcTime.color,
        noOfItem: Below,
      };
      try {
        const response = await GlobalService(
          API.TRAFFIC_LIGHT.Create_Traffic,
          data,
          "post"
        );
        if (response.ack === 1) {
          setTimeout(() => {
            GET_Time();
          }, 100);
          clearItem();
        }

        if (response) {
          setToaster({
            status: "success",
            text: `Picking time add successfully`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({
              status: "",
              text: ``,
              visibilty: false,
            });
          }, 2000);
        }
      } catch (error) {
        console.log("Error_In_store Listing", error);
      }
    }
  };

  const GET_Time_Start_Picking = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_Time_Start_Picking;
      const response = await GlobalService(url);
      setStartPicking((prevState) => ({
        ...prevState,
        color: response?.startTime?.color,
        time: response?.startTime?.time,
      }));
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const Update_Time_Start_Picking = async (arg) => {
    let data;

    if (arg === "reset") {
      data = {
        time: 0,
        color: "white",
      };
    } else {
      data = {
        time: StartPicking?.time,
        color: StartPicking?.color,
      };
    }

    try {
      const response = await GlobalService(
        API.TRAFFIC_LIGHT.Update_Time_Start_Picking,
        data,
        "post"
      );

      if (response) {
        setToaster({
          status: "success",
          text: `Start Picking time Updated successfully`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({
            status: "",
            text: ``,
            visibilty: false,
          });
        }, 2000);
        GET_Time_Start_Picking();
      }
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };
  return (
    <>
      {toaster.visibilty && toaster.status === "success" && (
        <Alert
          severity="success"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {toaster.text}
        </Alert>
      )}

      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography
                variant="h6"
                component="h6"
                mb={2}
                mt={1}
                fontWeight={500}
              >
                Pickup traffic light system 
              </Typography>
            </div>

            <Grid container spacing={2} maxWidth={"600px"} marginBottom={3}>
              <Grid item xs={3}>
                <Typography
                  variant="body"
                  component="h6"
                  mb={2}
                  mt={1}
                  fontWeight={500}
                >
                  Start Picking time
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  size="small"
                  className="small"
                  id="outlined-basic"
                  label="Enter Time"
                  variant="outlined"
                  value={StartPicking.time}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setStartPicking({
                        ...StartPicking,
                        time: value !== "" ? parseInt(value, 10) : 0,
                      });
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                        <Button
                          className="pick-color"
                          onClick={(e) =>
                            setStartPicking({
                              ...StartPicking,
                              showPicker: !StartPicking.showPicker,
                              anchorEl: e.currentTarget,
                            })
                          }
                          style={{
                            backgroundColor: StartPicking.color,
                            minWidth: 30,
                            minHeight: 30,
                            marginTop: 0,
                            marginRight: 66,
                          }}
                        ></Button>
                        <Popover
                          open={StartPicking.showPicker}
                          anchorEl={StartPicking.anchorEl}
                          onClose={() =>
                            setStartPicking({
                              ...StartPicking,
                              showPicker: false,
                            })
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <SketchPicker
                            color={StartPicking.color}
                            onChangeComplete={(col) =>
                              setStartPicking({
                                ...StartPicking,
                                color: col.hex,
                              })
                            }
                          />
                        </Popover>
                      </>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item={true} xs={8} mt={1}> */}
              <Grid mt={1.8}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => Update_Time_Start_Picking("save")}
                  sx={{ ml: 2 }}
                >
                  Save
                </Button>
              </Grid>
              <Grid mt={1.8}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => Update_Time_Start_Picking("reset")}
                  sx={{ ml: 1 }}
                >
                  Reset
                </Button>
              </Grid>

              {/* <Grid item xs={3}>
                  <Button
                    className="pick-color"
                    onClick={(e) =>
                      setStartPicking({
                        ...StartPicking,
                        showPicker: !StartPicking.showPicker,
                        anchorEl: e.currentTarget,
                      })
                    }
                    style={{
                      backgroundColor: StartPicking.color,
                      minWidth: 40,
                      minHeight: 40,
                      marginTop: 0,
                    }}
                  ></Button>
                  <Popover
                    open={StartPicking.showPicker}
                    anchorEl={StartPicking.anchorEl}
                    onClose={() =>
                      setStartPicking({ ...StartPicking, showPicker: false })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <SketchPicker
                      color={StartPicking.color}
                      onChangeComplete={(col) =>
                        setStartPicking({ ...StartPicking, color: col.hex })
                      }
                    />
                  </Popover> 
                </Grid>*/}
            </Grid>

            <Grid container spacing={2} maxWidth={"900px"} marginBottom={3}>
            <Grid item xs={3}>
                <TextField
                  required={true}
                  size="small"
                  className="small"
                  id="outlined-basic"
                  label="Below Number of items"
                  variant="outlined"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setBelow(value !== "" ? parseInt(value, 10) : 0);
                    }
                    setBelowError("")
                  }}
                  value={Below}
                />
                 <span style={{ color: "red" }}>{BelowError}</span>
              </Grid>


              <Grid item xs={4}>
                <TextField
                  required={true}
                  size="small"
                  className="small"
                  id="outlined-basic"
                  label="Picking Time"
                  variant="outlined"
                  value={PickingTime.time}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setPickingTime({
                        ...PickingTime,
                        time: value !== "" ? parseInt(value, 10) : 0,
                      });
                      setPickError("");
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                        <Button
                          className="pick-color"
                          onClick={(e) =>
                            setPickingTime({
                              ...PickingTime,
                              showPicker: !PickingTime.showPicker,
                              anchorEl: e.currentTarget,
                            })
                          }
                          style={{
                            backgroundColor: PickingTime.color,
                            minWidth: 30,
                            minHeight: 30,
                            marginTop: 0,
                            marginRight: 66,
                          }}
                        ></Button>
                        <Popover
                          open={PickingTime.showPicker}
                          anchorEl={PickingTime.anchorEl}
                          onClose={() =>
                            setPickingTime({
                              ...PickingTime,
                              showPicker: false,
                            })
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <SketchPicker
                            color={PickingTime.color}
                            onChangeComplete={(col) =>
                              setPickingTime({
                                ...PickingTime,
                                color: col.hex,
                              })
                            }
                          />
                        </Popover>
                      </>
                    ),
                  }}
                />
                <span style={{ color: "red" }}>{PickError}</span>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  required={true}
                  size="small"
                  className="small"
                  id="outlined-basic"
                  label="QC Time"
                  variant="outlined"
                  value={QcTime.time}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setQcTime({
                        ...QcTime,
                        time: value !== "" ? parseInt(value, 10) : 0,
                      });
                      setQcError("");
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end" className="bg-pink">
                          Minute
                        </InputAdornment>
                        <Button
                          className="pick-color"
                          onClick={(e) =>
                            setQcTime({
                              ...QcTime,
                              showPicker: !QcTime.showPicker,
                              anchorEl: e.currentTarget,
                            })
                          }
                          style={{
                            backgroundColor: QcTime.color,
                            minWidth: 30,
                            minHeight: 30,
                            marginTop: 0,
                            marginRight: 66,
                          }}
                        ></Button>
                        <Popover
                          open={QcTime.showPicker}
                          anchorEl={QcTime.anchorEl}
                          onClose={() =>
                            setQcTime({
                              ...QcTime,
                              showPicker: false,
                            })
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <SketchPicker
                            color={QcTime.color}
                            onChangeComplete={(col) =>
                              setQcTime({
                                ...QcTime,
                                color: col.hex,
                              })
                            }
                          />
                        </Popover>
                      </>
                    ),
                  }}
                />
                <span style={{ color: "red" }}>{QcError}</span>
              </Grid>

              <Grid item xs={1}>
                <Tooltip title="Add" placement="top">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={CreateTraffic}
                  >
                    <img src={plusIcon} alt="plus-icon" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            {/* this is other child */}
            {GetData?.count > 0 &&
              GetData?.rows?.map((item, index) => (
                <Grid container spacing={2} maxWidth={"900px"} marginBottom={3}>
                  <Grid item xs={3}>
                    <TextField
                      required={true}
                      size="small"
                      className="small"
                      id="outlined-basic"
                      label="Below Number of items"
                      variant="outlined"
                      value={item?.no_of_item}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required={true}
                      size="small"
                      className="small"
                      id="outlined-basic"
                      label="Picking Time"
                      variant="outlined"
                      value={item?.in_pick_time}
                      disabled={true}
                      onChange={(e) =>
                        setPickingTime({
                          ...PickingTime,
                          time: e.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end" className="bg-pink">
                              Minute
                            </InputAdornment>

                            <Button
                              className="pick-color"
                              style={{
                                backgroundColor: item?.in_pick_color,
                                minWidth: 30,
                                minHeight: 30,
                                marginTop: 0,
                                marginRight: 66,
                              }}
                            ></Button>
                          </>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      required={true}
                      size="small"
                      className="small"
                      id="outlined-basic"
                      label="QC Time"
                      variant="outlined"
                      value={item?.in_qc_time}
                      disabled={true}
                      onChange={(e) =>
                        setQcTime({
                          ...QcTime,
                          time: e.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end" className="bg-pink">
                              Minute
                            </InputAdornment>
                            <Button
                              className="pick-color"
                              style={{
                                backgroundColor: item?.in_qc_color,
                                minWidth: 30,
                                minHeight: 30,
                                marginTop: 0,
                                marginRight: 66,
                              }}
                            ></Button>
                          </>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Remove" placement="top">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => DeleteApi(item?.id)}
                      >
                        <img src={minusIcon} alt="plus-icon" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}

            {/* <Grid container spacing={2} maxWidth={"950px"} marginBottom={3}>
              <Grid
                item={true}
                xs={8}
                mt={1}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={Update_Time_Start_Picking}
                >
                  Save
                </Button>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
