import { useState, useEffect } from "react";
import { Typography, Table, TableBody, TableCell, TextField , TableHead, TableRow, Grid, Pagination  } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { API } from "../../ENUM";
import { priceHandler, formatedDate } from "../../helper/custom";
import GlobalService from "../../../services/GlobalService";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";

const OrderHistory = ({ TabPanel, value, persnalDetail }) => {

  const orderStatus = [
    {
      status: "shipped",
      path: "shipped"
    },
    {
      status: "placed",
      path: "orders"
    },
    {
      status: "cancelled",
      path: "cancelled"
    },
    {
      status: "readytopick",
      path: "ready-to-pick"
    },
    {
      status: "completed",
      path: "completed"
    },
    {
      status: "rejected",
      path: "rejected"
    },
    {
      status: "pending",
      path: "pending"
    },
    {
      status: "processing",
      path: "processing"
    }
  ]

  const location = useLocation();
  const dispatch = useDispatch();
  const limit = 10;
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState(""); 
  let [total, setTotal] = useState(0);
  let [orderHistory, setOrderHistory] = useState([]);

  const getOrderHistory = async () => {
    if (persnalDetail?.id) {
      dispatch(changeLoader(true));
      let url = API.GET_ORDER_HISTORY;
      url += `?customer_id=${persnalDetail?.id}&page=${page}&limit=${limit}`;
      url += search ? `&search=${search}` : "";
      const result = await GlobalService(url);
      orderHistory = result?.orders;
      setOrderHistory(orderHistory);
      total = Math.ceil(result?.totalCount / limit);
      setTotal(total);
      dispatch(changeLoader(false));
    }
  };

  const getOrderRoutes = (status) => {
    const state = orderStatus.find(stat => stat.status === status)
    return state?.path;
  };

  const handlePageChange = (newPage) => {
    
    page = newPage;
    setPage(page);
    getOrderHistory();
  };

  useEffect(() => {
    if (persnalDetail?.id) {
      getOrderHistory();
    }
  }, [persnalDetail]);

  useEffect(() => {
    const timer = setTimeout(() => {
      page = 1;
      setPage(page);
      getOrderHistory();
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  const SortBy = ({key}) => {

    return (<>
      {/* <ArrowDropUpIcon />
      <ArrowDropDownIcon /> */}
    </>)
  };
  
  return (
    <>
      <TabPanel value={value} index={3}>
        <Grid item xs={12}>
          <div className="top-heading-search">
            <Typography variant="h6" component="h6" mb={3} fontSize={"16px"} fontWeight={500}>Order History</Typography>
            <div className="right-filter">
              <div className="search-box">
                <TextField 
                  id="outlined-size-small"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{ 
                    endAdornment: search ? (<CloseIcon onClick={() => {
                      search = ""; setSearch(search);
                    }} />) : null,
                  }}
                  placeholder="Search by Order ID"
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} mb={4}>
            <Grid item xs={12}>
              <div className="card-white overflow" style={{ marginBottom: "16px" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="short-div"> Order Date <div className="short-box">
                          <SortBy key="orderDate" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Store Name <div className="short-box">
                          <SortBy key="storeName" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Order ID <div className="short-box">
                          <SortBy key="orderId" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Customer Name <div className="short-box">
                          <SortBy key="customerName" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Unique Product(s) <div className="short-box">
                          <SortBy key="noOfItems" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Total Amount <div className="short-box">
                          <SortBy key="totalAmount" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Status <div className="short-box">
                          <SortBy key="status" />
                        </div></div>
                      </TableCell>
                      <TableCell>
                        <div className="short-div">
                          Last modified date <div className="short-box">
                          <SortBy key="lastModified" />
                        </div></div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderHistory.length > 0 ? orderHistory.map((row, index) => {
                      const logs = row.order_status_log !== ''?JSON.parse(row.order_status_log): [];
                      const lastLog = logs?.length > 0 ? logs[logs.length - 1] : {};
                    return (<TableRow key={`orderHistory-${index}`}>
                      <TableCell>{formatedDate(row.createdAt)}</TableCell>
                      <TableCell>{row.store.stores_locales[0].business_name}</TableCell>
                      <TableCell>
                        <Link to={`/order-manage-detail/${getOrderRoutes(row.status)}/${row.id}`}>
                          {row.order_id}
                        </Link>
                        </TableCell>
                      <TableCell>{row.user.full_name}</TableCell>
                      <TableCell>{row.orderCount} Item(s)</TableCell>
                      <TableCell>QAR {priceHandler(row.total_amount)}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{formatedDate(lastLog.updated_at)}</TableCell>
                    </TableRow>)} ) : <TableRow><TableCell colSpan={8} align="center">No Record Found</TableCell></TableRow>}
                  </TableBody>
                </Table>

               {total > 1? <Pagination
                  count={total}
                  page={page}
                  onChange={(e, newPage) => handlePageChange(newPage)} />: null}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </>
  );
};

export default OrderHistory;
