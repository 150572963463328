import React, { useState, useEffect, useRef  } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import uploadImg from "../../../assets/images/structure/upload-img.png";
import CancelIcon from "@mui/icons-material/Cancel";
import constant from "../../../constant";
import { getStoreNameFromLocale, getdataByKey } from "../../helper/custom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader, currentLoader } from "../../../redux/reducers/loader";
import {
  Typography, TextField, Button, Grid, IconButton, Switch, Alert,
  Dialog, DialogContent, Box, Menu, MenuItem, Select
} from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router-dom";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { currentPermission } from "../../../redux/reducers/permission";
import { ImageCropper, base64toFile } from "../../../dialog/Cropper";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { API } from "../../ENUM";
import GlobalService from "../../../services/GlobalService";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { blobToBase64 } from "../../../pages/helper/custom";
import imageCompression from 'browser-image-compression';
import DoneAllIcon from '@mui/icons-material/DoneAll';
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  alwaysKeepResolution: true
}

export default function BusinessCategory() {

  const permission = useSelector(currentPermission);
  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canUpdateStatus, setCanUpdateStatus] = useState(true);
  const currentLoaderValue = useSelector(currentLoader);

  const locations = useLocation();
  const dragItem = useRef();
  const dragOverItem = useRef();
  
  const dragSubItem = useRef();
  const dragOverSubItem = useRef();
  const dragSubSubItem = useRef();
  const dragOverSubSubItem = useRef();

  const dispatch = useDispatch();
  const [selectedBusinessType, setSelectedBusinessType] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [businesstypeData, setBusinesstypeData] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [subCategoryList, setSubCategoryList] = useState([]);
  let [subSubCategoryList, setSubSubCategoryList] = useState([]);
  const [search, setSearch] = useState({
    category: "",
    subCategory: "",
    subSubCategory: "",
  });
  const [modelData, setModelData] = useState({
    isOpen: false,
    text: "",
    level: "",
  });
  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
    type: "",
  });
  const [editModal, setEditModal] = useState({
    isOpen: false,
    id: null,
    name: "",
    name_ar: "",
    category_desc_en: '',
    category_desc_ar: '',
    err: "",
    level: "",
    image: "",
    imageGif: "",
    isValid: false,
    type: "",
  });
  const [reloadKey, setReloadKey] = useState(Math.random());
  const [addCategory, setAddcategory] = useState({
    val: "",
    err: "Required",
    isValid: false,
  });
  const [categorySubTextEn, setCategorySubTextEn] = useState('')
  const [categorySubTextAr, setCategorySubTextAr] = useState('')

  const [addCategoryArabic, setaddCategoryArabic] = useState({
    val: "",
    err: "Required",
    isValid: false,
  });

  const [businessTypeLogo, setBusinessTypeLogo] = useState({
    file: "",
    isValid: false,
    err: "Please upload business logo",
  });
  const [businessTypeGif, setBusinessTypeGif] = useState({
    file: "",
    isValid: false,
    err: "Please upload GIF File",
  });
  let [cropperData, setCropperData] = useState({
    croppedImage: "",
    isOpen: false,
  });
  let [cropperData2, setCropperData2] = useState({
    croppedImage: "",
    isOpen: false,
  });

  // listing of all type of business
  useEffect(() => {
    GetAllBusinessType();
  }, []);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...categoryList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCategoryList(copyListItems);
    const data = copyListItems.map((item, index) => {
      return {
        id: item?.id,
        order: index + 1,
        parent_id: item?.parent_id,
        businessTypeId: item?.businessTypeId,
      }
    })
  };

  const dragSubStart = (e, position) => {
    dragSubItem.current = position;
  };

  const dragSubEnter = (e, position) => {
    dragOverSubItem.current = position;
  };

  const dropSub = (e) => {
    const copyListItems = [...subCategoryList];
    const dragItemContent = copyListItems[dragSubItem.current];
    copyListItems.splice(dragSubItem.current, 1);
    copyListItems.splice(dragOverSubItem.current, 0, dragItemContent);
    dragSubItem.current = null;
    dragOverSubItem.current = null;
    setSubCategoryList(copyListItems);
    const data = copyListItems.map((item, index) => {
      return {
        id: item?.id,
        order: index + 1,
        parent_id: item?.parent_id,
        businessTypeId: item?.businessTypeId,
      }
    })
  };

  const dragSubSubStart = (e, position) => {
    dragSubSubItem.current = position;
  };

  const dragSubSubEnter = (e, position) => {
    dragOverSubSubItem.current = position;
  };

  const dropSubSub = (e) => {
    const copyListItems = [...subSubCategoryList];
    const dragItemContent = copyListItems[dragSubSubItem.current];
    copyListItems.splice(dragSubSubItem.current, 1);
    copyListItems.splice(dragOverSubSubItem.current, 0, dragItemContent);
    dragSubSubItem.current = null;
    dragOverSubSubItem.current = null;
    setSubSubCategoryList(copyListItems);
    const data = copyListItems.map((item, index) => {
      return {
        id: item?.id,
        order: index + 1,
        parent_id: item?.parent_id,
        businessTypeId: item?.businessTypeId,
      }
    })
  };

  const selectBusinessTypeHandler = async (event = null) => {
    const selectedBusiness = event ? event.target.value : selectedBusinessType;
    setSelectedBusinessType(selectedBusiness);
    if (selectedBusiness && selectedBusiness.category_level > 0) {
      if (selectedBusiness.category_level >= 1) {
        const query = new URLSearchParams({
          businessTypeId: selectedBusiness.id,
          parent_id: 0,
        }).toString();
        dispatch(changeLoader(true));
        let catList = (await services.Category.GET_CATEGORIES(query)).data
          .categories;
        setCategoryList(createCategoryArr(catList));
        dispatch(changeLoader(false));
        if (selectedBusiness.category_level >= 2) {
          if (catList && catList.length) {
            const query = new URLSearchParams({
              businessTypeId: selectedBusiness.id,
              parent_id: catList[0].id,
            }).toString();
            dispatch(changeLoader(true));
            const subCatList = (await services.Category.GET_CATEGORIES(query))
              .data.categories;

            setSubCategoryList(createCategoryArr(subCatList));
            dispatch(changeLoader(false));
            if (selectedBusiness.category_level === 3) {
              if (subCatList && subCatList.length) {
                const query = new URLSearchParams({
                  businessTypeId: selectedBusiness.id,
                  parent_id: subCatList[0].id,
                }).toString();
                dispatch(changeLoader(true));
                const subSubCatList = await services.Category.GET_CATEGORIES(query);
                setSubSubCategoryList(
                  createCategoryArr(subSubCatList.data.categories)
                );
                dispatch(changeLoader(false));
              } else {
                setSubSubCategoryList([]);
              }
            }
          } else {
            setSubCategoryList([]);
            setSubSubCategoryList([]);
          }
        }
      }
    } else {
      setCategoryList([]);
      setSubSubCategoryList([]);
      setSubSubCategoryList([]);
    }
  };

  const createCategoryArr = (arr, index = 0) => {
    const res = arr.map((item) => ({ ...item, isActive: false }));
    if (res && res.length) {
      res[index].isActive = true;
    }
    return res;
  };

  const handleClick = (e, item, type) => {
    // setAnchorEl(e.currentTarget);
    // setDeleteModal({ type, id: item.id, isOpen: false });
    setEditModal({
      isValid: true,
      err: "",
      isOpen: true,
      id: item.id,
      name: getdataByKey(item.category_locales, "en", "name"),
      name_ar: getdataByKey(item.category_locales, "ar", "name"),
      category_desc_en: getdataByKey(item.category_locales, "en", "description"),
      category_desc_ar: getdataByKey(item.category_locales, "ar", "description"),
      image: item.image,
      imageGif: item.image_gif,
      type,
    });
  };

  const handelCategoryChange = async (index, level, search = null, type='search') => {
    switch (level) {
      case 1: {
        let catList = categoryList;
        if (!search || !search.trim().length) {
          if (type === 'search') {
          const catQuery = new URLSearchParams({
            businessTypeId: selectedBusinessType.id,
            parent_id: 0,
          }).toString();
          dispatch(changeLoader(true));
          catList = (await services.Category.GET_CATEGORIES(catQuery)).data
            .categories;
          setCategoryList(createCategoryArr(catList, index));
        } else {
          setCategoryList(categoryList.map((item, ind) => ({
            ...item,
            isActive: index === ind
          })));
        }

          dispatch(changeLoader(false));
        } else {
          const query = new URLSearchParams({
            businessTypeId: selectedBusinessType.id,
            parent_id: 0,
            search,
          }).toString();
          dispatch(changeLoader(true));
          catList = (await services.Category.GET_CATEGORIES(query)).data
            .categories;
          setCategoryList(
            catList && catList.length
              ? createCategoryArr(catList)
              : setCategoryList([])
          );
          index = 0;
          dispatch(changeLoader(false));
        }
        if (catList && catList.length) {
          const subCatQuery = new URLSearchParams({
            businessTypeId: selectedBusinessType.id,
            parent_id: catList[index].id,
          }).toString();
          dispatch(changeLoader(true));
          const subCatList = (
            await services.Category.GET_CATEGORIES(subCatQuery)
          ).data.categories;
          setSubCategoryList(createCategoryArr(subCatList));
          dispatch(changeLoader(false));
          if (subCatList && subCatList.length) {
            const subSubQuery = new URLSearchParams({
              businessTypeId: selectedBusinessType.id,
              parent_id: subCatList[0].id,
            }).toString();
            dispatch(changeLoader(true));
            const subSubCatList = (
              await services.Category.GET_CATEGORIES(subSubQuery)
            ).data.categories;
            setSubSubCategoryList(createCategoryArr(subSubCatList));
            dispatch(changeLoader(false));
          } else {
            setSubSubCategoryList([]);
          }
        } else {
          setReloadKey(Math.random());
          setCategoryList([]);
          setSubCategoryList([]);
          setSubSubCategoryList([]);
        }

        break;
      }
      case 2: {
        let subCatList = subCategoryList;
        const selectedCategory = categoryList.filter(
          (item) => item.isActive === true
        );
        if (!search || !search.trim().length) {
          if (search === null) {
            if (type === 'search') {
              const subCatQuery = new URLSearchParams({
                parent_id: selectedCategory[0].id,
              }).toString();
              dispatch(changeLoader(true));
              subCatList = (await services.Category.GET_CATEGORIES(subCatQuery))
                .data.categories;
              setSubCategoryList(createCategoryArr(subCatList, index));
            } else {
              setSubCategoryList(subCategoryList.map((item, ind) => ({
                ...item,
                isActive: index === ind
              })));
            }

            dispatch(changeLoader(false));
          }
        } else {
          const query = new URLSearchParams({
            parent_id: selectedCategory[0].id,
            search,
          }).toString();
          dispatch(changeLoader(true));
          subCatList = (await services.Category.GET_CATEGORIES(query)).data
            .categories;
          setSubCategoryList(
            subCatList && subCatList.length ? createCategoryArr(subCatList) : []
          );
          dispatch(changeLoader(false));
          // index = 0;
        }
        if (subCatList && subCatList.length) {
          const query = new URLSearchParams({
            parent_id: subCatList[index].id,
          }).toString();
          dispatch(changeLoader(true));
          const subSubCatList = (await services.Category.GET_CATEGORIES(query))
            .data.categories;
          setSubSubCategoryList(createCategoryArr(subSubCatList));
          dispatch(changeLoader(false));
        } else {
          setSubCategoryList([]);
          setSubSubCategoryList([]);
        }
        break;
      }
      case 3: {
        const selectedSubCategory = subCategoryList.filter(
          (item) => item.isActive === true
        );
        setSubSubCategoryList(createCategoryArr(subSubCategoryList, index));
        let subSubCatQuery = {};
        if ((!search || !search.trim().length) && search === null) {
          subSubCatQuery = new URLSearchParams({
            parent_id: selectedSubCategory[0].id,
          }).toString();
        } else {
          subSubCatQuery = new URLSearchParams({
            parent_id: selectedSubCategory[0].id,
            search,
          }).toString();
        }
        dispatch(changeLoader(true));
        const subSubCatList = (
          await services.Category.GET_CATEGORIES(subSubCatQuery)
        ).data.categories;
        setSubSubCategoryList(createCategoryArr(subSubCatList, index));
        dispatch(changeLoader(false));
        break;
      }

      default:
        break;
    }
  };

  const addCategoryHandler = (level) => {
    switch (level) {
      case 1:
        {
          setModelData({ text: "Add Category", isOpen: true, level });
        }

        break;
      case 2:
        {
          setModelData({ text: "Add Sub Category", isOpen: true, level });
        }

        break;
      case 3:
        {
          setModelData({ text: "Add Sub Sub Category", isOpen: true, level });
        }

        break;

      default:
        break;
    }
    setCategorySubTextEn('')
    setCategorySubTextAr('')
    // setModelData(categoryName);
    // setOpenBusinessTypeAdd(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setModelData({ isOpen: false, text: "", level: "" });
    setAddcategory({
      val: "",
      err: "Required",
      isValid: false,
    });

    setaddCategoryArabic({
      val: "",
      err: "Required",
      isValid: false,
    });

    setBusinessTypeLogo({
      file: "",
      err: "Required",
      isValid: false,
    });
    setBusinessTypeGif({
      file: "",
      isValid: false,
      err: "Required",
    });
    setCategorySubTextEn('')
    setCategorySubTextAr('')
  };

  const GetAllBusinessType = async () => {
    try {
      dispatch(changeLoader(true));
      const response = await services.BusinessType.GET_ALL_BUSINESS_TYPE();
      if (response) {
        setBusinesstypeData(response.data.businesstype);

        let baseBusinessType = response.data.businesstype[0];
        if (locations.search.split("=")[1]) {
          baseBusinessType = response.data.businesstype.find(
            (item) => item.id == locations.search.split("=")[1]
          )
        }

        setSelectedBusinessType(baseBusinessType);
        const query = new URLSearchParams({
          businessTypeId: baseBusinessType?.id,
          parent_id: 0,
        }).toString();
        // geting category list
        let catList = (await services.Category.GET_CATEGORIES(query)).data
          .categories;
        if (catList && catList.length) {
          catList = catList.map((item) => ({
            ...item,
            isActive: false,
          }));
          catList[0].isActive = true;
          setCategoryList(catList);

          // geting subCategory list
          const query = new URLSearchParams({
            businessTypeId: baseBusinessType.id,
            parent_id: catList[0].id,
          }).toString();
          let subCatList = (await services.Category.GET_CATEGORIES(query)).data
            .categories;
          if (subCatList && subCatList.length) {
            subCatList = subCatList.map((item) => ({
              ...item,
              isActive: false,
            }));
            subCatList[0].isActive = true;
            setSubCategoryList(subCatList);

            if (subCatList && subCatList.length) {
              const query = new URLSearchParams({
                businessTypeId: baseBusinessType.id,
                parent_id: subCatList[0].id,
              }).toString();

              let subSubCatList = (
                await services.Category.GET_CATEGORIES(query)
              ).data.categories;
              if (subSubCatList && subSubCatList.length) {
                subSubCatList = subSubCatList.map((item) => ({
                  ...item,
                  isActive: false,
                }));
                subSubCatList[0].isActive = true;
                setSubSubCategoryList(subSubCatList);
              }
            }
          }
        }
      }
      dispatch(changeLoader(false));
    } catch (error) {}
  };

  const onChangeCategory = (e, type) => {
    if (type == "en") {
      if (e.target.value.length == 0) {
        setAddcategory({
          val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
          err: "",
          isValid: false,
        });
      } else {
        if (e.target.value.length > 50) {
          setAddcategory({
            val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
            err: "max 50 char allowed",
            isValid: false,
          });
        } else {
          setAddcategory({
            val: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
            err: "",
            isValid: true,
          });
        }
      }
    }
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setaddCategoryArabic({
          val: e.target.value,
          err: "",
          isValid: false,
        });
      } else {
        setaddCategoryArabic({
          val: e.target.value,
          err: "",
          isValid: true,
        });
      }
    }
  };

  const onSaveCategoryHandler = async (level) => {
    try {
      dispatch(changeLoader(true));
      let parent;
      let index = 0;
      if (addCategory.isValid) {
        switch (level) {
          case 1:
            parent = 0;
            for (let i = 0; i < categoryList.length; i++) {
              if (categoryList[i].isActive) {
                break;
              }
              index++;
            }
            break;
          case 2: {
            for (let i = 0; i < categoryList.length; i++) {
              if (categoryList[i].isActive) {
                break;
              }
              index++;
            }
            parent = categoryList[index].id;
            break;
          }
          case 3: {
            for (let i = 0; i < subCategoryList.length; i++) {
              if (subCategoryList[i].isActive) {
                break;
              }
              index++;
            }
            parent = subCategoryList[index].id;
            break;
          }
          default:
            break;
        }
        const data = {
          name_en: addCategory.val,
          name_ar: addCategoryArabic.val,
          description_en: categorySubTextEn,
          description_ar: categorySubTextAr,
          parent_id: parent,
          businessTypeId: selectedBusinessType.id,
          image: businessTypeLogo.file,
          imageGif: businessTypeGif.file,
        };
        var bodyFormData = new FormData();
        Object.keys(data).forEach((key) => {
          bodyFormData.append(key, data[key]);
        });
        const response = await services.Category.ADD_CATEGORIES(bodyFormData);

        if (response) {
          //  GetAllBusinessType();
          setModelData({ isOpen: false, text: "", level: "" });
          setAddcategory({
            val: "",
            err: "Required",
            isValid: false,
          });
          setBusinessTypeLogo({
            file: "",
            err: "Required",
            isValid: false,
          });
          setBusinessTypeGif({
            file: "",
            isValid: false,
            err: "Required",
          });
          // handelCategoryChange(0, level,null,true);
          // selectBusinessTypeHandler();
          if (level > 1) {
            handelCategoryChange(index, level - 1);
          } else {
            selectBusinessTypeHandler();
          }
        }
        dispatch(changeLoader(false));
      }
    } catch (error) {
      dispatch(changeLoader(false));
      setModelData({ isOpen: false, text: "", level: "" });
      setAddcategory({
        val: "",
        err: "Required",
        isValid: false,
      });
      setBusinessTypeLogo({
        file: "",
        err: "Required",
        isValid: false,
      });
      setBusinessTypeGif({
        file: "",
        isValid: false,
        err: "Required",
      });
    }
  };

  const editChangeHandler = (e, type = "en") => {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setEditModal({
          ...editModal,
          err: "",
          isValid: false,
          isOpen: true,
          name_ar: e.target.value,
        });
      } else {
        if (e.target.value) {
          if (e.target.value.length > 50) {
            setEditModal({
              ...editModal,
              err: "max 50 character allowed",
              isValid: false,
              isOpen: true,
              name_ar: e.target.value,
            });
          } else {
            setEditModal({
              ...editModal,
              err: "",
              isValid: true,
              isOpen: true,
              name_ar: e.target.value,
            });
          }
        } else {
          setEditModal({
            ...editModal,
            err: "required",
            isValid: false,
            isOpen: true,
            name_ar: e.target.value,
          });
        }
      }

      return true;
    }
    if (e.target.value.length == 0) {
      setEditModal({
        ...editModal,
        err: "",
        isValid: false,
        isOpen: true,
        name: e.target.value,
      });
    } else {
      if (e.target.value) {
        if (e.target.value.length > 50) {
          setEditModal({
            ...editModal,
            err: "max 50 character allowed",
            isValid: false,
            isOpen: true,
            name: e.target.value,
          });
        } else {
          setEditModal({
            ...editModal,
            err: "",
            isValid: true,
            isOpen: true,
            name: e.target.value,
          });
        }
      } else {
        setEditModal({
          ...editModal,
          err: "required",
          isValid: false,
          isOpen: true,
          name: e.target.value,
        });
      }
    }
  };

  const editImageChangeHandler = async (e, key) => {
    if (key === "image") {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const compressedFile = await imageCompression(file, options);
        const imageUrl = await blobToBase64(compressedFile);
        cropperData = {
          croppedImage: imageUrl,
          isOpen: true,
        };
        setCropperData(cropperData);
      } else {
        setEditModal({
          ...editModal,
          image: "",
        });
      }
    } else if (key === "gif") {
      if (e.target.files[0]) {
        setEditModal({
          ...editModal,
          imageGif: e.target.files[0],
        });
      } else {
        setEditModal({
          ...editModal,
          imageGif: "",
        });
      }
    }
  };

  const handleToggle = async (id, status, levelCategory) => {
    try {
      dispatch(changeLoader(true));
      const response = await services.Category.UPDATE_CATEGORIES(id, {
        status: status == false ? "inactive" : "active",
      });
      // selectBusinessTypeHandler();
      switch (levelCategory) {
        case 1:
          categoryList = categoryList.map((obj, index) => {
            if(obj.id === id) {
              obj.status = status == false ? "inactive" : "active"
            }
            return obj;
          })
          setCategoryList(categoryList)
          break;
        case 2:
          
          subCategoryList = subCategoryList.map((obj, index) => {
            if(obj.id === id) {
              obj.status = status == false ? "inactive" : "active"
            }
            return obj;
          })
          setSubCategoryList(subCategoryList)
          break;
        case 3:
          
          subSubCategoryList = subSubCategoryList.map((obj, index) => {
            if(obj.id === id) {
              obj.status = status == false ? "inactive" : "active"
            }
            return obj;
          })
          setSubSubCategoryList(subSubCategoryList)
          break;
        default:
          break;
      }

      dispatch(changeLoader(false));
    } catch (error) {}
  };

  const handleOnEdit = async () => {
    try {
      dispatch(changeLoader(true));
      try {

        if (editModal.name !== "") {
          const data = {
            name: editModal.name,
            name_ar: editModal.name_ar,
            image: editModal.image,
            imageGif: editModal.imageGif,
            description_en: editModal.category_desc_en,
            description_ar: editModal.category_desc_ar
          };
          // if(editModal.imageGif)
          var bodyFormData = new FormData();
          Object.keys(data).forEach((key) => {
            bodyFormData.append(key, data[key]);
          });
          const response = await services.Category.UPDATE_CATEGORIES(
            editModal.id,
            bodyFormData
          );
          if (response) {
            setEditModal({
              isOpen: false,
              id: null,
              name: "",
              name_ar: "",
              category_desc_en: '',
              category_desc_ar: '',
              err: "",
              level: "",
              image: "",
              imageGif: "",
              isValid: false,
              type: "",
            });
            handleClose();
            setToaster({
              status: "success",
              text: `Category Updated Successfully`,
              visibilty: true,
            });
            setTimeout(() => {
              setToaster({ ...toaster, visibilty: false });
            }, 6000);
            selectBusinessTypeHandler();
          }
        }
      } catch (error) {
        console.log(error);
        dispatch(changeSnackbar({
          isOpen: true,
          message: 'Something went wrong',
          state: "error",
        }));
      }
      dispatch(changeLoader(false));
    } catch (error) {
      setEditModal({
        isOpen: false,
        id: null,
        name: "",
        name_ar: "",
        category_desc_en: '',
        category_desc_ar: '',
        err: "",
        level: "",
        image: "",
        imageGif: "",
        isValid: false,
        type: "",
      });
      dispatch(changeLoader(false));
    }
  };

  const handleOnDelete = async () => {
    try {
      dispatch(changeLoader(true));
      const response = await services.Category.DELETE_CATEGORIES(
        deleteModal.id
      );

      if (response) {
        handleClose();
        setDeleteModal({ isOpen: false, id: null });
        setToaster({
          status: "success",
          text: `Category Deleted Successfuly`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        selectBusinessTypeHandler();
      }
      setDeleteModal({ id: null, isOpen: false });
      dispatch(changeLoader(false));
    } catch (error) {
      handleClose();
      if (error.response.status === 403) {
        setDeleteModal({ isOpen: false, id: null });
        setToaster({
          status: "error",
          text: `${error.response.data.msg}!`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        dispatch(changeLoader(false));
        return;
      }
    }
  };

  const BasicMenu = ({
    obj, type, handleEdit, handleDelete,
    canEdit, canDelete
  }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    
    const handleClose = () => {setAnchorEl(null);};

    if (obj?.is_approved !== 0) {
      return (
        <div>
          { canEdit || canDelete ?
            <Button style={{minWidth: 'auto'}}
            id="basic-button" aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true" aria-expanded={open ? "true" : undefined}
            onClick={handleClick} type="button"
          ><MoreVertIcon /></Button>: null}
          {canEdit || canDelete ?<Menu
            id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}
            MenuListProps={{"aria-labelledby": "basic-button",}}
          >
            {canEdit?<MenuItem onClick={(e) => handleEdit(e,obj, type )}><BorderColorIcon />Edit</MenuItem>: null}
            {canDelete?<MenuItem onClick={(e) => handleDelete(e,obj, type )}><DeleteForever />Delete</MenuItem>: null}
          </Menu>: null}
        </div>
      );
    }
  };

  const isDisabled = () => {
    if (!addCategory.isValid) return true;
    if (!addCategoryArabic.isValid) return true;
    return false;
  };

  const isDisabledEdit = () => {
    if (editModal.name == "") return true;
    if (editModal.name_ar == "") return true;
    return false;
  };

  const handleMarkForBusiness = async (id, categoryStatus) => {
    const data = {
      categoryId: id,
      businessTypeId: selectedBusinessType.id,
      isBusinessLanding: categoryStatus
    }
    const response = await GlobalService(API.UPDATE_CATEGORY_BUSINESS_PAGE, data, "put")
    if (response.ack === 1) {
      categoryList = categoryList.map((obj) => {
        if(obj.id === id) { obj['is_business_landing'] = categoryStatus }
        return obj;
      })
      setCategoryList(categoryList)
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: "Category updated successfully"
      }));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Something went wrong!",
      }));
    }
    dispatch(changeLoader(false));
  }

  const handleClickSpecial = async (categoryId, status, type) => {
    dispatch(changeLoader(true));
    const data = {
      categoryId: categoryId,
      status: status,
      type: type,
    }
    const response = await GlobalService(API.UPDATE_CATEGORY_TYPE, data, "put")
    if (response.ack === 1) {
      categoryList = categoryList.map((obj) => {
        if(obj.id === categoryId) { obj[type] = status }
        return obj;
      })
      setCategoryList(categoryList)
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: "Category updated successfully"
      }));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Something went wrong!",
      }));
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.businessCategory?.create === 1)
      setCanEdit(permission?.businessCategory?.edit === 1)
      setCanDelete(permission?.businessCategory?.delete === 1)
      setCanUpdateStatus(permission?.businessCategory?.actions.status === 1)
    } else {
      setCanCreate(true)
      setCanEdit(true)
      setCanDelete(true)
      setCanUpdateStatus(true)
    }
  }, [permission]);
  
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={2} fontWeight={500}>
              Manage Category
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"14px"}
                  mb={0}
                  mr={2}
                  fontWeight={500}
                >
                  Change Business Type
                </Typography>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={"Grocery"}
                  value={selectedBusinessType}
                  size="small"
                  onChange={(e) => {
                    selectBusinessTypeHandler(e);
                    setSearch({
                      category: "",
                      subCategory: "",
                      subSubCategory: "",
                    });
                  }}
                  className="min-width150"
                >
                  {businesstypeData.map((typeName, index) => (
                    <MenuItem key={index} value={typeName} id={typeName.id}>
                      {getStoreNameFromLocale(
                        typeName?.business_type_locales,
                        "en"
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {selectedBusinessType?.category_level >= 1 && (
                <Grid item xs={4}>
                  <div className="category-box">
                    <div className="category-head">
                      <Typography
                        variant="h6"
                        component="h6"
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        Category
                      </Typography>
                      {canCreate?<IconButton onClick={() => addCategoryHandler(1)}>
                        <AddCircleOutlineIcon />
                      </IconButton>: null}
                    </div>
                    <div className="search-box">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        value={search.category}
                        onChange={(e) => {
                          handelCategoryChange(
                            0,
                            1,
                            e.target.value.trim().length ? e.target.value : null
                          );
                          setSearch({ ...search, category: e.target.value });
                        }}
                        placeholder="Search.."
                        variant="outlined"
                        InputProps={{
                          endAdornment: search.category ? (
                            <ClearIcon
                              sx={{ marginLeft: "8px" }}
                              onClick={() => {
                                setSearch({
                                  ...search,
                                  category: "",
                                });
                                handelCategoryChange(0, 1, '');
                              }}
                            />
                          ) : (
                            ""
                          ),
                        }}
                      />
                    </div>

                    <div className="category-content">
                      {categoryList && categoryList.length ? categoryList.map((item, index) => 
                      <div
                        className={`category-row ${item.isActive ? "active" : ""}`}
                        key={`mailcat-${index}`}
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        draggable
                      >
                        <p className="category-name"
                          onClick={() => {
                            // hhhhh
                            handelCategoryChange(index, 1, null, 'click');
                            setSearch({ ...search, subCategory: "", subSubCategory: "", });
                          }}
                        >{getdataByKey( item?.category_locales, "en", "name" )}</p>

                        {selectedBusinessType?.id === 2 && <WorkspacePremiumIcon
                          className={`mx-1`}
                          style={{fill: `${item.premium_category === 1 ? '#ed4731' : ''}`}}
                          onClick={() => handleClickSpecial(item.id, item.premium_category === 1?0:1, 'premium_category') }
                        />}
                        
                        {selectedBusinessType?.id === 2 && <WhatshotIcon
                          className={`mx-1`}
                          style={{fill: `${item.hot_deal === 1  ? '#ed4731' : ''}`}}
                          onClick={() => handleClickSpecial(item.id, item.hot_deal === 1?0:1, 'hot_deal') }
                        />}

                        <DoneAllIcon
                          className={`mx-1`}
                          style={{fill: `${item.is_business_landing  ? '#ed4731' : ''}`}}
                          onClick={() => handleMarkForBusiness(item.id, item.is_business_landing === true?false:true, 'business') }
                        />
                        
                        <Switch
                          checked={item.status === "active" ? true : false}
                          onClick={(e) => handleToggle(item.id, e.target.checked, 1)}
                          disabled={!canUpdateStatus}
                        />
                        <BasicMenu
                          key={`menu-${index}`}
                          obj={item}
                          id="long-button"
                          type="Category"
                          handleEdit={(e, item, type) => handleClick(e, item, type) }
                          handleDelete={(e, item, type) => setDeleteModal({ type, id: item.id, isOpen: true }) }
                          canEdit={canEdit}
                          canDelete={canDelete}
                        />
                      </div>): "No category"}
                    </div>
                  </div>
                </Grid>
              )}
              {selectedBusinessType.category_level >= 2 && (
                <Grid item xs={4}>
                  <div className="category-box">
                    <div className="category-head">
                      <Typography
                        variant="h6"
                        component="h6"
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        Sub Category
                      </Typography>
                      {categoryList && categoryList.length ? (
                        canCreate?<IconButton onClick={() => addCategoryHandler(2)}>
                          <AddCircleOutlineIcon />
                        </IconButton>: null
                      ) : null}
                    </div>
                    <div className="search-box">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        value={search.subCategory}
                        onChange={(e) => {
                          handelCategoryChange(
                            0,
                            2,
                            e.target.value.trim().length ? e.target.value : null
                          );
                          setSearch({ ...search, subCategory: e.target.value });
                        }}
                        placeholder="Search.."
                        variant="outlined"
                        InputProps={{
                          endAdornment: search.subCategory ? (
                            <ClearIcon
                              sx={{ marginLeft: "8px" }}
                              onClick={() => {
                                setSearch({
                                  ...search,
                                  subCategory: "",
                                });
                                handelCategoryChange(0, 2, null);
                              }}
                            />
                          ) : (
                            ""
                          ),
                        }}
                      />
                    </div>
                    <div className="category-content" key={reloadKey}>
                      {subCategoryList && subCategoryList.length ? subCategoryList.map((item, index) => 
                      <div
                        className={`category-row ${item.isActive ? "active" : ""}`}
                        key={index}
                        onDragStart={(e) => dragSubStart(e, index)}
                        onDragEnter={(e) => dragSubEnter(e, index)}
                        onDragEnd={dropSub}
                        draggable
                      >
                        <p className="category-name"
                          onClick={() => {
                            handelCategoryChange(index, 2, null, 'click');
                            setSearch({ ...search, subSubCategory: "", });
                          }}
                        >{getdataByKey( item?.category_locales, "en", "name" )}</p>
                        <Switch
                          checked={item.status === "active" ? true : false}
                          onClick={(e) => handleToggle(item.id, e.target.checked, 2)}
                          disabled={!canUpdateStatus}
                        />
                        <BasicMenu
                          key={`menu-${index}`}
                          obj={item}
                          id="long-button"
                          type="Sub Category"
                          handleEdit={(e, item, type) => handleClick(e, item, type) }
                          handleDelete={(e, item, type) => setDeleteModal({ type, id: item.id, isOpen: true }) }
                          canEdit={canEdit}
                          canDelete={canDelete}
                        />
                      </div>) : "No category"}
                    </div>
                  </div>
                </Grid>
              )}
              {selectedBusinessType.category_level >= 3 && (
                <Grid item xs={4}>
                  <div className="category-box">
                    <div className="category-head">
                      <Typography
                        variant="h6"
                        component="h6"
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        Sub Sub Category
                      </Typography>
                      {subCategoryList && subCategoryList.length ? (
                        canCreate?<IconButton onClick={() => addCategoryHandler(3)}>
                          <AddCircleOutlineIcon />
                        </IconButton>: null
                      ) : null}
                    </div>
                    <div className="search-box">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        value={search.subSubCategory}
                        onChange={(e) => {
                          handelCategoryChange(
                            0,
                            3,
                            e.target.value.trim().length ? e.target.value : null
                          );

                          setSearch({
                            ...search,
                            subSubCategory: e.target.value,
                          });
                        }}
                        placeholder="Search.."
                        variant="outlined"
                        InputProps={{
                          endAdornment: search.subSubCategory ? (
                            <ClearIcon
                              sx={{ marginLeft: "8px" }}
                              onClick={() => {
                                setSearch({
                                  ...search,
                                  subSubCategory: "",
                                });
                                handelCategoryChange(0, 3, null);
                              }}
                            />
                          ) : (
                            ""
                          ),
                        }}
                      />
                    </div>
                    <div className="category-content" key={reloadKey}>
                      {subSubCategoryList && subSubCategoryList.length ? subSubCategoryList.map((item, index) => 
                      <div
                        className={`category-row ${item.isActive ? "active" : ""}`}
                        key={`sub-sub-${index}`}
                        onDragStart={(e) => dragSubSubStart(e, index)}
                        onDragEnter={(e) => dragSubSubEnter(e, index)}
                        onDragEnd={dropSubSub}
                        draggable
                      >
                        <p className="category-name"
                          onClick={() => {
                            handelCategoryChange(index, 3, null, 'click');
                            // setSearch({ category: "", subCategory: "", subSubCategory: "", });
                          }}
                        >{getdataByKey( item?.category_locales, "en", "name" )}</p>
                        <Switch
                          checked={item.status === "active" ? true : false}
                          onClick={(e) => handleToggle(item.id, e.target.checked, 3)}
                          disabled={!canUpdateStatus}
                        />
                        <BasicMenu
                          key={`menu-${index}`}
                          obj={item}
                          id="long-button"
                          type="Sub Sub Category"
                          handleEdit={(e, item, type) => handleClick(e, item, type) }
                          handleDelete={(e, item, type) => setDeleteModal({ type, id: item.id, isOpen: true }) }
                          canEdit={canEdit}
                          canDelete={canDelete}
                        />
                      </div>) : "No category"}
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog open={modelData.isOpen} onClose={() => handleClose()}>
        <img
          src={modalClose}
          alt="..."
          onClick={() => handleClose()}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            {modelData.text}
          </Typography>

          <form>
            <Grid container spacing={2}>
              {modelData.level === 1 || modelData.level === 2 || modelData.level === 3 ? 
              <Grid item xs={12}>
                <div
                  className="upload-img"
                  style={{ margin: "0px 0px 30px 0px" }}
                >
                  {!(businessTypeLogo && businessTypeLogo.isValid) ? 
                  <div className="before-upload">
                    <img src={uploadImg} alt=".." className="upload-icon" />
                    <p className="upload-text">Upload Image</p>
                    <input
                      type="file"
                      accept={`image/png, image/jpeg`}
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const compressedFile = await imageCompression(file, options);
                        const imageUrl = await blobToBase64(compressedFile);
                        cropperData2 = {
                          croppedImage: imageUrl,
                          isOpen: true,
                        };
                        setCropperData2(cropperData2);
                      }}
                    />
                  </div>:
                  <div className="after-upload">
                    <CancelIcon
                      className="delete-icon"
                      onClick={() => {
                        setBusinessTypeLogo({
                          file: "",
                          isValid: false,
                          err: "Please upload business logo",
                        });
                      }}
                    />
                    <img
                      src={URL.createObjectURL(businessTypeLogo.file)}
                      alt=".."
                      className="upload-icon"
                    />
                    <input
                      type="file"
                      accept={`image/png, image/jpeg`}
                      onChange={(e) => {
                        setBusinessTypeLogo({
                          file: e.target.files[0],
                          isValid: true,
                          err: "",
                        });
                      }}
                    />
                  </div>}
                </div>
              </Grid>: null}
              {selectedBusinessType &&
              selectedBusinessType.id == constant?.BALADI_STORE_ID &&
              modelData.level === 1 ? (
                <Grid item xs={12}>
                  <div
                    className="upload-img"
                    style={{ margin: "0px 0px 30px 0px" }}
                  >
                    {!(businessTypeGif && businessTypeGif.isValid) ?
                    <div className="before-upload">
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload GIF</p>
                      <input
                        type="file"
                        // accept={`image/png, image/jpeg, image/gif`}
                        // multiple
                        accept={`image/gif`}
                        onChange={(e) => {
                          setBusinessTypeGif({
                            file: e.target.files[0],
                            isValid: true,
                            err: "",
                          });
                        }}
                      />
                    </div>: <div className="after-upload">
                      <CancelIcon
                        className="delete-icon"
                        onClick={() => {
                          setBusinessTypeGif({
                            file: "",
                            isValid: false,
                            err: "Please upload GIF file",
                          });
                        }}
                      />
                      <img
                        src={URL.createObjectURL(businessTypeGif.file)}
                        alt=".."
                        className="upload-icon"
                      />
                      <input
                        type="file"
                        accept={`image/gif`}
                        onChange={(e) => {
                          setBusinessTypeGif({
                            file: e.target.files[0],
                            isValid: true,
                            err: "",
                          });
                        }}
                      />
                    </div>}
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  required
                  id="category_en"
                  label={
                    modelData.level === 1
                      ? "Category (En)"
                      : modelData.level === 2
                      ? "Sub Category (En)"
                      : modelData.level === 3
                      ? "Sub Sub Category (En)"
                      : ""
                  }
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => onChangeCategory(e, "en")}
                />
                </Grid>
              <Grid item xs={12}>
                <TextField
                  id="category_en"
                  style={{ marginTop: "5px" }}
                  required
                  label={
                    modelData.level === 1
                      ? "Category (Ar)"
                      : modelData.level === 2
                      ? "Sub Category (Ar)"
                      : modelData.level === 3
                      ? "Sub Sub Category (Ar)"
                      : ""
                  }
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => onChangeCategory(e, "ar")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="category_desc_en"
                  style={{ marginTop: "5px" }}
                  label={'Category Sub text EN'}
                  value={categorySubTextEn}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => setCategorySubTextEn(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="category_desc_ar"
                  style={{ marginTop: "5px" }}
                  label={'Category Sub text AR'}
                  inputProps={{ maxLength: 100 }}
                  value={categorySubTextAr}
                  onChange={(e) => setCategorySubTextAr(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={isDisabled() || currentLoaderValue}
                  onClick={() => onSaveCategoryHandler(modelData.level)}
                > Save </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={editModal.isOpen}
        onClose={() => {
          setEditModal({
            ...editModal,
            isOpen: false,
          });
          setAnchorEl(null);
        }}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => {
            setEditModal({
              ...editModal,
              isOpen: false,
            });
            setAnchorEl(null);
          }}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Edit Category
          </Typography>
          <form>
            <Grid container spacing={2}>
              {(
                editModal.type === "Category" ||
                editModal.type === "Sub Category"  ||
                editModal.type === "Sub Sub Category"
              ) ? <Grid item xs={12}>
                  <div
                    className="upload-img"
                    style={{ margin: "0px 0px 30px 0px" }}
                  >
                    {!editModal.image ?
                    <div className="before-upload">
                      <img src={uploadImg} alt=".." className="upload-icon" />
                      <p className="upload-text">Upload Image</p>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e, "image")}
                      />
                    </div>: <div className="after-upload">
                      <CancelIcon
                        className="delete-icon"
                        onClick={() => {
                          editImageChangeHandler("", "image");
                        }}
                      />
                      {editModal?.image.type ? 
                      <img src={URL.createObjectURL(editModal?.image)} alt=".." className="upload-icon" />:
                      <img src={`${constant.IMG_BASE_URL}${editModal.image}`} alt=".." className="upload-icon"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = uploadImg;
                        }}
                      />}
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => editImageChangeHandler(e, "image")}
                      />
                    </div>}
                  </div>
                </Grid>: null
              }
              {(editModal.type === "Category" ||
                editModal.type === "Sub Category"  ||
                editModal.type === "Sub Sub Category") &&
                selectedBusinessType &&
                selectedBusinessType.id == constant.BALADI_STORE_ID && (
                  <Grid item xs={12}>
                    <div
                      className="upload-img"
                      style={{ margin: "0px 0px 30px 0px" }}
                    >
                      {!editModal.imageGif ? (
                        <div className="before-upload">
                          <img
                            src={uploadImg}
                            alt=".."
                            className="upload-icon"
                          />
                          <p className="upload-text">Upload GIF/PNG</p>
                          <input
                            type="file"
                            accept={`image/gif , image/png`}   
                            onChange={(e) => editImageChangeHandler(e, "gif")}
                          />
                        </div>
                      ) : (
                        <div className="after-upload">
                          <CancelIcon
                            className="delete-icon"
                            onClick={() => {
                              editImageChangeHandler("", "gif");
                            }}
                          />
                          {editModal?.imageGif.type ? (
                            <img
                              src={URL.createObjectURL(editModal?.imageGif)}
                              alt=".."
                              className="upload-icon"
                            />
                          ) : (
                            <img
                              src={`${constant.IMG_BASE_URL}${editModal.imageGif}`}
                              alt=".."
                              className="upload-icon"
                            />
                          )}
                          <input
                            type="file"
                            accept={`image/gif , image/png`}   
                            onChange={(e) => editImageChangeHandler(e, "gif")}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              <Grid item xs={12}>
                <TextField
                  required
                  label={editModal.type + " (English)"}
                  defaultValue={editModal.name}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => editChangeHandler(e)}
                />
                </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ marginTop: "10px" }}
                  required
                  label={editModal.type + " (Arabic)"}
                  defaultValue={editModal?.name_ar}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => editChangeHandler(e, "ar")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ marginTop: "10px" }}
                  label={'Category Sub text EN'}
                  value={editModal?.category_desc_en}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    setEditModal({
                      ...editModal,
                      category_desc_en: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ marginTop: "10px" }}
                  label={'Category Sub text AR'}
                  value={editModal?.category_desc_ar}
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    setEditModal({
                      ...editModal,
                      category_desc_ar: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                  disabled={isDisabledEdit() || currentLoaderValue}
                  onClick={() => handleOnEdit() }
                > Save </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this {deleteModal.type}?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleOnDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {cropperData.isOpen ? (
        <ImageCropper
          cropperImage={cropperData.croppedImage}
          closepopup={() => {
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setEditModal({
              ...editModal,
              image: response,
            });
            setCropperData({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}

      {cropperData2.isOpen ? (
        <ImageCropper
          cropperImage={cropperData2.croppedImage}
          closepopup={() => {
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          setCropImage={async (cropped) => {
            const response = await base64toFile(cropped.croppedImage, 'categoryImage.png')
            setBusinessTypeLogo({
              file: response,
              isValid: true,
              err: "",
            });
            setCropperData2({
              croppedImage: "",
              isOpen: false,
            });
          }}
          otherParams={{aspectRatio: 1/1,}}
        />
      ) : null}

    </>
  );
}
