import { useEffect, useState } from "react";
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { API } from "../../../ENUM";
import { currentPickerSocket } from "../../../../redux/reducers/socket";
import { useSelector } from "react-redux";
import GlobalService from "../../../../services/GlobalService";
import moment from "moment";
import Rows from "./Rows";

const Delayed = ({ search, sortBy }) => {
  
  const limit  = 10;
  const currentPickerSocketObj = useSelector(currentPickerSocket);
  const [data, setData] = useState("");
  const [traffic, setTraffic] = useState([]);
  const [ColorPicked, setColorPicked] = useState("");
  let [page, setPage] = useState(1);
  let [count, setCount] = useState();

  const GET_Time = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_TIME;
      const response = await GlobalService(url);
      if (response.ack == 1) {
        setTraffic(response.results.rows);
        const PickQc = response.results.count;
        const color = response.pickstartime.color;
        const picktime = response.pickstartime.time;
        getAllOrder(color, PickQc, picktime);
      }
    } catch (error) {
      console.log("Error_In_Picker_traffic_light", error);
    }
  };
  
  useEffect(() => {
    const getData = setTimeout(() => {
      page = 1;
      setPage(page);
      getAllOrder();
    }, 500);
    return () => clearTimeout(getData);
  }, [search, sortBy]);
  
  useEffect(() => {
    GET_COLOR();
    GET_Time();
  }, []);

  useEffect(() => {
    if (currentPickerSocketObj) {
      getAllOrder();
    }
  }, [currentPickerSocketObj]);

  const GET_COLOR = async () => {
    try {
      let url = API.TRAFFIC_LIGHT.GET_Time_Start_Picking;
      const response = await GlobalService(url);
      setColorPicked(response?.startTime?.color);
    } catch (error) {
      console.log("Error_In_store Listing", error);
    }
  };

  const createData = () => {
    let array =
      data &&
      data.length > 0 &&
      data.map((item) => {
        const productArray = item?.orders_details
          ? item?.orders_details.map((detail, index) => ({
              SNo: (index + 1).toString(),
              ProductName: detail?.product?.products_locales[1]?.title,
              ItemCode: detail?.product?.itemCode,
              PickupStatus: detail?.status,
            }))
          : [];
        let matchingTraffic =
          traffic.find(
            (element) => item.total_quantity <= element.no_of_item
          ) || "";
        let TabStatus, Color;
        if (item?.picker_status === "pending_pickup") {
          TabStatus = "Not Picked";
          Color = ColorPicked;
        } else if (item?.picker_status === "completed") {
          TabStatus = "In Qc";
          Color = matchingTraffic?.in_qc_color;
        } else if (item?.picker_status === "in_pick") {
          TabStatus = "In Picked";
          Color = matchingTraffic?.in_pick_color;
        }
        let statusLog = item?.order_status_log !== '' ? JSON.parse(item?.order_status_log) : [];
        let log = statusLog.pop();
        const orderDate = new Date(log?.updated_at);// new Date(item?.updatedAt);
        const currentDate = new Date(
          moment.tz(new Date(), "Asia/Qatar").format("DD MMMM YYYY hh:mm a")
        );
        const diff = Math.abs(currentDate - orderDate);
        const duration = Math.floor(diff / 1000 / 60);
        return {
          name: `${item?.picker_user?.first_name || ""} ${
            item?.picker_user?.last_name || ""
          }`,
          OrderID: item?.order_id,
          NoOfItems: item?.total_quantity,
          color: matchingTraffic,
          Priority: Color ? "High" : "--",
          PlacementDateTime: moment(item?.createdAt).format(
            "DD MMMM YYYY hh:mm a"
          ),
          product: productArray,
          // OrderDateTime:
          //   duration < item?.start_Time_Threshold
          //     ? "--"
          //     : Math.abs(duration - item?.start_Time_Threshold) + " min",
          OrderDateTime: Color
            ? Math.abs(duration - item?.start_Time_Threshold) + " min"
            : "--",
          status: TabStatus,
          TrafficColor: Color,
        };
      });
    return array;
  };

  const getAllOrder = async (color = "", PickQc = "", picktime = "") => {
    if (PickQc > 0 || (color !== "white" && color !== "") || picktime > 0) {
      const object = {
        page: page,
        limit: limit,
        search: search,
        sortBy: sortBy,
      };
      const query = new URLSearchParams(object);
      try {
        let url = API.PICKER_TRACKING.GET_DELAY(query);
        const response = await GlobalService(url);
        setData(response?.res.rows);
        count = Math.ceil(response?.res?.count / limit);
        setCount(count);
      } catch (error) {
        console.log("Error_In_store Listing", error);
      }
    }
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
    getAllOrder();
  };

  return (
    <>
      {/* this is in-QC table */}
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Pending With</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>No. of Items</TableCell>
              <TableCell>Priority on the basis threshold</TableCell>
              <TableCell>Placement Date and Time</TableCell>
              <TableCell>Over Due By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {createData() && createData().map((row) =>
              <Rows key={`Row-${row.id}`} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 1 ? 
        <Pagination count={count} page={page} onChange={handlePagination} color="primary" />: null}
    </>
  );
};
export default Delayed;
