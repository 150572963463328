import { useState, useEffect, useRef } from "react";
import { Button, Typography, TextField, Grid, Box, Switch, Pagination } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import arrowLeftBlackImg from "../../../assets/images/structure/arrow-left-black.svg";
import deleteBlackImg from "../../../assets/images/structure/delete-black.svg";
import { TreeItem, TreeView } from "@material-ui/lab";
import { Boxed } from "../../../Styles-Elements/Box";
import ChooseCategories from "../../../dialog/ChooseCategories";
import { useParams, useNavigate } from "react-router-dom";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../../pages/ENUM";
import { getdataByKey } from "../../helper/custom";
import DeleteConfirmBox from "../../../dialog/DeleteConfirmBox";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import SubCategoryComponent from "./CategoryComponents/SubCategory";
import ProductComponent from "./CategoryComponents/ProductComponent";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import uploadBlackIcon from "../../../assets/images/structure/upload-dark-icon.svg"
import UploadImage from "../../../dialog/UploadImage";
import constant from "../../../constant";

export default function StoreListingDetail() {

  const dragItem = useRef();
  const dragOverItem = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState('');
  const [storeData, setStoreData] = useState({});
  const [availableCategories, setAvailableCategories] = useState([]);
  let [categories, setCategories] = useState([]);
  let [deleteId, setDeleteId] = useState(0);
  let [openDeletePopup, setOpenDeletePopup] = useState(false);
  let [selectedId, setSelectedId] = useState(0);
  let [selectedName, setSelectedName] = useState('');
  let [products, setProducts] = useState([]);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(20);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState('');
  let [totalProducts, setTotalProducts] = useState(0);
  let [uploadImageData, setUploadImageData] = useState({ id: 0, name: '', image: '' });
  let [openUploadImagePopup, setOpenUploadImagePopup] = useState(false);

  const paginationHandler = (event, value) => {
    page = value;
    setPage(page);
    getProducts(selectedId)
  };

  const getAllCategories = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.CATEGORIES_STORE_RELATED(id));
    let allCat = response.results;
    setAvailableCategories(allCat);
    if (selectedId === 0) {
      selectedId = allCat[0]?.id;
      setSelectedId(selectedId)
      selectedName = allCat[0]?.name;
      setSelectedName(selectedName)
    }
    dispatch(changeLoader(false));
    getProducts(selectedId)
  }

  const getStore = async () => {
    const response = await GlobalService(API.GET_STORE_BY_ID(id));
    setStoreData(response.store);
    const CategoryResponse = await GlobalService(API.GET_ALL_PARENT_CATEGORIES(response.store.businessTypeId, 0));
    categories = CategoryResponse.categories.map((item) => {
      return { id: item.id, title: getdataByKey(item.category_locales, 'en', 'name') }
    });
    setCategories(categories);
  }

  const handleDelete = async () => {
    selectedId = 0;
    setSelectedId(selectedId)
    handleStatusUpdate('deleted', deleteId);
  }

  const createStoreJson = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.CATEGORIES_STORE_CREATE_JSON(id));
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  };

  const handleStatusUpdate = async (status, catId) => {
    dispatch(changeLoader(true));
    const response = await GlobalService(
      API.UPDATE_CATEGORY_OF_STORE,
      { storeId: id, categoryId: catId, status: status },
      'post'
    );
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
      getAllCategories()
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));
      dispatch(changeLoader(false));
    }
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // const copyListItems = [...availableCategories];
    // const dragItemContent = copyListItems[dragItem.current];
    // copyListItems.splice(dragItem.current, 1);
    // copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    // setAvailableCategories(copyListItems);
  };

  const drop = async (e, index) => {
    const copyListItems = [...availableCategories];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setAvailableCategories(copyListItems);
    const newSequence = copyListItems.map((item, index) => {
      return {
        catStoreId: item.catStoreId,
        sequence: index + 1,
      };
    });
    await GlobalService( API.UPDATE_CATEGORY_SEQUENCE, newSequence, 'post');
  };

  const getProducts = async (categoryId) => {
    if (categoryId !== undefined) {
      dispatch(changeLoader(true));
      let url = API.GET_PRODUCTS_FOR_CATEGORIES
      url += `?storeId=${id}&category_id=${categoryId}&page=${page}&limit=${limit}&sort_by=id&order_by=desc`
      if (search !== '') { url += `&search=${search}` }
      const response = await GlobalService(url);
      totalCount = Math.ceil(response.count / limit);
      setTotalCount(totalCount)
      totalProducts = response.count;
      setTotalProducts(totalProducts)
      products = response.products.map((product) => {
        return product
      })
      setProducts(products);
      dispatch(changeLoader(false));
    } else {
      setProducts([]);
    }
  };

  const deleteStoreJson = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.CATEGORIES_STORE_DELETE_JSON(id), {}, 'delete');
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "success",
      }));
      dispatch(changeLoader(false));
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        message: response?.msg,
        state: "error",
      }));

    }
    dispatch(changeLoader(false));
  };

  const openUploadImage = (id, name, image) => {
    uploadImageData = { id: id, name: name, image: image };
    setUploadImageData(uploadImageData)
    setOpenUploadImagePopup(true)
  }

  useEffect(() => {
    getStore();
    getAllCategories();
  }, [id]);

  useEffect(() => {
    if (selectedId !== 0) {
      getProducts(selectedId)
    }
  }, [selectedId]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getProducts(selectedId)
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2} width={'100%'}>
                  <Box display={'flex'} alignItems={'center'} mb={2}>
                    <img
                      src={arrowLeftBlackImg} alt="back-icon" style={{ cursor: 'pointer' }}
                      onClick={() => navigate(-1)}
                    />
                    <Typography variant="h6" component="h6" style={{ marginLeft: "12px" }} fontWeight={500}>
                    {getdataByKey(storeData.stores_locales, 'en', 'business_name')}
                    </Typography>
                  </Box>
                  <Box display={'flex'} alignItems={'end'} mb={2}>
                    <Button variant="contained" size="medius" style={{ marginRight: "12px" }} onClick={() => createStoreJson()}>Create Categories json </Button>
                    <Button variant="contained" size="medius" onClick={() => deleteStoreJson()}>Delete Categories json</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <div className="left-side left-side-new" style={{ maxWidth: '380px' }}>
                  <Box style={{ background: '#fff', borderRadius: '10px', boxShadow: '0 1px 2px rgba(0,0,0,.1), 0 2px 6px 2px rgba(0,0,0,.1)', }}>
                    <Box padding={'16px 16px'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{ borderBottom: '1px solid #e8e8e8' }}>
                      <Typography variant="h6" component="h6" mb={0} fontWeight={500} >Categories </Typography>
                      <Button variant="contained" size="medius" startIcon={<AddCircleOutlineIcon />} onClick={() => { setOpen(true);}}>Add</Button>
                    </Box>
                    <Box>
                     {availableCategories.length > 0 ?
                     availableCategories.map((category, index) => 
                      category.subCategory.length > 0?
                      <TreeView
                        key={`category-${index}`}
                        aria-label="file system navigator"
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={(e) => drop(e, index)}
                        draggable
                      >
                        <TreeItem
                          nodeId={`category${category.id}`}
                          label={
                            <Boxed display={'flex'} alignItems={'center'} justifyContent={'flex-start'}
                              onClick={() => {
                                selectedId = category.id;
                                setSelectedId(selectedId)
                                selectedName = category.name;
                                setSelectedName(selectedName)
                              }}
                            >
                              <DragIndicatorIcon sx={{ marginRight: "3px", color: "#a7a7a7" }} />
                              <Typography variant="p" component="p" width={"100%"} fontSize={"16px"} mb={0} fontWeight={500}>
                                {category.name}
                              </Typography>
                              <Box display={'flex'} alignItems={'center'}>
                                <Switch
                                  checked={category.status === 'active' ? true : false}
                                  onChange={(e) => {handleStatusUpdate(e.target.checked ? 'active' : 'inactive', category.id)}}
                                />

                                <img src={deleteBlackImg} alt=""
                                  onClick={() => {
                                    deleteId = category.id;
                                    setDeleteId(deleteId)
                                    setOpenDeletePopup(true);
                                  }}
                                />

                                 <img src={category.image?
                                    constant?.IMG_BASE_URL + category.image:
                                    uploadBlackIcon
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = uploadBlackIcon
                                  }}
                                  width={'20px'}
                                  onClick={() => {
                                    openUploadImage(category.id, category.name, category.image)
                                  }}
                                alt=""/>
                              </Box>
                            </Boxed>
                          }
                          className="main-tree"
                        >

                          <SubCategoryComponent
                            subCategory={category.subCategory}
                            id={id}
                            handleStatusUpdate={handleStatusUpdate}
                            handleSetDeleteId={(deleteIds) => {
                              deleteId = deleteIds; 
                              setDeleteId(deleteId)
                            }}
                            setOpenDeletePopup={setOpenDeletePopup}
                            selectedId={selectedId}
                            handleSelectedId={(selId, selName) => {
                              selectedId = selId;
                              setSelectedId(selectedId)
                              selectedName = selName;
                              setSelectedName(selectedName)
                            }}
                            openUploadImage={openUploadImage}
                          />
                        </TreeItem>
                      </TreeView> :
                      
                      <Box p={'8px 44px 8px 16px'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          selectedId = category.id;
                          setSelectedId(selectedId)
                          selectedName = category.name;
                          setSelectedName(selectedName)
                        }}
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={(e) => drop(e, index)}
                        draggable
                      >
                        <DragIndicatorIcon sx={{ marginRight: "3px", color: "#a7a7a7" }} />
                        <Typography variant="p" component="p" width={"100%"} fontSize={"16px"} mb={0} fontWeight={500}>
                        {category.name}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'}>
                          <Switch
                            checked={category.status === 'active' ? true : false}
                            onChange={(e) => {
                              handleStatusUpdate(e.target.checked ? 'active' : 'inactive', category.id)
                            }}
                          />
                          <img src={deleteBlackImg} alt=""
                            onClick={() => {
                              deleteId = category.id
                              setDeleteId(deleteId)
                              setOpenDeletePopup(true);
                            }}
                          />
                           <img src={category.image?
                              constant?.IMG_BASE_URL + category.image:
                              uploadBlackIcon}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = uploadBlackIcon
                              }}
                            width={'20px'}
                            onClick={() => {
                              openUploadImage(category.id, category.name, category.image)
                            }}
                          alt=""/>
                        </Box>
                      </Box>): null}
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Box display={'flex'} alignItems={'center'} mb={3}>
                  <Typography variant="h6" component="h6" fontSize={"18px"} fontWeight={500}>
                    {selectedName} &nbsp;
                  </Typography>
                  <Typography variant="p" component="p" fontSize={"12px"} ml={2} fontWeight={500} color={'#666666'}>
                    ({totalProducts} Product{parseInt(totalProducts)>1?'s': ''})
                  </Typography>
                </Box>
                <Grid container spacing={3} mb={4} disabled="true">
                  <Grid item xs={12} sm={8}>
                    <div className="search-box">
                      <TextField
                        id="outlined-size-small"
                        size="small"
                        
                        placeholder="Search Products"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        
                        InputProps={{
                          startAdornment: <SearchIcon sx={{ marginRight: "3px" }} />,
                          endAdornment: search !== ''?
                            <Box
                              display={'flex'} alignItems={'center'}
                              style={{ cursor: 'pointer' }}
                              onClick={() => { setSearch('') }}>X</Box>: null,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {products?.length > 0 ? products.map((product, index) =>
                      <ProductComponent
                        key={`product-${index}`}
                        product={product}
                        getList={() => getProducts(selectedId)}
                        selectedCategoryId={selectedId}
                        selectedId={id}
                      />
                    ): <NoDataFound text={'No Products Found'} />}
                    {totalCount > 1 ? (
                      <Pagination
                        count={totalCount}
                        color="primary"
                        page={page}
                        onChange={paginationHandler}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {open?<ChooseCategories
        categories={categories}
        availableCategories={availableCategories}
        businessTypeId={storeData.businessTypeId}
        storeId={id}
        categoryLevel={storeData?.business_type?.category_level}
        handleClose={() => { setOpen(false) }}
        getList={() => { setOpen(false); getAllCategories(); }}
      />: null}
      {openDeletePopup ? <DeleteConfirmBox
        title={'Delete Category'}
        description={'Are you sure you want to delete this category?'}
        handleClose={() => setOpenDeletePopup(false) }
        handleConfirm={() => {
          setOpenDeletePopup(false)
          handleDelete()
        }}
      /> : null}

      {openUploadImagePopup ? <UploadImage
        storeId={id}
        uploadImageData={uploadImageData}
        handleClose={() => setOpenUploadImagePopup(false) }
        handleSuccess={() => {
          setOpenUploadImagePopup(false)
          getAllCategories()
        }}
      />: null}
    </>
  );
}