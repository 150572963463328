import { useEffect, useState } from "react";
import "./aside.scss";
import logo from "./../../../assets/images/logo/logo.svg";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavItem,
  CNavGroup,
} from "@coreui/react";
// import CIcon from "@coreui/icons-react";
// import dot from "./../../../assets/images/dot.png";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentPermission } from "../../../redux/reducers/permission";

const Aside = () => {
  const permission = useSelector(currentPermission);
  const vars = { "--cui-sidebar-header-height": "800px" };
  const [activeMenu, setActiveMenu] = useState("");
  let [sidebarMenu, setSidebarMenu] = useState([]);
  const sidebar = [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      name: "Management System",
      link: "#",
      sub: [
        { name: "Baladi Landing", link: "/widget-page/baladi" },
        { name: "Home Page", link: "/widget-page/home" },
        { name: "Web Home Page", link: "/widget-page/web_home" },
        { name: "Business Type", link: "/business-type" },
      ],
    },
    {
      name: "Store Management ",
      link: "#",
      sub: [
        { name: "Store Request ", link: "/store-request" },
        { name: "Store Listing", link: "/store-listing" },
        // { name: "Store Ranking", link: "/store-ranking" },
      ],
    },
    {
      name: "Zone Management ",
      link: "#",
      sub: [
        { name: "Create Zone", link: "/zone-managment" },
         { name: "Zone Store Management", link: "/store-zone-management" },
      ],
    },
    
    {
      name: "User & Roles",
      link: "#",
      sub: [
        { name: "User Management", link: "/user-management" },
        { name: "Role Management", link: "/manage-role" },
      ],
    },
    {
      name: "Cuisine Management",
      sub: [
        { name: "Cuisine Category", link: "/cuisine-category-listing" },
        { name: "Cuisine list New", link: "/cuisine-listing-new" },
      ],
    },
    {
      name: "Coupon Management",
      link: "/coupon-listing",
      // nodeId: 6,
    },
    {
      name: "Category Management ",
      link: "#",
      sub: [
        {
          name: "Store Type ",
          link: "/business-type-listing",
        },
        {
          name: "Business Category",
          link: "/business-category",
        },
        {
          name: "Unit of measurement",
          link: "/unit-of-measurement",
        },
      ],
    },
    {
      name: "Product Management ",
      link: "#",
      sub: [
        {
          name: "Add Product Request ",
          link: "/product-request",
        },
        {
          name: "Edit Product Request ",
          link: "/edit-product-request",
        },
        {
          name: "Product Listing",
          link: "/product-listing",
        },
      ],
    },
    {
      name: "Manage Orders",
      link: "#",
      sub: [
        {
          name: "Order Listing ",
          link: "/order-listing",
        },
        // {
        //   name: "Push Notification",
        //   link: "#",
        // },
      ],
    },
    {
      name: "CMS",
      link: "#",
      sub: [
        {
          name: "Bulk",
          link: "/bulk-upload",
        },
        {
          name: "Faq",
          link: "/faq",
        },
        {
          name: "Term and Conditions",
          link: "/terms-services",
          nodeId: 52,
        },
        {
          name: "Disclaimer",
          link: "/disclaimer",
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy",
        },
        {
          name: "Warranty Policy",
          link: "/warrenty-policy",
        },
        {
          name: "Delivery Information",
          link: "/delivery-information",
        },
        {
          name: "About Baladi",
          link: "/about-baladi",
        },
        {
          name: "Cancellation Reason",
          link: "/cancellation-reason",
        },
        {
          name: "Cancellation Department",
          link: "/cancellation-department",
        },
        {
          name: "Traffic Light System",
          link: "/traffic-light-system",
        },
      ],
    },
    {
      name: "Manage Customer Listing",
      link: "/manage-customer-listing",
      // nodeId: 6,
    },
    {
      name: "Manage Users",
      link: "#",
      sub: [
        {
          name: "Manage Picker User",
          link: "/manage-user-listing",
          // nodeId: 10,
        },
        {
          name: "Manage Financial  User",
          link: "/manage-financial-user",
          // nodeId: 11,
        },
      ],
    },
    {
      name: "Payout Management Listing",
      link: "/payout-management-listing",
      // nodeId: 7,
    },
    {
      name: "Driver Onboarding Listing",
      link: "/driver-onboarding-listing",
      // nodeId: 8,
    },
    // {
    //   name: "Section Management Listing",
    //   link: "/section-management-listing",
    //   // nodeId: 9,
    // },
    {
      name: "Zone",
      link: "/zone-list",
    },

    // {
    //   name: "Manage User",
    //   link: "/manage-user-listing",
    //   nodeId: 10,
    // },
    // {
    //   name: "Manage Financial  User",
    //   link: "/manage-financial-user",
    //   nodeId: 11,
    // },
    {
      name: "System Settings",
      link: "#",
      sub: [
        {
          name: "Banner Management",
          link: "/banner-management",
        },
        {
          name: "Admin Setting",
          link: "/admin-setting",
        },
      ],
      // nodeId: 12,
    },
    {
      name: "Picker Tracking System",
      link: "#",
      sub: [
        {
          name: "Picker Tracking",
          link: "/picker-tracking-system",
        },
        {
          name: "Pickup traffic light system",
          link: "/pickup-traffic-light-system",
        },
      ],
    },
    {
      name: "My Offers",
      link: "/offer-listing",
    },
  ];
  let selectedMenu = "";
  if (localStorage.getItem("selectedMenu")) {
    selectedMenu = localStorage.getItem("selectedMenu");
  }

  useEffect(() => {
    const url = window.location.href;
    const extractedPart = url.substring(url.lastIndexOf("/") + 1);
    setActiveMenu(extractedPart);
  }, [selectedMenu]);

  useEffect(() => {
    if (permission.role === "admin") {
      sidebarMenu = sidebar;
    } else {
      sidebarMenu = [{ name: "Dashboard", link: "/dashboard" }];
      if (
        permission.storeRequest.read === 1 ||
        permission.storeListing.read === 1
      ) {
        let subMenu = [];
        if (permission.storeRequest.read === 1) {
          subMenu.push({ name: "Store Request ", link: "/store-request" });
        }
        if (permission.storeListing.read === 1) {
          subMenu.push({ name: "Store Listing", link: "/store-listing" });
        }
        sidebarMenu.push({
          name: "Store Management ",
          link: "#",
          sub: subMenu,
        });
      }
      if (
        permission.manageUsers.read === 1 ||
        permission.rolesPermissions.read === 1
      ) {
        let subMenu = [];
        if (permission.manageUsers.read === 1) {
          subMenu.push({ name: "User Management", link: "/user-management" });
        }
        if (permission.rolesPermissions.read === 1) {
          subMenu.push({ name: "Role Management", link: "/manage-role" });
        }
        sidebarMenu.push({ name: "User & Roles", link: "#", sub: subMenu });
      }
      if (permission.cuisine.read === 1) {
        sidebarMenu.push({
          name: "Cuisine Management",
          link: "/cuisine-category-listing",
        });
      }
      if (permission.coupon.read === 1) {
        sidebarMenu.push({
          name: "Coupon Management",
          link: "/coupon-listing",
        });
      }
      if (
        permission.storeType.read === 1 ||
        permission.businessCategory.read === 1
      ) {
        let subMenu = [];
        if (permission.storeType.read === 1) {
          subMenu.push({ name: "Store Type ", link: "/business-type-listing" });
        }
        if (permission.businessCategory.read === 1) {
          subMenu.push({
            name: "Business Category",
            link: "/business-category",
          });
        }
        if (permission.uom.read === 1) {
          subMenu.push({
            name: "Unit of measurement",
            link: "/unit-of-measurement",
          });
        }
        sidebarMenu.push({
          name: "Category Management",
          link: "#",
          sub: subMenu,
        });
      }
      if (
        permission.productRequest.read === 1 ||
        permission.addProduct.read === 1
      ) {
        let subMenu = [];
        if (permission.productRequest.read === 1) {
          subMenu.push({
            name: "Add Product Request ",
            link: "/product-request",
          });
          subMenu.push({
            name: "Edit Product Request ",
            link: "/edit-product-request",
          });
        }
        if (permission.addProduct.read === 1) {
          subMenu.push({ name: "Product Listing", link: "/product-listing" });
        }
        sidebarMenu.push({
          name: "Product Management",
          link: "#",
          sub: subMenu,
        });
      }
      if (permission.orderListing.read === 1) {
        sidebarMenu.push({
          name: "Manage Orders",
          link: "#",
          sub: [{ name: "Order Listing ", link: "/order-listing" }],
        });
        // { name: "Push Notification", link: "#" }
      }
      if (permission.cms.read === 1) {
        sidebarMenu.push({
          name: "CMS",
          link: "#",
          sub: [
            { name: "Bulk", link: "/bulk-upload" },
            { name: "Faq", link: "/faq" },
            { name: "Term and Conditions", link: "/terms-services" },
            { name: "Disclaimer", link: "/disclaimer" },
            { name: "Privacy Policy", link: "/privacy-policy" },
            { name: "Warranty Policy", link: "/warrenty-policy" },
            { name: "Delivery Information", link: "/delivery-information" },
            { name: "About Baladi", link: "/about-baladi" },
            {
              name: "Cancellation Department",
              link: "/cancellation-department",
            },
            { name: "Cancellation Reason", link: "/cancellation-reason" },
            { name: "Traffic Light System", link: "/traffic-light-system" },
          ],
        });
      }
      if (permission.customerManagement.read === 1) {
        sidebarMenu.push({
          name: "Manage Customer Listing",
          link: "/manage-customer-listing",
        });
      }
      if (permission.manageUsers.read === 1) {
        sidebarMenu.push({
          name: "Manage Users",
          link: "#",
          sub: [
            { name: "Manage Picker User", link: "/manage-user-listing" },
            { name: "Manage Financial User", link: "/manage-financial-user" },
          ],
        });
      }
      if (permission.payoutManagement.read === 1) {
        sidebarMenu.push({
          name: "Payout Management Listing",
          link: "/payout-management-listing",
        });
      }
      if (permission.driverOnboarding.read === 1) {
        sidebarMenu.push({
          name: "Driver Onboarding Listing",
          link: "/driver-onboarding-listing",
        });
      }
      // if (permission.sectionManagement.read === 1) {
      //   sidebarMenu.push({ name: "Section Management Listing", link: "/section-management-listing" });
      // }
      if (permission.zone.read === 1) {
        sidebarMenu.push({ name: "Zone", link: "/zone-list" });
      }
      if (
        permission.banner.read === 1 ||
        permission?.adminSetting?.read === 1
      ) {
        let subMenu = [];
        if (permission.banner.read === 1) {
          subMenu.push({
            name: "Banner Management",
            link: "/banner-management",
          });
        }
        if (permission?.adminSetting?.read === 1) {
          subMenu.push({ name: "Admin Setting", link: "/admin-setting" });
        }
        sidebarMenu.push({ name: "System Settings", link: "#", sub: subMenu });
      }
      if (permission?.pickerTracking?.read === 1) {
        sidebarMenu.push({
          name: "Picker Tracking System",
          link: "#",
          sub: [
            { name: "Picker Tracking", link: "/picker-tracking-system" },
            {
              name: "Pickup traffic light system",
              link: "/pickup-traffic-light-system",
            },
          ],
        });
      }
      if (permission?.offerDiscount?.read === 1) {
        sidebarMenu.push({ name: "My Offers", link: "/offer-listing" });
      }
    }
    setSidebarMenu(sidebarMenu);
  }, [permission]);

  return (
    <>
      <div
        className="aside-wrapper"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <CSidebar style={vars}>
          <CSidebarBrand>
            <img src={logo} />
          </CSidebarBrand>
          <CSidebarNav>
            {sidebarMenu.map((obj, index) => {
              if (obj?.sub?.length) {
                return (
                  <CNavGroup
                    className={selectedMenu == obj.name ? "show" : ""}
                    onClick={(e) => {
                      localStorage.setItem("selectedMenu", obj.name);
                    }}
                    toggler={obj.name}
                    key={`menu-${index}`}
                  >
                    {obj.sub.map((obj1, index1) => {
                      return (
                        <Link
                          key={`menu-link-${index1}`}
                          to={
                            obj1.link === "/product-listing" ? "#" : obj1.link
                          }
                          onClick={(e) => {
                            if (obj1.link === "/product-listing") {
                              e.preventDefault();
                              window.location.href = obj1.link;
                            } else {
                            }
                          }}
                          className={
                            "/" + activeMenu == obj1.link
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          <CNavItem key={index1}>{obj1.name}</CNavItem>
                        </Link>
                      );
                    })}
                  </CNavGroup>
                );
              } else {
                return (
                  <Link
                    key={`menu-link-${index}`}
                    to={obj.link}
                    onClick={(e) => {
                      localStorage.removeItem("selectedMenu");
                    }}
                    className={
                      "/" + activeMenu == obj.link
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <CNavItem key={index}>{obj.name}</CNavItem>
                  </Link>
                );
              }
            })}
          </CSidebarNav>
        </CSidebar>
      </div>
    </>
  );
};

export default Aside;
