import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DndContext, useDraggable, useDroppable, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";
import Header from "../../layout/header/Header";
import Aside from "../../layout/aside/Aside";
import GlobalService from "../../../services/GlobalService";
import { API } from "../../ENUM";

// DroppableArea component
const DroppableArea = ({ id, children }) => {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div ref={setNodeRef} style={{ minHeight: 200, border: "1px solid #ddd", padding: 8 }}>
      {children}
    </div>
  );
};

// SortableItem component
const SortableItem = ({ id, children }) => {
  const { setNodeRef, attributes, listeners, transform, transition } = useDraggable({ id });
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const AreaZoneStoreManagement = () => {
  const { location_id } = useParams();
  const [zoneData, setZoneData] = useState(null);
  const [sortedStores, setSortedStores] = useState([]);
  const [unsortedStores, setUnsortedStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slotDialogOpen, setSlotDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [currentStore, setCurrentStore] = useState(null);
  const [newSlot, setNewSlot] = useState({ name: "", validFromDate: "", validFromTime: "", validUntilDate: "", validUntilTime: "" });
  const [slots, setSlots] = useState([]);

  const getZoneDetails = async () => {
    try {
      const response = await GlobalService(API.GET_AREAZONE_BY_ID(location_id));
      if (response?.ack === 1) {
        setZoneData(response.data);
        // const sorted = response.data.stores.filter(store => store.isSorted).sort((a, b) => a.sortOrder - b.sortOrder);
        // const unsorted = response.data.stores.filter(store => !store.isSorted);
        setSortedStores(response.data.sortedStores);
        setUnsortedStores(response.data.unsortedStores);
      } else {
        console.error(response?.msg || "Error fetching zone details.");
      }
    } catch (err) {
      console.error("An error occurred while fetching zone details.");
    }
  };

  useEffect(() => {
    getZoneDetails();
  }, [location_id]);

  const onDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeStoreId = active.id;
    const overStoreId = over.id;

    if (unsortedStores.find(store => store.id === activeStoreId)) {
      const movedStore = unsortedStores.find(store => store.id === activeStoreId);
      setCurrentStore(movedStore);
      setNewSlot({ name: "", validFromDate: "", validFromTime: "", validUntilDate: "", validUntilTime: "" });
      setSlots([]);
      setSlotDialogOpen(true);
    } else if (sortedStores.find(store => store.id === activeStoreId)) {
      const oldIndex = sortedStores.findIndex(store => store.id === activeStoreId);
      const newIndex = sortedStores.findIndex(store => store.id === overStoreId);
      const newSortedStores = arrayMove(sortedStores, oldIndex, newIndex).map((store, index) => ({
        ...store,
        sortOrder: index + 1,
      }));
      setSortedStores(newSortedStores);
      updateBackendSortOrder(newSortedStores);
    }
  };

  const addSlot = () => {
    if (newSlot.name && newSlot.validFromDate && newSlot.validFromTime && newSlot.validUntilDate && newSlot.validUntilTime) {
      setSlots([...slots, newSlot]);
      setNewSlot({ name: "", validFromDate: "", validFromTime: "", validUntilDate: "", validUntilTime: "" });
    } else {
      alert("Please fill in all slot fields.");
    }
  };

  const saveSortOrderWithSlots = async () => {
    setLoading(true);
    const updatedStore = { 
      ...currentStore, 
      sortOrder: sortedStores.length + 1, 
      slots: slots.map(slot => ({
        ...slot,
        validFrom: `${slot.validFromDate}T${slot.validFromTime}`,
        validUntil: `${slot.validUntilDate}T${slot.validUntilTime}`,
      }))
    };
    const updatedSortedStores = [...sortedStores, updatedStore];
    setSortedStores(updatedSortedStores);
    setUnsortedStores(unsortedStores.filter(store => store.id !== currentStore.id));
    setSlotDialogOpen(false);
    await updateBackendSortOrder(updatedSortedStores);
    setLoading(false);
  };

  const confirmRemoveFromSorted = (store) => {
    setCurrentStore(store);
    setRemoveDialogOpen(true);
  };

  const removeFromSorted = async () => {
    setLoading(true);
    const updatedSortedStores = sortedStores.filter(store => store.id !== currentStore.id).map((store, index) => ({
      ...store,
      sortOrder: index + 1,
    }));
    setSortedStores(updatedSortedStores);
    setUnsortedStores([...unsortedStores, currentStore]);
    setRemoveDialogOpen(false);
    await GlobalService(API.REMOVE_STORE_SORT_ORDER, { zoneId: location_id, storeId: currentStore.id }, "post");
    await updateBackendSortOrder(updatedSortedStores);
    setLoading(false);
  };


  
  const updateBackendSortOrder = async (reorderedStores) => {
    const payload = reorderedStores.map(store => ({
      storeId: store.id,
      sortOrder: store.sortOrder,
      slots: store.slots || [],
    }));
    await GlobalService(API.UPDATE_STORE_SORT_ORDER, { zoneId: location_id, stores: payload }, "post");
  };

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20-30">
          {zoneData && (
            <>
              <Typography mb={2} variant="h6">Zone Name: {zoneData.name}</Typography>
            
             
            </>
          )}
          <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">Unsorted Stores</Typography>
                <DroppableArea id="unsorted">
                  {unsortedStores.map((store) => (
                    <SortableItem key={store.id} id={store.id}>
                      <Paper style={{ display: "flex", alignItems: "center", padding: 8, marginBottom: 8 }}>
                        <Avatar
                          src={"https://api.baladiexpress.com/" + store.business_logo}
                          alt={store.business_address}
                          variant="square"
                          sx={{ width: 50, height: 50, marginRight: 2 }}
                        />
                        <Box>
                          <Typography variant="subtitle1">{store.stores_locales[0].business_name} - {store.business_address}</Typography>
                          <Typography variant="caption" color="textSecondary">Status: {store.status}</Typography>
                          <Typography variant="caption" color="textSecondary">Approval: {store.is_approved ? "Yes" : "No"}</Typography>
                        </Box>
                      </Paper>
                    </SortableItem>
                  ))}
                </DroppableArea>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6">Sorted Stores</Typography>
                <SortableContext items={sortedStores.map(store => store.id)} strategy={verticalListSortingStrategy}>
                  <DroppableArea id="sorted">
                    {sortedStores.map((store) => (
                      <>
                       <SortableItem key={store.id} id={store.id} style={{ width: "100%" }}>
                        <Paper style={{ display: "flex", alignItems: "center", padding: 8, marginBottom: 8, width: "100%" }}>
                          <Avatar
                            src={"https://api.baladiexpress.com/" + store.business_logo}
                            alt={store.business_address}
                            variant="square"
                            sx={{ width: 50, height: 50, marginRight: 2 }}
                          />
                          <Box style={{ flexGrow: 1 }}>
                            <Typography variant="subtitle1">
                              {store.stores_locales[0].business_name} - {store.business_address} - Order: {store.sortOrder}
                            </Typography>
                            {store.slots && store.slots.map((slot, idx) => (
                              <Typography key={idx} variant="body2" color="textSecondary">
                                Slot: {slot.name} ({slot.validFrom} - {slot.validUntil})
                              </Typography>
                            ))}
                          </Box>
                        </Paper>
                      </SortableItem>
                                                <Button onClick={() => confirmRemoveFromSorted(store)} color="secondary" style={{ opacity: 1, marginLeft: 8 }}>Remove</Button>
</>
                    ))}
                  </DroppableArea>
                </SortableContext>
              </Grid>
            </Grid>
          </DndContext>

          <Backdrop open={loading} style={{ zIndex: 1000 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog open={slotDialogOpen} onClose={() => setSlotDialogOpen(false)}>
            <DialogTitle>Add Slot</DialogTitle>
            <DialogContent>
              <TextField
                label="Slot Name"
                fullWidth
                value={newSlot.name}
                onChange={(e) => setNewSlot({ ...newSlot, name: e.target.value })}
                margin="normal"
              />
              <TextField
                label="Valid From - Date"
                type="date"
                fullWidth
                value={newSlot.validFromDate}
                onChange={(e) => setNewSlot({ ...newSlot, validFromDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
              <TextField
                label="Valid From - Time"
                type="time"
                fullWidth
                value={newSlot.validFromTime}
                onChange={(e) => setNewSlot({ ...newSlot, validFromTime: e.target.value })}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
              <TextField
                label="Valid Until - Date"
                type="date"
                fullWidth
                value={newSlot.validUntilDate}
                onChange={(e) => setNewSlot({ ...newSlot, validUntilDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
              <TextField
                label="Valid Until - Time"
                type="time"
                fullWidth
                value={newSlot.validUntilTime}
                onChange={(e) => setNewSlot({ ...newSlot, validUntilTime: e.target.value })}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
              <Button onClick={addSlot} color="primary" style={{ marginTop: 8 }}>Add Slot</Button>
              <Box mt={2}>
                {slots.map((slot, index) => (
                  <Typography key={index} variant="body2" color="textSecondary">
                    {slot.name}: {slot.validFromDate} {slot.validFromTime} - {slot.validUntilDate} {slot.validUntilTime}
                  </Typography>
                ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSlotDialogOpen(false)} color="secondary">Cancel</Button>
              <Button onClick={saveSortOrderWithSlots} color="primary">Save</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={removeDialogOpen} onClose={() => setRemoveDialogOpen(false)}>
            <DialogTitle>Confirm Remove</DialogTitle>
            <DialogContent>Are you sure you want to remove this store from the sorted list?</DialogContent>
            <DialogActions>
              <Button onClick={() => setRemoveDialogOpen(false)} color="secondary">Cancel</Button>
              <Button onClick={removeFromSorted} color="primary">Confirm</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default AreaZoneStoreManagement;
