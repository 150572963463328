import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
    GET_ALL_DRIVERONBOARDING: (query) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.get(
                    constant.BASE_URL + api.Drivers.GET_ALL_DRIVERONBOARDING(query),
                    {
                        headers: {
                            "x-api-key": constant.X_API_KEY,
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        },

                    }
                );
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    },
    GET_DRIVER_ONBOARDING: (id) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              constant.BASE_URL + api.Drivers.GET_DRIVER_ONBOARDING(id),
              {
                headers: {
                    "x-api-key": constant.X_API_KEY,
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                },
            }
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        });
      },
      DELETE_DRIVER_ONBOARDING: (id) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.delete(
              constant.BASE_URL + api.Drivers.DELETE_DRIVER_ONBOARDING(id),
              {
                headers: {
                    "x-api-key": constant.X_API_KEY,
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                },
            }
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        });
      },
      UPDATE_DRIVER_ONBOARDING: (id, data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.put(
              constant.BASE_URL + api.Drivers.UPDATE_DRIVER_ONBOARDING(id),
              data,
              {
                headers: {
                    "x-api-key": constant.X_API_KEY,
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                },
            }
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        });
      },
    ADD_DRIVER_ONBOARDING: (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(
                    constant.BASE_URL + api.Drivers.ADD_DRIVER_ONBOARDING(),
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-api-key": constant.X_API_KEY,
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        },
                    }
                );
                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    },
};
