import React, { useState, useEffect, useRef } from "react";
import { Boxed } from "../../../../Styles-Elements/Box";
import { useSearchParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./../OrderManage.scss";
// import ProcessingTab from "./ProcessingTab";
// import ReadyToPickTab from "./ReadyToPickTab";
// import ShippedTab from "./ShippedTab";
// import CompletedTab from "./CompletedTab";
// import RejectedTab from "./RejectedTab";
// import CancelledTab from "./CancelledTab";
// import Pending from "./Pending";
import Orders from "./Orders";
import Header from "../../../layout/header/Header";
import Aside from "../../../layout/aside/Aside";
import { Dropdown } from "primereact/dropdown";
import {
  Typography, Box, TextField, Autocomplete,
  Button, MenuItem, Select,Tabs, Tab
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getdataByKey } from "../../../helper/custom";
import services from "../../../../services";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { currentPermission } from "../../../../redux/reducers/permission";
import { useSelector } from "react-redux";
import GlobalService from "../../../../services/GlobalService";
import { API } from "../../../ENUM";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
// import _debounce from "lodash/debounce";
// import ProcessingTabDetails from "../OrderManageDetail/ProcessingTab";
import { currentSocket, currentSocketObject, currentPickerSocket } from "../../../../redux/reducers/socket";
import { currentBaladiInfo } from "../../../../redux/reducers/baladiInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const thresholdOption = [
  { label: "All", value: "all" },
  { label: "New", value: "new" },
  { label: "Delay", value: "delay" },
];

const OrderListing = () => {

  const dispatch = useDispatch();
  const currentSocketObj = useSelector(currentSocket);
  const currentSocketObjectObj = useSelector(currentSocketObject);
  const currentPickerSocketObj = useSelector(currentPickerSocket);
  const baladiInfo = useSelector(currentBaladiInfo);
  let [BaladiTabs, setBaladiTabs] = useState([
    {
      label: "Scheduled Order",
      value: "scheduled_order",
      url: "processing",
      count: 0,
    },
    {
      label: "Picking Pending",
      value: "picking_pending",
      url: "processing",
      count: 0,
    },

    {
      label: "Picking In progress",
      value: "picking_in_progress",
      url: "processing",
      count: 0,
    },
    {
      label: "QC In progress",
      value: "qc_in_progress",
      url: "processing",
      count: 0,
    },
    {
      label: "Ready to Pick",
      value: "readytopick",
      url: "ready-to-pick",
      count: 0,
    },
    {
      label: "Shipping",
      value: "shipped",
      url: "shipped",
      count: 0,
    },
    {
      label: "Complete",
      value: "completed",
      url: "completed",
      count: 0,
    },
    {
      label: "Reject",
      value: "rejected",
      url: "rejected",
      count: 0,
    },
    {
      label: "Cancel",
      value: "cancelled",
      url: "cancelled",
      count: 0,
    },
    {
      label: "CS Confirmation",
      value: "pending",
      url: "pending",
      count: 0,
    },
    // {
    //   label: "Critical Orders",
    //   value: 'critical_orders',
    //   url: 'pending',
    //   count: 0,
    // }
  ]);
  let [OtherTabs, setOtherTabs] = useState([
    {
      label: "Vendor not accepted",
      value: "placed",
      url: "orders",
      count: 0,
    },
    {
      label: "Order Preparing In progress",
      value: "processing",
      url: "processing",
      count: 0,
    },
    {
      label: "Ready to Pick",
      value: "readytopick",
      url: "ready-to-pick",
      count: 0,
    },
    {
      label: "Shipping",
      value: "shipped",
      url: "shipped",
      count: 0,
    },
    {
      label: "Complete",
      value: "completed",
      url: "completed",
      count: 0,
    },
    {
      label: "Reject",
      value: "rejected",
      url: "rejected",
      count: 0,
    },
    {
      label: "Cancel",
      value: "cancelled",
      url: "cancelled",
      count: 0,
    },
    {
      label: "CS Confirmation",
      value: "pending",
      url: "pending",
      count: 0,
    },
    // {
    //   label: "Critical orders",
    //   value: 'critical_orders',
    //   url: 'pending',
    //   count: 0,
    // }
  ]);
  const permission = useSelector(currentPermission);
  let [availableTypes, setAvailableTypes] = useState([]);
  let [role, setRole] = useState("admin");
  const [searchParams] = useSearchParams();
  let [value, setValue] = useState(0);
  const [allStores, setAllStores] = useState([]);
  const [stores, setStores] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [allbusiness, setAllbusiness] = useState("");
  const [businessid, setBusinessid] = useState([]);
  const [startDateSelect, setStartDateSelect] = useState("");
  const [endDateSelect, setEndDateSelect] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  let [businessType, setBusinessType] = useState([]);
  let [businessTypeSelected, setBusinessTypeSelected] = useState([]);
  let [selectedStore, setSelectedStore] = useState(null);
  const [selectFilter, setSelectFilter] = useState({ name: "Desc", code: "desc" });
  let [searchTerm, setSearchTerm] = useState("");
  let [valueDatepickerStart, setValueDatepickerStart] = useState(null);
  let [valueDatepickerEnd, setValueDatepickerEnd] = useState(null);
  let [statusColors, setStatusColors] = useState([]);
  let [socketRun, setSocketRun] = useState(false);
  let [dataMapped, setDataMapped] = useState([]);
  let [currentTabCount, setCurrentTabCount] = useState(0);
  const [statusBaladi, setStatusBaladi] = useState(null);
  let [selectedTab, setSelectedTab] = useState('placed');
  const Lists = [
    { name: "Desc", code: "desc" },
    { name: "Asc", code: "asc" },
  ];
  let [threshold, setThreshold] = useState("all");
  let [isBaladi, setIsBaladi] = useState(null);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");

  const clearAllInputs = () => {
    setSearch("");
    setValueDatepickerStart(null);
    setValueDatepickerEnd(null);
    setSelectedStore(null);
    setBusinessTypeSelected([]);
    getAllOrderCount(businessid);
    setSelectedStoreId("");
    setStartDateSelect("");
    setEndDateSelect("");
    threshold = "all";
    setThreshold(threshold);
  };

  // useEffect(() => {
  //   if (!search) clearAllInputs();
  // }, [search]);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.id}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.id}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const updateCount = (count, type) => {
    if (isBaladi) {
      BaladiTabs = BaladiTabs.map((item) => {
        if (item.value === type) {
          item.count = count;
        }
        return item;
      });
      setBaladiTabs(BaladiTabs);
    } else {
      OtherTabs = OtherTabs.map((item) => {
        if (item.value === type) {
          item.count = count;
        }
        return item;
      });
      setOtherTabs(OtherTabs);
    }
  };

  const handleChange1 = (event, newValue) => {
    const tabValue = isBaladi ? BaladiTabs[newValue] : OtherTabs[newValue];
    selectedTab = tabValue?.value;
    setSelectedTab(selectedTab)
    setValue(newValue);
  };

  const getAll = async () => {
    dispatch(changeLoader(true));
    const res = await GlobalService(API.TRAFFIC_LIGHT_SYSTEM.GET_ALL);

    let allTimes = [];

    res.results.map((item) => {
      const arr = item.time;
      allTimes.push(arr);
    });

    if (res.ack === 1) {
      statusColors = res.results;
      setStatusColors(statusColors);
    }
    dispatch(changeLoader(false));
  };

  const getAllBusinessType = async () => {
    dispatch(changeLoader(true));
    const response = await GlobalService(API.GET_ALL_BUSINESS);

    const data = await response.businesstype.rows.map((item) => {
      return item.id;
    });
    setAllbusiness(data);
    if (dataMapped.length === 0) {
      let newData;
      if (Array.isArray(data)) {
        newData = data;
      } else {
        newData = [data];
      }
    }
    let newbusinessType = response.businesstype.rows
      .filter((item) => {
        if (permission.role !== "admin") {
          availableTypes = permission?.orderListing?.availableTypes;
          if (availableTypes.includes(item.id)) {
            return item;
          }
        } else {
          return item?.id !== 2;
        }
      })
      .map((item) => {
        return {
          id: item.id,
          name: item.business_type_locales[0].name,
        };
      });

    setBusinessType(newbusinessType);

    const data1 = newbusinessType.map((item) => {
      return item.id;
    });

    setBusinessid(data1);
    setDataMapped(data1);
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getAll();
    // getAllStores();
    getAllBusinessType();
  }, []);

  // for filter orders by store id
  const getAllStores = async () => {
    try {
      dispatch(changeLoader(true));
      const store = (await services.Stores.GET_STORE_LIST()).data.stores;
      setAllStores(store);

      const storeArr = [{ id: "", name: "ALL" }];
      store.map((store) => {
        storeArr.push({
          id: store.id,
          name: getdataByKey(store?.stores_locales, "en", "business_name"),
        });
        if (
          getdataByKey(store?.stores_locales, "en", "business_name") ===
          "Baladi Express Store"
        ) {
          selectedStore = store;
          setSelectedStore(selectedStore);
        }
      });
      setIsLoaded(true);

      dispatch(changeLoader(false));
    } catch (error) {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    if (valueDatepickerStart && valueDatepickerStart?.$d) {
      let date = new Date(valueDatepickerStart.$d);
      let formatted_date = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} 00:00:00`;
      setStartDateSelect(formatted_date);
    }
  }, [valueDatepickerStart]);

  useEffect(() => {
    if (valueDatepickerEnd && valueDatepickerEnd?.$d) {
      let date = new Date(valueDatepickerEnd.$d);
      date.setDate(date.getDate() + 1);
      let formatted_date = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} 23:59:59`;
      setEndDateSelect(formatted_date);
    }
  }, [valueDatepickerEnd]);



  const getAllOrderCount = async (dataMapped = "") => {
    const isBaladiType = (urlStore, urlStoreType) =>
      [
        "baladi",
        "radytopickBaladi",
        "processingBaladi",
        "shippedBaladi",
        "rejectedBaladi",
        "cancelledBaladi",
        "completedBaladi",
        "pendingBaladi",
        "processingScheduled",
        "processingPicking",
        "processingQc",
      ].includes(urlStore || urlStoreType);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlStore = urlSearchParams.get("isbaladi");
    setUrl(urlStore);
    const urlSearch = new URLSearchParams(window.location.search);
    const urlStoreType = urlSearch.get("status");
    let mappedIds = dataMapped;
    if (dataMapped === "") {
      mappedIds = businessid.join(",");
    }
    try {
      dispatch(changeLoader(true));
      const startDateTime = startDateSelect
        ? moment(new Date(startDateSelect)).format("YYYY-MM-DD HH:mm:ss")
        : moment(new Date()).format("YYYY-MM-DD 00:00:00");
      const endDateTime = endDateSelect
        ? moment(new Date(endDateSelect))
            .subtract(1, "days")
            .format("YYYY-MM-DD HH:mm:ss")
        : moment(new Date(startDateTime)).format("YYYY-MM-DD 23:59:59");
      let query = `search=${
        search === ""
          ? searchRef.current.length <= 1
            ? search
            : searchRef.current
          : search
      }&start_date=${startDateTime ? startDateTime : ""}&end_date=${
        endDateTime && startDateTime ? endDateTime : ""
      }&storeId=${isBaladiType(urlStore, urlStoreType) ? 1 : selectedStoreId}`;
      if (isBaladiType(urlStore, urlStoreType)) {
        query += `&businessTypeId=2`;
      } else {
        query += mappedIds ? `&businessTypeId=${mappedIds}` : "";
      }
      const response = await services.Orders.GET_ALL_ORDERS_COUNT(query);
      if (isBaladiType(urlStore, urlStoreType)) {
        BaladiTabs = BaladiTabs.map((item) => {
          item.count =
            response.data.orderCounts.find((order) => order.type === item.value)
              ?.count || 0;
          return item;
        });
        setBaladiTabs(BaladiTabs);
      } else {
        OtherTabs = OtherTabs.map((item) => {
          item.count =
            response.data.orderCounts.find((order) => order.type === item.value)
              ?.count || 0;
          return item;
        });
        setOtherTabs(OtherTabs);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    if (searchParams.get("status")) {
      switch (searchParams.get("status")) {
        case "processingScheduled":
          value = 0;
          break;
        case "processingPicking":
          value = 2;
          break;
        case "processingQc":
          value = 3;
          break;
        case "processingBaladi":
          value = 1;
          break;
        case "processing":
          value = 1;
          break;
        case "radytopickBaladi":
          value = 4;
          break;
        case "radytopick":
          value = 2;
          break;
        case "shippedBaladi":
          value = 5;
          break;
        case "shipped":
          value = 3;
          break;
        case "completedBaladi":
          value = 6;
          break;
        case "completed":
          value = 4;
          break;
        case "rejectedBaladi":
          value = 7;
          break;
        case "rejected":
          value = 5;
          break;
        case "cancelledBaladi":
          value = 8;
          break;
        case "cancelled":
          value = 6;
          break;
        case "pendingBaladi":
          value = 9;
          break;
        case "pending":
          value = 7;
          break;
        default:
          break;
      }
      const tabValue = isBaladi ? BaladiTabs[value] : OtherTabs[value];
      selectedTab = tabValue?.value;
      setSelectedTab(selectedTab)
      setValue(value);
    }
  }, [searchParams]);

  useEffect(() => {
    const getData = setTimeout(() => {
      searchTerm = search;
      setSearchTerm(searchTerm);
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    if (permission.role !== "admin") {
      availableTypes = permission?.orderListing?.availableTypes;
      setAvailableTypes(availableTypes);
      role = "subAdmin";
      setRole(role);
    } else {
      role = "admin";
      setRole(role);
    }
  }, [permission]);

  useEffect(() => {
    if (currentSocketObj || currentPickerSocketObj) {
      setSocketRun(true);
    }
  }, [currentSocketObj, currentPickerSocketObj]);

  const searchRef = useRef(search);
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (dataMapped.length == 0) {
        getAllOrderCount(businessid);
      } else if (businessTypeSelected.length === 0) {
        getAllStores();
        getAllOrderCount();
      } else {
        getAllOrderCount(dataMapped);
      } 
    }, 500);
    return () => clearTimeout(getData);
  }, [dataMapped, search, startDateSelect, endDateSelect, selectedStoreId, businessTypeSelected]);

  useEffect(() => {
    const getData = setTimeout(() => {
      let ids = [];
      businessType.forEach((item) => {
        ids.push(item.id);
      });
      const remStore = allStores.filter((item) =>
        ids.includes(item.businessTypeId)
      );
      const storeArr1 = [{ id: null, name: "ALL" }];
      remStore.map((store) => {
        storeArr1.push({
          id: store.id,
          name: getdataByKey(store?.stores_locales, "en", "business_name"),
        });
      });
      setStores(storeArr1);
    }, 500);
    return () => clearTimeout(getData);
    
  }, [businessType, allStores]);

  const downloadStoreOrders = async () => {
    
    const currentTab = isBaladi ? BaladiTabs[value] : OtherTabs[value];
    const type = currentTab.value;
    const limit = currentTab.count;
    const bussinessTypes = isBaladi ?
      [{ id: 2 }] :
      businessTypeSelected.length  === 0?
      businessType: businessTypeSelected; 
    try {
      dispatch(changeLoader(true));
      const startDateTime = valueDatepickerStart
        ? moment(new Date(startDate)).format("YYYY-MM-DD 00:00:00")
        : moment(new Date()).format("YYYY-MM-DD 00:00:00");

      const endDateTime = valueDatepickerEnd
        ? moment(new Date(valueDatepickerEnd)).format("YYYY-MM-DD 23:59:59")
        : moment(new Date(startDateTime)).format("YYYY-MM-DD 23:59:59");
      const query = new URLSearchParams({
        download: "yes",
        // page: 1,
        // limit: limit,
        storeId: isBaladi ? baladiInfo.BALADI_STORE_SLUG: selectedStore?.id ? selectedStore?.id : "",
        type,
        search: searchTerm ? searchTerm.trim() : "",
        start_date: startDateTime ? startDateTime : "",
        end_date: endDateTime && startDateTime ? endDateTime : "",
        businessTypeId: bussinessTypes.map((item) => item.id).join(",")
      }).toString();
      const response = (await services.Orders.GET_ALL_ORDERS(query)).data.orders;
      if (response) {
        downloadCSV(response.rows);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
    }
  };

  function convertArrayOfObjectsToCSV(data) {
    const separator = ",";
    // Define the column names
    const columns = [
      "StoreName",
      "Customer Name",
      "Customer Number",
      "Order Id",
      "No of Items",
      "Total Amount",
      "Payment Method",
      "Payment status",
      "Status",
      "Order Date",
      "picker name",
      "picker Email",
    ];
    // Create the CSV header
    const csvHeader = columns.join(separator);
    // Create the CSV rows
    let csvRows = data.map((row) => {
      let pickerDetails = row.pickerDetails || {};
      let pickerFullName =
        pickerDetails.first_name && pickerDetails.last_name
          ? `${pickerDetails.first_name} ${pickerDetails.last_name}`
          : "No picker found";
      let pickerEmail = pickerDetails.email || "No picker found";
      return [
        row?.store?.stores_locales[0]?.business_name,
        row?.user?.full_name,
        row?.user?.contact_number,
        row?.order_id,
        row?.total_quantity,
        row?.total_amount,
        row?.payment_method,
        row?.payment_status,
        row?.status,
        moment(new Date(row?.createdAt)).format("DD MMMM YYYY hh:mm A").toString(),
        // row?.createdAt,
        pickerFullName,
        pickerEmail,
      ]
        .map((cell) =>
          cell === null || cell === undefined ? "" : cell.toString()
        )
        .join(separator);
    });
    // Combine the header and rows
    const csvContent = [csvHeader, ...csvRows].join("\n");
    return csvContent;
  }

  function downloadCSV(data) {
    const csvContent = convertArrayOfObjectsToCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv" });
    
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "orders.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const handleStoreChange = (bool) => {

    if (bool) {
      navigate(`/order-listing?isbaladi=baladi`);
    } else {
      navigate(`/order-listing`);
    }
    isBaladi = bool;
    setIsBaladi(isBaladi);
    setValue(0);
    getAllOrderCount();
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  
  const urlStoreType = urlSearchParams.get("status");

  const isBaladiTypeUrl = (url, urlStoreType) =>
    [
      "baladi",
      "radytopickBaladi",
      "processingBaladi",
      "shippedBaladi",
      "rejectedBaladi",
      "cancelledBaladi",
      "completedBaladi",
      "pendingBaladi",
      "processingScheduled",
      "processingPicking",
      "processingQc",
    ].includes(url || urlStoreType);

  return (
    <Box className="dashboard-parent">
      <Header />
      <Aside />
      <Box className="main-wrapper">
        <Box className="white-box-20">
          <div className="top-heading-search">
            <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
              Order Listing
            </Typography>
            <div className="right-filter">
              <Button
                variant={
                  isBaladiTypeUrl(url, urlStoreType) ? "contained" : "outlined"
                }
                color="error"
                style={{ marginRight: "10px" }}
                size="large"
                onClick={() => {
                  handleStoreChange(true);
                }}
              >
                {" "}
                Baladi Express Store{" "}
              </Button>
              <Button
                variant={
                  url !== "baladi" &&
                  ![
                    "radytopickBaladi",
                    "processingBaladi",
                    "shippedBaladi",
                    "rejectedBaladi",
                    "cancelledBaladi",
                    "completedBaladi",
                    "pendingBaladi",
                    "processingScheduled",
                    "processingPicking",
                    "processingQc",
                  ].includes(urlStoreType)
                    ? "contained"
                    : "outlined"
                }
                color="error"
                size="large"
                onClick={() => handleStoreChange(false)}
              >
                {" "}
                Other Store{" "}
              </Button>
            </div>
          </div>
          <Box display={"flex"} justifyContent={"flex-end"} my={"20px"}>
            <div className=" ">
              <button className="btn clear-all" onClick={() => downloadStoreOrders()} style={{ marginRight: '10px', }}>Download CSV</button>
            </div>
            <div className="search-box mr-20" style={{ width: "190px" }}>
              <TextField
                id="outlined-size-small"
                size="small"
                placeholder="Search By Order ID"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ marginRight: "8px" }} />,
                }}
              />
            </div>
            <div className="mr-20" style={{ width: "200px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={valueDatepickerStart}
                  onChange={(newValue) => {
                    valueDatepickerStart = newValue;
                    setValueDatepickerStart(valueDatepickerStart);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="start date"
                    />
                  )}
                  inputFormat="DD-MM-YYYY"
                />
              </LocalizationProvider>
            </div>
            {valueDatepickerStart ? (
              <div className="mr-20" style={{ width: "200px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="width160"
                    minDate={new Date(valueDatepickerStart)}
                    maxDate={new Date(
                      new Date(valueDatepickerStart).setDate(
                        new Date(valueDatepickerStart).getDate() + 15
                      )
                    )}
                    value={valueDatepickerEnd}
                    onChange={(newValue) => {
                      valueDatepickerEnd = newValue;
                      setValueDatepickerEnd(valueDatepickerEnd);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="end date"
                      />
                    )}
                    label="End Date"
                    inputFormat="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </div>
            ) : null}
            {/* {!(url === "baladi") ? ( */}
            {!isBaladiTypeUrl(url, urlStoreType) ? (
              <Autocomplete
                id="combo-box-demo"
                label="Business type"
                options={businessType}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Business type"
                    variant="outlined"
                  />
                )}
                multiple={true}
                value={businessTypeSelected}
                className="w-150 mr-20"
                onChange={(e, newValue) => {
                  businessTypeSelected = newValue;
                  setBusinessTypeSelected(businessTypeSelected);
                  selectedStore = null;
                  setSelectedStore(selectedStore);
                  const mappedIds = businessTypeSelected.map((item) => item.id);
                  setDataMapped(mappedIds);
                  const remStore = allStores.filter((item) =>
                    mappedIds.includes(item.businessTypeId)
                  );
                  const storeArr = [{ id: null, name: "ALL" }];
                  remStore.map((store) => {
                    storeArr.push({
                      id: store.id,
                      name: getdataByKey(
                        store?.stores_locales,
                        "en",
                        "business_name"
                      ),
                    });
                  });
                  setStores(storeArr);
                }}
              />
            ) : null}
            <div className="mr-20">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={threshold}
                onChange={(e) => {
                  threshold = e.target.value;
                  setThreshold(threshold);
                  getAllOrderCount();
                }}
                size="small"
                className="status-drop"
              >
                {thresholdOption.map((option, index) => (
                  <MenuItem key={`threshold-${index}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* {!isBaladi ? ( */}
            {!isBaladiTypeUrl(url, urlStoreType) ? (
              <Dropdown
                value={selectedStore}
                onChange={(e) => {
                  selectedStore = e.value;
                  setSelectedStore(selectedStore);
                  setSelectedStoreId(selectedStore?.id);
                }}
                options={stores}
                optionLabel="name"
                placeholder={
                  selectedStore
                    ? stores.map((item) => {
                        if (item.id === selectedStore) {
                          return item.name;
                        }
                      })
                    : "All"
                }
                filter
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem search-drop-option"
              />
            ) : null}
            <span className="clear-all" onClick={() => clearAllInputs()}>
              {" "}
              Clear{" "}
            </span>
          </Box>
          <div className="order-listing-wrapper">
            <div className="">
              <div className="tabs-wrap">
                <div
                  style={{
                    borderBottom: "1px solid #E3E3E3",
                    marginBottom: "12px",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange1}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {isBaladiTypeUrl(url, urlStoreType)
                      ? BaladiTabs.map((BaladiTab, index) => (
                          <Tab
                            key={`baladi-${index}`}
                            className={""}
                            label={`${BaladiTab.label} ${
                              
                              BaladiTab.count > 0 ? `(${BaladiTab.count})` : ""
                            }`}
                            {...a11yProps(index)}
                          />
                        ))
                      : OtherTabs.map((BaladiTab, index) => (
                          <Tab
                            key={`baladi-${index}`}
                            className={""}
                            label={`${BaladiTab.label} ${
                              BaladiTab.count > 0 ? `(${BaladiTab.count})` : ""
                            }`}
                            {...a11yProps(index)}
                          />
                        ))}
                  </Tabs>
                </div>
                <div className="">
                  <Orders
                    isBaladi={isBaladiTypeUrl(url, urlStoreType) ? true : false}
                    storeId={selectedStore?.id}
                    searchTerm={searchTerm}
                    startDate={startDateSelect}
                    endDate={endDateSelect}
                    setCurrentCountTemp={setCurrentTabCount}
                    updateCount={updateCount}
                    businessTypes={
                      businessTypeSelected.length
                        ? businessTypeSelected
                        : businessTypeSelected.length < 1 &&
                          permission.role !== "admin"
                        ? businessType
                        : businessTypeSelected
                    }
                    statusColors={
                      isBaladi && ['scheduled_order', 'picking_pending', 'picking_in_progress', 'qc_in_progress'].includes(selectedTab) ?
                      statusColors.find((item) => item.order_status === 'processing'):
                      statusColors.find((item) => item.order_status === selectedTab)
                    }
                    socketRun={currentSocketObj}
                    currentTab={value === 0 ? true : false}
                    resetSocketRun={() => setSocketRun(false)}
                    dataProp={selectFilter.code}
                    checked={false}
                    getOrderValue={
                      isBaladiTypeUrl(url, urlStoreType)
                        ? BaladiTabs[value]
                        : OtherTabs[value]
                    }
                    threshold={threshold}
                    businessid={businessid}
                    baladiStoreTabChangeHandler={handleStoreChange}
                    getOrderForBaladi={
                      isBaladiTypeUrl(url, urlStoreType)
                        ? BaladiTabs[value]
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default OrderListing;
