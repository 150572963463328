import { useEffect, useState } from "react";
import { changeLoader } from "../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Button, Switch } from "@mui/material";
import { MoreVert, DeleteForever, BorderColor, ContentCopy, ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { formatedDate, priceHandler } from "../../../pages/helper/custom";
import { API } from "../../../pages/ENUM";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import ConfirmBox from "../../../dialog/ConfirmBox";
import GlobalService from "../../../services/GlobalService";

const applyTypes = [
  { label: "Business Type", value: "business_type" },
  { label: "Category", value: "category" },
  { label: "Store", value: "store" },
  { label: "Product", value: "product" },
]

const  OfferListTable = ({ type, offers, sortOrder, getOffers, canEdit, canDelete, canUpdateStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [rows, setRows] = useState([]);
  let [selectedId, setSelectedId] = useState(null);

  const handleDelete = async (id) => {
    dispatch(changeLoader(true));
    const url = API.DISCOUNT_AND_OFFERS.DELETE_DISCOUNT_AND_OFFERS(id);
    const response = await GlobalService(url, { status: "deleted" }, "post");
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: response.msg
      }))
      getOffers();
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Error While Deleting Offer",
      }))
    }
    dispatch(changeLoader(false));
  };

  const changeStatus = async (id, status) => {
    dispatch(changeLoader(true));
    const url = API.DISCOUNT_AND_OFFERS.DELETE_DISCOUNT_AND_OFFERS(id);
    const response = await GlobalService(url, { status }, "post");
    if (response.ack === 1) {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "success",
        message: response.msg
      }))
    } else {
      dispatch(changeSnackbar({
        isOpen: true,
        state: "error",
        message: "Error While Deleting Offer",
      }))
    }
    dispatch(changeLoader(false));
    getOffers();
  };

  const BasicMenu = ({ item, handleEdit, handleDelete, canEdit, canDelete }) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    return (
      <div>
        <Button
          id="basic-button" aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true" aria-expanded={open ? "true" : undefined}
          onClick={(event) => {setAnchorEl(event.currentTarget);}} type="button"
        ><MoreVert /></Button>
        <Menu
          id="basic-menu" anchorEl={anchorEl} open={open} onClose={() => {setAnchorEl(null);}}
          MenuListProps={{"aria-labelledby": "basic-button",}}
        >
          {canEdit?<MenuItem onClick={() => { handleEdit(item.id); }}>   <BorderColor />Edit</MenuItem>: null}
          {canDelete?<MenuItem onClick={() => { handleDelete(item.id); }}> <DeleteForever />Delete</MenuItem>: null}
        </Menu>
      </div>
    );
  };

  const SortOption = ({ name, type, sortOrder }) => {

    return (
      <div className="short-div"> {name}
        <div className="short-box">
          <ArrowDropUp onClick={() => { sortOrder(type, "asc"); }} />
          <ArrowDropDown onClick={() => { sortOrder(type, "desc"); }}/>
        </div>
      </div>
    )
  };

  useEffect(() => { setRows(offers); }, [offers]);

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> <SortOption type="name" name="Discount Name" sortOrder={sortOrder} /> </TableCell>
              <TableCell> <SortOption type="name" name="Discount Amount" sortOrder={sortOrder} /> </TableCell>
              <TableCell> <SortOption type="name" name="Discount Type" sortOrder={sortOrder} /> </TableCell>
              <TableCell> <SortOption type="name" name="Start Date" sortOrder={sortOrder} /> </TableCell>
              <TableCell> <SortOption type="name" name="End Date" sortOrder={sortOrder} /> </TableCell>
              <TableCell> <SortOption type="name" name="Applied To" sortOrder={sortOrder} /> </TableCell>
              {canUpdateStatus?<TableCell align="center">Status</TableCell>: null}
              { canEdit || canDelete?<TableCell align="center">Action</TableCell>: null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
            <TableRow key={`offers-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.type !== 'bogo'? priceHandler(row.amount): "-"}</TableCell>
              <TableCell className="capitalize">{row.type}</TableCell>
              <TableCell>{formatedDate(row.start_date)}</TableCell>
              <TableCell>{formatedDate(row.end_date)}</TableCell>
              <TableCell>{applyTypes.find(
                (applyType) => applyType.value === row.apply_type
              ).label} ({row.apply_on})</TableCell>
              {canUpdateStatus?<TableCell align="center">
                <Switch
                  checked={row.status === "active"}
                  onChange={async (event) => changeStatus(row.id, event.target.checked ? "active" : "inactive")}
                />
              </TableCell>: null}
              { canEdit || canDelete?<TableCell align="center">
                <BasicMenu
                  item={row}
                  type={type}
                  handleEdit={() => { navigate(`/edit-offer/${row.id}`); }}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  handleDelete={(id) => {
                    selectedId = id
                    setSelectedId(selectedId)
                    setOpenDeleteConfirm(true)
                  }}
                />
              </TableCell>: null}
            </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>

      {openDeleteConfirm?<ConfirmBox
        isOpen={openDeleteConfirm}
        message="Are you sure you want to delete this offer?"
        handleClose={() => setOpenDeleteConfirm(false)}
        submit={() => {
          setOpenDeleteConfirm(false);
          handleDelete(selectedId);
        }}
      />: null}
    </div>
  );
}
export default OfferListTable;
