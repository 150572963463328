
const Forbidden = () => {

    return (
        <div>
            <h1>403</h1>
            <p> You do not have permission to access this page. </p>
        </div>
    );
};

export default Forbidden;