import { Box, Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Header from "../layout/header/Header";
import Aside from "../layout/aside/Aside";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { API} from "../ENUM";
import ConfirmBox from "../../dialog/ConfirmBox";
import { currentPermission } from "../../redux/reducers/permission";
import GlobalService from "../../services/GlobalService";
import { useNavigate } from "react-router-dom";

const ZoneList = () => {

  const navigate = useNavigate();
  const permission = useSelector(currentPermission);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);
  let [canAdd, setCanAdd] = useState(true);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [id,setId] = useState(0);

  const getZoneList = async () => {
    const response = await GlobalService(`${API.GET_LOCATION}`);
    if (response.ack === 1) {
      setList(prev => prev = response.data);
    } else {
      dispatch(
        changeSnackbar({
          message: response.msg,
          isOpen: true,
          state: "error",
        })
      );
    }
  }

  const deleteZone = async (id) => {
    const response = await GlobalService(`${API.DELETE_LOCATION}`, { zoneId: id }, "delete");
    if (response?.ack == 1) {
      getZoneList();
    }
    dispatch(
      changeSnackbar({
        message: response?.msg,
        isOpen: true,
        state: response?.ack === 1 ? "success" : "error",
      })
    );
    setOpen(false);
    setId(0);
  };

  useEffect(() => {
    getZoneList();
  }, []);

  const Data = ({ obj, ind, canEdit, canDelete }) => {
 
    return (
      <TableRow>
        <TableCell> {ind + 1}</TableCell>
        <TableCell>{obj?.name}</TableCell>
        <TableCell>{obj?.latlng}</TableCell>

        { canEdit || canDelete ?
          <TableCell style={{ width: "200px" }}>

          {canEdit?
          <Button
            onClick={() => {navigate(`/add-zone/${obj?.id}`);}}
            variant="contained" size="small"
            style={{ marginRight: "10px", }}
          >
            Edit
          </Button>: null}
          {obj?.id !== 1 ?  canDelete?
            <Button
              onClick={()=> { setId(obj?.id); setOpen(true); }}
              variant="contained"
              size="small"
            >Del</Button>: null : null}
        </TableCell>: null}
      </TableRow>
    );
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanAdd(permission?.zone.create === 1)
      setCanEdit(permission?.zone.edit === 1)
      setCanDelete(permission?.zone.delete === 1)
    } else {
      setCanEdit(true)
      setCanAdd(true)
      setCanDelete(true)
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
              Zone
            </Typography>
            <Grid container spacing={2}>
              {canAdd?<Button
                variant="contained"
                onClick={() => { navigate("/add-zone"); }}
              > Add Zone{" "} </Button>: null}
            </Grid>

            <div style={{ marginTop: "40px" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell> Sno </TableCell>
                    <TableCell> Name </TableCell>
                    <TableCell> Coordinate </TableCell>
                    { canEdit || canDelete ?
                      <TableCell> Action </TableCell>: null}
                  </TableRow>
                </TableBody>
                <TableBody>
                  {list.map((obj, ind) => {
                    return <Data
                      key={`zone-${ind}`}
                      obj={obj} ind={ind}
                      canEdit={canEdit}
                      canDelete={canDelete}
                    />;
                  })}
                </TableBody>
              </Table>
            </div>
          </Box>
        </Box>
      </Box>

      <ConfirmBox
        isOpen={open}
        handleClose={()=>{
          setOpen(false)
          setId(0);
        }}
        submit={()=>{
          deleteZone(id)
        }}
      />
    </>
  );
};

export default ZoneList;
