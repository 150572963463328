import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import DeleteForever from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Pagination from "@mui/material/Pagination";
import { Grid, Button, Typography, TextField, Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import { getdataByKey } from "../../helper/custom";
import modalClose from "../../../assets/images/structure/close-modal.png";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import services from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import NoDataFound from "../../../global-modules/EmptyPages/NoDataFound";
import { currentPermission } from "../../../redux/reducers/permission";

export default function UnitOfMeasurement() {
  
  const dispatch = useDispatch();
  const permission = useSelector(currentPermission);

  let [canCreate, setCanCreate] = useState(true);
  let [canEdit, setCanEdit] = useState(true);
  let [canDelete, setCanDelete] = useState(true);

  const [unitModel, setUnitModel] = useState({ text: "", isOpen: false });
  const [unitData, setUnitData] = useState([]);
  const [searchUnit, setSearchUnit] = useState([]);
  const [limit, setLimit] = useState(10);
  let [page, setPage] = useState(1);
  const [editModal, setEditModal] = useState({
    isOpen: false,
    id: null,
    name: "",
    name_ar: "",
    err: "required",
    isValid: false,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });

  let [count, setCount] = useState();
  const [sortCriteria, setSortCriteria] = useState({
    field: "id",
    order: "desc",
  });

  const [toaster, setToaster] = useState({
    visibilty: false,
    status: "",
    text: "",
  });
  const navigate = useNavigate();

  const [unit, setUnit] = useState({
    val: "",
    err: "required",
    isValid: false,
  });

  const [unit_ar, setUnitAr] = useState({
    val: "",
    err: "required",
    isValid: false,
  });

  useEffect(() => {
    getAllUnits();
  }, [page, sortCriteria]);

  useEffect(() => {
    page = 1;
    setPage(page);
    const getData = setTimeout(() => {
      getAllUnits();
    }, 500);
    return () => clearTimeout(getData);
  }, [searchUnit]);

  const getAllUnits = async () => {
    try {
      const query = new URLSearchParams({
        page: page,
        limit: limit,
        sort: sortCriteria.field,
        order: sortCriteria.order,
        search: searchUnit,
      }).toString();
      dispatch(changeLoader(true));
      const response = await services.Unit.GET_ALL_UNITS(query);
      setUnitData(response.data.units.rows);
      Math.ceil(count / limit);
      count = Math.ceil(response.data.units.count / limit);
      setCount(count);
      dispatch(changeLoader(false));
    } catch (error) {
      console.log(error);
    }
  };

  const getSpecificUnit = async (id) => {
    try {
      const query = new URLSearchParams({
        id,
      }).toString();
      const response = await services.Unit.GET_ALL_UNITS(query);
      const locale = response?.data?.units?.rows[0]?.unit_locales;
      const name = getdataByKey(locale, "en", "name");
      const name_ar = getdataByKey(locale, "ar", "name");
      setEditModal({
        ...editModal,
        name: name,
        name_ar: name_ar,
        isOpen: true,
        id: id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnitModel = (level) => {
    switch (level) {
      case 1:
        setUnitModel({ text: "Add Unit", isOpen: true });
        break;
      case 2:
        setUnitModel({ text: "Edit Unit", isOpen: true });
        break;
      default:
        break;
    }
  };
  const handleClose = () => {
    setUnitModel({ ...unitModel, isOpen: false });
    setUnit({
      val: "",
      err: "required",
      isValid: false,
    });
    setUnitAr({
      val: "",
      err: "required",
      isValid: false,
    });
  };

  const handlePagination = (e, value) => {
    page = value;
    setPage(page);
  };

  const inputChangeHandler = (e, type = "en") => {
    if (type == "ar") {
      if (e.target.value.length == 0) {
        setUnitAr({ val: "", err: "required", isValid: false });
        return false;
      }
      if (e.target.value) {
        if (e.target.value.length > 50) {
          setUnitAr({
            val: e.target.value,
            err: "max 50 character allowed",
            isValid: false,
          });
        } else {
          setUnitAr({ val: e.target.value, err: "", isValid: true });
        }
      } else {
        setUnitAr({ val: "", err: "required", isValid: false });
      }
      return false;
    }
    if (e.target.value) {
      if (e.target.value.length > 50) {
        setUnit({
          val: e.target.value,
          err: "max 50 character allowed",
          isValid: false,
        });
      } else {
        setUnit({ val: e.target.value, err: "", isValid: true });
      }
    } else {
      setUnit({ val: "", err: "required", isValid: false });
    }
  };

  const handleOnSave = async () => {
    if ((unit.isValid, unit_ar.isValid)) {
      try {
        const data = {
          name_en: unit.val,
          name_ar: unit_ar.val,
        };
        dispatch(changeLoader(true));
        const response = await services.Unit.ADD_UNIT(data);
        if (response.data.ack) {
          handleClose();
          setUnitModel({ text: "", isOpen: false });
          setToaster({
            status: "success",
            text: `${response.data.msg}`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 6000);
          getAllUnits();
        }
        dispatch(changeLoader(false));
      } catch (error) {
        handleClose();
        setToaster({
          status: "error",
          text: `${error.data.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllUnits();
        console.log(error);
      }
    }
  };

  const editChangeHandler = (e, type = "en") => {
    if (type == "ar") {
      if (e.target.value) {
        if (e.target.value.length > 50) {
          setEditModal({
            ...editModal,
            err: "max 50 character allowed",
            isValid: false,
            isOpen: true,
            name_ar: e.target.value,
          });
        } else {
          setEditModal({
            ...editModal,
            err: "",
            isValid: true,
            isOpen: true,
            name_ar: e.target.value,
          });
        }
      } else {
        setEditModal({
          ...editModal,
          err: "required",
          isValid: false,
          isOpen: true,
          name_ar: e.target.value,
        });
      }
      return;
    }

    if (e.target.value) {
      if (e.target.value.length > 50) {
        setEditModal({
          ...editModal,
          err: "max 50 character allowed",
          isValid: false,
          isOpen: true,
          name: e.target.value,
        });
      } else {
        setEditModal({
          ...editModal,
          err: "",
          isValid: true,
          isOpen: true,
          name: e.target.value,
        });
      }
    } else {
      setEditModal({
        ...editModal,
        err: "required",
        isValid: false,
        isOpen: true,
        name: e.target.value,
      });
    }
  };
  const handleOnUpdate = async () => {
    if (editModal.isValid && editModal.name_ar && editModal.name) {
      try {
        const data = {
          name_en: editModal.name,
          name_ar: editModal.name_ar,
        };
        dispatch(changeLoader(true));

        const response = await services.Unit.UPDATE_UNIT(editModal.id, data);
        if (response.data.ack) {
          setEditModal({
            isOpen: false,
            id: null,
            name: "",
            err: "",
            isValid: false,
          });
          setToaster({
            status: "success",
            text: `${response.data.msg}`,
            visibilty: true,
          });
          setTimeout(() => {
            setToaster({ ...toaster, visibilty: false });
          }, 6000);
          getAllUnits();
        }
        dispatch(changeLoader(false));
      } catch (error) {
        setToaster({
          status: "error",
          text: `${error.data.msg}`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllUnits();
        console.log(error);
      }
    }
  };

  const handleOnDelete = async () => {
    try {
      dispatch(changeLoader(true));
      const response = await services.Unit.DELETE_UNIT(deleteModal.id);
      if (response) {
        setDeleteModal({ id: null, isOpen: false });
        setToaster({
          status: "success",
          text: `Unit Deleted Successfuly`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllUnits();
      }
      dispatch(changeLoader(false));
    } catch (error) {
      if (error.response.status === 403) {
        setDeleteModal({ id: null, isOpen: false });
        setToaster({
          status: "error",
          text: `${error.response.data.msg}!`,
          visibilty: true,
        });
        setTimeout(() => {
          setToaster({ ...toaster, visibilty: false });
        }, 6000);
        getAllUnits();
      }
    }
  };

  const handleSorting = (field, order) => {
    setSortCriteria({ field, order });
  };

  useEffect(() => {
    if (permission.role !== "admin") {
      setCanCreate(permission?.uom?.create === 1)
      setCanEdit(permission?.uom?.edit === 1)
      setCanDelete(permission?.uom?.delete === 1)
    } else {
      setCanCreate(true);
      setCanEdit(true);
      setCanDelete(true);
    }
  }, [permission]);

  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          {toaster.visibilty && (
            <Alert severity={toaster.status}>{toaster.text}</Alert>
          )}
          <Box className="white-box-20">
            <div className="top-heading-search">
              <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
                Unit Of Measurement
              </Typography>

              <div className="right-filter">
                <TextField
                  id="outlined-size-small"
                  size="small"
                  value={searchUnit}
                  onChange={(e) => {
                    setSearchUnit(e.target.value);
                  }}
                  placeholder="Search By Unit"
                />

                { canCreate ?
                  <Button
                  variant="contained"
                  onClick={() => handleUnitModel(1)}
                  size="medius"
                  style={{ width: "200px" }}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Add Unit
                </Button>: null}
              </div>
            </div>
            {/* NoDataFound */}
            {unitData.length > 0?<TableContainer className="height-fix">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">
                        Unit
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => handleSorting("name", "asc")}
                          />
                          <ArrowDropDownIcon
                            onClick={() => handleSorting("name", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className="short-div">
                        Unit (Arabic)
                        <div className="short-box">
                          <ArrowDropUpIcon
                            onClick={() => handleSorting("unit", "asc")}
                          />
                          <ArrowDropDownIcon
                            onClick={() => handleSorting("unit", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell> */}

                    { canEdit || canDelete ?
                      <TableCell className="nowrap" align="center">
                      Action
                    </TableCell>: null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {getdataByKey(item?.unit_locales, "en", "name")}
                      </TableCell>

                      { canEdit || canDelete ?
                        <TableCell align="center" className="nowrap">
                        {canEdit?<Tooltip
                          title="Edit"
                          placement="top"
                          sx={{ marginRight: 1 }}
                        >
                          <BorderColorIcon
                            color="action"
                            onClick={() => {
                              getSpecificUnit(item?.id);
                              setEditModal({
                                ...editModal,
                                isOpen: true,
                              });
                            }}
                            fontSize="small"
                          />
                        </Tooltip>: null}
                        { canDelete ?
                          <Tooltip title="Delete" placement="top">
                          <DeleteForever
                            color="error"
                            fontSize="small"
                            onClick={() =>
                              setDeleteModal({ isOpen: true, id: item.id })
                            }
                          />
                        </Tooltip>: null}
                      </TableCell>: null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>: <NoDataFound
              text={"No Unit Found"}
            />}
            {count > 1 ? (
              <Pagination
                count={count}
                color="primary"
                onChange={handlePagination}
                page={page}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Dialog open={unitModel.isOpen} onClose={handleClose}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            {unitModel.text}
          </Typography>

          {/* <form> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Unit (English)"
                value={unit.val}
                onChange={(e) => inputChangeHandler(e)}
                onKeyUp={(e) => (e.key === "Enter" ? handleOnSave() : "")}
              />

              <TextField
                style={{ marginTop: "20px" }}
                required
                label="Unit (Arabic)"
                value={unit_ar.val}
                // defaultValue={editModal.name}
                onChange={(e) => inputChangeHandler(e, "ar")}
                onKeyUp={(e) => (e.key === "Enter" ? handleOnUpdate() : "")}
              />
            </Grid>

            {/* {!unit.isValid && <div className="error">{unit.err}</div>} */}
            <Grid item xs={12} textAlign="center">
              <Button
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "16px" }}
                onClick={() => handleOnSave()}
                disabled={!unit.isValid || !unit_ar.isValid}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* </form> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={editModal.isOpen}
        onClose={() => setEditModal({ ...editModal, isOpen: false })}
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setEditModal({ ...editModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Edit Modal
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Unit (English)"
                value={editModal.name}
                // defaultValue={editModal.name}
                onChange={(e) => editChangeHandler(e)}
                onKeyUp={(e) => (e.key === "Enter" ? handleOnUpdate() : "")}
              />

              <TextField
                className="modal-arabic-unit"
                style={{ marginTop: "20px" }}
                required
                label="Unit (Arabic)"
                value={editModal.name_ar}
                // defaultValue={editModal.name}
                onChange={(e) => editChangeHandler(e, "ar")}
                onKeyUp={(e) => (e.key === "Enter" ? handleOnUpdate() : "")}
              />
            </Grid>
            {/* {!editModal.isValid && (
                <div className="error">{editModal.err}</div>
              )} */}
            <Grid item xs={12} textAlign="center">
              <Button
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "16px" }}
                disabled={!editModal.isValid}
                onClick={() => handleOnUpdate()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteModal.isOpen}
        onClose={() => setDeleteModal({ ...deleteModal, isOpen: false })}
        className="alert-delete"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={() => setDeleteModal({ ...deleteModal, isOpen: false })}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" textAlign={"left"} mb={3}>
            Alert
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontSize={"16px"}
                  fontWeight={500}
                  mb={0}
                  textAlign={"left"}
                >
                  Are you sure you want to delete this Unit?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px" }}
                  onClick={() =>
                    setDeleteModal({ ...deleteModal, isOpen: false })
                  }
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginTop: "16px", marginLeft: "16px" }}
                  onClick={() => handleOnDelete()}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
