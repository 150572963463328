import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import GlobalService from "../services/GlobalService";
import { useNavigate } from "react-router-dom";

import services from "../services";

const CompleteModalTwo = ({
  handleClose,
  title,
  driverData,
  orderId,
  driverID,
  description,
  handleComplete,
}) => {
  const navigate = useNavigate();
  const [selectDriver, setSelectDriver] = useState("");

  const data =
    driverData?.length > 0 &&
    driverData.map((el) => {
      const { first_name, fleet_id } = el;
      return { first_name, fleet_id };
    });

  const CompleteOrder = async () => {
    try {
      const response = await GlobalService(
        services.Orders.COMPLETE_ORDER_BY_ADMIN(orderId, {
          status: "completed",
          fleet_id: selectDriver.toString(),
        })
      );
      handleComplete();
      // navigate("/order-listing");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        className="main-modal maxwidth-430"
      >
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "12px" }}>
            {title}
          </h2>
          <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>
            {description}
          </p>

          {driverID > 0 && data.length > 0? null : (
            <Autocomplete
              className="mb-6"
              fullWidth
              disablePortal
              id="combo-box-demo"
              options={data}
              getOptionLabel={(option) => option?.first_name+" "+option?.last_name}
              getOptionSelected={(option, value) =>
                option.fleet_id === value.fleet_id
              }
              renderInput={(params) => (
                <TextField {...params} label="Select Driver" />
              )}
              onChange={(e, value) => {
                setSelectDriver(value?.fleet_id);
              }}
            />
          )}

          <Boxed
            textAlign="center"
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Button
              type="button"
              size="medium"
              variant="text"
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>

            {driverID > 0 ? (
              <Button
                type="button"
                size="medium"
                variant="text"
                color="error"
                onClick={() => {
                  CompleteOrder();
                }}
              >
                Yes
              </Button>
            ) : (
              <Button
                type="button"
                size="medium"
                variant="text"
                color="error"
                onClick={() => {
                  
                  CompleteOrder();
                }}
                disabled={!selectDriver}
              >
                Yes
              </Button>
            )}
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CompleteModalTwo;
