import { Box } from "@mui/material";
import Header from "../layout/header/Header";
import Aside from "../layout/aside/Aside";
import { Grid, Typography } from "@mui/material";
import Maps from "./Maps";

const AddZone = () => {
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30">
            <Typography variant="h6" component="h6" mb={4} fontWeight={500}>
              Zone
            </Typography>
            <Grid container spacing={2}>
                <Maps/>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddZone;
